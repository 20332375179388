import React from 'react';
import ReactDom from 'react-dom';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import App from './components/App';
import Record from './recorder-app/Record';
import Thankyou from './components/thankyou';
import * as serviceWorker from './serviceWorker';
import Candidate from './proctoring-app/candidate';
// import Config from './config';
ReactDom.render(
  <BrowserRouter >
        <Switch>
        <Route exact path='/' component={App} />
        {/*
        <Route exact path='/join/:room/:userName/:userId/:callToken' component={App} />
        */
        }
        <Route exact path='/join/:room/:userName' component={App} />
        <Route exact path='/join/:room/:userName/:temphostid' component={App} />
        <Route exact path='/join/:room' component={App} />
        <Route exact path='/host/:room/:userName' component={App} />
        <Route exact path='/host/:room' component={App} />
        <Route exact path='/createroom' component={App} />
        <Route exact path='/thankyou' component={Thankyou} />
        <Route exact path='/record' component={Record} />
        <Route exact path='/proctoring/candidate'>
          <Candidate examinor="false"/>
        </Route>
        <Route exact path='/proctoring/examinor'>
          <Candidate examinor="true"/>
        </Route>
        </Switch>
   </BrowserRouter>
 , document.getElementById('root'))
serviceWorker.unregister();

