import UserSession from "./user-session";
import axios from 'axios';
import Config from "../config";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


var CryptoJS = require("crypto-js");
/* 
var encrypt = (msg,password)=>{
    return CryptoJS.AES.encrypt(msg, password).toString()
  }
*/
var decrypt =(msg,password)=>{
    return CryptoJS.AES.decrypt(msg, password).toString(CryptoJS.enc.Utf8)
}

var encrypt = (msg,password) => {
    return CryptoJS.AES.encrypt(msg, password);
}

var checkError = (res) => {
    if(res.status === 401) {
        toast.dark('Please check your credentials.' , {autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,});
    } else if (res.status === 403) {
        toast.dark('Please Verify your Email.' , {autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,});
        window.loginComponent.verifyAccount();
    } else if (res.status === 412) {
        window.verifyComponent.verifyValidateAccount();
        // toast.dark('Please Enter Valid PIN.' , {autoClose: 3000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,});
    } else if (res.status === 406) {
        toast.dark('Email ID already exists.' , {autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,});
    }  else if (res.status === 404) {
        const domain = `${Config.API_DOMAIN}/canstring/v1/sendEmailToAllUser`
        if(res.url === domain) {
            toast.dark('Sorry, Already mail send to all active users.' , {autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,});
        }
    }
}
var RequestHandler = (() => {
    var deleteAPI = (url,callback) => {
        const key = UserSession.getSession().user_id+url;
        if(localStorage.getItem(key)){
            callback(JSON.parse(localStorage.getItem(key)));
        }
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Accept': 'application/json','Content-Type': 'application/json','x-auth-token':UserSession.getSession().token}
        }
        fetch(url, {
            method: 'DELETE',
            headers: headers
        })
        .then((response) => response.json())        
        .then((responseData) => {
            if(Config.API_DOMAIN !== 'http://localhost:8080') {
                let decodedObj = JSON.parse(decrypt(responseData.encData,'WIiOiIxMjM0NTY3ODkwIiwibmFtZ'))
                // localStorage.setItem(key,JSON.stringify(decodedObj));
                console.log(`'URL :' ${url} 
                'RESPONSE :'${JSON.stringify(decodedObj)}`)
                callback(decodedObj);  
            } else {
                // console.log(JSON.stringify(responseData))
                callback(responseData);  
            }
        })       
        .catch(error => console.log(error));
    };
    var post = (url,param,callback) => {
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Content-Type': 'application/json','x-auth-token':UserSession.getSession().token}
        }
        let bodyParsed;
        if(Config.API_DOMAIN !== 'http://localhost:8080') {
            let encodedObj = encrypt(JSON.stringify(param),'WIiOiIxMjM0NTY3ODkwIiwibmFtZ')
            const encryptStr = { encData: encodedObj.toString() };
            bodyParsed = JSON.stringify(encryptStr);
        } else {
            bodyParsed = JSON.stringify(param);
        } 

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: bodyParsed
        };
        // console.log(encryptStr)
        fetch(url, requestOptions)
        .then((response) => { 
            if(response.status >= 400) {
                // console.log(response)
                return checkError(response);
                // throw new Error("Server responds with error!");
            }
            return response.json()
        })
        .then(
            (responseData) => {
                if(Config.API_DOMAIN !== 'http://localhost:8080') {
                    if(responseData){
                        
                        let decodedObj = JSON.parse(decrypt(responseData.encData,'WIiOiIxMjM0NTY3ODkwIiwibmFtZ'))
                        console.log('URL : '+url+' | PARAM:'+JSON.stringify(param)+'| RESPONSE : '+JSON.stringify(decodedObj))
                    // console.log(`'URL :' ${url} 
                    // 'PARAM :' ${JSON.stringify(param)}
                    // 'RESPONSE :'${JSON.stringify(decodedObj)}`)
                        callback(decodedObj);  
                    }else{
                        callback({message:'invalid request'});
                    }
                } else {
                    const mainRes = responseData
                    // console.log(JSON.stringify(mainRes))
                    callback(mainRes);
                }
              
        },
        (error) => {
            // toast.dark('Server Throw Error' , {autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,});
            console.log(error)
          }
        )
        .catch(error => { 
            console.log(error);
            //callback(error);
        });
    };
    var postPlain = (url,param,callback) => {
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Content-Type': 'application/json'}
        }
        let bodyParsed = JSON.stringify(param);
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: bodyParsed
        };
        // console.log(encryptStr)
        fetch(url, requestOptions)
        .then((response) => { 
            return response.json()
        })
        .then(
            (responseData) => {
                callback(responseData);  
        },
        (error) => {
           
            console.log(error)
          }
        )
        .catch(error => { 
            console.log(error);
            callback(error);
        });
    };
    var get = (url,callback) => {
        const key = UserSession.getSession().user_id+url;
        if(localStorage.getItem(key)){
            callback(JSON.parse(localStorage.getItem(key)));
        }
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Accept': 'application/json','Content-Type': 'application/json','x-auth-token':UserSession.getSession().token}
        }
        fetch(url, {
            method: 'GET',
            headers: headers
        })
        .then((response) => response.json())
        .then((responseData) => {
            if(Config.API_DOMAIN !== 'http://localhost:8080') {
                let decodedObj = JSON.parse(decrypt(responseData.encData,'WIiOiIxMjM0NTY3ODkwIiwibmFtZ'))
                console.log('URL : '+url+' | RESPONSE : '+JSON.stringify(decodedObj));
                console.log('###############################')
                // localStorage.setItem(key,JSON.stringify(decodedObj));
            //     console.log(`'URL :' ${url} 
            // 'RESPONSE :'${JSON.stringify(decodedObj)}`)
                callback(decodedObj);  
            } else {
                // console.log(JSON.stringify(responseData))
                callback(responseData);  
            }
        })
        .catch(error => {
            console.log(error);
            callback(error);
        });
    };

    var plainput = (url,callback) => {
        const key = UserSession.getSession().user_id+url;
        if(localStorage.getItem(key)){
            callback(JSON.parse(localStorage.getItem(key)));
        }
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Accept': 'application/json','Content-Type': 'application/json','x-auth-token':UserSession.getSession().token}
        }
        fetch(url, {
            method: 'PUT',
            headers: headers
        })
        .then((response) => response.json())
        .then((responseData) => {
            if(Config.API_DOMAIN !== 'http://localhost:8080') {
                let decodedObj = JSON.parse(decrypt(responseData.encData,'WIiOiIxMjM0NTY3ODkwIiwibmFtZ'))
                // localStorage.setItem(key,JSON.stringify(decodedObj));
            //     console.log(`'URL :' ${url} 
            // 'RESPONSE :'${JSON.stringify(decodedObj)}`)
                callback(decodedObj);  
            } else {
                // console.log(JSON.stringify(responseData))
                callback(responseData);  
            }
        })
        .catch(error => {
            console.log(error);
            callback(error);
        });
    };

    var patch = (url,callback) => {
        const key = UserSession.getSession().user_id+url;
        if(localStorage.getItem(key)){
            callback(JSON.parse(localStorage.getItem(key)));
        }
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Accept': 'application/json','Content-Type': 'application/json','x-auth-token':UserSession.getSession().token}
        }
        fetch(url, {
            method: 'PATCH',
            headers: headers
        })
        .then((response) => response.json())
        .then((responseData) => {
            if(Config.API_DOMAIN !== 'http://localhost:8080') {
                let decodedObj = JSON.parse(decrypt(responseData.encData,'WIiOiIxMjM0NTY3ODkwIiwibmFtZ'))
                // localStorage.setItem(key,JSON.stringify(decodedObj));
                callback(decodedObj);  
            } else {
                // console.log(JSON.stringify(responseData))
                callback(responseData);  
            }
        })
        .catch(error => {
            console.log(error);
            callback(error);
        });
    };

    var put = (url,param,callback) => {
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Content-Type': 'application/json','x-auth-token':UserSession.getSession().token}
        }
        let bodyParsed;
        if(Config.API_DOMAIN !== 'http://localhost:8080') {
            let encodedObj = encrypt(JSON.stringify(param),'WIiOiIxMjM0NTY3ODkwIiwibmFtZ')
            const encryptStr = { encData: encodedObj.toString() };
            bodyParsed = JSON.stringify(encryptStr);
        } else {
            bodyParsed = JSON.stringify(param);
        } 

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: bodyParsed
        };
        // console.log(encryptStr)
        fetch(url, requestOptions)
        .then((response) => {
            if(response.status >= 400) {
                return checkError(response);
            }
            return response.json();
        })
        .then(
            (responseData) => {
            if(Config.API_DOMAIN !== 'http://localhost:8080') {
            let decodedObj = JSON.parse(decrypt(responseData.encData,'WIiOiIxMjM0NTY3ODkwIiwibmFtZ'))
            // console.log(`'URL :' ${url} 
            // 'PARAM :' ${JSON.parse(JSON.stringify(param))}
            // 'RESPONSE :'${JSON.parse(JSON.stringify(decodedObj))}`)
            callback(decodedObj);  
        } else {
            const mainRes = responseData
            // console.log(JSON.stringify(mainRes))
            callback(mainRes);
        }
              
        })
        .catch(error => {
            // console.log(error)
            callback(error);
        });
    };

    var patchpayload = (url,param,callback) => {
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Content-Type': 'application/json','x-auth-token':UserSession.getSession().token}
        }
        let bodyParsed;
        if(Config.API_DOMAIN !== 'http://localhost:8080') {
            let encodedObj = encrypt(JSON.stringify(param),'WIiOiIxMjM0NTY3ODkwIiwibmFtZ')
            const encryptStr = { encData: encodedObj.toString() };
            bodyParsed = JSON.stringify(encryptStr);
        } else {
            bodyParsed = JSON.stringify(param);
        } 

        const requestOptions = {
            method: 'PATCH',
            headers: headers,
            body: bodyParsed
        };
        // console.log(encryptStr)
        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((responseData) => {
            if(Config.API_DOMAIN !== 'http://localhost:8080') {
            let decodedObj = JSON.parse(decrypt(responseData.encData,'WIiOiIxMjM0NTY3ODkwIiwibmFtZ'))
            // console.log(`'URL :' ${url} 
            // 'PARAM :' ${JSON.parse(JSON.stringify(param))}
            // 'RESPONSE :'${JSON.parse(JSON.stringify(decodedObj))}`)
            callback(decodedObj);  
        } else {
            const mainRes = responseData
            // console.log(JSON.stringify(mainRes))
            callback(mainRes);
        }
              
        })
        .catch(error => console.log(error));
    };

    return {
        deleteAPI: deleteAPI,
        post: post,
        put: put,
        plainput: plainput,
        get: get,
        patch: patch,
        postPlain:postPlain,
        patchpayload: patchpayload
    }
  
  })();
 export default RequestHandler;