import React, { Component } from 'react';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';
import InteractiveVideoComponent from './InteractiveVideoComponent';


class LessonListing extends Component {

    constructor(props) {
        super(props);
        let currentUserSession =  UserSession.getSession();
        if(!currentUserSession.device) {
            currentUserSession.device = '';
        }

        this.state = {
            curentloggedUser: { ...JSON.parse(localStorage.getItem('userData')) },
            userData: {...currentUserSession},
            lessonList: [],
            classId:this.props.classId,
            selectedClass:false //{view_Url:"dsfsfsdf"}
        }
        this.getLessonList(this.props.classId);
    }
    onUserselect = (user) => {
        this.setState({selectedClass:false})
        this.getLessonList(user.group_id)
    }
    getLessonList = (classId) => {
        // RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/classList?offset=0&limit=25&sort=time'
            RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/interactiveVideoList?offset=0&limit=25&classId='+classId
            , (response) => {
                if (response.success === true) {
                    console.log(JSON.stringify(response.recordList))
                    this.setState({ lessonList: response.recordList})
                } else {
                    this.setState({ lessonList: []})
                }
            })
    }
    backToSchedule = () =>{
        this.props.backToSchedule();
    }
    backto = () =>{
        this.setState({selectedClass:false})
    }
    onSelectClass = (lesson) =>{
        this.setState({selectedClass:lesson})
    }
    render() {
        this.currentUser = JSON.parse(localStorage.getItem('currentuserData'));
        const {selectedClass} = this.state;
        if(selectedClass){
            return (
            
                <div>
                    <header className="top-box-shadow">
                        <div className=" chats width100">
                            <div className="content-wrapper is-clearfix">
                                <div className="is-clearfix is-pulled-right mar-t-5">
                                    <a href='#' data-toggle="modal" data-target="#shedule-modal" className='button is-small is-link' onClick={this.backToSchedule}>
                                        <span className='icon is-small'><i className='fa fa-calendar-check-o'></i></span> <span>Go to Schedule</span></a>
                                        <a href='#' data-toggle="modal" data-target="#shedule-modal" className='button is-small is-link marg-left-10' onClick={this.backto}>
                                        <span className='icon is-small '><i className='fa fa-info-circle'></i></span> <span>Back</span></a>
                                   </div>
                            </div>
                        </div>
                    </header>
                    <InteractiveVideoComponent classInfo={selectedClass}></InteractiveVideoComponent>
                </div>
            )
        }else{
            return (
            
                <div>
                    <header className="top-box-shadow">
                        <div className=" chats width100">
                            <div className="content-wrapper is-clearfix">
                                <div className="is-clearfix is-pulled-right mar-t-5">
                                    <a href='#' data-toggle="modal" data-target="#shedule-modal" className='button is-small is-link' onClick={this.backToSchedule}>
                                        <span className='icon is-small'><i className='fa fa-calendar-check-o'></i></span> <span>Go to Schedule</span></a>
                                    <a className="is-small pad-10 hide"><i aria-hidden="true" className="fa fa-info-circle is-size-6"></i></a>
                                </div>
                            </div>
                        </div>
                    </header>
                    {
                       
                        this.state.lessonList.map((lesson, index) => {
                            return(
                                <div className="lesson-card pointer" key={index} onClick={this.onSelectClass.bind(this,lesson)}>
                                    <img src={lesson.thumb_Url} />
                                    <div className="card-info">
                                       <div className="card-title">{lesson.message.length > 12 ? lesson.message.substr(0,12)+'..':lesson.message}</div>
                                       <div>{lesson.shared_by}</div>
                                       <div>{lesson.created_at}</div>
                                       <div>{lesson.duration}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        

    }
}

export default LessonListing;
