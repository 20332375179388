import React, { Component } from 'react';
import Header from './Header';
import ChatUsers from './ChatUsers';
import CreateUsers from './CreateUser';
// import CreateUserForm from './CreateUserForm';
import SingleTextChatWindow from './SingleTextChatWindow';
import OnGoingCallWindow from './OnGoingCallWindow';
import CallOpenWindow from './CallOpenWindow';
import NewChatUserList from './NewChatUserList';
import RequestHandler from '../helpers/request-handler';
import Config from '../config';
import CallNotification from './CallNotification';
import SocketHandler from '../helpers/socket-service';
import UserSession from '../helpers/user-session';
import Popup from './Popup';
import CommonFunctions from '../helpers/common-functions';
import CallOpenMediaServer from './CallOpenMediaServer';
import { Redirect } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
// import SessionListing from './SessionListing';
class ChatParent extends Component {
  constructor(props) {
    super(props);
    this.state = {viewState:'welcom',viewCreate: false, toggleUserList:false, showUserList:false,rignerInfo:false,currentChatUser:false,popup:'',popData:{},chatUserListShown:true,room:'',redirect:false}
    this.initializeTextChat = false;
    /**
     * Listening
     */
    SocketHandler.getSocket((socket)=>{
        socket.on('onReceiveSendToAll',(data) => {
            data =  JSON.parse(data);
            switch (data.method) {
                case 'onNewCallAlert':
                    console.log('onNewCallAlert => '+data.senderId+'//'+data.senderName);
                    if(data.receiverId === UserSession.getSession().user_id){
                      this.setState({rignerInfo:{user_id:data.senderId,first_name:data.senderName}})
                      this.playAudio();
                    }
                break;
                case 'onCallAccepted':
                     console.log('onCallAccepted => '+data.receiverId+'//'+UserSession.getSession().user_id);
                    if(data.receiverId === UserSession.getSession().user_id){
                      this.setState({viewState:'callOpened'});
                      this.refs.callOpenComp.onAcceptCallReply(data.room);
                      let callStateBeforeReload = {room:data.room,viewState:'callOpened',popup:false,currentChatUser:{full_name:data.senderName,user_id:data.senderId}};
                      CommonFunctions.setItem('callStateBeforeReload',callStateBeforeReload,1000000000000)
                    }
                break;
                case 'onRingRejected':
                  console.log('onRingRejected => '+data.receiverId+'//'+UserSession.getSession().user_id);
                  if(data.receiverId === UserSession.getSession().user_id){
                    let viewstt = this.initializeTextChat ? 'textchat': 'initial';
                    this.setState({viewState:viewstt,rignerInfo:false});
                    this.stopAudio();
                  }
                break;
                case 'onListRecordings':
                  this.setState({popup:'recordings',popData:data.recordings})
                break;
            }
        });
    })
  }

  componentDidMount = ()=> {
    if(CommonFunctions.getItem('callStateBeforeReload')){
        let callStateBeforeReload = CommonFunctions.getItem('callStateBeforeReload');
        this.setState(callStateBeforeReload)
        this.refs.callOpenComp.onAcceptCallReply(callStateBeforeReload.room);
        CommonFunctions.setItem('callStateBeforeReload',callStateBeforeReload,1000000000000)
    }
  }
  playAudio = () => {
    let audio = document.getElementById('testaudio');
    audio.play();
    audio.loop = true;
  }
  stopAudio = () => {
      try{
      let audio = document.getElementById('testaudio');
      audio.pause();
      audio.currentTime = 0;
      }catch(e){
          
      }
  } 
  onSetSession = ()=>{
    SocketHandler.onAddUser({user_id:UserSession.getSession().user_id,userName:UserSession.getSession().userDetail.first_name});
  }
  
  componentWillUnmount = ()=> {
    
  }
  onLogout = ()=> {
    this.props.onLogout();
  }
  onStartCall = ()=> {
      this.setState({viewState:'calling'});
      let sendData = {method:'onNewCallAlert',receiverId:this.state.currentChatUser.user_id,senderId:UserSession.getSession().user_id,senderName:UserSession.getSession().userDetail.first_name};
      SocketHandler.sendToAll(sendData);
  }
  alert = (msg) =>{
    this.setState({popup:'alert',popData:{alert:msg}});
  }
  userOffileCall = ()=>{
    this.setState({popup:'alert',popData:{alert:'User is not available for the call !'}});
  }
  attendCall = (user_id,first_name)=> {
    
      this.setState({currentChatUser:{full_name:first_name,user_id:user_id},viewState:'callOpened',rignerInfo:false})
      this.refs.callOpenComp.onAcceptRing(user_id,(room)=>{
        SocketHandler.createRoom({room:room,userId:UserSession.getSession().userDetail.user_id});
        let sendData = {method:'onCallAccepted',room:room,receiverId:user_id,senderId:UserSession.getSession().user_id,senderName:UserSession.getSession().userDetail.first_name};
        SocketHandler.sendToAll(sendData);
        let callStateBeforeReload = {room:room,viewState:'callOpened',popup:false,currentChatUser:{full_name:first_name,user_id:user_id}};
        CommonFunctions.setItem('callStateBeforeReload',callStateBeforeReload,1000000000000)
        
      });
      this.stopAudio();

  }
  rejectRinging = ()=>{
       let sendData = {method:'onRingRejected',receiverId:this.state.rignerInfo.user_id,senderId:UserSession.getSession().user_id,senderName:UserSession.getSession().userDetail.first_name};
       SocketHandler.sendToAll(sendData);
       this.setState({rignerInfo:false});
       this.stopAudio();
  }
  onHangupCall = (user)=> {
      if(user.user_id){
        this.onSelectChatUser(user)
      }
      let viewstt = this.initializeTextChat ? 'textchat': 'initial';
      this.setState({viewState:viewstt});
      localStorage.removeItem('callStateBeforeReload')
      this.stopAudio();
  }
  inviteUers = (room) => {
     this.setState({popup:'invite',popData:{room:room}})
  }
  editName = (name,userId,room) => {
    this.setState({popup:'editName',popData:{name:name,userId:userId,room:room}})
}
  
  createNewRoom = () =>{
    this.setState({popup:'createRoom',popData:{}})
  }
  onHangupRinging = (user) => {
    if(user.user_id){
      this.onSelectChatUser(user)
    }
    let sendData = {method:'onRingRejected',receiverId:user.user_id,senderId:UserSession.getSession().user_id,senderName:UserSession.getSession().userDetail.first_name};
    SocketHandler.sendToAll(sendData);
    let viewstt = this.initializeTextChat ? 'textchat': 'initial';
    this.setState({viewState:viewstt});
  }
  onNewChat = ()=> {
      this.setState({viewState:'newchat'});
  }
  onCloseChat = ()=> {
    let viewstt = this.initializeTextChat ? 'textchat': 'welcom';
      this.setState({viewState:viewstt});
  }
  onClosePopup = () =>{
    this.setState({popup:false});
  }
  joinInstantRoom =(room) =>{
    this.setState({popup:false});
  }
  getCurrentChatUsers = ()=>{
    // const userDate = JSON.parse(localStorage.getItem('userData'));
    this.refs.chatlistUpdate.getCurrentChatUsers('time', false);
  }
  onSelectChatUser = (user)=>{
    let chatUserListShown = true;
    let chatToggleList = false;
    if(window.innerWidth < 769){
      // chatUserListShown = false;
      chatToggleList = true;
    }
    this.setState({currentChatUser:user,chatUserListShown:chatUserListShown,viewState:'textchat', toggleUserList: chatToggleList})
    this.refs.singleChatWindow.onNewUserSelect(user)
    this.initializeTextChat = true;

  }
  onBackToList = () =>{
    this.setState({chatUserListShown:true})
  }
  notAutherized = (msg)=>{
    alert(msg)
  }
  openDocumentPop = (room) =>{
    this.setState({popup:'docshare',popData:{room:room}})
  }

  toggleList = () => {
    console.log('parent hit');
    this.setState(prevState => ({
      toggleUserList: false
  }));
  }

  renderChat = ()=>{
    if(this.state.currentChatUser){
      return(
          <SingleTextChatWindow
              onStartCall={this.onStartCall}
              currentChatUser={this.state.currentChatUser}
              ref="singleChatWindow"
              />
      )
    }else{
      return(
        ''
      )
    }
  }
  renderRiging = ()=>{
    if(this.state.rignerInfo){
      return(
        <CallNotification
        rignerInfo={this.state.rignerInfo}
        attendCall = {this.attendCall}
        rejectRinging={this.rejectRinging}/>
      )
    }else{
      return(
        ''
      )
    }
  }
  renderCallWindow = () =>{
    if(Config.MEDIA_SERVER){
      return(
            <CallOpenMediaServer
              currentChatUser={this.state.currentChatUser}
              onHangupCall = {this.onHangupCall}
              inviteUers = {this.inviteUers}
              alert = {this.alert}
              ref={(callOpenComp) => this.callOpenComp = callOpenComp}
              notAutherized={this.notAutherized}
              openDocumentPop={this.openDocumentPop}
              editName = {this.editName}
              />
      )
    }else{
      return(
        <CallOpenWindow
          currentChatUser={this.state.currentChatUser}
          onHangupCall = {this.onHangupCall}
          inviteUers = {this.inviteUers}
          alert = {this.alert}
          ref="callOpenComp"
          notAutherized={this.notAutherized}
          />
  )
    }
                
  }
  onGetRecordings = () =>{
    SocketHandler.getRecordings({});
  }
  shareDocument = (fileName,totalPages) =>{
    this.callOpenComp.shareDocument(fileName,totalPages);
  }
  createNewUser = () => {
    console.log('createUser')
    this.setState({viewState:'newuser'});
  }
  handleShow = () => {
    this.setState({
      viewCreate: true,
      viewState: 'users'
    });
  };

  backtoChat = () => {
    this.setState({
      viewCreate: false,
      viewState: 'welcom'
    });
  }
  render() {
    if(this.state.redirect){
      return <Redirect to={this.state.redirect} />
    }
    return (
        <div>
            <Header onLogout={this.onLogout} onSetSession={this.onSetSession}
            onGetRecordings={this.onGetRecordings}/>
            <app-menu-options class="flex-grid tab-view mobile-wrap">
                <div class="" className={this.state.toggleUserList || this.state.showUserList ? 'right-chevern flex-grid width100 main-cht-area': 'flex-grid width100 main-cht-area'}>
                {!this.state.viewCreate && !this.state.toggleUserList ? <section className={(this.state.viewState === 'callOpened' || !this.state.chatUserListShown) ? "hide":"chat-user-wrap"}>
                    
                    <ChatUsers
                    onNewChat = {this.onNewChat}
                    createNewRoom = {this.createNewRoom}
                    onSelectChatUser = {this.onSelectChatUser}
                    viewCreate = {this.handleShow}
                    ref="chatlistUpdate"
                    /> 
                    </section> : null }
                    {this.state.viewCreate ? <section className={(this.state.viewState === 'callOpened' || !this.state.chatUserListShown) ? "hide":"chat-user-wrap width100"}>
                    <CreateUsers
                    createNewUser = {this.createNewUser} 
                    openViewChat = {this.backtoChat}
                    /> 
                    </section> : null }
                    <section className={this.state.viewState === 'callOpened' ? 'width100':'hide'}>
                      {this.renderCallWindow()}
                    </section>
                    <section className={this.state.viewState === 'calling' ? 'width100':'hide'}>
                      <OnGoingCallWindow
                      currentChatUser={this.state.currentChatUser}
                      onHangupRinging = {this.onHangupRinging}
                      />
                      </section>
                      <section className={this.state.viewState === 'textchat' ? 'width100':'hide'}>
                      {!this.state.viewCreate ?
                      <SingleTextChatWindow
                       onStartCall={this.onStartCall}
                       currentChatUser={this.state.currentChatUser}
                       userOffileCall={this.userOffileCall}
                       onBackToList = {this.onBackToList}
                       connectUserList = {this.toggleList}
                       ref="singleChatWindow"
                      /> : null }
                      </section>
                      {!this.state.viewCreate ? <section className={this.state.viewState === 'newchat' ? 'width100':'hide'}>
                      <NewChatUserList
                      onCloseChat={this.onCloseChat}
                      onSelectChatUser = {this.onSelectChatUser}
                      updateChatList = {this.getCurrentChatUsers}
                      />
                      </section> : null}
                      {/* <section className={this.state.viewState === 'newuser' ? 'width100 flex-grid':'hide'}>
                        
                      </section> */}
                      <section className={this.state.popup? 'pop-parent':'hide'}>
                      <Popup
                        onClosePopup={this.onClosePopup}
                        popup={this.state.popup}
                        popData = {this.state.popData}
                        joinInstantRoom = {this.joinInstantRoom}
                        shareDocument = {this.shareDocument}
                      />
                      
                      </section>
                      {/* <section className={this.state.viewState === 'initial' ? 'width100':'hide'}> */}
                        {/* <SessionListing /> */}
                      {/* </section> */}
                      {!this.state.viewCreate ? <div class={this.state.viewState === 'welcom' ? "empty-state":"hide"}>
                        <div class="has-text-centered box">
                            <img src="/files/chat_empty.png"/>
                            <p>So nice to meet you, Stay tuned for exciting events heading YOUR way!</p>
                            
                            {/* <a onClick={this.createNewRoom} class="button is-link mar-top15 newmeeting"><span class="icon"><i class="fa fa-plus"></i></span><span> New Meeting</span></a> */}
                            {/* <a onClick={this.onNewChat} class="button is-link mar-top15"><span class="icon"><i class="fa fa-plus"></i></span><span> New Conversation</span></a> */}
                        </div>
                      </div> : null}
                    
                </div>
            </app-menu-options>
            {/* <ToastContainer/><ToastContainer /> */}
            
            {this.renderRiging()}
            <audio id="testaudio" width="1" height="1" >
                <source src="/files/ring.mp3" type="audio/mpeg" loop="true"/>
            </audio>
            
        </div>
       )
  }
}
export default ChatParent;