import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import RequestHandler from '../helpers/request-handler';
import Config from '../config';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
// import "./Form.css";

class CreateGroupForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            group_name: '',
            group_desc: '',
            user_Ids: [],
            activeUserList: [],
            userIdList: [],
            userList: [],
            groupDetail: {},
            addUserView: false,
            updateFormView: false,
            deleteModal: false
        };
    }

    componentWillMount = () => {
        this.validator = new SimpleReactValidator();
    }

    componentDidMount = () => {
        // this.getactiveUserList();
    }

    getactiveUserList = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/activeUsers?offset=0&limit=25', (response) => {
            if (response.success === true) {
                this.setState({ activeUserList: response.userList });
            } else {
                console.log(response.message);
            }
        })
    }

    onGroupselect = (group) => {
        this.setState(this.getClearUser())
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/currentRoom?groupId=' + group.group_id, (response) => {
            if (response.success === true) {
                // const userIdList = response.groupMemberList.map(user => {
                //     const Obj= Object.assign({},user);
                //     Obj.first_name= user.full_name
                //     return Obj;
                //   });
                this.setState({ groupDetail: response.group_detail, updateFormView: true, userIdList: [] })
                this.setState(this.setFormValue());
                this.groupUserList();
                this.getNonGroupMembers();
            } else {
                console.log(response.message);
            }
        });
    }

    groupUserList = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/chats/groupMemberList?offset=0&limit=25&groupId=' + this.state.groupDetail.chat_group_id, (response) => {
            if (response.success === true) {
                // const userIdList = response.groupMemberList.map(user => {
                //     const Obj= Object.assign({},user);
                //     Obj.first_name= user.full_name
                //     return Obj;
                //   });
                this.setState({ userList: response.groupMemberList })
            } else {
                console.log(response.message);
            }
        });
    }

    getNonGroupMembers = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/chats/getNonGroupMemberList?groupId=' + this.state.groupDetail.chat_group_id + '&offset=0&limit=25', (response) => {
            if (response.success === true) {
                const userIdList = response.memberList.map(user => {
                    const Obj = Object.assign({}, user);
                    Obj.first_name = user.full_name
                    return Obj;
                });
                this.setState({ activeUserList: userIdList });
            } else {
                console.log(response.message);
            }
        });
    }

    deleteModal = () => {
        this.setState({ deleteModal: true });
    }

    deleteCancelModal = () => {
        this.setState({ deleteModal: false });
    }

    deleteGroup = () => {
        RequestHandler.deleteAPI(Config.API_DOMAIN + '/canstring/v1/groupRoom/' + this.state.groupDetail.chat_group_id, (response) => {
            if (response.success === true) {
                // const userObj = {...this.state.groupDetail}
                // userObj.acc_active = response.acc_active
                // this.setState({ groupDetail:userObj })
                this.setState({ deleteModal: false });
                this.hideUserForm();
                this.handleGroupList();
                // this.setState(this.setFormValue());
                toast.dark(response.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                console.log(response.message);
            }
        });
    }

    deleteUser = (user, ind) => {
        if (this.state.userList.length > 1) {
            RequestHandler.deleteAPI(Config.API_DOMAIN + '/canstring/v1/groupUser/' + this.state.groupDetail.chat_group_id + '/' + user.user_id, (response) => {
                if (response.success === true) {
                    // const userObj = {...this.state.groupDetail}
                    // userObj.acc_active = response.acc_active
                    // this.setState({ groupDetail:userObj })
                    // this.hideUserForm();
                    // this.setState(this.setFormValue());
                    const array = [...this.state.userList]
                    array.splice(ind, 1);
                    this.setState({ userList: array });
                    this.getNonGroupMembers();
                    this.handleGroupList();
                    toast.dark(response.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    console.log(response.message);
                }
            });
        } else {
            toast.dark('Group must be need one user.', {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    handleChange = (param, event) => {
        let stateObj = {};
        stateObj[param] = event.target.value;
        this.setState(stateObj);
    };

    handleGroupList = () => {
        this.props.getGroupList();
    }

    hideUserForm = () => {
        this.setState({ groupDetail: {}, acc_active: null, updateFormView: false, userIdList: [] });
        this.setState(this.getClearUser());
        this.validator.hideMessages();
        this.props.cancelform();
    }

    selectUserId = (userId) => {
        const arr = [...this.state.activeUserList]
        if (this.state.userIdList.indexOf(userId) > -1) {
            this.state.userIdList = _.without(this.state.userIdList, userId);
            this.setState({ activeUserList: arr });
            return;
        } else {
            this.state.userIdList.push(userId);
            this.setState({ activeUserList: arr });
            return;
        }
    };

    getClearUser = () => {
        return {
            group_name: '',
            group_desc: ''
        }
    }

    setFormValue = () => {
        return {
            group_name: this.state.groupDetail.chat_group_name,
            group_desc: this.state.groupDetail.chat_description
        }
    }

    addUser = () => {
        this.setState({ addUserView: true });
    }

    updatedUserList = () => {
        this.setState({ addUserView: false });
    }

    cancelAddUser = () => {
        this.setState({ addUserView: false, userIdList: [] });
    }

    createNewGroup = () => {
        if (this.validator.allValid()) {
            if (this.state.userIdList.length !== 0) {
                const payload = {
                    chat_group_name: this.state.group_name,
                    chat_description: this.state.group_desc,
                    user_Ids: this.state.userIdList
                }
                // console.log('payload', payload)
                RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/chats/createChatGroup', payload, (response) => {
                    if (response.success) {
                        this.handleGroupList();
                        this.setState(this.getClearUser());
                        this.setState({ userIdList: [] });
                    }
                    toast.dark(response.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            } else {
                toast.dark('You must add one user!', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            this.validator.showMessages();
            // toast.dark('Fill the Mandatory fields', {autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,});
            this.forceUpdate();
        }
    }

    updateGroup = () => {
        if (this.validator.allValid()) {
            if (this.state.userList.length !== 0 || this.state.userIdList.length !== 0) {
                let userArr = [];
                for (let i = 0; i < this.state.userIdList.length; i++) {
                    this.state.activeUserList.map((user) => {
                        if (user.user_id === this.state.userIdList[i]) {
                            return userArr.push(user);
                        }
                    })
                }
                const userIdList = userArr.map(user => {
                    return Object.assign({},
                        {
                            user_id: user.user_id,
                            is_selected: true
                        });
                });
                const payload = {
                    chat_group_name: this.state.group_name,
                    chat_description: this.state.group_desc,
                    userIdList: userIdList
                }

                RequestHandler.put(Config.API_DOMAIN + '/canstring/v1/groupsUsers/' + this.state.groupDetail.chat_group_id, payload, (response) => {
                    if (response.success) {
                        this.handleGroupList();
                        const copyPreUser = [...this.state.userList];
                        const mergeArr = [...copyPreUser, ...userArr];
                        this.setState({ userList: mergeArr });
                        this.setState({ userIdList: [] });
                        this.getNonGroupMembers();
                    }
                    toast.dark('Group details updated successfully', {
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            } else {
                toast.dark('You must add one user!', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div className={this.props.viewForm ? 'col th-p notification-content-wrapper' : 'hide'}>


                <header class="top-box-shadow">
                    <div class="columns is-gapless is-vcentered is-mobile">
                        {this.state.updateFormView ? <div className='column'><div class="is-pulled-left">
                            <div className='empty-head-icon display-ib'>
                                {this.state.groupDetail.chat_group_name.charAt(0).toUpperCase()}
                            </div>
                            <span className='is-size-6 has-text-weight-semibold'>{this.state.groupDetail.chat_group_name}</span>

                        </div>
                            <div class="is-pulled-right userandgroupDetails" >
                                <a class="button is-small " onClick={this.deleteModal}>
                                    <i class="fa fa-trash-o " aria-hidden="true"></i> Delete Group
                            </a>
                            </div>

                        </div> : null}

                        {!this.state.updateFormView ? <div class="is-pulled-left">
                            <span className='is-size-6 has-text-weight-semibold'>Group Creation</span>
                        </div> : null}
                    </div>
                </header>


                { !this.state.updateFormView ?
                    <div className="contents is-marginless">
                        <div className="scroll-bar">
                            <div className="column">
                                <div className="columns">


                                    <div className="column is-half is-offset-one-quarter">
                                        <div className='card'>
                                            <div className='card-content'>
                                                <div className='content form-style'>
                                                    <h3>Group Creation</h3>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Group Name <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.group_name}
                                                                        onChange={this.handleChange.bind(this, "group_name")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className="help is-danger height20">
                                                                {this.validator.message('Group Name', this.state.group_name, 'required')}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Description</label>
                                                            <div className="control">
                                                                <textarea
                                                                    style={{ maxHeight: '70%' }}
                                                                    className="textarea"
                                                                    value={this.state.group_desc}
                                                                    onChange={this.handleChange.bind(this, "group_desc")}
                                                                    rows='1'
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="columns display-flex is-clearfix">

                                                        <div className="column is-4">
                                                            <button className="button is-link is-right mar-0-10" onClick={this.addUser} disabled={this.state.group_name ? false : true}>Add User</button>
                                                            <p className={this.state.userIdList.length > 0 ? 'display-ib mar-10-0' : 'hide'}><em>{this.state.userIdList.length + ' User Added'}</em></p>
                                                        </div>
                                                        <div className="column is-8 has-text-right">
                                                            {/* <button className="button is-link is-right mar-0-10" onClick={this.addUser} disabled={this.state.group_name ? false : true}>Add User</button> */}
                                                            <button className="button is-right mar-0-10" onClick={this.hideUserForm}>Cancel</button>
                                                            <button className="button is-link" onClick={this.createNewGroup}>Save</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

                { this.state.updateFormView ?
                    <div className="contents is-marginless">
                        <div className="scroll-bar">
                            <div className="column">
                                <div className="columns">


                                    <div className="column is-half is-offset-one-quarter">
                                        <div className='card'>
                                            <div className='card-content'>
                                                <div className='content form-style'>
                                                    <h3>Group Details</h3>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Group Name <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.group_name}
                                                                        onChange={this.handleChange.bind(this, "group_name")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className="help is-danger height20">
                                                                {this.validator.message('Group Name', this.state.group_name, 'required')}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Description</label>
                                                            <div className="control">
                                                                <textarea
                                                                    style={{ maxHeight: '70%' }}
                                                                    className="textarea"
                                                                    value={this.state.group_desc}
                                                                    onChange={this.handleChange.bind(this, "group_desc")}
                                                                    rows='1'
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="columns display-flex is-clearfix">

                                                        <div className="column is-4">
                                                            <button className="button is-link is-right mar-0-10" onClick={this.addUser} disabled={this.state.group_name ? false : true}>Add User</button>
                                                            <p className={this.state.userIdList.length > 0 ? 'display-ib mar-10-0' : 'hide'}><em>{this.state.userIdList.length + ' User Added'}</em></p>
                                                        </div>
                                                        <div className="column is-8 has-text-right">
                                                            {/* <button className="button is-link is-right mar-0-10" onClick={this.addUser} disabled={this.state.group_name ? false : true}>Add User</button> */}
                                                            <button className="button is-right mar-0-10" onClick={this.hideUserForm}>Cancel</button>
                                                            <button className="button is-link" onClick={this.updateGroup}>Update</button>
                                                        </div>

                                                    </div>

                                                    <div class="columns userList">
                                                        <div class="column">
                                                            <div class="field pos-relative scroll-bar">
                                                                <div class="panel">
                                                                    <div class="column">
                                                                        <h3>Group Users</h3>
                                                                        <div class="field pos-relative scroll-bar" >
                                                                            <div class="panel">
                                                                                {this.state.userList.map((user, index) => {
                                                                                    return (
                                                                                        <label class="panel-block pos-relative">
                                                                                            <div class="display-ib">
                                                                                                <div className="empty-img">{user.full_name.charAt(0).toUpperCase()}</div>
                                                                                            </div>
                                                                                            <span className='display-b is-capitalized mar-0-10'>{user.full_name}</span>
                                                                                            {/* <span className={this.state.userIdList.indexOf(user.user_id) > -1 ? 'icon is-small icon-align is-pulled-right' : 'hide'}>
                                                                            <i class="fa fa-check"></i>
                                                                        </span> */}
                                                                                            <a class="icon is-small icon-align is-pulled-right" onClick={this.deleteUser.bind(this, user, index)}>
                                                                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                                                                            </a>
                                                                                        </label>
                                                                                    )
                                                                                }
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null}


                {/* User list modal */}
                <div className={this.state.addUserView ? 'is-active modal' : 'hide'}>
                    <div class="modal-background"></div>
                    <div class="modal-card">
                        <div class="modal-card-head">
                            <p class="modal-card-title">Select User</p>
                        </div>
                        <section class="modal-card-body">
                            <div class="content">
                                <div class="columns">
                                    <div class="column">
                                        <div class="field">
                                            {/* <div class="panel-block">
                <p class="control has-icons-left has-icons-right">
                  <input class="input is-small" type="text" placeholder="search" />
                  <span class="icon is-small is-left">
                    <i class="fa fa-search"></i>
                  </span>
                  <span class="icon is-small is-right">
                    <i class="fa fa-close"></i>
                  </span>
                </p>
              </div> */}

                                            <div class="panel panel-overflow scroll-bar">
                                                {this.state.activeUserList.map((user, index) => {
                                                    return (
                                                        <label class="panel-block pos-relative" onClick={this.selectUserId.bind(this, user.user_id)}>
                                                            <div class="display-ib">
                                                                <div className="empty-img">{user.first_name.charAt(0).toUpperCase()}</div>
                                                            </div>
                                                            {user.first_name}
                                                            <span className={this.state.userIdList.indexOf(user.user_id) > -1 ? 'icon is-small icon-align is-pulled-right' : 'hide'}>
                                                                <i class="fa fa-check"></i>
                                                            </span>
                                                        </label>
                                                    )
                                                }
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="modal-card-foot">
                            <div class="column has-text-right">
                                <button type="button" class="button is-right mar-0-10" onClick={this.cancelAddUser}>Cancel</button>
                                <button type="submit" class="button is-link is-right" onClick={this.updatedUserList}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={this.state.deleteModal ? 'is-active modal delete-modal' : 'hide'}>
                    <div class="modal-background"></div>
                    <div class="modal-content" style={{ paddingLeft: 15, paddingRight: 15, width: 540 }}>
                        <h2>Confirmation</h2>
                        <p>Are you sure want to Delete?</p>
                        <div class="column has-text-right">
                            <button type="button" class="button is-right mar-0-10" onClick={this.deleteCancelModal}>Cancel</button>
                            <button type="submit" class="button is-danger is-right" onClick={this.deleteGroup}>Delete</button>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close"></button>
                </div>
            </div>
        );
    }
}

export default CreateGroupForm;
