import React, { Component } from 'react';
import { Redirect, Route } from 'react-router';
import ReactTooltip from 'react-tooltip';
import Config from '../config';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';
import Header from '../components/Header';
import SocketHandler from '../helpers/socket-service';
class Candidate extends Component {

    constructor(props) {
        super(props);
        this.state = { appstate: 'stopped', botAnsewer: false, toquestion: false , languge:'en-IN',loader:'',
                       candidateStreams:{},testStarted:false,testTimeDisplay:'00:00',testEnabled:false,currentChat:'',
                       receivedMessage : '',isMessageOpen:false}
        this.room = '';
        this.examinor =  this.props.examinor;
        this.onlineExam = true;
        this.userId = '100';
        this.userName = 'nithin';
        this.testTime = 0;
        this.selectedUserStream = false;
        this.onListenSocket();
    }

    componentDidMount = () => {
        this.initConference();
        if(window.proTimer){
            clearInterval(window.proTimer)
        }
        window.proTimer = setInterval(() => {
            if(this.state.testStarted){
                 this.testTime++;
                 this.setState({testTimeDisplay:this.toHHMMSS(this.testTime)})
            }
            if(this.examinor === 'false'){
                let sendData = {method:'updateCandidateTime',room:this.room,time:this.state.testTimeDisplay,userId:this.userId};
                SocketHandler.sendToAll(sendData);
            }
        }, 1000);
    }
    toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60
    
        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
    }
    componentWillUnmount = () => {
        
    }
    initConference = () =>{
        this.setState({loader:'CONNECTING'})
        this.conference = new window.Owt.Conference.ConferenceClient(this.configuration);
        this.createToken();
    }
    createToken = () =>{
        var body = {
            room: this.room,
            user: 'user',
            role: 'presenter'
        };
        this.send('POST', '/tokens/', body, this.onTokenResponse.bind(this));
    }
    onTokenResponse = (token)=>{
        let connected = false;
        this.token = token;
        try{
            if (token) {
                var respOb = JSON.parse(atob(token));
                if (respOb.secure == true) {
                    this.connectionSuccess = true;
                    //alert('Server connection successfull');
                    this.joinConference();
                    connected = true;
    
                }else{
                   // this.props.notAutherized('Un authorized')
                   alert('Un authorized')
                }
            }
        }catch(e){
            alert('Un authorized')
        }
    }
    joinConference= () => {
        this.conference.join(this.token).then(resp => {
            this.setState({loader:false})
            if(this.examinor == 'true'){
                var streams = resp.remoteStreams;
                let candidateStreams = [];
                for (const stream of streams) {
                    if (stream.source.audio === 'mixed' || stream.source.video ===
                        'mixed') {
                        //this.subscribeStream(stream, true);
                    } else {
                        let streamAttributes = stream.attributes 
                        candidateStreams = this.state.candidateStreams;
                        let userStreams = {};
                        if(candidateStreams[streamAttributes.userId]){
                            userStreams = candidateStreams[streamAttributes.userId];
                        }
                        if(streamAttributes.isScreen){
                            userStreams['screen'] = stream;
                        }else{
                            userStreams['cam'] = stream;
                        }
                        userStreams['userName'] = streamAttributes.userName;
                        userStreams['time'] = streamAttributes.time;
                        candidateStreams[streamAttributes.userId] = userStreams;
                    }
                }
                this.setState({candidateStreams:candidateStreams})
            }else{
                if(this.onlineExam){
                    this.publishScreen();
                    setTimeout(this.publishCam, 1000);
                }else{
                    this.publishCam();
                }
                
            }
            
        }, function(err) {
            alert('Room join failed');
        });

        if(this.examinor == 'true'){
            this.conference.addEventListener('streamadded', (event) => {
                let stream = event.stream;
                let candidateStreams = this.state.candidateStreams;
                if (stream.source.audio === 'mixed' || stream.source.video ===
                    'mixed') {
                    //this.subscribeStream(stream, true);
                } else {
                    let streamAttributes = stream.attributes 
                    let candidateStreams = this.state.candidateStreams;
                    let userStreams = {};
                    if(candidateStreams[streamAttributes.userId]){
                        userStreams = candidateStreams[streamAttributes.userId];
                    }
                    if(streamAttributes.isScreen){
                        userStreams['screen'] = stream;
                    }else{
                        userStreams['cam'] = stream;
                    }
                    userStreams['userName'] = streamAttributes.userName;
                    userStreams['time'] = streamAttributes.time;
                    candidateStreams[streamAttributes.userId] = userStreams;
                }
                this.setState({candidateStreams:candidateStreams})
            });
        }
    }
    publishCam = () =>{
        let audioConstraintsForMic      = new window.Owt.Base.AudioTrackConstraints(window.Owt.Base.AudioSourceInfo.MIC);
        let videoConstraintsForCamera   = new window.Owt.Base.VideoTrackConstraints(window.Owt.Base.VideoSourceInfo.CAMERA);
        
        //this.setState({loader:'Publishing Media'})
        let constraints = new window.Owt.Base.StreamConstraints(audioConstraintsForMic, videoConstraintsForCamera);
        window.Owt.Base.MediaStreamFactory.createMediaStream(constraints).then(stream => {
            window.globalMediaStream = stream;
            let streamAttribute         = {};
            streamAttribute.isScreen    = false;
            streamAttribute.userId      = this.userId;
            streamAttribute.userName      = this.userName;
            streamAttribute.time        = this.state.testTimeDisplay;
            document.getElementById('pro-cam-video').srcObject = stream;
            document.getElementById('pro-cam-video').muted = true;
            this.localStream = new window.Owt.Base.LocalStream(
                stream, new window.Owt.Base.StreamSourceInfo(
                    'mic', 'camera'), streamAttribute);
            this.conference.publish(this.localStream).then(publication => {
                window.globalPublication = publication;
                
                publication.addEventListener('error', (err) => {
                    // this.props.alert('Publishing failed, Publishing failed due to unknown reason please reload and try again.');
                });
                publication.addEventListener('ended', (err) => {
                   //  this.props.alert('Your were disconnected, may be due to internet fluctuation, Please reload and try again');
                });
            });
        }, err => {
           
        });
    }
    publishScreen = () =>{
        let resolution                  = new window.Owt.Base.Resolution(window.screen.width*1.1,window.screen.height*1.1);
        let audioConstraintsForMic      = true
        let videoConstraintsForCamera   = new window.Owt.Base.VideoTrackConstraints(window.Owt.Base.VideoSourceInfo.SCREENCAST);
        let constraints = new window.Owt.Base.StreamConstraints(audioConstraintsForMic, videoConstraintsForCamera);
        videoConstraintsForCamera.resolution = resolution;
        window.Owt.Base.MediaStreamFactory.createMediaStream(constraints).then(stream => {
            this.mediaScreenStream = stream;
            let streamAttribute         = {};
            streamAttribute.isScreen    = true;
            streamAttribute.userId      = this.userId;
            streamAttribute.userName      = this.userName;
            streamAttribute.time        = this.state.testTimeDisplay;
            document.getElementById('pro-screen-video').srcObject = stream;
            window.ScreenStream = stream;
            this.localScreenStream = new window.Owt.Base.LocalStream(
                stream, new window.Owt.Base.StreamSourceInfo(
                    'mic', 'camera'), streamAttribute);
            this.conference.publish(this.localScreenStream).then(publication => {
                this.globalScreenPublication = publication;
                //this.mixStream(this.room, publication.id, 'common');
                stream.getVideoTracks()[0].onended = () => {
                    this.onStopScreenShare();
                };
                publication.addEventListener('error', (err) => {
                    this.onStopScreenShare();
                });
                publication.addEventListener('ended', (err) => {
                    this.onStopScreenShare();
                });
                this.setState({screenEnabled:true});
            });
        }, err => {
           
        });
    }
    onStopScreenShare = ()=>{

    }
    onSelectCandidate = (userStreams) =>{
        if(userStreams['screen']){
            this.subscribeStream(userStreams['screen'])
        }
        if(userStreams['cam']){
            this.subscribeStream(userStreams['cam'])
            if(userStreams['cam']){
                this.selectedUserStream = userStreams['cam'].id;
            }

        }
        
    }
    subscribeStream = (stream) => {
        let streamAttributes = stream.attributes;
        this.conference.subscribe(stream, {
            audio: false,
            video: true
        }).then((subscription) => {
            if(streamAttributes.isScreen){
                document.getElementById('pro-screen-video-examinor').srcObject = stream.mediaStream;
            }else{
                document.getElementById('pro-cam-video-examinor').srcObject = stream.mediaStream;
            }
            subscription.addEventListener('error', (err) => {
                if(stream.id === this.selectedUserStream){
                    document.getElementById('pro-screen-video-examinor').srcObject = null;
                    document.getElementById('pro-cam-video-examinor').srcObject = null;
                }
            })
            subscription.addEventListener('ended', (err) => {
                // alert('end')
            })
        });
    }
    onLogout = ()=>{
        window.localStorage.clear();
        window.location.href = '/';
    }
    send = (method, entity, body, onRes)  => {
            entity = 'https://'+Config.DOMAIN + entity;
            var req = new XMLHttpRequest();
            req.onreadystatechange = function() {
                if (req.readyState === 4) {
                    onRes(req.responseText);
                }
            };
            req.open(method, entity, true);
            req.setRequestHeader('Content-Type', 'application/json');
            if (body !== undefined) {
                req.send(JSON.stringify(body));
            } else {
                req.send();
            }
        

    };

    onLogoClick = () =>{
        for(let key in this.state.candidateStreams){
            alert(key)
        }
    }
    onStartTest = () =>{
        this.setState({testStarted:true})
        if(this.examinor == 'true'){
            let sendData = {method:'testStarted',room:this.room};
            SocketHandler.sendToAll(sendData);
        }
        
    }
    onListenSocket = () =>{
        SocketHandler.getSocket((socket)=>{
            socket.on('onReceiveSendToAll',(data) => {
                data =  JSON.parse(data);
                if(data.room !== this.room){
                    //return;
                }
                switch (data.method) {
                    
                    case 'chatexam': 
                        this.setState({receivedMessage:'Examinor : '+data.message});
                    break;
                    case 'testStarted': 
                        this.setState({testEnabled:true})
                    break;
                    case 'updateCandidateTime': 
                        if(this.examinor === 'true'){
                            let candidateStreams = this.state.candidateStreams;
                            let myStream = candidateStreams[data.userId];
                            if(myStream){
                                myStream['time'] = data.time;
                                candidateStreams[data.userId] = myStream;
                                this.setState({candidateStreams:candidateStreams});
                            }
                        }
                    break;
                    
                }
            });
        });
    }
    onMessageKeyUp = (e)=>{
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) {
           this.sendChat();
        }
    }
    onMsgInputChange = (e)=>{
        this.setState({currentChat:e.target.value})
    }
    sendChat = () =>{
        // alert(this.userName)
       if(this.state.currentChat.replace(/\s/g, '')){
               let chatObject = {method:'chatexam',name:this.userName,message:this.state.currentChat,room:this.room};
               SocketHandler.sendToAll(chatObject);
               this.setState({isMessageOpen:false})
           }
           this.setState({currentChat:''})
       }
       closeMsgPop = () =>{
           this.setState({isMessageOpen:false,receivedMessage:''})
       }
       openChatPop = () =>{
        this.setState({isMessageOpen:true})
    }
       
    render() {
        if(this.examinor == 'true'){
            return(
            <div>
                    <div class="header-wrapper">
                        <nav class="navbar">
                            <div class="navbar-start"><a class="menu-bar"><i class="fa fa-bars" aria-hidden="true"></i></a>
                                <a class="nav-item"><img onClick={this.onLogoClick} alt="CanString logo" src="/files/pure-logo.png" /></a><strong class="pad-13-10 is-size-5 has-text-white">Canstring Meet</strong>
                            </div>
                            <div className="test-timer-wrap"> 
                                    <div className={this.state.testStarted ? "pro-timer":"hide"}>{this.state.testTimeDisplay}</div>
                                    <div className="pro-starttest" onClick={this.onStartTest}>{this.state.testStarted ? 'Stop Test':'Start Test'}</div>
                                </div>
                            <div class="navbar-end">
                                <div class="navbar-item has-dropdown is-hoverable">
                                <a class="navbar-link">
                                    <div class="empty-head-icon">M</div>
                                    <span class="icon is-small"><i class="fa fa-circle circle-green" ng-reflect-klass="fa fa-circle" ng-reflect-ng-class="[object Object]"></i></span>
                                </a>
                                <div class="navbar-dropdown is-right">
                                    <a onClick={this.onLogout} class="navbar-item"><i class="fa fa-sign-out"></i> Log out </a>
                                </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div class="col se-p is-chat right-box-shadow chat-list call-cont" ng-reflect-klass="col se-p is-chat right-box-sha" ng-reflect-ng-class="[object Object]">
                        <div class="call-detail">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active user" id="user" role="tabpanel" aria-labelledby="user-tab">
                                <div class="title pro-userlisthead">
                                    <h6>Candidates (1)</h6>
                                </div>
                                <ul class="content user-list">
                                    {
                                        Object.keys(this.state.candidateStreams).map((value,i)=>{
                                            let userStreams = this.state.candidateStreams[value]
                                            if(!userStreams.isScreen && userStreams['cam']){
                                                return(
                                                    <li index={i}>
                                                        <p class="pro-name">
                                                            <span>AC</span>{userStreams.userName} {userStreams.time}
                                                        </p>
                                                        <p>
                                                            <i onClick={this.openChatPop} class="fa fa-commenting-o chat" aria-hidden="true"></i>
                                                            <i onClick={this.onSelectCandidate.bind(this,userStreams)} class={userStreams['cam'].id === this.selectedUserStream ? "hide":"fa fa-play video"} aria-hidden="true"></i>
                                                        </p>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                    
                                </ul>
                                
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="proc-middle-area tab-pane fade chat">
                        <video autoPlay="true" muted id="pro-screen-video-examinor" className={this.onlineExam ? "pro-screen-video-examinor":"hide"}/>
                        <video autoPlay="true" muted id="pro-cam-video-examinor"  className={this.onlineExam ? "pro-cam-video-examinor":"pro-cam-video-examinor pro-cam-video-examinor-offline"}/>
                    </div>
                    <div className={this.state.isMessageOpen ? "message-pop":"hide"}>
                        <div className="msg-inp">
                            <textarea onKeyUp={this.onMessageKeyUp}  value={this.state.currentChat} onChange={this.onMsgInputChange} placeholder="Type message and press Enter key to send"></textarea>
                            <button onClick={this.closeMsgPop} className="exm-cls">X</button>
                        </div>
                        
                    </div>
            </div>
            )
        }else{
            return(
                <div>
                        <div class="header-wrapper">
                            <nav class="navbar">
                                <div class="navbar-start"><a class="menu-bar"><i class="fa fa-bars" aria-hidden="true"></i></a>
                                    <a class="nav-item"><img onClick={this.onLogoClick} alt="CanString logo" src="/files/pure-logo.png" /></a><strong class="pad-13-10 is-size-5 has-text-white">Canstring Meet</strong>
                                </div>
                               
                                <div className="test-timer-wrap"> 
                                    <div className={this.state.testStarted ? "pro-timer":"hide"}>{this.state.testTimeDisplay}</div>
                                </div>
                                <div class="navbar-end">
                                    <div class="navbar-item has-dropdown is-hoverable">
                                    <a class="navbar-link">
                                        <div class="empty-head-icon">M</div>
                                        <span class="icon is-small"><i class="fa fa-circle circle-green" ng-reflect-klass="fa fa-circle" ng-reflect-ng-class="[object Object]"></i></span>
                                    </a>
                                    <div class="navbar-dropdown is-right">
                                        <a onClick={this.onLogout} class="navbar-item"><i class="fa fa-sign-out"></i> Log out </a>
                                    </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className='bot-recommentation'>
                            <div class="msg-container bg-white">
                            <div className="pro-start-test-wrap">
                                    <div className="pro-test-instruction">
                                        <h6>Read the rule carefully before start the test : </h6>
                                        <ul>
                                            <li>1. Allow your camera and screen share popup and make sure your camera and screen appearing on the screen.</li>
                                            <li>2. Click on the Start Test button once examinor start the exam.</li>
                                            <li>3. If you close this page before finishing the exam then you will be disqalified.</li>
                                            <li>4. If your internet failed then also you will be disqalified, make sure you have stable internet before starting the exam.</li>
                                        </ul>
                                     </div>
                                    <div className="pro-video-aria">
                                        <div className="pro-self-video-cam">
                                            <video autoPlay="true" muted id="pro-cam-video"  className="pro-cam-video" />
                                        </div>
                                        <div className="pro-self-video-screen">
                                            <video autoPlay="true" muted id="pro-screen-video" className="pro-screen-video"/>
                                        </div>
                                    </div>
                                    
                                   
                            </div>
                                <div className={this.state.testStarted ? "hide":"pro-start-btn-wrap"}>
                                    <button className={this.state.testEnabled ? "":"hide"} onClick={this.onStartTest}>Start Test</button>
                                </div>
                                <div className={!this.state.testStarted ? "hide":"pro-start-btn-wrap"}>
                                    <h2>Test has been started, You will be exited once examinor stop the test</h2>
                                </div>
                            </div>
                        </div>
                        <div className={this.state.receivedMessage ? "message-pop":"hide"}>
                            <div className="msg-inp">
                                {this.state.receivedMessage}
                                <button onClick={this.closeMsgPop} className="exm-cls view-exxm-cls">X</button>
                            </div>
                            
                        </div>
                </div>
                )
        }
    }
}
export default Candidate;
