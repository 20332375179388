'use strict'
import React, {
    Component
} from 'react'
import Config from '../config'
import CommonFunctions from '../helpers/common-functions'
import SocketHandler from '../helpers/socket-service'
let SpeechRecognition;
if (window.SpeechRecognition) {
    SpeechRecognition = window.SpeechRecognition
}
if (window.webkitSpeechRecognition) {
    SpeechRecognition = window.webkitSpeechRecognition
}
let recognition;
if (SpeechRecognition) {

    recognition = new SpeechRecognition()
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US'
}
class Speech extends Component {
    constructor(props) {
        super(props)
        this.room = this.props.room
        SocketHandler.getSocket((socket) => {
            socket.on('onReceiveSendToAll', (data) => {
                data = JSON.parse(data);
                switch (data.method) {
                    case 'onTranscript':
                        if (data.text && data.room === this.room) {
                            if (this.lastUser !== data.userName) {
                                document.getElementById('transcript-txt').innerHTML = document.getElementById('transcript-txt').innerHTML + '<br/>' + data.userName + ':' + data.text;
                            } else {
                                document.getElementById('transcript-txt').innerHTML = document.getElementById('transcript-txt').innerHTML + '<br/>' + data.text;
                            }
                            document.getElementById('transcript-txt').scrollTop = document.getElementById('transcript-txt').scrollHeight;
                            this.lastUser = data.userName;
                        }
                        break;
                }
            });
        });
        this.transcriptType = 'browser';
        this.socketGoogle = null;
        this.userName = this.props.userName;
        this.googleConnected = false;
        this.lastUser = '';
        this.mic = false;
    }
    componentDidMount = () => {
        
    }
    changeMicStatus = (status) =>{
        this.mic = status;
    }
    startTranscript = () => {

        if (this.transcriptType === 'google') {
            this.startGoogleSocket();
        } else {
            if (CommonFunctions.getOS() != 'Android') {
                this.handleListen();
            }
        }

    }
    stopTranscript = () => {
        document.getElementById('transcript-txt').innerHTML = '';
        this.stopListen();
    }
    stopListen = () => {
        recognition.stop()
        recognition.onend = () => {
            // console.log("Stopped listening per click")
        }
    }
    handleListen = () => {
        try{
        if (recognition) recognition.stop();
        if (recognition) {
            recognition.start()
            recognition.onend = () => {
                recognition.start()
            }
        }
    }catch(e){
        
    }

        if (recognition) {
            recognition.onstart = () => {
                // console.log("Listening!")
            }
            recognition.onresult = event => {
                for (let i = event.resultIndex; i < event.results.length; i++) {
                    const transcript = event.results[i][0].transcript;
                    if (event.results[i].isFinal && this.mic) {
                        if(this.lastUser == 'Me'){
                          document.getElementById('transcript-txt').innerHTML = document.getElementById('transcript-txt').innerHTML+'<br/>' + transcript
                        }else{
                          document.getElementById('transcript-txt').innerHTML = document.getElementById('transcript-txt').innerHTML+'<br/>Me : ' + transcript
                        }
                        
                        this.props.onTranscription(transcript)
                        document.getElementById('transcript-txt').scrollTop = document.getElementById('transcript-txt').scrollHeight;
                        this.lastUser = 'Me';

                    }
                }
            }
            recognition.onerror = event => {
                // console.log("Error occurred in recognition: " + event.error)
            }

        }
    }
  render() {
    return (
      <div>
        <p id="transcript-txt"></p>
    </div>
    )
  }

  startGoogleTrans = () => {
    try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        window.audioContext = new AudioContext();
    } catch (e) {
        alert('Web Audio API not supported.');
    }
    let audio = { echoCancellation: true, noiseSuppression: true , sampleRate:41000}
    var constraints = window.constraints = {
        audio: audio,
        video: false
    };
    navigator.mediaDevices.getUserMedia(constraints).
    then(this.handleSuccessGoogle).catch(this.handleError);
}
handleError = (error) => {

}
stopStream = () => {
    window.Mediastream.getTracks().forEach(function(track) {
        track.stop();
    });
}
handleSuccessGoogle = (stream) => {

    stream.oninactive = () => {
        console.log('STREAM END DUE TO INACTIVITY');
        this.stopStream()
    };
    window.Mediastream = stream;
    var script = window.audioContext.createScriptProcessor(4096, 1, 1);
    script.onaudioprocess = (event) => {
        console.log('audio activity')
        var input = event.inputBuffer.getChannelData(0) || new Float32Array(4096);
        for (var idx = input.length, newData = new Int16Array(idx); idx--;)
            newData[idx] = 32767 * Math.min(1, input[idx]);
        if (this.socketGoogle != null) {
            console.log('start');
            if (this.socketGoogle.readyState === 1) {
                console.log('ready state 1');
                console.log('Sending data to server..')
                this.socketGoogle.send(newData);
            }
        }
    }

    this.socketGoogle.onmessage = (b) => {
        const data = JSON.parse(b.data);
        if (data.isFinal) {
            //alert(data.text)
            if (this.lastUser !== 'Me') {
                document.getElementById('transcript-txt').innerHTML = document.getElementById('transcript-txt').innerHTML + '<br/>Me : ' + data.text;
            } else {
                document.getElementById('transcript-txt').innerHTML = document.getElementById('transcript-txt').innerHTML + '<br/>' + data.text;
            }
            this.lastUser = 'Me';
            this.props.onTranscription(data.text)
            document.getElementById('transcript-txt').scrollTop = document.getElementById('transcript-txt').scrollHeight;
        } else {
            //document.getElementById('transcript-txt').innerHTML = 'Me : '+data.text;
        }
    };

    var mic = window.audioContext.createMediaStreamSource(stream);
    mic.connect(script);
    script.connect(window.audioContext.destination);

    window.socketGoogle = this.socketGoogle;
    window.script = script;
}
startGoogleSocket = () => {
    if (this.googleConnected) {
        return;
    }
    this.socketGoogle = new WebSocket("wss://" + Config.DOMAIN + ":80/?name=" + this.userName + "&room=" + this.room);
    //socket = new WebSocket("wss://localhost/");
    this.socketGoogle.binaryType = "arraybuffer";
    this.socketGoogle.onopen = () => {
        console.log('WSS SOCKET SUCCESSFULLY CONNECTED');
        this.socketGoogle.send('start');
        this.startGoogleTrans();
        this.googleConnected = true;
    }
    this.socketGoogle.onclose = (e) => {
        switch (e.code) {
            case 1000: // CLOSE_NORMAL
                console.log("WebSocket: closed");
                break;
            default: // Abnormal closure
                this.onReconnectGoogle(this.socketGoogle);
                break;
        }
    }
    this.socketGoogle.onerror = (e) => {
        switch (e.code) {
            case 'ECONNREFUSED':
                this.onReconnectGoogle(this.socketGoogle);
                break;
            default:

                break;
        }
    }
}
onReconnectGoogle = (socketGoogle) => {
    this.socketGoogle = null;
    setTimeout(() => {
        console.log("WebSocketClient: reconnecting...");
        this.startGoogleSocket()
    }, 5000);
}


}

export default Speech