import React, { Component } from 'react';
import UserSession from '../helpers/user-session';
import SocketHandler from '../helpers/socket-service';
import CommonFunctions from '../helpers/common-functions';
import { Redirect } from 'react-router-dom';
import Config from '../config';
import moment from 'moment';
import RequestHandler from '../helpers/request-handler';
import CallSideBarComponent from './CallSideBarComponent';
import Whiteboard from './Whiteboard';
import ReactTooltip from 'react-tooltip';
import Dialog from './Dialog';
import { toast } from 'react-toastify';
import Speech from './speech';
import AvSettingComponent from './AvSettingComponent';
window.webkitRTCPeerConnection = window.RTCPeerConnection;
class CallOpenMediaServer extends Component {
  constructor(props) {
            super(props);
            this.public = true;
            this.temphost = false;
            this.newDate= moment().utc().format();
            this.meeetingUserId = 0;
            this.count = 0;
            // this.newDate= moment().format('yyyy-MM-DDTHH:mm');
            if(window.location.href.indexOf('/host') > -1 ){
                this.public = false;
            }
            if(localStorage.getItem('temphost'+this.props.room+this.props.userName)){
                if(localStorage.getItem('temphost'+this.props.room+this.props.userName) -  (new Date().getTime()) > 0){
                    this.public = false;
                    if(window.location.href.indexOf('/host') > -1 ){
                        this.temphost = false;
                    }else{
                        this.temphost = true;
                    }
                    localStorage.setItem('temphost'+this.props.room+this.props.userName,(new Date().getTime() + (60 * 1000)));
                }else{
                    localStorage.removeItem('temphost'+this.props.room+this.props.userName)
                }
                
            }
            this.player = null;
            this.unAutorised = false;
            this.addUserLoop = false;
            this.alias = this.props.room;
            let userMode = 'presenter';
            let wbAllowed = true;
            let screenAllowed = true;
            this.video_extensions = ['mp4','webm'];

            if(this.public){
                userMode = 'presenter';
                wbAllowed = false;
                screenAllowed = false;
            }

            this.callToken = this.props.callToken;
            this.userName = this.props.userName;
            this.token = null;
            this.connectionSuccess = false;
            this.globalScreenPublication = null;
            this.mixedStreamId = '';
            this.firstTimeCam = true;
            this.isInitMicLevel = false;
            this.revokePow = false; 
            this.youTubeSyncLock = false
            this.initialVideoTime = 0;
            this.initialPaused = false;
            this.mp4SyncLocked = false;
            this.mp4player = null;
            this.configurationPeer = { 
                "iceServers": [{
                    urls: "turn:signal.dev.canstringai.com:3478",
                    credential: 'UY9TKs9jBT',
                    username: 'acinfotech'
                }]
            }
            this.configuration = {
                rtcConfiguration: {
                    iceServers: [{
                        urls: "stun:meet.avnik.com:3478"
                    }, {
                        urls: ["turn:meet.avnik.com:3478?transport=udp"],
                        credential: "turn",
                        username: "turn"
                    }]
                }
            };
            /*
            this.configuration = {
                rtcConfiguration: {
                    iceServers: [{
                        urls: "stun:signal.dev.canstringai.com:3478"
                    }, {
                        urls: ["turn:signal.dev.canstringai.com:3478"],
                        credential: "UY9TKs9jBT",
                        username: "acinfotech"
                    }]
                }
            };
            */
            /*Peer variables 
            **/
            this.participants = {}; 
            this.existingList = {};

            this.state = {user_id:this.props.currentChatUser.user_id,first_name:this.props.currentChatUser.full_name,acceptedUser:false,speakerEnabled:true,
                        videoEnabled:false,audioEnabled:this.public ? false:true,screenEnabled:false,public:this.public,redirect:false,
                        screenShareSupported:false,callSupported:true,numSubs:0,connected:false,meetingStarted:false,startedTxt:'',
                        pListOpen:false,loader:false,userMode:userMode,isHandRaised:false,participantHiddenCount:0,raiseHandHiddenCount:0,chatHiddenCount:0,userListMeeting:{},wbEnabled:false,documentOpen:false,currentPage:1,currentDocument:false,totalPages:0,encryptTxtHide:false,notification:false,
                        wbAllowed:wbAllowed,screenAllowed:screenAllowed,audioProceccing:false,videoProceccing:false,screenProceccing:false,allowPrtrait:false,hostAvailable:true,chatCnt:0,
                        showDialog:false,locked:false,zoomCount:4,mounted:false,caption:true,isWeb:true,recordingStatus:false,count:0,recshow:true,
                        PEER_TO_PEER:false,camallowed:true,micallowed:true,WEBINAR:false,apiLoaded:false,videoOpen:false}    
        this.name = '';
        if(this.public || this.temphost){
            this.name = this.props.currentChatUser.user_id
        }else{
            if(UserSession.getSession().user_id){
                this.name = UserSession.getSession().user_id.toString();
            }else{
                //alert('123redirect 1')
                window.location.href = '/';
            }
            
        }
  }
  onApiCall = () =>{
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(this.alias){
        const payload = {
            meeting_alias_name:this.alias, 
            currentDate: this.newDate.toString(), 
            zone: tz
        }
       // alert(this.public)
       if(this.state.WEBINAR && this.public){
           this.room = this.alias;
           this.setState({apiLoaded:true,userMode:'viewer'})
           setTimeout(() => {
            this.wbRef.updateRoom(this.room);
            this.onApiSuccess();
           }, 3000);
           
           
       }else{
        RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/meeting/'+(this.public || this.temphost ? 'user':'validation'), payload, (response) => {
                if (response.success === true) {
                    this.setState({apiLoaded:true})
                    // console.log('Meeting info : '+JSON.stringify(response))
                    this.room = response.meeting.meeting_number
                    this.meeetingUserId = response.meeting.user_id
                    this.wbRef.updateRoom(this.room);
                    this.setMeetingInfo(response.meeting)
                // alert(JSON.stringify(response.meeting))
                    localStorage.setItem('meetData',JSON.stringify(response.meeting))
                    
                    if(response.meeting.meet_type){
                        //console.log(response.meeting.meet_type)
                        if(response.meeting.meet_type === 'onetoone'){
                            this.setState({PEER_TO_PEER:true})
                        }
                        if(response.meeting.meet_type === 'webinar'){
                            this.setState({WEBINAR:true})
                            if(this.public){
                                this.setState({userMode:'viewer'})
                            }
                        }
                    }
                    this.onApiSuccess();
                } else {
                    if(response.message){
                        this.props.alert(response.message)
                        setTimeout(() => {
                            // alert('123redirect 2')
                            //window.location.href = '/';
                        }, 2000);
                    }
                    console.log(response.message);
                }
            
        })
       }
        
        
    
    }
  }
  onApiSuccess = () =>{
    if(localStorage.getItem('temphost'+this.alias+this.userName)){
        if(localStorage.getItem('temphost'+this.props.room+this.props.userName) -  (new Date().getTime()) < 0){
            if(!this.public){
                if(!UserSession.getSession().user_id){
                    // alert('123redirect 3')
                   window.location.href = '/';
                }
            }
        }
        
    }else{
        if(!this.public){
            if(!UserSession.getSession().user_id){
               // alert('123redirect 4')
               window.location.href = '/';
            }
        }
    }
    
    this.setState({public:this.public,})
    this.windowId = CommonFunctions.generateUID();
    this.name = (this.state.public || this.temphost) ?  this.props.currentChatUser.user_id:UserSession.getSession().user_id.toString();
    
    
    SocketHandler.getSocket((socket)=>{
        socket.on('onUserListMeeting',(data) => {
            console.warn('LINE : 199 : onUserListMeeting')
            data =  JSON.parse(data);
            this.setState({userListMeeting:data})
            let raiseHandHiddenCount = 0;
            for(let key in data){
                if(document.getElementsByClassName(data[key].windowId).length === 0 && !this.state.WEBINAR) {
                    if(data[key].windowId != this.windowId){
                        if(document.getElementById('remote-video')){
                            document.getElementById('remote-video').appendChild(this.renderVideoParent(data[key].userName,0,false,data[key].windowId));
                            if(data[key].videoEnabled){
                                document.getElementsByClassName('remote-video-'+data[key].windowId)[0].style.display = 'block';
                                document.getElementsByClassName('remote-novideo-'+data[key].windowId)[0].style.display = 'none';
                            }

                        }
                    }
                }
                if(data[key].handRaised){
                    raiseHandHiddenCount++;
                }
                if(data[key].userMode !== 'presenter' && this.state.WEBINAR){
                    if(document.getElementsByClassName(data[key].windowId)){
                        if(document.getElementsByClassName(data[key].windowId)[0]){
                            document.getElementsByClassName(data[key].windowId)[0].remove();
                        }
                    }
                    if(data[key].windowId == this.windowId){
                        if(document.getElementById('local-video')){
                         document.getElementById('local-video').remove();
                        }
                    }
                }
              
            }
            this.setState({raiseHandHiddenCount:raiseHandHiddenCount})
            this.layoutVideos();

        });
        socket.on('onInitialData',(data) => {
            console.warn('LINE : 237 : onInitialData')
            data =  JSON.parse(data);
            this.onInitialData(data)
            

        });
        socket.on('onUserDisconnected',(data) => {
            console.warn('LINE : 244 : onUserDisconnected')
            data =  JSON.parse(data);
            const elements = document.getElementsByClassName(data.windowId);
            while (elements.length > 0) elements[0].remove();
            

        });
        socket.on('onNameChange',(data) => {
            
            data =  JSON.parse(data);
            this.userName = data.parsedData.userName;
            //alert(this.userName)
            

        });
        
        
        
        socket.on('onReceiveSendToAll',(data) => {
            data =  JSON.parse(data);
            // console.warn(data.method);
            if(data.room !== this.room){
                return;
            }
            console.warn('LINE : 268 : onReceiveSendToAll | method = '+data.method)
            switch (data.method) {
                
                case 'camEnabled': 
                if(document.getElementById('remote-video-'+data.windowId)){
                    if(data.status){
                        document.getElementById('remote-video-'+data.windowId).style.display = 'block';
                        document.getElementById('remote-novideo-'+data.windowId).style.display = 'none';
                    }else{
                        document.getElementById('remote-video-'+data.windowId).style.display = 'none';
                        document.getElementById('remote-novideo-'+data.windowId).style.display = 'block';
                    }
                }
                     
                break;
                case 'isSpeaking': 
                this.showSpeaking(data)
                break;
                case 'chat':
                    let allow = false;
                    if(data.group == 'public' || data.group == this.userId ){
                        allow = true;
                    }
                    if(allow && !this.state.pListOpen){
                        let chatHiddenCount = this.state.chatHiddenCount;
                        chatHiddenCount++;
                        this.setState({chatHiddenCount:chatHiddenCount})
                    }
                break;
                case 'onRaiseHand':
                    //if(!this.state.pListOpen){
                        if(data.status && !this.public){
                            this.showNotification('Hand raised');
                        }
                        
                    //}
                break;
                case 'onkickUser':
                    if(data.userId == this.name){
                        this.props.alert('You have been removed from this session');
                        SocketHandler.close();
                        setTimeout(() => {
                            window.location.href = '/thankyou';
                        }, 3000);
                    }
                break;
                case 'onLowerHand':
                    if(data.userId == this.name){
                        this.showNotification('Your hand raise request has been rejected by host')
                        this.setState({isHandRaised:false});
                        let sendData = {method:'onRaiseHand',room:this.room,status:false,userId:this.name,userName:this.userName};
                        SocketHandler.sendToAll(sendData);
                    }
                break;
                
                case 'onGiveConrol':
                    if(data.userId == this.name){
                        if(data.status){
                            this.setState({userMode:'presenter'})
                            this.setState({isHandRaised:false});
                            let sendData = {method:'onRaiseHand',room:this.room,status:false,userId:this.name,userName:this.userName};
                            SocketHandler.sendToAll(sendData);
                            this.showNotification('You have been promoted as a presenter, You can now turn on Mic Cam etc');
                        }else{
                            this.setState({userMode:'viewer',audioEnabled:false,videoEnabled:false})
                            this.unPublishStream();
                            if(this.state.screenEnabled){
                                this.onStopScreenShare();
                            }
                            this.showNotification('Your presenting power has been revoked');
                            let sendData = {method:'micEnabled',room:this.room,name:'0',status:false,userId:this.name};
                            SocketHandler.sendToAll(sendData);
                            let sendData1 = {method:'camEnabled',room:this.room,name:'0',status:false,userId:this.name,windowId:this.windowId};
                            SocketHandler.sendToAll(sendData1);
                        }
                    }
                break;
                case 'onGiveWbConrol':
                    if(data.userId == this.name){
                        this.setState({wbAllowed:data.status})
                        if(data.status){
                            this.showNotification('Host given you Whiteboard control');
                        }else{
                            this.showNotification('Host revoked Whiteboard control from you');
                        }
                        this.wbRef.setWbAllowed(data.status)
                    }
                break;
                case 'onGiveScreenConrol':
                    if(data.userId == this.name){
                        this.setState({screenAllowed:data.status})
                        if(data.status){
                            this.showNotification('You now have a access to share your screen');
                        }else{
                            this.showNotification('Host has ended screen share');
                            this.onStopScreenShare();
                        }
                        this.addUserMeeting();
                    }
                break; 
                case 'onHostingTransfer':
                    if(data.userId == this.name){
                        if(data.status){
                            localStorage.setItem('temphost'+this.alias+this.userName,(new Date().getTime() + (15 * 1000)));
                            let currentSession =  UserSession.getSession();
                            if(!currentSession){
                                currentSession = {};
                            } 
                            currentSession.token = data.token;
                            UserSession.setSession(currentSession)
                            this.showNotification('Hosting has been transfered to you');
                        }else{
                            this.revokePow = true;
                            //localStorage.setItem('temphost'+this.alias+this.userName,'false');
                            localStorage.removeItem('temphost'+this.alias+this.userName);
                            this.showNotification('Hosting power has been revoked from you');
                        }
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                break; 
                
                case 'onGiveConrolToAll':
                    if(this.public && !this.temphost){
                        if(data.status){
                            this.setState({userMode:'presenter'})
                            this.setState({isHandRaised:false});
                            let sendData = {method:'onRaiseHand',room:this.room,status:false,userId:this.name,userName:this.userName};
                            SocketHandler.sendToAll(sendData);
                            this.showNotification('You have been promoted as a presenter');
                        }else{
                            this.setState({userMode:'viewer',audioEnabled:false,videoEnabled:false})
                            this.unPublishStream();
                            this.showNotification('Your presenting power has been revoked');
                            
                        }
                    }
                break;
                case 'onDisablePvChat':
                    if(this.public && !this.temphost){
                        this.showNotification('Private chat has been disabled by host');
                        this.sedeBarRef.controlpv('true')
                    }
                break; 
                case 'onEnablePvChat':
                    if(this.public && !this.temphost){
                        this.showNotification('Private chat has been enabled by host');
                        this.sedeBarRef.controlpv('false')
                    }
                break; 
                case 'onMuteMicAll':
                    if(this.public && !this.temphost){
                        if(this.state.audioEnabled){
                            this.onEnableOrDisableMic();
                            this.showNotification('Your mic has been muted by host');
                            
                        }
                        this.setState({micallowed:false})
                    }
                break;
                case 'onDisableCamAll':
                    if(this.public && !this.temphost){
                        if(this.state.videoEnabled){
                            this.onEnableOrDisableVideo();
                            this.showNotification('Your camera has been disabled by host');
                        }
                        this.setState({camallowed:false})
                    }
                break;
                case 'onAllowMicAll':
                    if(this.public && !this.temphost){
                        this.setState({micallowed:true})
                    }
                break;
                case 'onAllowCamAll':
                    if(this.public && !this.temphost){
                        this.setState({camallowed:true})
                    }
                break;
                case 'controlMic':
                    if(data.userId == this.name){
                        if(this.state.audioEnabled){
                            this.onEnableOrDisableMic();
                            this.showNotification('Your mic has been muted by host');
                        }else{
                            if(this.state.userMode === 'presenter'){
                                this.props.alert('Host requested you to unmute your microphone');
                            }
                        }
                    }
                break;
                case 'controlCam':
                    if(data.userId == this.name){
                        if(this.state.videoEnabled){
                            this.onEnableOrDisableVideo();
                            this.showNotification('Your camera has been disabled by host');
                        }else{
                            if(this.state.userMode === 'presenter'){
                                this.props.alert('Host requested you to turn your camera on');
                            }
                        }
                    }
                break;
                
                case 'meetingInitialStatus':
                    if(data.status){
                        if(this.public){
                            this.initConference();
                        }
                        this.setState({meetingStarted:true})
                        this.sedeBarRef.setMeetingStarted(true);
                    }else{
                        if(this.public){
                            if(this.conference){
                                this.conference.leave(this.token);
                            }
                        }
                        this.setState({meetingStarted:false,startedTxt:'Meeting will start when organizer starts'})
                        this.sedeBarRef.setMeetingStarted(false);
                     }
                    
                break;
                
                case 'lockStatus':
                    //alert(this.public)
                    if(data.status){
                        this.setState({locked:true})
                    }else{
                        this.setState({locked:false})
                        
                    }
                break;
                case 'recordingStatus':
                    //alert(this.public)
                    if(data.status){
                        this.setState({recordingStatus:data.status,isRecording:true})
                        this.recordId = data.status;
                    }else{
                        this.setState({recordingStatus:false,isRecording:false})
                        
                    }
                break;
                case 'captionstate':
                    //alert(this.public)
                    if(data.status){
                        this.setState({caption:true})
                        this.sedeBarRef.setCaption(true)
                    }else{
                        this.setState({caption:false})
                        this.sedeBarRef.setCaption(false)
                        
                    }
                break;
                
                case 'meetingStatus':
                    if(data.status){
                        this.initConference();
                        this.setState({meetingStarted:true})
                        this.sedeBarRef.setMeetingStarted(true);
                    }else{
                        this.onHangupCall();
                        this.setState({meetingStarted:false,startedTxt:'Meeting stopped by the organizer'})
                        socket.disconnect();
                        this.sedeBarRef.setMeetingStarted(false);
                        setTimeout(() => {
                            
                            this.setState({redirect:"/Thankyou"})
                        }, 2000);
                        localStorage.removeItem('temphost'+this.alias+this.userName);
                    }
                break;
                case 'wbEnable':
                    if(data.status){
                        this.loadWb();
                    }else{
                        this.unLoadWb();
                        
                        
                    }
                    
                break;
                case 'shareDoc':
                    if(data.status){
                        if(data.video){
                            this.setState({currentDocument:data.fileName, totalPages:data.totalPages,currentPage:1, documentOpen:true,wbEnable:false,videoOpen:data.video})
                            this.playVideo(data.fileName)
                        }else{
                            this.setState({currentDocument:data.fileName, totalPages:data.totalPages,currentPage:1, documentOpen:true,wbEnable:false})
                            this.stopSharedVideo();
                        }
                        
                        window.demoLC.clear();
                        document.getElementById('remote-video').classList.add('screen-layout');
                        this.layoutVideos();
                        this.wbRef.updatePage(1)

                    }else{
                        this.setState({documentOpen:false,wbEnabled:false})
                        document.getElementById('remote-video').classList.remove('screen-layout');
                        this.layoutVideos();
                        this.wbRef.clearDict();
                        this.wbRef.updatePage(0)
                        this.stopSharedVideo();
                        
                        
                    }
                    
                break;
                case 'pageSwitch':
                   
                    this.setState({currentPage:data.currentPage})
                    this.wbRef.updatePage(data.currentPage)
                break;
                case 'onScreeShare':
                    if(document.getElementById('remote-video-'+data.windowId+'_screen')){
                        if(data.status){
                            document.getElementById(data.windowId+'_screen').classList.add('screen-video')
                            document.getElementById('remote-video').classList.add('screen-layout');
                            this.isScreenShareProgress = true;
                            
                        }else{
                            document.getElementById(data.windowId+'_screen').classList.remove('screen-video')
                            if(!this.state.wbEnabled){
                                document.getElementById('remote-video').classList.remove('screen-layout');
                            }
                            this.isScreenShareProgress = false;
                            if(document.getElementById(data.windowId+'_screen')){
                                document.getElementById(data.windowId+'_screen').remove()
                            }
                            this.layoutVideos();
                            document.getElementById('remote-video-screen').style.display = 'none';
                            
                        }
                    }
                break;
                case 'mp4Seek':
                    this.mp4SyncLocked = true;
                    document.getElementById('sharedVideo').currentTime = parseInt(data.time)
                    this.releaseMp4SyncLock();
                    break;
                case 'mp4Play':
                    this.mp4SyncLocked = true;
                    document.getElementById('sharedVideo').play();
                    document.getElementById('sharedVideo').currentTime = parseInt(data.time)
                    this.releaseMp4SyncLock();
                    break;
                case 'mp4Pause':
                    this.mp4SyncLocked = true;
                    document.getElementById('sharedVideo').pause();
                    document.getElementById('sharedVideo').currentTime = parseInt(data.time)
                    this.releaseMp4SyncLock();
                    break;
                case 'youtubePlayOrSeek':
                    this.youTubeSyncLock = true;
                    this.player.seekTo(parseInt(data.time), true);
                    this.player.playVideo();
                    this.releaseYoutubeSyncLock();
                    break;
                case 'youtubePause':
                    this.youTubeSyncLock = true;
                    this.player.pauseVideo();
                    this.releaseYoutubeSyncLock();
                    break;
                    }
            
        });

    })


    if(this.state.PEER_TO_PEER){
        this.peerToPeerListeners();
    }
    this.afterSecurityCheck();
  }
  releaseMp4SyncLock = () =>{
        if(window.mp4SyncLockTimeout){
            clearTimeout(window.mp4SyncLockTimeout);
        }
        window.mp4SyncLockTimeout = setTimeout(()=>{
            this.mp4SyncLocked = false;
        },3000)
  }
  releaseYoutubeSyncLock = () =>{
    if(window.youtubeSyncLockTimeout){
        clearTimeout(window.youtubeSyncLockTimeout);
    }
    window.youtubeSyncLockTimeout = setTimeout(()=>{
        this.youTubeSyncLock = false;
    },3000)
  }
  setMeetingInfo = (data)=>{
    this.sedeBarRef.setMeetingInfo(data,this.room,this.state.meetingStarted);
  }
  extention = (filename) => {
    return filename.substr(filename.lastIndexOf('.') + 1)
}
  onInitialData = (data)=>{
      if(data.wbEnable){
         this.loadWb();
      }
      if(data.shareDoc){
        data = data.shareDoc;
        if(data.totalPages === 'video' || data.totalPages === 'youtube'){
            if(data.paused){
                this.initialPaused = true;
            }
            if(data.time){
                this.initialVideoTime = data.time;
            }
            this.setState({currentDocument:data.fileName, totalPages:data.totalPages,currentPage:data.currentPage, documentOpen:true,wbEnable:false,videoOpen:data.totalPages})
            this.playVideo(data.fileName)
        }else{
            this.setState({currentDocument:data.fileName, totalPages:data.totalPages,currentPage:data.currentPage, documentOpen:true,wbEnable:false})
        }
        
        window.demoLC.clear();
        document.getElementById('remote-video').classList.add('screen-layout');
        this.layoutVideos();
        this.wbRef.updatePage(data.currentPage)
      }
      if(data.caption){
        this.setState({caption:true})
        this.sedeBarRef.setCaption(true)
      }else{
        this.setState({caption:false})
        this.sedeBarRef.setCaption(false)
      }
      if(data.locked){
        this.setState({locked:true})
          if(this.public){
          
          this.setState({meetingStarted:false,startedTxt:'Meeting is locked, Please contact organiser'})
          setTimeout(() => {
            this.onHangupCall();
            SocketHandler.getSocket((socket)=>{
              socket.disconnect();
            });
          }, 3000);
          SocketHandler.getSocket((socket)=>{
            socket.disconnect();
          });
          
        }
      }else{
        this.setState({locked:false})
      }
      if(data.onMuteCamAll === 'true'){
            if(!this.public){
                this.sedeBarRef.onDisableCamAll(false);
            }else{
                this.setState({camallowed:false})
            }
      }
      if(data.onMuteMicAll === 'true'){
        if(!this.public){
            this.sedeBarRef.onMuteMicAll(false);
        }else{
            this.setState({micallowed:false,audioEnabled:false})
        }
      }
      if(data.disablePv === 'true'){
        if(!this.public){
            this.sedeBarRef.onDisablePrivateChat(false);
        }else{
            this.sedeBarRef.controlpv('true');
        }
      }
      if(data.recording){
        this.setState({recordingStatus:data.recording,isRecording:true})
      }else{
        this.setState({recordingStatus:false,isRecording:false})
      }
  }
  componentDidMount = ()=> {
      setTimeout(() => {
        this.setState({mounted:true})
        
      }, 2000);
      
      setInterval(this.commonTimer, 1000);
      this.checkYtubeReady(); 
  }
  checkYtubeReady = () =>{
      if(window.ytimeout){
          clearTimeout(window.ytimeout);
      }
      if(window.youtubeReady ){
        this.initializeYPlayer();
      }else{
        window.ytimeout = setTimeout(this.checkYtubeReady, 1000);
      }
  }
  onSettingsContinueClicked = (camen,micen) =>{
    if(!window.init){
        this.onApiCall();
    }
    window.init = true;
    //this.setState({audioEnabled:micen,videoEnabled:camen})
    //if(!micen){
    this.setState({audioEnabled:micen})
//}
    if(!camen){
        this.setState({videoEnabled:false})
    }
  }
  showSpeaking  = (data) => {
    if(document.getElementsByClassName(data.windowId+'-audio')[0]){
        if(data.value < 40){
            document.getElementsByClassName(data.windowId+'-audio')[0].style.border = (data.value/10)+"px solid yellow";
        }else if(data.value >= 40 && data.value < 80){
            document.getElementsByClassName(data.windowId+'-audio')[0].style.border = (data.value/10)+"px solid #d6742e";
        }else{
            document.getElementsByClassName(data.windowId+'-audio')[0].style.border = (data.value/10)+"px solid rgb(214 46 46 / 100%)";
        }
        clearTimeout(window.speakingTimeout[data.windowId])
        window.speakingTimeout[data.windowId] = setTimeout((windowId)=>{
            if(document.getElementsByClassName(windowId+'-audio')[0]){
                document.getElementsByClassName(windowId+'-audio')[0].style.border = "0px solid rgb(214 46 46 / 50%)";
            }
        },2000,data.windowId)
    }
    if(!this.speakingLocked){
        this.speakingLocked = true;
        if(window.lockTimeout){
            clearTimeout(window.lockTimeout)
        }
        window.lockTimeout = setTimeout(() => {
            this.speakingLocked = false;
        }, 4000);

        if(document.getElementsByClassName(data.windowId)[0]){
            document.getElementById('remote-video').prepend(document.getElementsByClassName(data.windowId)[0])
            var elts = document.getElementsByClassName("video-wraper");
            for(var e = 0; e < elts.length; e++) { // For each element
                    var elt = elts[e];
                    //elt.classList.remove('OT_big')
            }
            //document.getElementsByClassName(data.windowId)[0].classList.add('OT_big')
        }
    }
}
  afterSecurityCheck = () =>{
    let device = UserSession.getSession().device;
    //alert(JSON.stringify(UserSession.getSession()))
    window.speakingTimeout = {};
    if(this.state.userMode === 'presenter' || !this.state.WEBINAR){
      document.getElementById('remote-video').appendChild(this.renderVideoParent('ME('+this.userName+')','local-video',false,'0'))
    }
    this.layoutVideos();
    // alert(this.room+'//'+this.unAutorised)
    if(this.unAutorised){
        this.props.alert('Not authorized to enter host page')
       // this.onHangupCall();
       // return;
    }
    
    setTimeout(() => {
        if(!this.state.meetingStarted && !this.state.public){
            this.props.alert('Please click the start button from the bottom of the list to start the meeting.');
        }
    }, 5000);
    /*
    setTimeout(() => {
        if(this.state.meetingStarted && !this.state.public){
            this.dialog.show('autoplay_block','Confirm','You have already started the meeting, The connected peoples can able to hear you.');
            this.speechRef.changeMicStatus(true);
        }
    }, 3000);
    */
    
   
    window.addEventListener("beforeunload", this.unload);
    if(this.room){
        this.joinPublicUser();
    }

    if(device !== 'mobile'){
            if(CommonFunctions.getOS() == 'Windows' || CommonFunctions.getOS() == 'Mac OS' || CommonFunctions.getOS() == 'Linux'){
                if(CommonFunctions.getBrowser() == 'Chrome' || CommonFunctions.getBrowser() == 'Firefox' || CommonFunctions.getBrowser() == 'Safari' || CommonFunctions.getBrowser() == 'Edge'){
                        this.setState({screenShareSupported:true})
                }
            }
            if(CommonFunctions.getBrowser() == 'Edge'){
                //this.props.alert('Calling feature have limitted support in this browser, We are recommending to use Google Chrome, But still you could use this browser');
                //this.setState({callSupported:false})
            }else if(CommonFunctions.getOS() == 'Mac OS' || CommonFunctions.getOS() == 'Windows' || CommonFunctions.getOS() == 'Linux'){
                if(CommonFunctions.getBrowser() != 'Chrome' && CommonFunctions.getBrowser() != 'Firefox' && CommonFunctions.getBrowser() != 'Safari'){
                        //this.props.alert('We are recommending to use Google Chrome in desktops(Mac,Windows,Linux) for better connectivity.');
                }
                if(CommonFunctions.getBrowser() == 'Safari'){
                    this.props.alert('For better experience please use Google Chrome or Firefox Browser, Safari recently start support for web based video & audio communications, so it have some limitations.');
                }
            }
            
            if(CommonFunctions.getOS() == 'Android'){
                if(CommonFunctions.getBrowser() != 'Chrome'){
                    this.props.alert('Browser not supported ! Calling feature works only in Google Chrome browser for Android phones.');
                    this.setState({callSupported:false})
                }
                this.setState({isWeb:false})
            }
            //alert(CommonFunctions.getOS()+'//'+CommonFunctions.getBrowser())
            if(CommonFunctions.getOS() == 'iOS'){
            // alert(CommonFunctions.getBrowser())
                if(CommonFunctions.getBrowser() != 'Mobile Safari' && CommonFunctions.getBrowser() != 'Chrome'){
                    this.props.alert('Browser not supported ! Calling feature works only in safari browser for IOS devices.');
                        this.setState({callSupported:false})
                }else{
                    // this.props.alert('Iphone / Ipad recently start support for web based video & audio communications, so it have some limitations.');
                }
            }
    }
    this.layout = false;
    window.addEventListener('resize', ()=>{
        if(this.layout){
            if(!document.getElementById('remote-video').classList.contains("screen-layout")){
                 this.layout();
            }
        }
    });
    setTimeout(() => {
        this.setState({encryptTxtHide:true})
    }, 10000);
  }

  layoutVideos = ()=>{
      try{
        setTimeout(()=>{
            if(!document.getElementById('remote-video').classList.contains("screen-layout")){
                if(!this.layout){
                    this.layout = window.initLayoutContainer(document.getElementById("remote-video"),{
                        maxRatio: 3/4,             // The narrowest ratio that will be used (default 2x3)
                        minRatio: 9/16,            // The widest ratio that will be used (default 16x9)
                        /*
                        fixedRatio: 3/4,         // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
                        */
                        scaleLastRow: true,        // If there are less elements on the last row then we can scale them up to take up more space
                        alignItems: 'center',      // Can be 'start', 'center' or 'end'. Determines where to place items when on a row or column that is not full
                        bigClass: "OT_big",        // The class to add to elements that should be sized bigger
                        bigPercentage: 0.8,        // The maximum percentage of space the big ones should take up
                        bigFixedRatio: false,      // fixedRatio for the big ones
                        bigScaleLastRow: true,     // scale last row for the big elements
                        bigAlignItems: 'center',   // How to align the big items
                        smallAlignItems: 'center', // How to align the small row or column of items if there is a big one
                        maxWidth: Infinity,        // The maximum width of the elements
                        maxHeight: Infinity,       // The maximum height of the elements
                        smallMaxWidth: Infinity,   // The maximum width of the small elements
                        smallMaxHeight: Infinity,  // The maximum height of the small elements
                        bigMaxWidth: Infinity,     // The maximum width of the big elements
                        bigMaxHeight: Infinity,    // The maximum height of the big elements
                        bigMaxRatio: 3/2,          // The narrowest ratio to use for the big elements (default 2x3)
                        bigMinRatio: 9/16,         // The widest ratio to use for the big elements (default 16x9)
                        bigFirst: true,            // Whether to place the big one in the top left (true) or bottom right (false).
                                                   // You can also pass 'column' or 'row' to change whether big is first when you are in a row (bottom) or a column (right) layout
                        animate: false,             // Whether you want to animate the transitions using jQuery (not recommended, use CSS transitions instead)
                        window: window,            // Lets you pass in your own window object which should be the same window that the element is in
                        ignoreClass: 'OT_ignore',  // Elements with this class will be ignored and not positioned. This lets you do things like picture-in-picture
                        onLayout: null,            //  A function that gets called every time an element is moved or resized, (element, { left, top, width, height }) => {} 
                        }).layout;
                }
                this.layout();
                this.setState({numSubs:document.getElementById('remote-video').childElementCount});
            }
            
        },50);
        setTimeout(()=>{
            if(!document.getElementById('remote-video').classList.contains("screen-layout")){
                if(!this.layout){
                        this.layout = window.initLayoutContainer(document.getElementById("remote-video"),{animate: false,bigClass: "OT_big",}).layout;
                    }
                    this.layout();
                    this.setState({numSubs:document.getElementById('remote-video').childElementCount});
            }
        },500);
    }catch(e){

    }

    if(document.getElementById('remote-video').classList.contains("screen-layout")){
        var elts = document.getElementsByClassName("video-wraper");
        for(var e = 0; e < elts.length; e++) { // For each element
        var elt = elts[e];
        elt.removeAttribute("style");
}
    }
}
 
  componentDidUpdate = (prevProps) => {
    if(prevProps!== this.props) {
      this.setState({user_id:this.props.currentChatUser.user_id,first_name:this.props.currentChatUser.full_name});
    }
  }
  sendToPeerServer = (message)  => {
    message.room = this.room;
    SocketHandler.onSendWebrtcSignal(message);
  
};
  send = (method, entity, body, onRes)  => {
      
            entity = 'https://'+Config.DOMAIN + entity;
            var req = new XMLHttpRequest();
            req.onreadystatechange = function() {
                if (req.readyState === 4) {
                    onRes(req.responseText);
                }
            };
            req.open(method, entity, true);
            req.setRequestHeader('Content-Type', 'application/json');
            if (body !== undefined) {
                req.send(JSON.stringify(body));
            } else {
                req.send();
            }
        
    
};
onResponse(response){

}
unload = () =>{
    this.unPublishStream();
    this.onStopScreenShare();
}
initConference = () =>{
    this.setState({loader:'CONNECTING'})
    if(this.state.PEER_TO_PEER){
        this.joinPublicUserPeer(this.state.videoEnabled);
    }else{
        this.conference = new window.Owt.Conference.ConferenceClient(this.configuration);
        this.createToken();
    }
    
    this.addUserMeeting();
}
onAcceptCallReply = (room)=>{
    
    this.room = room;
    this.initConference();
 }

 onAcceptRing = (user_id,callback)=>{
        CommonFunctions.generateMediaServerRoom((roomId)=>{
            this.room = roomId;
            this.setState({acceptedMe:user_id})
            this.initConference();
            callback(this.room);
        });
        
  }
joinPublicUser = ()=>{
    //alert(this.state.meetingStarted+'//'+this.state.public+'//'+this.callToken)
    if(this.state.meetingStarted || (!this.state.public || this.callToken)){
        this.initConference();
    }else{
        
        this.addUserMeeting();
    }
    SocketHandler.checkRoomStatus({room:this.room})
  }

  addUserMeeting = () =>{
    if(!this.userName){
        this.userName = UserSession.getSession().userDetail.first_name;
    }
    // console.log(this.userName)
    let addData = {meeetingUserId:this.meeetingUserId,room:this.room,userId:this.name,userName:this.userName,videoEnabled:this.state.videoEnabled,audioEnabled:this.state.audioEnabled,userMode:this.state.userMode,public:this.state.public,wbAllowed:this.state.wbAllowed,screenAllowed:this.state.screenAllowed, handRaised:this.state.isHandRaised,windowId:this.windowId,temphost:this.temphost,phone:0,webinar:this.state.WEBINAR};
    if(localStorage.getItem('phone'+this.userName)){
        addData.phone = localStorage.getItem('phone'+this.userName);
    }
    SocketHandler.addUserMeeting(addData);
    if(!this.addUserLoop){
        this.addUserLoop = setInterval(this.addUserMeeting, 5000);
    }
    if(localStorage.getItem('temphost'+this.alias+this.userName)){
        if(!this.revokePow){
            localStorage.setItem('temphost'+this.alias+this.userName,(new Date().getTime() + (60 * 1000)));
        }
    }
    if(!this.public){
        /*
        for(let i =0;i<500; i++){
            let addData = {meeetingUserId:this.meeetingUserId,room:this.room,userId:this.name+'-'+i,userName:this.userName+'-'+i,videoEnabled:this.state.videoEnabled,audioEnabled:this.state.audioEnabled,userMode:this.state.userMode,public:true,wbAllowed:this.state.wbAllowed,screenAllowed:this.state.screenAllowed, handRaised:this.state.isHandRaised,windowId:this.windowId,temphost:this.temphost,phone:0,webinar:this.state.WEBINAR};
            if(localStorage.getItem('phone'+this.userName)){
                addData.phone = localStorage.getItem('phone'+this.userName);
            }
            SocketHandler.addUserMeeting(addData);
        }
        */
    }
    
  }
  commonTimer = () =>{
    //let count = this.state.count;
    this.count ++;
    /*
    if(count % 2 === 0){
        this.setState({recshow:true,count:count})
    }else{
        this.setState({recshow:false,count:count})
    }
    */
   
    if(!this.state.meetingStarted && !this.state.public){
        if(this.count % 10 === 0 && window.init){
            this.showNotification('Session not started yet.No one can hear you !')
        }
    }
    
  }
/**
 * 
 * Webrtc handlers
 */

 
onStartScreenShare = ()=>{
        if(this.state.screenEnabled){
            this.onStopScreenShare();
            return;
        }
        if(this.isScreenShareProgress == true){
            this.props.alert('Someone is already sharing the screen, you could share screen only after that person stops sharing.');
            return;
        }else{
            /*
            if(document.getElementById('remote-video').classList.contains('screen-layout')){
                this.props.alert('You could not share screen at this time because presentation(eg : whiteboard or document ) on progress on main stage');
                return;
            }
            */
        }
        this.startScreenPublish();
   
}
onStopScreenShare = ()=>{
    if(this.state.PEER_TO_PEER){
        this.onStopScreenSharePeer();
    }else{
        if (this.globalScreenPublication) {
            this.globalScreenPublication.stop();
            if (this.mediaScreenStream) {
                this.mediaScreenStream.getVideoTracks().forEach(function(track) {
                    track.stop();
                });
            }
            
            this.globalScreenPublication = null;
        }
    }
    this.setState({screenEnabled:false});
}
 inviteUers = ()=>{
     this.props.inviteUers(this.alias);
 }
editName = ()=>{
    this.props.editName(this.userName,this.name,this.room);
}
 onEnableOrDisableWb = ()=>{
      if(this.state.wbEnabled){
        this.unLoadWb();
        
      }else{
        this.loadWb();
      }
      
      let sendData = {method:'wbEnable',room:this.room,name:'0',status:!this.state.wbEnabled,userId:this.name};
      SocketHandler.sendToAll(sendData);
      
  }
  loadWb = ()=>{
    this.setState({wbEnabled:true,documentOpen:false})
    document.getElementById('remote-video').classList.add('screen-layout');
    this.layoutVideos();
    this.wbRef.updatePage(0)
    this.stopSharedVideo();
    
  }
  unLoadWb = ()=>{
    this.setState({wbEnabled:false})  
    if(document.getElementById('remote-video-screen').childNodes.length === 0){
        document.getElementById('remote-video').classList.remove('screen-layout');
    }
    this.layoutVideos();
}

onEnableOrDisableSpeaker = ()=>{
    let speakerEnabled = this.state.speakerEnabled;
    speakerEnabled = !speakerEnabled
    this.setState({speakerEnabled:speakerEnabled});
    if(speakerEnabled){
        document.getElementById('remoteAudio').muted = false
        if(this.state.PEER_TO_PEER){
             let videos = document.getElementsByClassName('peer-video');
             for(let key in videos){
                 if(videos[key]){
                    videos[key].muted = false
                 }
             }
        }
        
    }else{
        document.getElementById('remoteAudio').muted = true
        if(this.state.PEER_TO_PEER){
            let videos = document.getElementsByClassName('peer-video');
             for(let key in videos){
                 if(videos[key]){
                    videos[key].muted = true
                 }
             }
        }
    }
}
 onEnableOrDisableMic = ()=>{
     if(!window.micAvailable){
          //this.showNotification('Unable to detect microphone')
          //return;
     }
    this.setState({audioProceccing:true})
    if(window.audioProceccingTimeout){
        clearTimeout(window.audioProceccingTimeout);
    }
    window.audioProceccingTimeout = setTimeout(() => {
        this.setState({audioProceccing:false})
    }, 2000);
    let audioEnabled = this.state.audioEnabled;
    audioEnabled = !audioEnabled

    this.setState({audioEnabled:audioEnabled});
    this.onEnableOrDisableMicAction(audioEnabled,this.state.videoEnabled);
    if(audioEnabled){
         this.speechRef.startTranscript();
    }else{
       // this.speechRef.stopTranscript();
    }
    this.speechRef.changeMicStatus(audioEnabled);
      
  }
  onEnableOrDisableMicAction = (audioEnabled,videoEnabled)=>{
      if(this.state.PEER_TO_PEER){
        window.stream.getAudioTracks().forEach( (track) => {
                track.enabled = audioEnabled;
        });
        if(!this.isInitMicLevel){
            this.initMicLevel();
            this.isInitMicLevel = true;
        }
      }else{
        if(audioEnabled){
            if(videoEnabled){
                window.globalPublication.unmute('audio');
            }else{
                this.publishStream(audioEnabled,videoEnabled);
            }
            
        }else{
            if(videoEnabled){
                window.globalPublication.mute('audio');
            }else{
                this.unPublishStream();
            }
            
        }
      }
      let sendData = {method:'micEnabled',room:this.room,name:'0',status:audioEnabled,userId:this.name};
      SocketHandler.sendToAll(sendData);
      
  }
onEnableOrDisableVideo = ()=>{
    if(!window.camAvailable){
        this.showNotification('Unable to detect camera')
        return;
   }
    let videoEnabled = this.state.videoEnabled
    if(videoEnabled){
        videoEnabled = false;
        this.setState({videoEnabled:videoEnabled})
        this.onEnableDisableCamAction(this.state.audioEnabled,videoEnabled,this.state.meetingStarted);
        
    }else{
        this.dialog.show('cam_confirm','Confirm Video Share','Are you sure, do you want to share the following video feed to all users connected in this meeting ?');
    } 
    
}
republish = (videoEnabled,audioEnabled) =>{
    if(window.stream){
        /*
        for(let key in this.participants){
            if(key.indexOf('_screen') === -1){
                this.participants[key].removeStream(window.stream)
            }
        }
        window.stream.getVideoTracks().forEach(function (track) {
            track.stop()
        });
        window.stream.getAudioTracks().forEach(function (track) {
            track.stop()
        });
        */

    }
    if(videoEnabled){
            navigator.getUserMedia({ video: videoEnabled, audio: false }, (myStream) => { 
            
            // this.stream = myStream; 
                // window.stream = myStream;

                window.stream.addTrack(myStream.getVideoTracks()[0])
                document.getElementById('remote-video-local-video').srcObject = this.stream;
                document.getElementById('remote-video-local-video').muted = true;
                
            
                for(let key in this.participants){
                    if(key.indexOf('_screen') === -1){
                        setTimeout(this.createOffer.bind(this),1000,key)
                    }
                }
               
            }, (error) => { 
                this.props.alert("There is some problem for accesing your camera and mic, please allow proper permission and reload this page !"); 
            });
        }else{
            window.stream.getVideoTracks().forEach(function (track) {
                track.stop()
            });
            window.stream.removeTrack(window.stream.getVideoTracks()[0]);
            document.getElementById('remote-video-local-video').srcObject = this.stream;
            document.getElementById('remote-video-local-video').muted = true;
            for(let key in this.participants){
                if(key.indexOf('_screen') === -1){
                // this.participants[key].addStream(window.stream)
                    setTimeout(this.createOffer.bind(this),1000,key)
                }
            }
        }
}
onEnableDisableCamAction = (audioEnabled,videoEnabled,meetingStarted)=>{
    if(this.state.PEER_TO_PEER){
        /*
        if(window.stream){
            window.stream.getVideoTracks().forEach( (track) => {
                track.enabled = videoEnabled;
            });
        }
        */
      // this.handleLeavePeer();
      // setTimeout(this.joinPublicUserPeer(videoEnabled), 1000);
      
        this.republish(videoEnabled,audioEnabled);
        let sendData = {method:'camEnabled',room:this.room,name:this.name,status:videoEnabled,windowId:this.windowId};
        SocketHandler.sendToAll(sendData);
        if(videoEnabled){
             document.getElementById('remote-video-local-video').style.display = 'block';
             document.getElementById('remote-novideo-local-video').style.display = 'none';
        }else{
             document.getElementById('remote-video-local-video').style.display = 'none';
             document.getElementById('remote-novideo-local-video').style.display = 'block';
        }
    }else{
            if(meetingStarted){
                this.publishStream(audioEnabled,videoEnabled);
                let sendData = {method:'camEnabled',room:this.room,name:'0',status:videoEnabled,userId:this.name,windowId:this.windowId};
                SocketHandler.sendToAll(sendData);
                
                if(videoEnabled){
                    document.getElementById('remote-video-local-video').style.display = 'block';
                    document.getElementById('remote-novideo-local-video').style.display = 'none';
                }else{
                    document.getElementById('remote-video-local-video').style.display = 'none';
                    document.getElementById('remote-novideo-local-video').style.display = 'block';
                    
                }
            }else{
                if(videoEnabled){
                    this.accessCam();
                }else{
                    
                    document.exitPictureInPicture()
                    if(window.stream){
                        window.stream.getTracks().forEach((track) =>{
                            track.stop();
                        });
                    }
                    
                }

                
            }
        }
}
accessCam = () =>{
    var constraints = {
        audio: false,
        video: {
            deviceId: window.selectedCam ? {
                exact: window.selectedCam
            } : undefined
        }
    };
navigator.mediaDevices.getUserMedia(constraints).
then(this.gotStreamCam).catch(this.handleError);
}


handleError = (stream) => {

}
gotStreamCam = (stream) => {
    try{
      window.stream = stream; 
      document.getElementById('contenvideo').srcObject = stream;
      document.getElementById('contenvideo').play();
      setTimeout(() => {
        document.getElementById('contenvideo').requestPictureInPicture();
    }, 1000);
    }
    catch(err)
    {
      console.log(err);
    }
}
onLeaveMeeting = () =>{
    if(this.public){
        this.props.confirmLeave('Are you sure, Do you want to leave from this meeting ?',this.alias,this.userName);
    }else{
        this.props.confirmLeave('Are you sure, Do you want to leave from this meeting ? Please transfer the host role to one of the participant, otherwise the entire meeting will end in 5 minute',this.alias,this.userName);
    }
    
}
 onHangupCall = () =>{
    this.handleLeave();
 }
 handleLeave= () => { 
    this.unload();
    document.getElementById('remote-video').innerHTML = '';
    document.getElementById('remote-video-screen').innerHTML = '';
    if(this.conference){
        this.conference.leave(this.token);
    }
     if(this.props.onHangupCall){
        this.props.onHangupCall({user_id:this.state.user_id,full_name:this.state.first_name});
     }
    
 };

 /**
  * INTEL MEDIASERVER CODDING 
  */
 mixStream = (room, stream, view) => {
    var jsonPatch = [{
        op: 'add',
        path: '/info/inViews',
        value: view
    }];
    this.send('PATCH', '/rooms/' + room + '/streams/' + stream, jsonPatch,
        this.onResponse);
};
unmixStream = (room, stream, view) => {
    var jsonPatch = [{
        op: 'remove',
        path: '/info/inViews',
        value: view
    }];
    this.send('PATCH', '/rooms/' + room + '/streams/' + stream, jsonPatch,
        this.onResponse);
};
 createToken = () =>{
    var body = {
        room: this.room,
        user: 'user',
        role: 'presenter'
    };
    this.send('POST', '/tokens/', body, this.onTokenResponse.bind(this));
}
onTokenResponse = (token)=>{
    let connected = false;
    this.token = token;
    try{
        if (token) {
            var respOb = JSON.parse(atob(token));
            if (respOb.secure == true) {
                this.connectionSuccess = true;
                //alert('Server connection successfull');
                this.joinConference();
                connected = true;

            }else{
                this.props.notAutherized('Un authorized')
            }
        }
    }catch(e){
        this.props.notAutherized('Un authorized')
    }
}
joinConference= () => {

    if(!this.userName){
        this.userName = UserSession.getSession().userDetail.first_name;
    }
    this.conference.join(this.token).then(resp => {
        this.setState({loader:false})
        this.setState({connected:true})
        var streams = resp.remoteStreams;
        for (const stream of streams) {
            if (stream.source.audio === 'mixed' || stream.source.video ===
                'mixed') {
                this.subscribeStream(stream, true);
            } else {
                this.subscribeStream(stream, false);
            }
        }
        if(!this.public){
            if(!window.micAvailable){
                this.setState({audioEnabled:false})
            }else{
                this.publishStream(this.state.audioEnabled,this.state.videoEnabled);
            }
        }else{
            //this.setState({userMode:'presenter'})
            if(this.state.audioEnabled || this.state.videoEnabled){
                this.publishStream(this.state.audioEnabled,this.state.videoEnabled);
            }
            
        }
        
    }, function(err) {
        alert('Room join failed');
    });

    this.conference.addEventListener('streamadded', (event) => {
        let stream = event.stream;
        if (stream.source.audio === 'mixed' || stream.source.video ===
            'mixed') {
            this.subscribeStream(stream, true);
        } else {
            this.subscribeStream(stream, false);
        }
    });
    this.conference.addEventListener('serverdisconnected', (event) => {
        
    });
}
subscribeStream = (stream, isMixed) => {
    if (isMixed) {
        this.mixedStreamId = stream.id;
        this.conference.subscribe(stream, {
            audio: {
                codecs: [{
                    name: 'opus'
                }]
            },
            video: false
        }).then((subscription) => {
            this.subscriptionForMixedStream = subscription;
            document.getElementById('remoteAudio').srcObject = stream.mediaStream;
            subscription.addEventListener('error', (err) => {
                console.log('Subscription error: ' + err.error.message);
            })
            subscription.addEventListener('ended', (err) => {
                setTimeout(() => {
                    this.props.alert('Your were disconnected, may be due to internet fluctuation, You will automatically reconnect in 10 seconds');
                }, 1000);
                
                setTimeout(() => {
                    window.location.reload();
                }, 10000);
            })
            setTimeout(() =>{
                let video = document.getElementById('remoteAudio');
                
                var promise = video.play();
                if (promise !== undefined) {
                promise.then(_ => {
                    console.log('video playing ok...')
                }).catch(error => {
                    console.log(error)
                    this.dialog.show('autoplay_block','Confirm','Application needs your permission to play sound on this page ?');
                    //alert('AUTOPLAY BLOCKED IN YOUR BROWSER, PLEASE RELOAD AND TRY AGAIN');
                    setTimeout(function(){
                        
                    },10000);
                    
                });
                }
             },1000);
        });
    }
    let streamAttributes = stream.attributes ? stream.attributes:{};
    if((!Config.MCU && isMixed == false) || streamAttributes.isScreen) {
        if (typeof(streamAttributes) == 'undefined') {
            return;
        }

        
        if (streamAttributes.windowId == this.windowId || !stream.source) {
            return;
        }
        let audio = false;
        let video = stream.source.video ? true:false;
        if(stream.source.audio && streamAttributes.isScreen){
            audio = true;
        }
        if(this.state.WEBINAR && !streamAttributes.isScreen){
            if(document.getElementsByClassName(streamAttributes.windowId).length === 0) {
                document.getElementById('remote-video').appendChild(this.renderVideoParent(streamAttributes.name,streamAttributes.windowId,streamAttributes.isScreen,streamAttributes.windowId));
            }
            
        }
        if(video){
            this.conference.subscribe(stream, {
                audio: audio,
                video: video
            }).then((subscription) => {
                
                if(streamAttributes.isScreen){
                    document.getElementById('remote-video-screen').appendChild(this.renderVideoParent(streamAttributes.name,stream.id,streamAttributes.isScreen,streamAttributes.windowId));
                    document.getElementById('remote-video-screen').style.display = 'block';
                    
                }else{
                    if(document.getElementsByClassName(streamAttributes.windowId).length > 0) {
                        this.replaceIds(streamAttributes.windowId,stream.id)
                    }else{
                        if(!this.state.WEBINAR){
                        document.getElementById('remote-video').appendChild(this.renderVideoParent(streamAttributes.name,stream.id,streamAttributes.isScreen,streamAttributes.windowId));
                        }
                    }
                    
                }
                document.getElementById('remote-video-'+stream.id).srcObject = stream.mediaStream;
                document.getElementById('remote-video-'+stream.id).style.display = 'block';
                document.getElementById('remote-novideo-'+stream.id).style.display = 'none';
                if(streamAttributes.isScreen){
                    document.getElementById(stream.id).classList.add('screen-video')
                    document.getElementById('remote-video').classList.add('screen-layout');
                    this.isScreenShareProgress = true;
                }
                this.layoutVideos();
                subscription.addEventListener('error', (err) => {
                    if(streamAttributes.isScreen){
                        if(!this.state.wbEnabled){
                            document.getElementById('remote-video').classList.remove('screen-layout');
                            
                        }
                        this.isScreenShareProgress = false;
                        document.getElementById('remote-video-screen').style.display = 'none';
                        var element = document.getElementById(stream.id);
                        element.parentNode.removeChild(element);
                        
                    }else{
                        if(document.getElementById('remote-video-'+stream.id)){
                         document.getElementById('remote-video-'+stream.id).style.display = 'none';
                         document.getElementById('remote-novideo-'+stream.id).style.display = 'block';
                        }
                    }
                    this.layoutVideos();
                })
            });
        }
    }
}
replaceIds = (className,id) =>{
    document.getElementsByClassName(className)[0].id = id;
    document.getElementsByClassName('remote-name-'+className)[0].id = 'remote-name-'+id;
    document.getElementsByClassName('remote-video-'+className)[0].id = 'remote-video-'+id;
    document.getElementsByClassName('remote-novideo-'+className)[0].id = 'remote-novideo-'+id;
}
renderVideoParent = (name,id , isScreen, windowId)=>{
    let videoParent = document.createElement('div');
        videoParent.id = id;
        videoParent.classList.add('video-wraper');
        if(isScreen == false){
            videoParent.classList.add(windowId);
        }
        let label = name
        let nameDiv = document.createElement('div')
        nameDiv.id = 'remote-name-'+id;
        if(isScreen == false){
            nameDiv.classList.add('remote-name-'+windowId);
        }
        nameDiv.innerHTML = label;
        nameDiv.classList.add('video-name');
        videoParent.appendChild(nameDiv)

        let video = document.createElement ("video");
        video.id = 'remote-video-'+id;
        if(isScreen == false){
            video.classList.add('remote-video-'+windowId);
            if(this.state.PEER_TO_PEER){
                video.style.display = 'none';
            }
            if(windowId !== '0'){
                video.classList.add('peer-video');
            }
        }
        video.controls = false;
        video.autoplay = true;
        video.muted = true;
        video.playsInline = true;
        video.disablePictureInPicture = true;
        
        videoParent.appendChild(video)
/*
        let audioIndicator = document.createElement('div');
        audioIndicator.classList.add('audio-indicator');
        audioIndicator.classList.add(windowId+'-audio');
        videoParent.appendChild(audioIndicator)

        /*
        let placeholder = document.createElement('img')
        placeholder.src = '/files/placeholder.png';
        placeholder.id = 'remote-novideo-'+id;
        videoParent.appendChild(placeholder)
        */
        let placeholder = document.createElement('div')
        placeholder.id = 'remote-novideo-'+id;
        placeholder.classList.add('video-poster');
        if(isScreen == false){
            placeholder.classList.add('remote-novideo-'+windowId);
        }

        let thumbPar = document.createElement('div')
        thumbPar.classList.add('vdo-user-avatar-blk');
        placeholder.appendChild(thumbPar)

        let thumbName = document.createElement('div');
        thumbName.classList.add('vdo-user-avatar');
        thumbName.classList.add(windowId+'-audio');
        thumbName.innerHTML = label.charAt(0).toUpperCase()+''+label.charAt(1).toUpperCase()
        thumbPar.appendChild(thumbName)
        videoParent.appendChild(placeholder)
        console.warn('#### rendering')
        return videoParent;
}
startScreenPublish = () =>{
    if(this.state.PEER_TO_PEER){
        this.onStartScreenSharePeer();
    }else{
        let resolution                  = new window.Owt.Base.Resolution(window.screen.width*1.1,window.screen.height*1.1);
        let audioConstraintsForMic      = true
        let videoConstraintsForCamera   = new window.Owt.Base.VideoTrackConstraints(window.Owt.Base.VideoSourceInfo.SCREENCAST);
        let constraints = new window.Owt.Base.StreamConstraints(audioConstraintsForMic, videoConstraintsForCamera);
        videoConstraintsForCamera.resolution = resolution;
        window.Owt.Base.MediaStreamFactory.createMediaStream(constraints).then(stream => {
            this.mediaScreenStream = stream;
            let streamAttribute         = {};
            streamAttribute.name        = this.userName;
            streamAttribute.room        = this.room;
            streamAttribute.isScreen    = true;
            streamAttribute.windowId    = this.windowId;
            document.getElementById('local-screen-video').srcObject = stream;
            setTimeout(() => {
                try{
                    //document.getElementById('local-screen-video').requestPictureInPicture();
                }catch(e){
    
                }
            }, 1000);
            window.ScreenStream = stream;
            this.localScreenStream = new window.Owt.Base.LocalStream(
                stream, new window.Owt.Base.StreamSourceInfo(
                    'mic', 'camera'), streamAttribute);
            this.conference.publish(this.localScreenStream).then(publication => {
                this.globalScreenPublication = publication;
                this.mixStream(this.room, publication.id, 'common');
                stream.getVideoTracks()[0].onended = () => {
                    this.onStopScreenShare();
                };
                publication.addEventListener('error', (err) => {
                    this.onStopScreenShare();
                });
                publication.addEventListener('ended', (err) => {
                    this.onStopScreenShare();
                });
                this.setState({screenEnabled:true});
            });
        }, err => {
           
        });
    }
}
publishStreamFromSettings = ()=> {
     this.publishStream(this.state.audioEnabled,this.state.videoEnabled);
}
publishStream = (audioEnabled,videoEnabled) => {
    
    this.unPublishStream();
    if(window.publishTimeout){
        clearTimeout(window.publishTimeout)
    }
    if(this.state.WEBINAR){
        if(!document.getElementById('local-video')){
            document.getElementById('remote-video').appendChild(this.renderVideoParent('ME('+this.userName+')','local-video',false,'0'))
        }
    }
    window.publishTimeout = setTimeout(this.publishStreamInAction, 1000, audioEnabled,videoEnabled);
    
}
publishStreamInAction = (audioEnabled,videoEnabled) => {
        if(audioEnabled == false && videoEnabled == false){
            return;
        }
        let resolution                  = new window.Owt.Base.Resolution(320,240);
        let audioConstraintsForMic      = new window.Owt.Base.AudioTrackConstraints(window.Owt.Base.AudioSourceInfo.MIC);
        let videoConstraintsForCamera   = new window.Owt.Base.VideoTrackConstraints(window.Owt.Base.VideoSourceInfo.CAMERA);
        
        if(videoEnabled === false){
            videoConstraintsForCamera = false;
            document.getElementById('remote-video-local-video').style.display = 'none';
            document.getElementById('remote-novideo-local-video').style.display = 'block';
        }else{
            videoConstraintsForCamera.resolution = resolution;
            videoConstraintsForCamera.frameRate  = 30;
            if(window.selectedCam){
              videoConstraintsForCamera.deviceId = window.selectedCam;  
            }
        }
        
        if(window.selectedMic){
            audioConstraintsForMic.deviceId = window.selectedMic;
        }
        if (window.chrome) {
            audioConstraintsForMic.mandatory = {echoCancellation: true}
        } else {
            audioConstraintsForMic.echoCancellation = true
        }

        //this.setState({loader:'Publishing Media'})
        let constraints = new window.Owt.Base.StreamConstraints(audioConstraintsForMic, videoConstraintsForCamera);
        window.Owt.Base.MediaStreamFactory.createMediaStream(constraints).then(stream => {
            window.globalMediaStream = stream;
            let streamAttribute         = {};
            streamAttribute.name        = this.userName;
            streamAttribute.room        = this.room;
            streamAttribute.isScreen    = false;
            streamAttribute.windowId    = this.windowId;
            document.getElementById('remote-video-local-video').srcObject = stream;
            document.getElementById('remote-video-local-video').muted = true;
            this.localStream = new window.Owt.Base.LocalStream(
                stream, new window.Owt.Base.StreamSourceInfo(
                    'mic', 'camera'), streamAttribute);
            this.conference.publish(this.localStream).then(publication => {
                window.globalPublication = publication;
                if(!audioEnabled){
                    window.globalPublication.mute('audio');
                }
                this.mixStream(this.room, publication.id, 'common');
                publication.addEventListener('error', (err) => {
                    this.props.alert('Publishing failed, application will restart in a moment. Error : '+err);
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                });
                publication.addEventListener('ended', (err) => {
                    setTimeout(() => {
                        this.props.alert('Your were disconnected, may be due to internet fluctuation, You will automatically reconnect in 10 seconds');
                    }, 1000);
                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 10000);
                });
                if(window.afterPubCheckTimeout){
                    clearTimeout(window.afterPubCheckTimeout);
                }
                window.afterPubCheckTimeout = setTimeout(() => {
                    if(!this.state.videoEnabled && !this.state.audioEnabled){
                        this.unPublishStream();
                    }else if(this.state.videoEnabled && !this.state.audioEnabled){
                        window.globalPublication.mute('audio');
                    }
                }, 2000);
            });
            if(!this.isInitMicLevel){
                this.initMicLevel();
                this.isInitMicLevel = true;
            }
        }, err => {
            this.props.alert('Publishing failed, application will restart in a moment. Error : '+err);
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        });
    
}

initMicLevel = () =>{
    navigator.mediaDevices.getUserMedia({video:false,audio:true}).
            then(this.gotStream).catch(this.handleError);
}
handleError = (stream) => {

}
gotStream = (stream) => {
    try{
      this.micLevelIndicator(stream);
    }
    catch(err)
    {
      console.log(err);
    }
}
micLevelIndicator = (stream) => {
    try {
        if(CommonFunctions.getBrowser() === 'Safari' || CommonFunctions.getBrowser() === 'Mobile Safari'){
            var audioContext = new window.webkitAudioContext;
        }else{
            var audioContext = new AudioContext; //or webkitAudioContext
            
        }
        
        var source = audioContext.createMediaStreamSource(stream);
        var analyser = audioContext.createAnalyser();
        var scriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        var volume = audioContext.createGain();
        source.connect(volume);
        volume.gain.value = 0; //turn off the speakers
        volume.connect(audioContext.destination);


        analyser.smoothingTimeConstant = 0.3;
        analyser.fftSize = 1024;
        analyser.minDecibels = -90;
        analyser.maxDecibels = -10;

        source.connect(analyser);
        analyser.connect(scriptNode);
        scriptNode.connect(audioContext.destination);
        scriptNode.onaudioprocess = () => {
                var array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                var values = 0;

                var length = array.length;
                for (var i = 0; i < length; i++) {
                    values += array[i];
                }
                var average = (values / length);
                average = average * 3;
                // console.log('audio level : '+average)
                if(average > 100){
                    average = 100;
                }
                if(average > 20 && this.state.audioEnabled){
                    // console.log('send : '+average)
                    if(!window.isSpeakBlock){
                        let sendData = {method:'isSpeaking',room:this.room,value:average,windowId:this.windowId};
                        SocketHandler.sendToAll(sendData);
                        window.isSpeakBlock = true;
                        if(window.isSpkSendTimeout){
                            clearTimeout(window.isSpkSendTimeout)
                        }
                        window.isSpkSendTimeout = setTimeout(() => {
                            window.isSpeakBlock = false;
                        }, 3000);
                    }
                    

                    this.showSpeaking({windowId:'0',value:average})
                    this.sedeBarRef.showSpeaking({windowId:this.windowId,value:average})
                }
                
            
        }

    } catch (e) {
         
    }

}
unPublishStream = () => {
    if(this.state.PEER_TO_PEER){
        this.disableVideo();
        this.disableAudio();
    }else{
        if (window.globalPublication) {
            this.unmixStream(this.room, window.globalPublication.id, 'common');
            window.globalPublication.stop();
            window.globalMediaStream.getTracks().forEach((track) =>{
                //alert('stoped')
                track.stop();
            });
        }
    }
    
}

startOrStopMeeting = () =>{
    if(this.state.meetingStarted){
        this.dialog.show('meeting_end','Confirm','Are you sure? Do you want to end current meeting ?');
    }else{
        this.startOrStopMeetingInAction();
    }
    this.speechRef.startTranscript();
}
startOrStopMeetingInAction = () =>{
    let sendData = {};
    if(this.state.meetingStarted){
        this.props.confirmLeave('Your meeting has now ended, all participants have been disconnected from this session.Do you want to stay on this page ?',this.alias,this.userName);
        this.setState({meetingStarted: false});
        sendData = {method:'meetingStatus',room:this.room,status:false};
        this.setState({startedTxt:'Meeting Stopped'})
        if(this.state.videoEnabled){
            this.onEnableOrDisableVideo();
        }
        this.sedeBarRef.setMeetingStarted(false);

        if(this.state.isRecording){
            this.startOrStopRecording();
        }
        if(this.state.wbEnabled){
            this.onEnableOrDisableWb();
        }
    }else{
        this.setState({meetingStarted: true});
        
        sendData = {method:'meetingStatus',room:this.room,status:true};
        if(this.state.videoEnabled){
            document.exitPictureInPicture()
            if(window.stream){
                window.stream.getTracks().forEach((track) =>{
                    track.stop();
                });
            }
            this.onEnableDisableCamAction(this.state.audioEnabled,true,true);

        }
        this.sedeBarRef.setMeetingStarted(true);
        //window.location.reload();
    }
    SocketHandler.sendToAll(sendData);
}
lockOrUnlockMeeting = () =>{
    let sendData = {};
    if(this.state.locked){
        this.setState({locked: false});
        sendData = {method:'lockStatus',room:this.room,status:false};
        this.showNotification('Meeting un locked successfully')
    }else{
        this.setState({locked: true});
        sendData = {method:'lockStatus',room:this.room,status:true};
        this.showNotification('Meeting locked successfully')
    }
    SocketHandler.sendToAll(sendData);
}
enableOrDisabeCaption = () =>{
    let sendData = {};
    if(this.state.caption){
        this.setState({caption: false});
        sendData = {method:'captionstate',room:this.room,status:false};
        this.showNotification('Caption disabled successfully')
        this.sedeBarRef.setCaption(false)
    }else{
        this.setState({caption: true});
        sendData = {method:'captionstate',room:this.room,status:true};
        this.showNotification('Caption enabled successfully')
        this.sedeBarRef.setCaption(true)
    }
    SocketHandler.sendToAll(sendData);
}

showAlert = (msg) =>{
    this.props.alert(msg);
}
storeRecord = () => {
    const meetdata = JSON.parse(localStorage.getItem('meetData'));
    let payload;
    if(meetdata.class_id !== null) {
        payload = {
            unique_name:this.recordId,
            room_id: this.room,
            group_id:meetdata.class_id,
            file_name:meetdata.session_name,
            record_desc:"testfile",
            record_file_path:"/testfilepath",
            title:meetdata.session_name,
        }
    } else {
        payload = {
            unique_name:this.recordId,
            room_id: this.room,
            user_id:meetdata.user_id,
            file_name:meetdata.session_name,
            record_desc:"testfile",
            record_file_path:"/testfilepath",
            title:meetdata.session_name,
        }
    }
        RequestHandler.post(Config.API_DOMAIN+'/canstring/v1/meets/records', payload,(response)=>{
            if(response.success === true){
                // this.setState({chatList:response.chatlist})
            }else{
                alert(response.message);
            } 
    })
}

startOrStopRecording = () =>{
    this.setState(prevState => ({
        isRecording: !prevState.isRecording
    }));
    if(this.state.isRecording){
        this.stopRecordingCall(this.room,this.recordId);
        let sendData = {method:'recordingStatus',room:this.room,status:false};
        SocketHandler.sendToAll(sendData);
        this.setState({recordingStatus:false})
    }else{
        this.startRecordingCall(this.room,this.mixedStreamId,this.mixedStreamId,null);
        
    }
    
}
startOrStopContentCreation = () =>{
    this.setState(prevState => ({
        isRecording: !prevState.isRecording
    }));
    if(this.state.isRecording){
        this.stopRecordingCall(this.room,this.recordId);
        if(this.state.videoEnabled){
            this.onEnableOrDisableVideo();
        }
        if(this.state.screenEnabled){
            this.onStartScreenShare();
        }
    }else{
        this.startRecordingCall(this.room,this.mixedStreamId,this.mixedStreamId,null);
        if(!this.state.videoEnabled){
            this.onEnableOrDisableVideo();
        }
        if(!this.state.screenEnabled){
            this.onStartScreenShare();
        }
    }
    
}

stopRecordingCall = (room, id) => {
    this.send('DELETE', '/rooms/' + room + '/recordings/' + id, undefined,(data)=>{
            this.recordId = false;
            this.props.alert('Recording saved successfully');
    });

    var options = {
        fileName:id+'.mkv',
        email:UserSession.getSession().email_id
    };
    RequestHandler.postPlain(Config.MEDIA_SERVER_DOMAIN+'/moveRecording', options,(response)=>{
        console.log(response)
    })
};

startRecordingCall = (room, audioFrom, videoFrom, container) => {
    var options = {
        media: {
            audio: {
                from: audioFrom
            },
            video: {
                from: videoFrom
            }
        },
        container: (container ? container : 'auto')
    };
    this.send('POST', '/rooms/' + room + '/recordings', options, (data)=>{
            data = JSON.parse(data);
            if (data.id) {
                this.recordId = data.id;
                this.storeRecord();
                let sendData = {method:'recordingStatus',room:this.room,status:this.recordId};
                SocketHandler.sendToAll(sendData);
                this.setState({recordingStatus:this.recordId})
                //SocketHandler.saveRecordings({room:this.room,id:this.recordId});
            }
    });
};
leftPannelOpen = (tab) =>{
    this.sedeBarRef.selectTab(tab)
    this.setState({pListOpen:true,participantHiddenCount:0,chatHiddenCount:0})
    this.layoutVideos();
    if(tab === 'chat'){
        this.setState({chatCnt:0})
    }
    //this.wbRef.whiteboardDocPageSwitch();
    //this.wbRef.changeCanvasSize();
}

closeLeftPannel = () =>{
    this.setState({pListOpen:false})
    this.layoutVideos();
    //this.wbRef.changeCanvasSize();
    //this.wbRef.whiteboardDocPageSwitch();
}
sendRaiseHandRequest = () =>{
    this.setState(prevState => ({
        isHandRaised: !prevState.isHandRaised
      }));
    this.showNotification('Your hand has been raised, host has been notified');
    let sendData = {method:'onRaiseHand',room:this.room,status:!this.state.isHandRaised,userId:this.name,userName:this.userName};
    SocketHandler.sendToAll(sendData);
}

closeDoc = () =>{
    this.setState({documentOpen:false,wbEnabled:false,videoOpen:false})
    document.getElementById('remote-video').classList.remove('screen-layout');
    this.stopSharedVideo();
    this.layoutVideos();
    this.wbRef.clearDict();
    this.wbRef.updatePage(0)

    let sendData = {method:'shareDoc',room:this.room,name:'0',status:false,userId:this.name};
    SocketHandler.sendToAll(sendData);

    
}
stopSharedVideo = ()=>{
    this.setState({videoOpen:false})
    document.getElementById('sharedVideo').src = null;

    if (this.player) {
        try {
            this.player.pauseVideo();
        } catch (e) {

        }
    }
}
setIinitialState = (type)=>{
    if(this.initialPaused){
        if(type === 'youtube'){
            this.player.pauseVideo();
        }else{
            document.getElementById('sharedVideo').pause();
            
        }
        
        this.initialPaused = false;
    }else{
        if(type === 'youtube'){
            this.player.playVideo();
            if(this.public){
                document.getElementById('youtube-share').classList.add('nocursr');
           }
        }else{
            document.getElementById('sharedVideo').play();
        }
    }
    if(this.initialVideoTime > 0){
        if(type === 'youtube'){
            this.player.seekTo(parseInt(this.initialVideoTime), true);
        }else{
            document.getElementById('sharedVideo').currentTime = parseInt(this.initialVideoTime)
        }
        this.initialVideoTime = 0;
    }
}
playVideo = (filename)=>{
    if(this.state.videoOpen === 'youtube'){
        if(!this.player){
            this.initializeYPlayer();
        }else{
            if(!this.player.loadVideoById){
                this.initializeYPlayer();
            }else{
                this.player.loadVideoById(filename);
                setTimeout(this.setIinitialState,1000,'youtube');
                
            }
        }
           
            document.getElementById('sharedVideo').src = null;

   
           
    }else{
        let vidurl = 'https://'+Config.DOMAIN+'/'+filename+'?t='+new Date().getTime();
        document.getElementById('sharedVideo').src = vidurl;
        document.getElementById('sharedVideo').play();
        if(this.public){
            document.getElementById('sharedVideo').removeAttribute('controls')
        }
        if (this.player) {
            try {
                this.player.pauseVideo();
            } catch (e) {
    
            }
        }
        this.addMp4VideoListeners(vidurl);
        this.setIinitialState('mp4')
    }
    
}
addMp4VideoListeners = (videoUrl)=>{
    if(this.mp4player){
        this.mp4player.removeEventListener("play");
        this.mp4player.removeEventListener("pause");
        this.mp4player.removeEventListener("mp4Seek");
    }
    this.mp4player = document.getElementById('sharedVideo');
    
    this.mp4player.addEventListener("play", ()=> {
        if(!this.mp4SyncLocked && !this.public){
            SocketHandler.sendToAll({
                method: 'mp4Play',
                url: videoUrl,
                time: this.mp4player.currentTime,
                room:this.room
            });
        }
        
    });
    this.mp4player.addEventListener("pause",  ()=>  {
        if(!this.mp4SyncLocked && !this.public){
            SocketHandler.sendToAll({
                method: 'mp4Pause',
                url: videoUrl,
                time: this.mp4player.currentTime,
                room:this.room
            });
        }
    });
    this.mp4player.onseeking =  ()=> {
        if(!this.mp4SyncLocked && !this.public){
            SocketHandler.sendToAll({
                method: 'mp4Seek',
                url: videoUrl,
                time: this.mp4player.currentTime,
                room:this.room
                
            });
        }
    };
    
}
shareDocument = (fileName,totalPages) =>{
     this.stopSharedVideo();
     this.setState({currentDocument:fileName, totalPages:totalPages,currentPage:1, documentOpen:true,wbEnable:false})
     window.demoLC.clear();
     let sendData = {method:'shareDoc',room:this.room,name:'0',fileName:fileName,totalPages:totalPages,status:true,userId:this.name,currentPage:'1'};
     SocketHandler.sendToAll(sendData);
     document.getElementById('remote-video').classList.add('screen-layout');
    this.layoutVideos();
    this.wbRef.updatePage(1)
}
shareVideo = (fileName,totalPages) =>{
        this.setState({currentDocument:fileName, documentOpen:true,wbEnable:false,videoOpen:totalPages})
        window.demoLC.clear();
        let sendData = {method:'shareDoc',video:totalPages,room:this.room,name:'0',fileName:fileName,totalPages:totalPages,status:true,userId:this.name,currentPage:'1'};
        SocketHandler.sendToAll(sendData);
        document.getElementById('remote-video').classList.add('screen-layout');
        this.layoutVideos();
        this.wbRef.updatePage(1)
        //this.playVideo(fileName);
        setTimeout(this.playVideo,500,fileName);
    
}
initializeYPlayer = () => {
    var playerVars = {
        'controls': 1,
        'showinfo': 0,
        'loop': 1
    };
    this.player = new window.YT.Player('youtube-share', {
        width: 600,
        height: 400,
        videoId: this.state.currentDocument,
        playerVars: playerVars,
        events: {
            onReady: this.initialize,
            onStateChange: this.onPlayerStateChange
        }
    });
}

initialize = () =>{
    this.player.loadVideoById(this.state.currentDocument);
    this.player.playVideo();
    setTimeout(this.setIinitialState,1000,'youtube');
    if(this.public){
         document.getElementById('youtube-share').classList.add('nocursr');
    }
}

onPlayerStateChange = (event) => {
    //if (youtubeIdClientIdMap[currentVideoId]) {
    
    if (!this.public && !this.youTubeSyncLock) {
        if (event.data == window.YT.PlayerState.PLAYING) {
            var moduleData = {};
            moduleData.method = 'youtubePlayOrSeek';
            moduleData.time = this.player.getCurrentTime();
            moduleData.room = this.room;
            SocketHandler.sendToAll(moduleData);
        }
        if (event.data == window.YT.PlayerState.PAUSED) {
            var moduleData = {};
            moduleData.method = 'youtubePause';
            moduleData.time = this.player.getCurrentTime();
            moduleData.room = this.room;
            SocketHandler.sendToAll(moduleData);
        }
    }
    
}
openDocumentSharePopup = () =>{
    this.props.openDocumentPop(this.room);
}
nextPage = () =>{
    let currentPage = this.state.currentPage;
    if(currentPage < this.state.totalPages){
        currentPage++;
        this.setState({currentPage:currentPage})
        let sendData = {method:'pageSwitch',room:this.room,name:'0',totalPages:this.state.totalPages,currentPage:currentPage,userId:this.name,status:true,fileName:this.state.currentDocument};
        SocketHandler.sendToAll(sendData);
        this.wbRef.updatePage(currentPage)
    }
    
}
prevPage = () =>{
    let currentPage = this.state.currentPage;
    if(currentPage > 1){
        currentPage--
        this.setState({currentPage:currentPage})
        let sendData = {method:'pageSwitch',room:this.room,name:'0',totalPages:this.state.totalPages,currentPage:currentPage,userId:this.name,status:true,fileName:this.state.currentDocument};
        SocketHandler.sendToAll(sendData);
        this.wbRef.updatePage(currentPage)
    }
    
}
nextPage = () =>{
    let currentPage = this.state.currentPage;
    if(currentPage < this.state.totalPages){
        currentPage++;
        this.setState({currentPage:currentPage})
        let sendData = {method:'pageSwitch',room:this.room,name:'0',totalPages:this.state.totalPages,currentPage:currentPage,userId:this.name,status:true,fileName:this.state.currentDocument};
        SocketHandler.sendToAll(sendData);
        this.wbRef.updatePage(currentPage)
    }
    
}
zoomIn = () =>{
    if(this.state.zoomCount <= 8){
        this.state.zoomCount++;
        document.getElementById('doc-wrap').width = (100*this.state.zoomCount)+'%';
    }
    
}
zoomOut = () =>{
    if(this.state.zoomCount > 0){
        this.state.zoomCount--;
        document.getElementById('doc-wrap').width = (100*this.state.zoomCount)+'%';
    }
}
closeNotification = () =>{
    this.setState({notification:false})
}
showNotification= (txt) =>{
    /*
    this.setState({notification:txt})
    if(this.timout){
        clearTimeout(this.timout)
    }
    this.timout = setTimeout(() => {
        this.setState({notification:false})
    }, 8000);
    */
   toast.dark(txt , {autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,});
}
closePortraitMsg = () =>{
    this.setState({allowPrtrait:true})
}
hostStatus = (status)=> {
    this.setState({hostAvailable:status})
    if(status === false){
        if(!window.leaveTimeout){
            window.leaveTimeout = setTimeout(this.exitMeeting,60000 * 5)
        }
    }else{
        clearTimeout(window.leaveTimeout)
        window.leaveTimeout = false;
    }
    
}
newChat = (flag) => {
   let chatCnt = this.state.chatCnt;
   chatCnt++;
   if(flag){
        chatCnt = 0;
    }
   this.setState({chatCnt:chatCnt})
   

}
exitMeeting = () => {
    this.unload();
    if(this.conference){
        this.conference.leave(this.token);
    }
    this.setState({connected:false,redirect:"/Thankyou"})
}
showDialog = () => {
   
    this.setState({showDialog:true})
}
onDialogStatus = (data) => {
    this.setState({showDialog:false})
    switch (data.type) {
        case 'cam_confirm':
            if(data.status === true){
                this.setState({videoEnabled:true})
                this.onEnableDisableCamAction(this.state.audioEnabled,true,this.state.meetingStarted);
        
            }
            break;
            
        case 'meeting_end':
            this.startOrStopMeetingInAction();
            break;
        case 'autoplay_block':
            if(data.status === true){
                document.getElementById('remoteAudio').play();
            }
            this.speechRef.startTranscript();
            break;
            
        default:
            break;
    }
}
  onTranscription = (text) => {
    if(this.state.caption){
        if(this.state.audioEnabled){
            this.sedeBarRef.sendChatDialogue(text)
            let sendData = {method:'onTranscript',room:this.room,userId:this.name,userName:this.userName,text:text};
            SocketHandler.sendToAll(sendData);
        }
    }
  }
  render= () => {
    if(this.state.redirect){
        return(
            <Redirect to={this.state.redirect} />
        )
    }
    if(this.state.apiLoaded){
    return (
        <section className="height100-20">
            <div class={this.state.notification ? "d-flex align-items-center slow-down-toast-blk":"hide"}><div class="participant-profile-icon-section"><div class="avatar-section"><div class="avatar_initial avatar-not"></div></div></div><div class="slow-down-txt">{this.state.notification}</div><div onClick={this.closeNotification} class="toast-close-btn">×</div></div>
        <div className={this.state.pListOpen ? "left-pannel window-height":"hide"}>
            <CallSideBarComponent alert = {this.showAlert} newChat={this.newChat} hostStatus={this.hostStatus} publishStreamFromSettings={this.publishStreamFromSettings} showNotification={this.showNotification} editName={this.editName} inviteParticipants={this.inviteUers} closeLeftPannel={this.closeLeftPannel} public={this.state.public} 
                       userId={this.name} alias={this.alias} userName={this.userName} temphost={this.temphost} ref={(sedeBarRef) => this.sedeBarRef = sedeBarRef} webinar={this.state.WEBINAR}/>
        </div>
        <div id="call-open" className="flex-grid widthheight100 window-height">
            <div className={(this.state.wbEnabled || this.state.documentOpen) ? "hide":"sml-logo"}><img src="/files/pure-logo.png" /> <div className="hidemobile hide">Canstring Meet</div></div>
             
            <div appdragdrop="" className="col th-p pos-relative">
            <header className="top-box-shadow hide">
                <div className=" chats width100">
                    <div className="content-wrapper is-clearfix">
                        <div className="chat-name">
                        <div className="is-pulled-left">
                            <i className={this.state.public ? "hide":"fa is-pulled-left fa-chevron-left"} onClick={this.onHangupCall}></i>
                            <div className="display-ib">
                                <div className="empty-head-icon hide">{this.state.first_name && this.state.first_name != 'Group' ?this.state.first_name.charAt(0).toUpperCase():<i className="fa fa-users"/>}</div>
                            </div>
                            <span className="chat-title-name is-size-6 mar-5-0">{this.state.first_name ? this.state.first_name:'Group'}</span>
                            
                        </div>
                        <div className="is-marginless is-clearfix is-pulled-right">
                            <div className="is-pulled-right mar-10-0">
                           
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className={!this.state.hostAvailable && this.state.meetingStarted &&  this.state.public? 'no-host':'hide'}><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Host is disconnected from the meeting, If the host not joined with in five minute then meeting will end</div>
            <div className={this.state.public ? "contents is-marginless parent-callvid blackbg":"contents is-marginless parent-callvid"}>
                <div className={this.state.loader ? 'media-loader':'hide'}>
                    <h1>{this.state.loader}</h1>
                </div>
                <div className={this.state.meetingStarted ? 'hide':(this.state.public && !this.callToken) ? 'not-started':'hide'}>
                    <h1>{this.state.startedTxt}</h1>
                </div>
                <div className={this.state.allowPrtrait ? 'hide':'not-started portraitmsg'}>
                    <h1 className="cls" onClick={this.closePortraitMsg}>X</h1>
                    <h1>ROTATE YOUR MOBILE TO LANDSCAPE VIEW FOR BETTER USER INTERFACE <br/>  <i class="fa fa-repeat" aria-hidden="true"></i></h1>
                </div>
                <div className={this.state.recordingStatus ? "recnot":"hide"}> <span className={this.state.recshow ? 'rectext':'opacity0 rectext'}><i class="fa fa-stop-circle-o red rectext"></i> REC</span>   </div>
                <div className={this.state.public ? "no-scroll black":"no-scroll black"}>
                    <div className={this.state.pListOpen ? "hidemobile audio_view cust-aud-view":"audio_view cust-aud-view"}>
                        <div className={(this.state.caption && !this.state.videoOpen) ? "transcript-parent":"hide"}>
                        
{(this.state.mounted && this.state.isWeb) && <Speech userName={this.userName} ref={(speechRef) => this.speechRef = speechRef} onTranscription={this.onTranscription} room={this.room}/>}
                                
                        
                                
                        </div>
                        <div className="audio_content">
                        <div className={this.state.encryptTxtHide ? "hide":"secure-wrap"}>
                        <div className={this.state.pListOpen ? "encrypt-txt encrypttxt-width-plist":"encrypt-txt"}><i className="fa fa-lock"></i> Both video and audio in this call is secured with end-to-end encryption</div>
                        </div>
                        <div className="call-icon-wrp">
                            <h1 className="font30">{this.state.first_name}</h1>
                            <div className="display-ib"> 
                                <div className="empty-head-icon">{this.state.first_name && this.state.first_name!= 'Group' ?this.state.first_name.charAt(0).toUpperCase():<i className="fa fa-users"/>}</div>
                            </div>
                        </div>
                        {/* <div className={this.state.connected ? (this.state.pListOpen ? "conference_icon toolwidth-plist left-tools mobile-hide":"conference_icon left-tools"):"hide"}>
                            <a data-tip={this.state.pListOpen ? "View Participants":"View Participants"} onClick={this.leftPannelOpen.bind(this,'userlist')} className={ this.state.pListOpen ?"option_btn on":"option_btn off"}><i  className={this.state.pListOpen ? "fa fa-user green":"fa fa-user red" }></i><span className="left-count">{Object.keys(this.state.userListMeeting).length}</span></a>
                            <a data-tip={this.state.pListOpen ? "View Hand Raise":"View Hand Raise"} className={this.state.raiseHandHiddenCount > 0 ? (!this.state.public ? (this.state.pListOpen ?"option_btn on":"option_btn off"):"hide"):"hide"} onClick={this.leftPannelOpen.bind(this,'userlist')}><i className={this.state.pListOpen ? "fa fa-hand-paper-o green":"fa fa-hand-paper-o red"}></i><span className={this.state.raiseHandHiddenCount > 0 ? "left-count":"hide"}>{this.state.raiseHandHiddenCount}</span></a>
                            <a data-tip={this.state.pListOpen ? "View Chats":"View Chats"} className={this.state.pListOpen ?"option_btn on":"option_btn off"} onClick={this.leftPannelOpen.bind(this,'chat')}><i className={this.state.pListOpen ? "fa fa-comment green":"fa fa-comment red"}></i><span className={this.state.chatHiddenCount > 0 ? "left-count":"hide"}>{this.state.chatHiddenCount}</span><span className={this.state.chatCnt > 0 ? "left-count":"hide"}>{this.state.chatCnt}</span></a>
                        </div>  */}
                        <div className={this.state.connected ? (this.state.pListOpen ? "conference_icon toolwidth-plist mobile-hide":"conference_icon"):"hide"}>
                            <span className="left-options">
                                <a data-tip={this.state.pListOpen ? "View Participants":"View Participants"} onClick={this.leftPannelOpen.bind(this,'userlist')} className={ this.state.pListOpen ?"option_btn on":"option_btn off"}><i  className={this.state.pListOpen ? "fa fa-user green":"fa fa-user red" }></i><span className="left-count">{Object.keys(this.state.userListMeeting).length}</span></a>
                                <a data-tip={this.state.pListOpen ? "View Hand Raise":"View Hand Raise"} className={this.state.raiseHandHiddenCount > 0 ? (!this.state.public ? (this.state.pListOpen ?"option_btn on":"option_btn off"):"hide"):"hide"} onClick={this.leftPannelOpen.bind(this,'userlist')}><i className={this.state.pListOpen ? "fa fa-hand-paper-o green":"fa fa-hand-paper-o red"}></i><span className={this.state.raiseHandHiddenCount > 0 ? "left-count":"hide"}>{this.state.raiseHandHiddenCount}</span></a>
                                <a data-tip={this.state.pListOpen ? "View Chats":"View Chats"} className={this.state.pListOpen ?"option_btn on":"option_btn off"} onClick={this.leftPannelOpen.bind(this,'chat')}><i className={this.state.pListOpen ? "fa fa-comment green":"fa fa-comment red"}></i><span className={this.state.chatHiddenCount > 0 ? "left-count":"hide"}>{this.state.chatHiddenCount}</span><span className={this.state.chatCnt > 0 ? "left-count":"hide"}>{this.state.chatCnt}</span></a>
                                <a data-tip={this.state.caption ? "Turn Off Voice To Text":"Turn On Voice To Text"} className={this.public ?"hide":(this.state.caption ? "option_btn on":"option_btn off")} onClick={this.enableOrDisabeCaption}><i className={this.state.pListOpen ? "fa fa-volume-control-phone green":"fa fa-volume-control-phone red"}></i></a>
                            </span>
                            <a data-tip={this.state.meetingStarted ? "End Meeting":"Start Meeting"} onClick={this.startOrStopMeeting} className={this.state.public && !this.callToken ? "hide":(this.state.meetingStarted ? "option_btn on":"option_btn off")}><i  className={this.state.meetingStarted ? "fa fa-power-off green":"fa fa-power-off red" }></i></a>
                            <a data-tip={this.state.locked ? "Unlock Meeting":"Lock Meeting"} onClick={this.lockOrUnlockMeeting} className={this.state.public && !this.callToken || !this.state.meetingStarted ? "hide":(this.state.locked ? "option_btn on":"option_btn off")}><i  className={this.state.locked ? "fa fa-lock green":"fa fa-unlock green" }></i></a>
                            <a data-tip={this.state.wbEnabled ? "Close Whiteboard":"Share Whiteboard"} onClick={this.onEnableOrDisableWb} className={this.state.wbAllowed ? (this.state.wbEnabled ? "option_btn on":"option_btn off"):"hide"}><i  className={this.state.wbEnabled ? "fa fa-pencil green":"fa fa-pencil red" }></i></a>
                            <a data-tip="Share Content" onClick={this.openDocumentSharePopup} className={this.public && !this.temphost ? "hide":"option_btn off"}><i  className="fa fa-file-text-o"></i></a>
                            <a data-tip={this.state.isHandRaised ? "Lower your hand":"Raise your hand"} className={this.state.public ? (this.state.isHandRaised ? "option_btn on": "option_btn off" ):"hide"} onClick={this.sendRaiseHandRequest}><i className={this.state.screenEnabled ? "fa fa-hand-paper-o gree":"fa fa-hand-paper-o red"}></i></a>
                            

                            <a data-tip={this.state.screenEnabled ? "Stop Screen Share":"Start Screen Share"} className={this.state.screenShareSupported && this.state.screenAllowed ? (this.state.screenEnabled ? (this.state.screenProceccing ? "option_btn on disabled-media":"option_btn on"):(this.state.screenProceccing ?"option_btn off disabled-media":"option_btn off")):"hide"} onClick={this.onStartScreenShare}><i class="fa fa-spinner medic" aria-hidden="true"></i><img src={this.state.screenEnabled ?"/files/share-screen.svg":"/files/share-screen-cross.svg"}/></a>
                            <span className={this.state.camallowed? '':'hide'}><a data-tip={this.state.videoEnabled ? "Disable":"Enable Video"} className={this.state.userMode ==='presenter' ? (this.state.videoEnabled ? (this.state.videoProceccing ? "option_btn on disabled-media":"option_btn on"):(this.state.videoProceccing ?"option_btn off disabled-media":"option_btn off")):"hide"} onClick={this.onEnableOrDisableVideo}><i class="fa fa-spinner medic" aria-hidden="true"></i><img src={this.state.videoEnabled ?"/files/video.svg":"/files/video-cross.svg"}/></a> </span>
                            <span className={this.state.micallowed? 'margin-left-5':'hide'}><a data-tip={this.state.audioEnabled ? "Mute":"Unmute audio"} onClick={this.onEnableOrDisableMic} className={this.state.userMode ==='presenter'? (this.state.audioEnabled ? (this.state.audioProceccing ? "option_btn on disabled-media":"option_btn on"):(this.state.audioProceccing ?"option_btn off disabled-media":"option_btn off")):"hide"}><i class="fa fa-spinner medic" aria-hidden="true"></i><img src={this.state.audioEnabled ?"/files/mic.svg":"/files/mic-cross.svg"}/></a></span>
                            <a data-tip={this.state.speakerEnabled ? "Mute Speaker":"Unmute Speaker"} onClick={this.onEnableOrDisableSpeaker} className={this.state.speakerEnabled ? "option_btn on":"option_btn off"}><i class={this.state.speakerEnabled ?"fa fa-volume-up sp-icn":"fa fa-volume-down sp-icn"}></i></a>
            
                            
                            <a data-tip="Leave Meeting" onClick={this.onLeaveMeeting} id="closecall" className={this.state.public || true?"option_btn":"hide"}><i className="fa fa-phone rotate-img"></i></a>
                            <a onClick={this.inviteUers} className={this.state.public && !this.callToken ? "hide":"hide option_btn"}><i className="fa fa-share-alt"></i></a>
                            <span className={this.state.PEER_TO_PEER ? 'hide':'pullright'}>
                            <a data-tip={this.state.isRecording ? "Stop Content Creation":"Start Content Creation"} onClick={this.startOrStopContentCreation} className={ this.state.meetingStarted || ( this.state.public && !this.callToken ) ? "hide":(this.state.isRecording ? "option_btn on":"option_btn off")}><i  className={this.state.isRecording ? "fa fa-film green":"fa fa-film red" }></i></a>
                            <a data-tip={this.state.isRecording ? "Stop Recording":"Start Recording"} onClick={this.startOrStopRecording} className={!this.state.meetingStarted || (this.state.public && !this.callToken) ? "hide":(this.state.isRecording ? "option_btn on":"option_btn off")}><i  className={this.state.isRecording ? "fa fa-stop-circle-o green":"fa fa-stop-circle-o red" }></i></a>
                            </span>
                            </div>
                        </div>
                    
                    
                        
                    </div>

                    <div id="centerarea" className={this.state.pListOpen ? "video-parent mobile-hide":"video-parent"}>
                        <div className={(!this.state.meetingStarted && this.public) || (!this.state.videoEnabled && !this.state.audioEnabled) ? "hide":(this.state.pListOpen ? "mobile-hide":"")} id="local-video-wrap">
                          
                        </div>
                        
                        <div className="video-inner" id ="remote-video">
                           {/* <video id="remoteVideo" className="video-1" playsInline autoPlay></video>*/}
                        </div>
                        <div className={this.state.pListOpen ?"video-inner leftopn":"video-inner"} id ="remote-video-screen">
                           {/* <video id="remoteVideo" className="video-1" playsInline autoPlay></video>*/}
                        </div>
                        <div className={(this.state.documentOpen && (this.state.meetingStarted || this.state.userMode === 'presenter')) ? (this.state.userMode ==='presenter'?"whiteboard showboard":"whiteboard showboard wbinactive"):"whiteboard hideboard wbinactive"}>
                            <div id="doc-wrap" className={!this.state.videoOpen ? "doc-page-wrap":"hide"}>
                                <img src={this.state.totalPages == 1 ? 'https://'+Config.DOMAIN+'/'+this.state.currentDocument+'/page.png':'https://'+Config.DOMAIN+'/'+this.state.currentDocument+'/page-'+(this.state.currentPage - 1)+'.png'}/>
                            </div>
                            <div id="doc-wrap" className={this.state.videoOpen === 'video' ? "doc-page-wrap":"hide"}>
                                <video id='sharedVideo' controls/>
                            </div>
                            <div id="doc-wrap" className={this.state.videoOpen === 'youtube' ? "doc-page-wrap":"hide"}>
                                     <div id="youtube-share"></div>
                            </div>
                            <div className={!this.state.videoOpen ? "current-page":"hide"}>
                                {this.state.currentPage}
                            </div>
                            
                           
                                 
                        </div>
                        
                        <div id="wb" className={((this.state.wbEnabled || this.state.documentOpen) && !this.state.videoOpen && (this.state.meetingStarted || this.state.userMode === 'presenter'))? (
                            this.state.wbAllowed ?(
                                this.state.documentOpen ? "whiteboard showboard transp":"whiteboard showboard"
                                
                                ) : (
                                    this.state.documentOpen ?"whiteboard showboard wbinactive transp":"whiteboard showboard wbinactive"
                                )
                            
                            ):"whiteboard hideboard wbinactive"}>
                            <Whiteboard wbAllowed = {this.state.wbAllowed} ref={(wbRef) => this.wbRef = wbRef} userId={this.name} userName={this.userName} showNotification={this.showNotification}/>
                       </div>
                       <div className={this.state.documentOpen && !this.state.videoOpen && this.state.wbAllowed? "doc-tool":"hide"}>
                            <button data-tip="Previous Page" onClick={this.prevPage}><i className="fa fa-backward"/></button>
                            <button data-tip="Next Page" onClick={this.nextPage}><i className="fa fa-forward"/></button>
                            <button className="hide" data-tip="Zoom In" onClick={this.zoomIn}><i className="fa fa-search-plus"/></button>
                            <button className="hide" data-tip="Zoom Out" onClick={this.zoomOut}><i className="fa fa-search-minus"/></button>
                        </div>
                        <div className={this.state.documentOpen && this.state.wbAllowed? "close-doc":"hide"} onClick={this.closeDoc}>X</div>
                    
                    
                    
                    
                    </div>
                    <div className={this.state.screenEnabled && !this.state.wbEnabled && !this.state.documentOpen ? "screen-not":"hide"}>
                            <video className="local-screen-video" id ="local-screen-video"  muted autoPlay playsInline></video>
                            You're presenting your screen to everyone
                    </div>
                    
                </div>
            </div>
            </div>
        </div>
        <audio id="remoteAudio" playsInline autoPlay></audio>
        
        <video className="content-video" id="contenvideo" autoPlay muted playsInline></video>
        <ReactTooltip />
        <section className={this.state.showDialog ? 'dialog':'hide'}>
            <Dialog showMe = {this.showDialog} onDialogStatus= {this.onDialogStatus} ref={(dialog) => this.dialog = dialog}/>
        </section>
            <div id="portrait-rotate">
                <div class="portrait-rotate-message">
                    <figure><img src="/files/rotate-screen-img.svg" alt=""/></figure>
                </div>
            </div>
        </section>
      )
    }else{
        if(this.alias){
            return(
                <section className="settings-parent">
                    <AvSettingComponent ref={(settingsRef) => this.settingsRef = settingsRef} onContine={this.onSettingsContinueClicked} audio={true}/>
                    <div id="portrait-rotate">
                        <div class="portrait-rotate-message">
                            <figure><img src="/files/rotate-screen-img.svg" alt=""/></figure>
                        </div>
                    </div>
                </section>
            )
        }else{
            return(
                ''
            )
        }
    }
    
  }
  joinPublicUserPeer = (videoEnabled)=>{
     // alert(this.state.videoEnabled)
    navigator.getUserMedia({ video: videoEnabled, audio: true }, (myStream) => { 
        this.stream = myStream; 
        window.stream = myStream;
        document.getElementById('remote-video-local-video').srcObject = this.stream;
        document.getElementById('remote-video-local-video').muted = true;
        //document.getElementById('remote-video-local-video').style.display = 'block';
       // document.getElementById('remote-novideo-local-video').style.display = 'none';
        let name = 'Guest';
        if(UserSession.getSession().userDetail){
            name = UserSession.getSession().userDetail.first_name+' '+UserSession.getSession().userDetail.last_name;
        }
        this.sendToPeerServer({ 
            type: "login", 
            name: this.name,
            room: this.room,
            token:this.callToken,
            windowId:this.windowId,
            isGuest : 'true',
            userName:this.userName ? this.userName : name
         }); 
         //this.disableVideo();
         if(!this.state.audioEnabled){
             this.disableAudio();
         }
     }, (error) => { 
        this.props.alert("There is some problem for accesing your camera and mic, please allow proper permission and reload this page !"); 
     });
  }
  onStartScreenSharePeer = ()=>{
        if(this.state.screenEnabled){
            this.onStopScreenShare();
            return;
        }
        CommonFunctions.getScreenCapture().then((stream) => {
        document.getElementById('local-screen-video').srcObject = stream;
        window.ScreenStream = stream;
        /*
        setTimeout(() => {
            try{
                document.getElementById('local-screen-video').requestPictureInPicture();
            }catch(e){

            }
        }, 1000);
        */
        for(let key in this.participants){
            if(key.indexOf('_screen') > -1){
                this.participants[key].addStream(window.ScreenStream)
                setTimeout(this.createOffer.bind(this),1000,key)
            }
        }
        this.setState({screenEnabled : true});
        window.ScreenStream.addEventListener('inactive', e => {
                this.onStopScreenShare();
        });
        });

        let sendData = {method:'onScreeShare',room:this.room,name:this.name+'_screen',status:true,windowId:this.windowId};
        SocketHandler.sendToAll(sendData);
        


}
onStopScreenSharePeer = ()=>{
        if(window.ScreenStream){
        window.ScreenStream.getTracks().forEach(track => track.stop());
        this.setState({screenEnabled : false});
        let sendData = {method:'onScreeShare',room:this.room,name:this.name+'_screen',status:false,windowId:this.windowId};
            SocketHandler.sendToAll(sendData);
            window.ScreenStream  = null;
            setTimeout(() => {
                try{
                    //document.getElementById('local-screen-video').requestPictureInPicture();
                }catch(e){

                }
            }, 1000);
        }
}
disableVideo = () =>{
    this.setState({videoEnabled: false});
    if(window.stream){
        window.stream.getVideoTracks().forEach( (track) => {
            track.enabled = false;
        });
    }
    document.getElementById('remote-video-local-video').style.display = 'none';
    document.getElementById('remote-novideo-local-video').style.display = 'block';
    let sendData = {method:'camEnabled',room:this.room,name:this.name,status:false,windowId:this.windowId};
    SocketHandler.sendToAll(sendData);
}
disableAudio = () =>{
        this.setState({audioEnabled: false});
        if(window.stream){
            window.stream.getAudioTracks().forEach( (track) => {
                track.enabled = false;
        });
        }
  }
  peerToPeerListeners = () =>{
    SocketHandler.getSocket((socket)=>{
        /**
         * Peer to peer listeners
         */
        socket.on('message', (data) => {
            var data = JSON.parse(data);
            console.log(data.type+' : '+JSON.stringify(data))
            switch (data.type) {
                case "login": 
                    this.handleLogin(data); 
                    break; 
                case "newUser": 
                    this.existingList[data.userData.name] = data.userData;
                    this.createPeerConnection(data.userData.name); 
                    this.createPeerConnection(data.userData.name+'_screen'); 
                break; 
                case "requestStream": 
                    setTimeout(this.createOffer.bind(this),2000,data.name+'_screen')
                break; 
                
                //when somebody wants to call us 
                case "offer": 
                    console.log('offere received : '+data.sender);
                    this.handleOffer(data.offer, data.sender.indexOf('_screen')>-1 ? data.name+'_screen':data.name); 
                    break; 
                case "answer": 
                    this.handleAnswer(data.answer,data.sender.indexOf('_screen')>-1 ? data.name+'_screen':data.name); 
                    break; 
                //when a remote peer sends an ice candidate to us 
                case "candidate": 
                    this.handleCandidate(data.candidate,data.sender.indexOf('_screen')>-1 ? data.name+'_screen':data.name); 
                    break; 
                case "userLeft":
                    this.onUserLeft(data.name);
                break;
                
                case "rejectHost": 
                    this.onHangupCall();
                    break; 
                case "reject": 
                    //this.destroyStream();
                    if(this.state.public || this.temphost){
                        this.props.notAutherized(data.message)
                        setTimeout(() => {
                            //window.location.href = '/';
                        }, 3000);
                    }
                    break; 
                case "leave": 
                    this.handleLeavePeer(); 
                    if(this.state.public){
                        this.props.notAutherized('Meeting has been ended');
                        socket.close();
                    }
                    break; 
                default: 
                    break; 
            }
        });
    })
  }
  handleLogin = (data) => { 
    if (data.success === false) { 
        this.props.alert("Something went wrong, Pleae reload and try again !"); 
     } else {  
        setTimeout((data)=>{
            this.onCallExistingUsers(data.users)
        },1000,data);

        this.existingList = data.existingList;
        this.connectionSuccess = true;
        this.setState({loader:false})
        this.setState({connected:true})
          
     } 
 };
 handleLeavePeer() { 
    if(this.props.onHangupCall){
       //this.props.onHangupCall({user_id:this.state.user_id,full_name:this.state.first_name});
    }
   for(let key in this.participants){
       this.participants[key].close(); 
       this.participants[key].onicecandidate = null; 
       this.participants[key].onaddstream = null; 
   } 
   this.destroyStream();
   
   
};
 
  destroyStream = ()=>{
    if(window.stream){
        window.stream.getVideoTracks().forEach(function (track) {
            track.stop()
        });
        window.stream.getAudioTracks().forEach(function (track) {
            track.stop()
        });
        if(window.ScreenStream){
            window.ScreenStream.getVideoTracks().forEach(function (track) {
                track.stop()
            });
        }
    }
 }
  onUserLeft = (user)=>{
    if(this.participants[user]){
        this.participants[user].close(); 
        this.participants[user].onicecandidate = null; 
        this.participants[user].onaddstream = null; 
        if(document.getElementById(user)){
         document.getElementById(user).src = null;
         document.getElementById(user).remove()
        }
         delete this.participants[user];
         this.layout();
    }
    user = user+'_screen';
    if(this.participants[user]){
        this.participants[user].close(); 
        this.participants[user].onicecandidate = null; 
        this.participants[user].onaddstream = null; 
        if(document.getElementById(user)){
         document.getElementById(user).src = null;
         document.getElementById(user).remove()
         document.getElementById('remote-video').classList.remove('screen-layout');
        }
         delete this.participants[user];
         this.layout();
    }
 }
  
 onCallExistingUsers = (users)=>{
    for(let key in users){
        let user = users[key];
        if(user !== this.name.toString()){
             console.log('create peer connection for existing users ='+user.toString()+' myname='+this.name.toString());
             this.createPeerConnection(user)
             this.createPeerConnection(user+'_screen')
             setTimeout(this.createOffer.bind(this),1000,user)
             //setTimeout(this.createOffer.bind(this),2000,user+'_screen')
             this.sendToPeerServer({ type: "requestStream", name:user,myName:this.name}); 
        }
        
    }
 }
 createPeerConnection = (user)=>{
    console.log('create peer connection '+user);
    this.participants[user] = new window.webkitRTCPeerConnection(this.configurationPeer);
    if(user.indexOf('_screen') <= -1){
        this.participants[user].addStream(window.stream)
        console.log('added camer stream')
    }else{
        if(window.ScreenStream){      
            this.participants[user].addStream(window.ScreenStream)
            console.log('added screen stream')
        }
    }
    this.participants[user].onaddstream =  (e) => { 
        
        if(user.indexOf('_screen') > -1){
            let streamAttributes = this.existingList[user.replace('_screen','')]
            document.getElementById('remote-video-screen').appendChild(this.renderVideoParent(streamAttributes.userName+'\'s screen',streamAttributes.windowId+'_screen',true,streamAttributes.windowId+'_screen'));
            document.getElementById('remote-video-screen').style.display = 'block';
            document.getElementById(streamAttributes.windowId+'_screen').classList.add('screen-video')
            document.getElementById('remote-video').classList.add('screen-layout');
            document.getElementById('remote-video-'+streamAttributes.windowId+'_screen').srcObject = e.stream;
            document.getElementById('remote-video-'+streamAttributes.windowId+'_screen').style.display = 'block';
            document.getElementById('remote-novideo-'+streamAttributes.windowId+'_screen').style.display = 'none';
            this.isScreenShareProgress = true;  
        }else{
            if(this.existingList[user]){
                /// alert(JSON.stringify(this.existingList[user]))
                let streamAttributes = this.existingList[user];
               
              if(document.getElementsByClassName(streamAttributes.windowId).length > 0) {
                  this.replaceIds(streamAttributes.windowId,streamAttributes.windowId)
              }else{
                  document.getElementById('remote-video').appendChild(this.renderVideoParent(streamAttributes.userName,streamAttributes.windowId,false,streamAttributes.windowId));
              }
              document.getElementById('remote-video-'+streamAttributes.windowId).srcObject = e.stream;
              document.getElementById('remote-video-'+streamAttributes.windowId).muted = false
              //document.getElementById('remote-video-'+streamAttributes.windowId).style.display = 'block';
             // document.getElementById('remote-novideo-'+streamAttributes.windowId).style.display = 'none';
  
          }
        }
        
       this.layoutVideos();
    };
        
    // Setup ice handling 
    this.participants[user].onicecandidate =  (event) => { 
       
        if (event.candidate) { 
            console.log('generate candidate for '+user);
            this.sendToPeerServer({ 
                type: "candidate", 
                candidate: event.candidate,
                name:user,
                myName:this.name
            }); 
        } 
    };  
}
createOffer = (user)=>{
    if(this.participants[user]){
        this.participants[user].createOffer( (offer) => { 
            //console.log('OFFER : '+JSON.stringify(offer))
            console.log('offer created '+user);
            if (offer.offerToReceiveAudio) {
                this.participants[user].addTransceiver('audio')
            }
            if (offer.offerToReceiveVideo) {
                this.participants[user].addTransceiver('video')
            }
            this.sendToPeerServer({ 
            type: "offer", 
            offer: offer,
            name:user
            }); 
            
            this.participants[user].setLocalDescription(offer); 
        },  (error) => { 
            this.props.alert("Something went wrong, if you have any connectivity issue Please reload and try again  !"); 
        });
    }
}
//when somebody sends us an offer 
handleOffer = (offer, senderName) => { 
    console.log('handle offer : '+senderName)
   if(this.participants[senderName]){
    this.participants[senderName].setRemoteDescription(new RTCSessionDescription(offer));
           console.log('create answer'); 
        //create an answer to an offer 
        this.participants[senderName].createAnswer( (answer) => { 
            this.participants[senderName].setLocalDescription(answer); 
                
            this.sendToPeerServer({ 
                type: "answer", 
                answer: answer,
                name:senderName,
                myName:this.name
            }); 
                
        },  (error) => { 
            this.props.alert("Something went wrong, if you have any connectivity issue Please reload and try again  !"); 
        }); 
    }
};
  
//when we got an answer from a remote user
handleAnswer = (answer,senderName) => { 
    console.log('handle answer : '+senderName+' --- '+ JSON.stringify(this.participants))
    if(this.participants[senderName]){
        this.participants[senderName].setRemoteDescription(new RTCSessionDescription(answer)); 
        console.log('set remote description for '+senderName)
    }else{
        console.log('pc not found answer'+senderName)
    }
};
  
//when we got an ice candidate from a remote user 
handleCandidate = (candidate,senderName) =>{ 
    if(this.participants[senderName]){
        console.log('handle candidate '+senderName)
        this.participants[senderName].addIceCandidate(new RTCIceCandidate(candidate)); 
    }else{
        console.log('pc not found ice'+senderName)
    }
};
  
}
export default CallOpenMediaServer;
