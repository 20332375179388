import React, { Component } from 'react';
class OnGoingCallWindow extends Component {
  
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps!== this.props) {
      this.setState({user_id:this.props.currentChatUser.user_id,fullName:this.props.currentChatUser.full_name});
    }
  }
  componentDidMount = ()=> {
    
  }
  
  componentWillUnmount = ()=> {
    
  }
  
  onHangupCall = ()=>{
        this.props.onHangupRinging({user_id:this.state.user_id,full_name:this.state.fullName});
    }
  render() {
    return (
        <div id="on-going-call" className="flex-grid ongoingcl3453">
                <div appdragdrop="" className="col th-p pos-relative">
                <a id="attendcall" className="attndcl3245 hide" data-toggle="tooltip" title="Just for Demo purpose">Attend call (for Demo)</a>
                <header className="top-box-shadow">
                    <div className="chats width100">
                        <div className="content-wrapper is-clearfix">
                            <div className="chat-name">
                            <div className="is-pulled-left">
                                <i className="fa is-pulled-left fa-chevron-left"></i>
                                <div className="display-ib">
                                    <div className="empty-head-icon">{this.state.fullName ? this.state.fullName.charAt(0).toUpperCase():''}</div>
                                </div>
                                <span className="chat-title-name is-size-6 mar-5-0">{this.state.fullName}</span>
                            </div>
                            <div className="is-marginless is-clearfix is-pulled-right">
                                <div className="is-pulled-right mar-10-0">
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="contents is-marginless">
                    <div>
                        <div className="audio_view">
                            <div className="audio_content">
                            <div className="">
                                <div>
                                    <div className="display-ib">
                                        <div className="empty-head-icon">{this.state.fullName ? this.state.fullName.charAt(0).toUpperCase():''}</div>
                                    </div>
                                </div>
                                <h2>{this.state.fullName}</h2>
                                <p className="is-small">Ringing...</p>
                                <a className="audio_btn" onClick={this.onHangupCall}><i className="fa fa-phone rotate-img"></i></a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
      )
    
  }
}
export default OnGoingCallWindow;
