import React, { Component } from 'react';
import AvSettingComponent from './AvSettingComponent';
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {type:'',header:'',body:''};
    
  }
  componentDidMount = ()=> {

  }
  show = (type,header,body)=> {
      this.setState({type:type,header:header,body:body})
      this.props.showMe();
      if(type === 'cam_confirm'){
        //this.accessCam();
        //this.settingsRef.gatherPermission();
    }
  }
  onConfirm = () =>{
    this.props.onDialogStatus({type:this.state.type,status:true});
    if(this.state.type === 'cam_confirm'){
        if(window.streamSettings){
            window.streamSettings.getTracks().forEach((track) =>{
                track.stop();
            });
        }
    }
  }
  onCancel = ()=>{
      this.props.onDialogStatus({type:false,status:false});
      if(this.state.type === 'cam_confirm'){
        if(window.streamSettings){
            window.streamSettings.getTracks().forEach((track) =>{
                track.stop();
            });
        }
    }
  }
  publishStream = () =>{
    this.settingsRef.stopCam();
    setTimeout(this.accessCam, 1000);
  }
  render() {
    return (
        <div className="flex-grid pop">
            <div className="col th-p">
                <div className="contents is-marginless">
                    <div className="scroll-bar">
                        <div className="column">
                        <div className="columns">
                            <div className="column is-half is-offset-one-quarter halffull">
                                <div className="columns new-chat mar-top15">
                                    <div className="column">
                                    <div className="card">
                                        <header class="card-header">
                                            <h3 class="card-header-title">
                                                {this.state.header}
                                            </h3>
                                            <span class="card-header-icon" onClick={this.onCancel}>
                                            <span class="icon">
                                            <i class="fa fa-close" aria-hidden="true" ></i>
                                            </span>
                                            </span>
                                        </header>
                                        <div className="card-content">
                                            <div className="content form-style">
                                                <div className="columns pop-scroll">
                                                <div className="column is-12">
                                                {this.state.body} 
                                                {/*
                                                <div className={this.state.type === 'cam_confirm' ? 'avsett':'hide'}>
                                                        <AvSettingComponent ref={(settingsRef) => this.settingsRef = settingsRef} publishStream={this.publishStream} audio={false}/>
                                                </div>
                                                */}
                                                </div>
                                            </div>
                                            <div className="columns is-clearfix">
                                                <div className="column pad-b-0">
                                                <div className="has-text-right is-pulled-right">
                                                    <button onClick={this.onConfirm} className="button mar-5" type="button">Yes, Proceed</button>
                                                    <button onClick={this.onCancel} className="button mar-5" type="button">Cancel</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
      )
    
  }
}
export default Dialog;
