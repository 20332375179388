import React, { Component } from 'react';
import UserSession from '../helpers/user-session';
import SocketHandler from '../helpers/socket-service';
import CommonFunctions from '../helpers/common-functions';
import { Redirect } from 'react-router-dom';
import Config from '../config';
import CallSideBarComponent from './CallSideBarComponent';
window.webkitRTCPeerConnection = window.RTCPeerConnection;
class CallOpenWindow extends Component {
  
  constructor(props) {
    super(props);
    this.public = true;
    this.unAutorised = false;
    
    if(window.location.href.indexOf('/host') > -1){
        //if(localStorage.getItem('createdRoom') == this.props.room){
            this.public = true;
       // }else{
       //     this.unAutorised = true;
       // }
        
    }
    this.state = {user_id:this.props.currentChatUser.user_id,first_name:this.props.currentChatUser.full_name,acceptedUser:false,
                  videoEnabled:true,audioEnabled:true,screenEnabled:false,public:this.public,redirect:false,
                  screenShareSupported:false,callSupported:true}
    
    this.room = this.props.room ? this.props.room : CommonFunctions.generateUID();
    this.name = this.state.public ?  this.props.currentChatUser.user_id:UserSession.getSession().user_id.toString();
    this.callToken = this.props.callToken;
    this.userName = this.props.userName;
    this.participants = {}; 
    this.stream = null;
    this.ScreenStream = null
    this.existingList = {};
    this.lastVideoEnableStatus = false;
    this.configuration = { 
        "iceServers": [{
            urls: "turn:"+Config.DOMAIN+":3478",
            credential: 'acinfotech',
            username: 'UY9TKs9jBT'
        }]
    }
    SocketHandler.getSocket((socket)=>{
        socket.on('onReceiveSendToAll',(data) => {
            data =  JSON.parse(data);
            switch (data.method) {
                case 'camEnabled':
                    if(document.getElementById('remote-video-'+data.name)){
                        
                        if(data.status){
                            document.getElementById('remote-video-'+data.name).style.display = 'block';
                            document.getElementById('remote-novideo-'+data.name).style.display = 'none';
                        }else{
                            document.getElementById('remote-video-'+data.name).style.display = 'none';
                            document.getElementById('remote-novideo-'+data.name).style.display = 'block';
                        }
                        this.existingList[data.name].cam = data.status;
                    }
                break;
                case 'onScreeShare':
                    if(document.getElementById('remote-video-'+data.name)){
                        if(data.status){
                            document.getElementById(data.name).classList.add('screen-video')
                            document.getElementById('remote-video').classList.add('screen-layout');
                            
                        }else{
                            document.getElementById(data.name).classList.remove('screen-video')
                            document.getElementById('remote-video').classList.remove('screen-layout');
                            if(document.getElementById(data.name)){
                                document.getElementById(data.name).remove()
                            }
                            this.layout();
                            
                        }
                    }
                break;
            }
        });
        socket.on('message',(data) => {
            //console.log('socketData = '+data);
            var data = JSON.parse(data);   
            switch(data.type) { 
                case "login": 
                    this.handleLogin(data); 
                    break; 
                case "newUser": 
                    this.existingList[data.userData.name] = data.userData;
                    this.createPeerConnection(data.userData.name); 
                    this.createPeerConnection(data.userData.name+'_screen'); 
                break; 
                case "requestStream": 
                    setTimeout(this.createOffer.bind(this),2000,data.name+'_screen')
                break; 
                
                //when somebody wants to call us 
                case "offer": 
                    console.log('offere received : '+data.sender);
                    this.handleOffer(data.offer, data.sender.indexOf('_screen')>-1 ? data.name+'_screen':data.name); 
                    break; 
                case "answer": 
                    this.handleAnswer(data.answer,data.sender.indexOf('_screen')>-1 ? data.name+'_screen':data.name); 
                    break; 
                //when a remote peer sends an ice candidate to us 
                case "candidate": 
                    //console.log('candidate received from '+name)
                    this.handleCandidate(data.candidate,data.sender.indexOf('_screen')>-1 ? data.name+'_screen':data.name); 
                    break; 
                case "userLeft":
                    this.onUserLeft(data.name);
                break;
                
                case "rejectHost": 
                    this.onHangupCall();
                    break; 
                case "reject": 
                    this.destroyStream();
                    if(this.state.public){
                        this.props.notAutherized(data.message)
                        if(data.reason == 'auth'){
                            //UserSession.destroySession();
                            setTimeout(() => {
                                //window.location.href = '/';
                            }, 2000);
                        }
                    }
                    break; 
                case "leave": 
                    this.handleLeave(); 
                    if(this.state.public){
                        this.props.notAutherized('Meeting has been ended');
                        socket.close();
                        //UserSession.destroySession();
                        setTimeout(() => {
                            //window.location.href = '/';
                        }, 2000);
                    }
                    break; 
                default: 
                    break; 
            }
        });
    })
  }
  componentDidMount = ()=> {
    if(this.unAutorised){
        this.props.notAutherized('Un authorized')
        this.onHangupCall();
        return;
    }
    if(this.props.room){
        this.joinPublicUser();
    }
    if(CommonFunctions.getOS() == 'Windows' || CommonFunctions.getOS() == 'Mac OS' || CommonFunctions.getOS() == 'Linux'){
          if(CommonFunctions.getBrowser() == 'Chrome' || CommonFunctions.getBrowser() == 'Firefox'){
                this.setState({screenShareSupported:true})
          }
    }
    if(CommonFunctions.getBrowser() == 'Edge'){
        this.props.alert('Browser not supported ! Calling feature not supported in edge browser, Please use Google Chrome');
        this.setState({callSupported:false})
    }else if(CommonFunctions.getOS() == 'Mac OS' || CommonFunctions.getOS() == 'Windows' || CommonFunctions.getOS() == 'Linux'){
        if(CommonFunctions.getBrowser() != 'Chrome'){
                this.props.alert('We are recommending to use Google Chrome in desktops(Mac,Windows,Linux) for better connectivity.');
        }
    }
    
    if(CommonFunctions.getOS() == 'Android'){
        if(CommonFunctions.getBrowser() != 'Chrome'){
                this.props.alert('Browser not supported ! Calling feature works only in Google Chrome browser for Android phones.');
                this.setState({callSupported:false})
        }
    }
    //alert(CommonFunctions.getOS()+'//'+CommonFunctions.getBrowser())
    if(CommonFunctions.getOS() == 'iOS'){
        if(CommonFunctions.getBrowser() != 'Mobile Safari'){
            this.props.alert('Browser not supported ! Calling feature works only in safari browser for IOS devices.');
                this.setState({callSupported:false})
        }
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps!== this.props) {
      this.setState({user_id:this.props.currentChatUser.user_id,first_name:this.props.currentChatUser.full_name});
    }
  }
  send = (message) =>{ 
    message.room = this.room;
    SocketHandler.onSendWebrtcSignal(message);
  }
  onAcceptCallReply = (room)=>{
    
    this.room = room;
    setTimeout((room)=>{
        this.room = room;
        navigator.getUserMedia({ video: true, audio: true }, (myStream) => { 
            this.stream = myStream; 
            window.stream = myStream;
            document.getElementById('local-video').srcObject = this.stream;
            
            let name = 'Guest';
            if(UserSession.getSession().userDetail){
                name = UserSession.getSession().userDetail.first_name+' '+UserSession.getSession().userDetail.last_name;
            }
            this.send({ 
                type: "login", 
                name: this.name,
                room: room,
                token:this.callToken,
                userName:this.userName ? this.userName : name
             }); 
             if(this.state.videoEnabled){
                this.disableCamera();
            }
         }, (error) => { 
            //console.log(error); 
            this.props.alert("There is some problem for accesing your camera and mic, please allow proper permission and reload this page !"); 
         });
        
    },5000,room);
    
 }
 onAcceptRing = (user_id,callback)=>{
        this.setState({acceptedMe:user_id})
        navigator.getUserMedia({ video: true, audio: true }, (myStream) => { 
            this.stream = myStream; 
            window.stream = myStream;
            document.getElementById('local-video').srcObject = this.stream;
            let name = 'Guest';
            if(UserSession.getSession().userDetail){
                name = UserSession.getSession().userDetail.first_name+' '+UserSession.getSession().userDetail.last_name;
            }
            this.send({ 
                type: "login", 
                name: this.name,
                room: this.room,
                token: this.callToken,
                userName:this.userName ? this.userName : name

            }); 
            if(this.state.videoEnabled){
                this.disableCamera();
            }
         }, (error) => { 
            this.props.alert("There is some problem for accesing your camera and mic, please allow proper permission and reload this page !"); 
         });
         callback(this.room);
  }
  joinPublicUser = ()=>{
    navigator.getUserMedia({ video: true, audio: true }, (myStream) => { 
        this.stream = myStream; 
        window.stream = myStream;
        document.getElementById('local-video').srcObject = this.stream;
        let name = 'Guest';
            if(UserSession.getSession().userDetail){
                name = UserSession.getSession().userDetail.first_name+' '+UserSession.getSession().userDetail.last_name;
            }
        this.send({ 
            type: "login", 
            name: this.name,
            room: this.room,
            token:this.callToken,
            isGuest : 'true',
            userName:this.userName ? this.userName : name
         }); 
         this.disableCamera();
     }, (error) => { 
        this.props.alert("There is some problem for accesing your camera and mic, please allow proper permission and reload this page !"); 
     });
     this.addUserMeeting();
  }
  addUserMeeting = () =>{
    if(!this.userName){
        this.userName = UserSession.getSession().userDetail.first_name;
    }
    SocketHandler.addUserMeeting({room:this.room,userId:this.name,userName:this.userName,videoEnabled:this.state.videoEnabled,audioEnabled:this.state.audioEnabled});
  }
  /**
   * 
   * Webrtc handlers
   */
  handleLogin = (data) => { 
    if (data.success === false) { 
        this.props.alert("Something went wrong, Pleae reload and try again !"); 
     } else {  
        setTimeout((data)=>{
            this.onCallExistingUsers(data.users)
        },1000,data);

        this.existingList = data.existingList;
          
     } 
 };
 layout = () =>{
    let childrens = document.getElementById('remote-video').childNodes;
    document.getElementById('local-video-wrap').classList.remove("width-hight-50");
    for (var i=0; i<childrens.length; i++) {
        childrens[i].classList.remove("video-1");
        childrens[i].classList.remove("video-2");
        childrens[i].classList.remove("video-3");
        childrens[i].classList.remove("video-4");
    }
    if(childrens.length == 1){
        childrens[0].classList.add("video-1");
    }
    if(childrens.length == 2){
        childrens[0].classList.add("video-2");
        childrens[1].classList.add("video-2");
    }
    if(childrens.length == 3){
        childrens[0].classList.add("video-4");
        childrens[1].classList.add("video-4");
        childrens[2].classList.add("video-3");
        if(childrens[0].id.indexOf('_screen') === -1 && childrens[1].id.indexOf('_screen') === -1 && childrens[2].id.indexOf('_screen') === -1){
            document.getElementById('local-video-wrap').classList.add("width-hight-50");
        }
    }
    if(childrens.length == 4){
        childrens[0].classList.add("video-4");
        childrens[1].classList.add("video-4");
        childrens[2].classList.add("video-4");
        childrens[3].classList.add("video-4");
    }
    if(childrens.length == 5){
        childrens[0].classList.add("video-5");
        childrens[1].classList.add("video-5");
        childrens[2].classList.add("video-5");
        childrens[3].classList.add("video-5");
        childrens[4].classList.add("video-5");
    }
}
 createPeerConnection = (user)=>{
    console.log('create peer connection '+user);
    this.participants[user] = new window.webkitRTCPeerConnection(this.configuration);
    if(user.indexOf('_screen') <= -1){
        this.participants[user].addStream(window.stream)
        console.log('added camer stream')
    }else{
        if(window.ScreenStream){      
            this.participants[user].addStream(window.ScreenStream)
            console.log('added screen stream')
        }
    }
    this.participants[user].onaddstream =  (e) => { 
        console.log('on add stream event '+user)
        let videoParent = document.createElement('div');
        videoParent.id = user;
        videoParent.classList.add('video-wraper');
        let label = 'Guest';
        let sufix = '';
        if(user.indexOf('_screen') > -1){
            sufix = '`s Screen';
        }
        //alert(JSON.stringify(this.existingList))
        if(this.existingList[user.replace('_screen','')].userName){
            label = this.existingList[user.replace('_screen','')].userName+sufix; 
        }
        let nameDiv = document.createElement('div')
        nameDiv.id = 'remote-name-'+user;
        nameDiv.innerHTML = label;
        nameDiv.classList.add('video-name');
        videoParent.appendChild(nameDiv)

        let video = document.createElement ("video");
        video.id = 'remote-video-'+user;
        video.controls = false;
        video.autoplay = true;
        video.playsInline = true;
        videoParent.appendChild(video)

        let placeholder = document.createElement('img')
        placeholder.src = '/files/placeholder.png';
        placeholder.id = 'remote-novideo-'+user;
        videoParent.appendChild(placeholder)
        
        document.getElementById('remote-video').appendChild(videoParent);
        video.srcObject = e.stream;
        if(user.indexOf('_screen') > -1){
            document.getElementById(user).classList.add('screen-video');
            document.getElementById('remote-video').classList.add('screen-layout');
        }else{
            if(this.existingList[user]){
                if(this.existingList[user].cam == false){
                    placeholder.style.display = 'block';
                    video.style.display = 'none';
                }
                
            }
        }
        
        this.layout()
    };
        
    // Setup ice handling 
    this.participants[user].onicecandidate =  (event) => { 
       
        if (event.candidate) { 
            console.log('generate candidate for '+user);
            this.send({ 
                type: "candidate", 
                candidate: event.candidate,
                name:user,
                myName:this.name
            }); 
        } 
    };  
}
onCallExistingUsers = (users)=>{
   for(let key in users){
       let user = users[key];
       if(user !== this.name.toString()){
            console.log('create peer connection for existing users ='+user.toString()+' myname='+this.name.toString());
            this.createPeerConnection(user)
            this.createPeerConnection(user+'_screen')
            setTimeout(this.createOffer.bind(this),1000,user)
            //setTimeout(this.createOffer.bind(this),2000,user+'_screen')
            this.send({ type: "requestStream", name:user,myName:this.name}); 
       }
       
   }
}
createOffer = (user)=>{
    if(this.participants[user]){
        this.participants[user].createOffer( (offer) => { 
            //console.log('OFFER : '+JSON.stringify(offer))
            console.log('offer created '+user);
            if (offer.offerToReceiveAudio) {
                this.participants[user].addTransceiver('audio')
            }
            if (offer.offerToReceiveVideo) {
                this.participants[user].addTransceiver('video')
            }
            this.send({ 
            type: "offer", 
            offer: offer,
            name:user
            }); 
            
            this.participants[user].setLocalDescription(offer); 
        },  (error) => { 
            this.props.alert("Something went wrong, if you have any connectivity issue Please reload and try again  !"); 
        });
    }
}

//when somebody sends us an offer 
handleOffer = (offer, senderName) => { 
    console.log('handle offer : '+senderName)
   if(this.participants[senderName]){
    this.participants[senderName].setRemoteDescription(new RTCSessionDescription(offer));
           console.log('create answer'); 
        //create an answer to an offer 
        this.participants[senderName].createAnswer( (answer) => { 
            this.participants[senderName].setLocalDescription(answer); 
                
            this.send({ 
                type: "answer", 
                answer: answer,
                name:senderName,
                myName:this.name
            }); 
                
        },  (error) => { 
            this.props.alert("Something went wrong, if you have any connectivity issue Please reload and try again  !"); 
        }); 
    }
};
  
//when we got an answer from a remote user
handleAnswer = (answer,senderName) => { 
    console.log('handle answer : '+senderName+' --- '+ JSON.stringify(this.participants))
    if(this.participants[senderName]){
        this.participants[senderName].setRemoteDescription(new RTCSessionDescription(answer)); 
        console.log('set remote description for '+senderName)
    }else{
        console.log('pc not found answer'+senderName)
    }
};
  
//when we got an ice candidate from a remote user 
handleCandidate = (candidate,senderName) =>{ 
    if(this.participants[senderName]){
        console.log('handle candidate '+senderName)
        this.participants[senderName].addIceCandidate(new RTCIceCandidate(candidate)); 
    }else{
        console.log('pc not found ice'+senderName)
    }
};
onStartScreenShare = ()=>{
        if(this.state.screenEnabled){
            this.onStopScreenShare();
            return;
        }
        CommonFunctions.getScreenCapture().then((stream) => {
        document.getElementById('local-screen-video').srcObject = stream;
        window.ScreenStream = stream;
        setTimeout(() => {
            try{
                document.getElementById('local-screen-video').requestPictureInPicture();
            }catch(e){

            }
        }, 1000);
        
        for(let key in this.participants){
            if(key.indexOf('_screen') > -1){
                this.participants[key].addStream(window.ScreenStream)
                setTimeout(this.createOffer.bind(this),1000,key)
            }
        }
        this.setState({screenEnabled : true});
        window.ScreenStream.addEventListener('inactive', e => {
                this.onStopScreenShare();
        });
        });

        let sendData = {method:'onScreeShare',room:this.room,name:this.name+'_screen',status:true};
        SocketHandler.sendToAll(sendData);
        
   
   
}
onStopScreenShare = ()=>{
    if(window.ScreenStream){
       window.ScreenStream.getTracks().forEach(track => track.stop());
       this.setState({screenEnabled : false});
       let sendData = {method:'onScreeShare',room:this.room,name:this.name+'_screen',status:false};
        SocketHandler.sendToAll(sendData);
        window.ScreenStream  = null;
        setTimeout(() => {
            try{
                //document.getElementById('local-screen-video').requestPictureInPicture();
            }catch(e){

            }
        }, 1000);
    }
}
 onUserLeft = (user)=>{
    if(this.participants[user]){
        this.participants[user].close(); 
        this.participants[user].onicecandidate = null; 
        this.participants[user].onaddstream = null; 
        if(document.getElementById(user)){
         document.getElementById(user).src = null;
         document.getElementById(user).remove()
        }
         delete this.participants[user];
         this.layout();
    }
    user = user+'_screen';
    if(this.participants[user]){
        this.participants[user].close(); 
        this.participants[user].onicecandidate = null; 
        this.participants[user].onaddstream = null; 
        if(document.getElementById(user)){
         document.getElementById(user).src = null;
         document.getElementById(user).remove()
         document.getElementById('remote-video').classList.remove('screen-layout');
        }
         delete this.participants[user];
         this.layout();
    }
 }
 inviteUers = ()=>{
     this.props.inviteUers(this.room);
 }
 onEnableOrDisableMic = ()=>{
    this.setState(prevState => ({
        audioEnabled: !prevState.audioEnabled
      }));
    window.stream.getAudioTracks().forEach( (track) => {
          track.enabled = !this.state.audioEnabled;
    });
  }
disableCamera = () =>{
    this.setState({videoEnabled: false});
    if(window.stream){
        window.stream.getVideoTracks().forEach( (track) => {
            track.enabled = false;
        });
    }
    let sendData = {method:'camEnabled',room:this.room,name:this.name,status:false};
    SocketHandler.sendToAll(sendData);
    document.getElementById('local-video').style.display = 'none';
    document.getElementById('local-novideo').style.display = 'block';
}
onEnableOrDisableVideo = ()=>{
    this.setState(prevState => ({
        videoEnabled: !prevState.videoEnabled
      }));
    if(window.stream){
        window.stream.getVideoTracks().forEach( (track) => {
            track.enabled = !this.state.videoEnabled;
        });
    }

    let sendData = {method:'camEnabled',room:this.room,name:this.name,status:!this.state.videoEnabled};
    SocketHandler.sendToAll(sendData);
    if(this.state.videoEnabled){
        document.getElementById('local-video').style.display = 'none';
        document.getElementById('local-novideo').style.display = 'block';
    }else{
        document.getElementById('local-video').style.display = 'block';
        document.getElementById('local-novideo').style.display = 'none';
    }
}
 onHangupCall = () =>{
    this.send({ type: "leave" , name:this.name});  
    this.handleLeave();
 }
 handleLeave() { 
     if(this.props.onHangupCall){
        this.props.onHangupCall({user_id:this.state.user_id,full_name:this.state.first_name});
     }
    for(let key in this.participants){
        this.participants[key].close(); 
        this.participants[key].onicecandidate = null; 
        this.participants[key].onaddstream = null; 
    } 
    this.destroyStream();
    
    
 };
 destroyStream = ()=>{
    if(window.stream){
        window.stream.getVideoTracks().forEach(function (track) {
            track.stop()
        });
        window.stream.getAudioTracks().forEach(function (track) {
            track.stop()
        });
        if(window.ScreenStream){
            window.ScreenStream.getVideoTracks().forEach(function (track) {
                track.stop()
            });
        }
    }
 }
  render() {
    if(this.state.redirect){
        return(
            <Redirect to={this.state.redirect} />
        )
    }
    return (
        <section className="height100-20">
        <div className="left-pannel">
            <CallSideBarComponent room={this.room} userName={this.userName} userId={this.name}/>
        </div>
        <div id="call-open" className="flex-grid widthheight100">
            <div appdragdrop="" className="col th-p pos-relative">
            <header className="top-box-shadow hide">
                <div className=" chats width100">
                    <div className="content-wrapper is-clearfix">
                        <div className="chat-name">
                        <div className="is-pulled-left">
                            <i className="fa is-pulled-left fa-chevron-left hide"></i>
                            <div className="display-ib">
                                <div className="empty-head-icon hide">{this.state.first_name && this.state.first_name != 'Group' ?this.state.first_name.charAt(0).toUpperCase():<i className="fa fa-users"/>}</div>
                            </div>
                            <span className="chat-title-name is-size-6 mar-5-0">{this.state.first_name ? this.state.first_name:'Group'}</span>
                        </div>
                        <div className="is-marginless is-clearfix is-pulled-right">
                            <div className="is-pulled-right mar-10-0">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className={this.state.public ? "contents is-marginless parent-callvid blackbg":"contents is-marginless parent-callvid"}>
                <div className="no-scroll black">
                    <div className="audio_view cust-aud-view">
                        <div className="audio_content">
                        <div className="secure-wrap">
                            <div className="encrypt-txt encrypttxt-width-plist"><i className="fa fa-lock"></i> Both video and audio in this call is secured with end-to-end encryption</div>
                        </div>
                        <div className="call-icon-wrp">
                            <h1 className="font30">{this.state.first_name}</h1>
                            <div className="display-ib"> 
                                <div className="empty-head-icon">{this.state.first_name && this.state.first_name!= 'Group' ?this.state.first_name.charAt(0).toUpperCase():<i className="fa fa-users"/>}</div>
                            </div>
                        </div>
                       
                        <div className="conference_icon toolwidth-plist">
                            <a className={this.state.screenShareSupported ? (this.state.screenEnabled ? "option_btn on":"option_btn off"):"hide"} onClick={this.onStartScreenShare}><i className={this.state.screenEnabled ? "fa fa-desktop gree":"fa fa-desktop red"}></i></a>
                            <a className={this.state.videoEnabled ? "option_btn on":"option_btn off"} onClick={this.onEnableOrDisableVideo}><i className={this.state.videoEnabled ? "fa fa-video-camera green":"fa fa-video-camera red"}></i></a>
                            <a onClick={this.onEnableOrDisableMic} className={this.state.audioEnabled ? "option_btn on":"option_btn off"}><i  className={this.state.audioEnabled ? "fa fa-microphone green":"fa fa-microphone red" }></i></a>
                            <a onClick={this.onHangupCall} id="closecall" className="option_btn"><i className="fa fa-phone rotate-img"></i></a>
                            <a onClick={this.inviteUers} className={this.state.public && !this.callToken ? "hide":"option_btn"}><i className="fa fa-share-alt"></i></a>
                        </div>
                        </div>
                    
                    
                        
                    </div>

                    <div className={this.state.screenEnabled ? "video-parent":"video-parent"}>
                        <div id="local-video-wrap">
                           <div className="video-name">Me</div>
                           <video className={this.state.screenEnabled ? "hide":"local-video"} id ="local-video" muted autoPlay playsInline></video> 
                           <img  id ="local-novideo" src="/files/placeholder.png" />
                        </div>
                        
                        <div className="video-parent" id ="remote-video"></div>
                    </div>
                    <div className={this.state.screenEnabled ? "screen-not":"hide"}>
                            <video className="local-screen-video" id ="local-screen-video"  muted autoPlay playsInline></video>
                            You're presenting your screen to everyone
                    </div>
                    
                </div>
            </div>
            </div>
        </div>
        </section>
      )
    
  }
}
export default CallOpenWindow;
