import React, { Component } from 'react';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
import SocketHandler from '../helpers/socket-service';
import UserSession from '../helpers/user-session';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequestHandler from '../helpers/request-handler';
import Form from './Form';
import Pagination from "react-js-pagination";
import { defaultConfig } from '../helpers/common-config';
class RecordingPop extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            files: [],
            viewInput: false,
            listInd: '', 
            editData: {}, 
            newFilename: '', 
            createMeetingPop: false,
            recordList:{},
            pageOffset: defaultConfig.DEFAULT_OFFSET,
            pageLimit: defaultConfig.DEFAULT_RECORD_LIMIT
        }

    }


    componentDidMount = () => {
        // this.updateState();
    }


    toggleCreateMeeting = () => {
        this.setState(prevState => ({
            createMeetingPop: !prevState.createMeetingPop
        }));
        if(!this.state.createMeetingPop) {
            this.getRecordList(this.props.selectedListDetails, defaultConfig.DEFAULT_OFFSET)
        }
    }


    getRecordList = (data, offset) => {
        if(data.chat_type === 'group') {
            RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/meets/records?groupId='+data.group_id+'&offset='+ offset +'&limit='+this.state.pageLimit, (response) => {
                // if(response.success === true){
                let dataRecord = [...this.state.files];
                dataRecord = [...response.record];
                // dataRecord.reverse();
                this.setState({ files: dataRecord, recordList: response })
                // }else{
                //     alert(response.message);
                // } 
            })
        } else if (data.chat_type === 'user') {
            RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/meets/records?userId='+data.user_id+'&offset='+ offset +'&limit='+this.state.pageLimit, (response) => {
                // if(response.success === true){
                let dataRecord = [...this.state.files];
                dataRecord = [...response.record];
                // dataRecord.reverse();
                this.setState({ files: dataRecord, recordList: response  })
                // }else{
                //     alert(response.message);
                // } 
            })
        }
        
    }

    editRecord = (data, ind) => {
        let dataRecordintex = this.state.files.findIndex(rec => {
            return rec.recording_id === data.recording_id
        });
        let dataRecord = { ...this.state.files[dataRecordintex] };
        this.setState({newFilename: data.file_name})
        this.setState({ viewInput: true, editData: dataRecord, listInd: data.recording_id})
    }

    handleInputChange = e => {
        this.setState({ newFilename: e.target.value });
    };

    handleFormSubmit = e => {
        e.preventDefault();
        let record = [...this.state.files]
        let dataRecordintex = this.state.files.findIndex(rec => {
            return rec.recording_id === this.state.editData.recording_id
        });
        let dataRecord = { ...this.state.files[dataRecordintex] };
        dataRecord.file_name = this.state.newFilename;
        const payload = {
            recording_id: dataRecord.recording_id,
            unique_name: dataRecord.unique_name,
            room_id: dataRecord.room_id,
            file_name: dataRecord.file_name,
            record_desc: dataRecord.record_desc,
            record_file_path: dataRecord.record_file_path,
            title: dataRecord.title,
        }
        RequestHandler.put(Config.API_DOMAIN + '/canstring/v1/meets/records', payload, (response) => {
            if (response.success === true) {
                // this.getfileList();
                record[dataRecordintex] = dataRecord;
                toast.dark("Record Updated Successfully", {
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ viewInput: false, files: record });
            } else {
                console.log(response.message);
            }
        })
        //   console.log(dataRecord)
        // this.props.onUpdate(this.state);
    };

    updateName = (data) => {
        this.setState({ newFilename: data });
    }

    onCancel = () => {
        this.setState({ viewInput: false });
    };


    componentWillUnmount = () => {

    }

    onChangeInput = (event) => {
        this.setState({ userName: event.target.value })
    }
    recordingDelete = (id, ind) => {
        RequestHandler.deleteAPI(Config.API_DOMAIN + '/canstring/v1/meets/records/' + id, (response) => {
            if (response.success === true) {
                const array = [...this.state.files]
                array.splice(ind, 1);
                toast.dark("Record Deleted Successfully", {
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ files: array })
            } else {
                console.log(response.message);
            }
        })
    }

    handleCopy = () => {
        let copyText = document.getElementById("globalurl");
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
    }

    handlePageChange(pageNumber) {
        this.getRecordList(this.props.selectedListDetails, pageNumber - 1);
        this.setState({pageOffset: pageNumber});
      }

    extention = (filename) => {
        return filename.substr(filename.lastIndexOf('.') + 1)
    }
    render() {
        return (
            <div>
                <div class={this.state.createMeetingPop ? "modal schedule fade bs-example-modal-lg demo modalshow" : "modal fade bs-example-modal-lg demo"} id="shedule-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content meet-input scroll-bar">
                                <div class="modal-header">
                                    <button onClick={this.toggleCreateMeeting.bind()} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    <h4 class="modal-title">Recording</h4>
                                </div>
                                <div class="modal-body">
                                    
                                <div className='card pop-scroll scroll-bar'>
                                                                        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                                                            <thead>
                                                                                {/* <tr>
                                                                <th colSpan='3'>
                                                                    Record Documents
                                                                </th>
                                                            </tr> */}
                                                                                <tr>
                                                                                    <th>File Name</th>
                                                                                    <th>Created Date</th>
                                                                                    <th className='has-text-centered'>Action</th>
                                                                                </tr>
                                                                                {this.state.viewInput ? <tr>
                                                                                    <th colSpan='3'>
                                                                                        <Form
                                                                                            newFilename = {this.state.newFilename}
                                                                                            handleFormSubmit={this.handleFormSubmit}
                                                                                            handleInputChange={this.handleInputChange}
                                                                                            onCancel={this.onCancel}
                                                                                            updateforminput={this.updateName}
                                                                                        />
                                                                                    </th>
                                                                                </tr> : null}
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.files.length !== 0 ?
                                                                                    this.state.files.map((file, index) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{file.file_name}</td>
                                                                                                <td>{moment(file.createdAt).format('MMM DD YYYY HH:mm')}</td>
                                                                                                <td className='has-text-centered'>
                                                                                                    <a data-tip="Download" href={'https://mediaserver.canstringai.com/recordings/' + file.unique_name + '.mkv'} target="_blank">
                                                                                                        <i className="fa fa-download" />
                                                                                                    </a>
                                                                                                    <a data-tip="Edit" onClick={this.editRecord.bind(this, file, index)}>
                                                                                                        <i className="fa fa-pencil" />
                                                                                                    </a>
                                                                                                    <a data-tip="Delete" onClick={this.recordingDelete.bind(this, file.recording_id, index)}>
                                                                                                        <i className="fa fa-trash" />
                                                                                                    </a>
                                                                                                </td>
                                                                                                <ReactTooltip place="top" type="dark" effect="float" />
                                                                                            </tr>
                                                                                        )
                                                                                    }) : <tr>
                                                                                        <td colSpan='3' className='has-text-centered'><p>No Records Here</p></td>
                                                                                    </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        {this.state.recordList.TotalRowCount > defaultConfig.DEFAULT_RECORD_LIMIT ? <div className='has-text-right pagination-align'>
                                                                            <Pagination
                                                                            activePage={this.state.pageOffset}
                                                                            itemsCountPerPage={this.state.pageLimit}
                                                                            totalItemsCount={this.state.recordList.TotalRowCount}
                                                                            pageRangeDisplayed={defaultConfig.DEFAULT_PAGE_RANGE}
                                                                            onChange={this.handlePageChange.bind(this)}
                                                                            />
                                                                        </div> : null}
                                                                    </div>

                                </div>
                                <div className={this.state.meetType === 'false' ? 'modal-footer justify-content-between' : 'modal-footer'}>
                                    <div>
                                        <button class="btn btn-fade mar-0-10" onClick={this.toggleCreateMeeting.bind()}>Cancel</button>                                      
                                    </div>
                                </div>
                        </div>


                    </div>
                
                </div>
            </div>
        )

    }
}
export default RecordingPop;
