import React, { Component } from 'react';
import SocketHandler from '../helpers/socket-service';
import UserSession from '../helpers/user-session';
import ReactTooltip from 'react-tooltip';
import RequestHandler from '../helpers/request-handler';
import Config from '../config';
class CallSideBarComponent extends Component {
  
  constructor(props) {
    super(props);
    this.state = {selectedMenu:'userlist',userListMeeting:{},public:this.props.public,userId:this.props.userId,selectedTab:'userlist',
    currentChat:'',chatList:[],selectedChatGroup:'public',selectedChatGroupName:'public',isPannelOpen:false,
    title:'',link:'',Org:'',startDate:'',endDate:'',meetingStarted:false,bot:false,chatListDialogue:[],caption:true,
    disablePv:'false',selectedChatVal:'public*_*Public',allowedUsers:20}
    this.WEBINAR = this.props.webinar;
    this.room = this.props.room;
    this.userId = this.props.userId;
    this.userName = this.props.userName;
    this.temphost = this.props.temphost;
    this.alias = this.props.alias;
    this.hosttrclick = false;
    this.streamId = '';
    

    SocketHandler.getSocket((socket)=>{
        socket.on('onUserListMeeting',(data) => {
            data =  JSON.parse(data);
            let hostAvailable = false;
            for(let k in data ){
                if(data[k].public === false){
                    hostAvailable = true;
                }
                // console.log(data[k].windowId+'//'+data[k].userName)
                this.updateName(data[k].windowId,data[k].userName)
                
            }
            this.props.hostStatus(hostAvailable)
            this.setState({userListMeeting:data})

        });
        
        socket.on('userLeftMeeting',(data) => {
            //alert(data)
            data =  JSON.parse(data);
            if(data.public){
                //this.props.showNotification(data.userName+' has left from meeting');
            }
            

        });
        socket.on('onNameChange',(data) => {
                    
            data =  JSON.parse(data);
            this.userName = data.parsedData.userName;
            //alert(this.userName)
            

        });
        socket.on('onReceiveSendToAll',(data) => {
            data =  JSON.parse(data);
            switch (data.method) {
                case 'isSpeaking': 
                    
                this.showSpeaking(data) 
                    
                break;
                case 'chat':
                    if(data.room === this.room){
                        let allow = false;
                        if(data.group == 'public' || data.group == this.userId ){
                            allow = true;
                        }
                        if(allow){
                            data.myChat = false;
                            let chatList = this.state.chatList;
                            chatList.push(data);
                            this.setState({chatList:chatList})
                            setTimeout(() => {
                                document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
                            }, 100);
                            this.props.newChat(false);
                        }
                    }
                break;
            }
        });
    })
  }
  loadMore = () =>{
      let ct = this.state.allowedUsers;
      ct = ct+100;
      this.setState({allowedUsers:ct})
  }
  updateName = (windowId,userName) =>{
    if(document.getElementsByClassName('remote-name-'+windowId)[0]){
        document.getElementsByClassName('remote-name-'+windowId)[0].innerHTML = userName;
        document.getElementsByClassName(windowId+'-audio')[0].innerHTML = userName.charAt(0).toUpperCase()+''+userName.charAt(1).toUpperCase()
    }
  }
  updateStreamId = (id) =>{
        this.streamId = id;
  }
  componentDidMount = ()=> {
    
  }
  setCaption  = (val) => {
     this.setState({caption:val})
     if(this.state.selectedMenu === 'dialogue'){
        this.selectTab('userlist')
     }
  }
  showSpeaking  = (data) => {
    if(document.getElementById(data.windowId+'a1')){
        if(data.value < 40){
            document.getElementById(data.windowId+'a1').style.opacity = '1';
            document.getElementById(data.windowId+'a2').style.opacity = '0';
            document.getElementById(data.windowId+'a3').style.opacity = '0';
        }else if(data.value >= 40 && data.value < 60){
            document.getElementById(data.windowId+'a1').style.opacity = '1';
            document.getElementById(data.windowId+'a2').style.opacity = '1';
            document.getElementById(data.windowId+'a3').style.opacity = '0';
        }else{
            document.getElementById(data.windowId+'a1').style.opacity = '1';
            document.getElementById(data.windowId+'a2').style.opacity = '1';
            document.getElementById(data.windowId+'a3').style.opacity = '1';
        }
        if(window.speakingTimeoutSidebar){
         clearTimeout(window.speakingTimeoutSidebar[data.windowId+'list'])
        }else{
            window.speakingTimeoutSidebar = {};
        }
        window.speakingTimeoutSidebar[data.windowId+'list'] = setTimeout((windowId)=>{
            if(document.getElementById(windowId+'a1')){
                document.getElementById(windowId+'a1').style.opacity = '0';
                document.getElementById(windowId+'a2').style.opacity = '0';
                document.getElementById(windowId+'a3').style.opacity = '0';
            }
        },2000,data.windowId)
    }
  }
  setMeetingStarted= (meetingStarted) =>{
    this.setState({meetingStarted,meetingStarted});
  }
  setMeetingInfo = (data,room) =>{
     this.room = room;
     this.setState({title:data.session_name,alias:data.meeting_alias_name,link:(Config.DOMAIN == 'localhost' ? 'http://localhost:3000/join/' + data.meeting_alias_name : 'https://'+Config.SITE_URL+'/join/' + data.meeting_alias_name),Org : data.first_name+' '+data.last_name,startDate:data.start_date_time,endDate:data.end_date_time})
  }
  sendChat = () =>{
     // alert(this.userName)
    if(this.state.currentChat.replace(/\s/g, '')){
        let chatList = this.state.chatList;
            let date = new Date().toLocaleString('en-US').split(',')[0]
            let time = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        if(this.state.bot === true){
            let myChatObject = {method:'chat',group:'Bot',name:this.userName,message:this.state.currentChat,myChat:true,date:date,time:time,room:this.room,selectedChatGroupName:'bot'};
                chatList.push(myChatObject)
            let param = {question:this.state.currentChat}
            RequestHandler.postPlain('https://mlcb.canstrings.com/chatbot', param,(response)=>{
                // alert(JSON.stringify(response))
                let chatObject = {method:'chat',group:'Bot',name:'Chat Bot',message:response.response,myChat:false,date:date,time:time,room:this.room,selectedChatGroupName:'bot'};
                chatList.push(chatObject)
                setTimeout(() => {
                    document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
               }, 100);
               let data = {};
                data.room = this.room;
                data.sender_name = this.userName;
                data.sender_userid = this.userId;
                data.sender_time = time;
                data.message = response.response;
                data.sendto_group = 'Chat Bot';
                
                this.saveChat(data)
            })
        }else{
            
            let chatObject = {method:'chat',group:this.state.selectedChatGroup,name:this.userName,message:this.state.currentChat,myChat:true,date:date,time:time,room:this.room,selectedChatGroupName:this.state.selectedChatGroupName};
            chatList.push(chatObject)
            this.setState({chatList:chatList})
            SocketHandler.sendToAll(chatObject);

            setTimeout(() => {
                 document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
            }, 100);
            let data = {};
            data.room = this.room;
            data.sender_name = this.userName;
            data.sender_userid = this.userId;
            data.sender_time = time;
            data.message = this.state.currentChat;
            data.sendto_group = this.state.selectedChatGroup;
            
            this.saveChat(data)
        }
        }
        this.setState({currentChat:''})
    }
    sendChatDialogue = (currentChat) =>{
        if(currentChat){
            if(currentChat.replace(/\s/g, '')){
                let chatList = this.state.chatListDialogue;
                let date = new Date().toLocaleString('en-US').split(',')[0]
                let time = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                let myChatObject = {method:'chat',group:'Bot',name:this.userName,message:currentChat,myChat:true,date:date,time:time,room:this.room,selectedChatGroupName:'bot'};
                chatList.push(myChatObject)
                let param = {question:currentChat}
                setTimeout(() => {
                    document.getElementById('chat-scroll-dialogue').scrollTop = document.getElementById('chat-scroll-dialogue').scrollHeight;
                }, 100);
                RequestHandler.postPlain('https://mlcb.canstrings.com/chatbot', param,(response)=>{
                    // alert(JSON.stringify(response))
                    let chatObject = {method:'chat',group:'Bot',name:'Chat Bot',message:response.response,myChat:false,date:date,time:time,room:this.room,selectedChatGroupName:'bot'};
                    chatList.push(chatObject)
                    setTimeout(() => {
                        document.getElementById('chat-scroll-dialogue').scrollTop = document.getElementById('chat-scroll-dialogue').scrollHeight;
                    }, 100);
                })
            }
        }
    }
    saveChat = (parem) => {
        /*
            RequestHandler.post(Config.API_DOMAIN+'/canstring/v1/meets/save', parem,(response)=>{
                if(response.success === true){
                    //this.getfileList();
                }else{
                    //alert(response.message);
                } 
        })
        */
    }
    giveWbControl = (data) =>{
        let status = true;
        if(data.wbAllowed){
            status = false
        }
        let sendData = {method:'onGiveWbConrol',room:this.room,status:status,userId:data.userId};
        SocketHandler.sendToAll(sendData);
      }
      
      callAWSConnect = (data) => {
        const payload = {
            user_id: data.userId,
            to_user_name: data.userName,
            phone_no:data.phone
        }
        if(!payload.phone_no){
            alert('User does not provide phone number !')
            return
        }
        RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/awsConnect', payload, (response) => {
            window.open('https://canstringmeet.my.connect.aws/ccp-v2/softphone', '_blank');
        });
    }
      onGiveScreenConrol = (data) =>{
        let status = true;
        if(data.screenAllowed){
            status = false
        }
        let sendData = {method:'onGiveScreenConrol',room:this.room,status:status,userId:data.userId};
        SocketHandler.sendToAll(sendData);
      }
      onHostingTransfer = (data) =>{
        if(this.hosttrclick){
            return;
        }
        this.hosttrclick = true;
        if(window.hostTranswerTimeout){{
            clearTimeout(window.hostTranswerTimeout)
        }}
        window.hostTranswerTimeout = setTimeout(() => {
            this.hosttrclick = false;
        }, 4000);
        let status = true;
        if(data.temphost){
            status = false
        }
        let sendData = {method:'onHostingTransfer',room:this.room,status:status,userId:data.userId,token:UserSession.getSession().token};
        SocketHandler.sendToAll(sendData);
        if(status){
            this.props.alert('Host role has been transfered, You can now leave from current meeting')
        }else{
            this.props.alert('Host role has been revoked from the user')
        }
      }
      
      
  giveControl = (data) =>{
    let status = true;
    if(data.userMode == 'presenter'){
        status = false
    }
    let sendData = {method:'onGiveConrol',room:this.room,status:status,userId:data.userId,windowId:data.windowId};
    SocketHandler.sendToAll(sendData);
  }
  controlCam = (data) =>{
    if(this.state.public){
        return;
    }
    let status = true;
    if(data.videoEnabled){
        status = false
    }else{
        if(data.userMode != 'presenter'){
            let sdata = {method:'onGiveConrol',room:this.room,status:true,userId:data.userId,windowId:data.windowId};
            SocketHandler.sendToAll(sdata);
        }
    }
    let sendData = {method:'controlCam',room:this.room,status:status,userId:data.userId};
    SocketHandler.sendToAll(sendData);
  }
  controlMic = (data) =>{
    if(this.state.public){
        return;
    }
    let status = true;
    if(data.audioEnabled){
        status = false
    }else{
        if(data.userMode != 'presenter'){
            let sdata = {method:'onGiveConrol',room:this.room,status:true,userId:data.userId,windowId:data.windowId};
            SocketHandler.sendToAll(sdata);
        }
    }
    let sendData = {method:'controlMic',room:this.room,status:status,userId:data.userId};
    SocketHandler.sendToAll(sendData);
  }
  onKick = (data) =>{
    let sendData = {method:'onkickUser',room:this.room,userId:data.userId};
    SocketHandler.sendToAll(sendData);
  }
  onLowerHand = (data) =>{
    let sendData = {method:'onLowerHand',room:this.room,userId:data.userId};
    SocketHandler.sendToAll(sendData);
  }
  
  
  givePresentingToAll = (status) =>{
    if(this.WEBINAR && status){
        this.props.showNotification('You cannot give presenting power to all for Webinar events');
        return;
    }
    let sendData = {method:'onGiveConrolToAll',room:this.room,status:status};
    SocketHandler.sendToAll(sendData);
    this.props.showNotification('You have '+(status?'given presenting power to':'revoked presenting power from')+' all users');
    this.setState({isPannelOpen:false})
  }
  chatSettingsChange = (event) =>{
    if(event.target.checked){
        this.onDisablePrivateChat(true);
    }else{
        this.onEnablePrivateChat();
    }
  }
  onDisablePrivateChat = (notification) =>{
    let sendData = {method:'onDisablePvChat',room:this.room};
    SocketHandler.sendToAll(sendData);
    if(notification){
        this.props.showNotification('You have disabled private chat for all');
    }
    document.getElementById('chatcontrol').checked = true;
  }
  onEnablePrivateChat = () =>{
    let sendData = {method:'onEnablePvChat',room:this.room};
    SocketHandler.sendToAll(sendData);
    this.props.showNotification('You have enabled private chat for all');
  }
  controlpv = (status) =>{
      this.setState({disablePv:status})
      if(status == 'true'){
        Object.keys(this.state.userListMeeting).map( (key,index) =>{
                if(this.state.userListMeeting[key].public === false){
                    this.setState({selectedChatGroup:this.state.userListMeeting[key].userId,selectedChatGroupName:'Chat With Host',selectedChatVal:this.state.userListMeeting[key].userId+'*_*'+this.state.userListMeeting[key].userName})
                }
        },this) 
          
      }else{
        this.setState({selectedChatGroup:'public',selectedChatGroupName:'public',selectedChatVal:'public*_*public'})
      }
  }
  micAllControl = (event) =>{
        if(event.target.checked){
            this.onMuteMicAll(true);
        }else{
            this.onAllowMicAll();
        }
  }
  camAllControl = (event) =>{
    if(event.target.checked){
        this.onDisableCamAll(true);
    }else{
        this.onAllowCamAll();
    }
}
  onMuteMicAll = (notification) =>{
    let sendData = {method:'onMuteMicAll',room:this.room};
    SocketHandler.sendToAll(sendData);
    if(notification){
        this.props.showNotification('You have muted all users');
    }
    this.setState({isPannelOpen:false})
    document.getElementById('miccontrlall').checked = true;
  }
  onAllowMicAll = () =>{
    let sendData = {method:'onAllowMicAll',room:this.room};
    SocketHandler.sendToAll(sendData);
    this.props.showNotification('You have allow mic for all users');
    this.setState({isPannelOpen:false})
  }
  onDisableCamAll = (notification) =>{
    let sendData = {method:'onDisableCamAll',room:this.room};
    SocketHandler.sendToAll(sendData);
    if(notification){
    this.props.showNotification('You have disable all users camera');
    }
    this.setState({isPannelOpen:false})
    document.getElementById('camcontrlall').checked = true;
  }
  onAllowCamAll = () =>{
    let sendData = {method:'onAllowCamAll',room:this.room};
    SocketHandler.sendToAll(sendData);
    this.props.showNotification('You have allowed all users camera');
    this.setState({isPannelOpen:false})
  }
  inviteParticipants = () =>{
      this.props.inviteParticipants();
  }
  editName = () =>{
    this.props.editName();
}
  onClose = ()=>{
      this.props.closeLeftPannel();
      //this.settingsRef.stopCam();
  }
  selectTab = (tab) =>{
     this.setState({selectedMenu : tab})
     if(tab === 'chat'){
        this.props.newChat(true);
     }
     if(tab === 'settings'){
         if(this.state.selectedMenu !== 'settings'){
            //this.settingsRef.gatherPermission();
         }
        
     }else{
        //this.settingsRef.stopCam();
     }
  }
  onMessageKeyUp = (e)=>{
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code == 13) {
       this.sendChat();
    }
}
onMsgInputChange = (e)=>{
    this.setState({currentChat:e.target.value})
}
onSendtoChange = (event) =>{
    if(event.target.value === 'bot'){
        this.setState({bot:true,selectedChatVal:event.target.value});
    }else{
        this.setState({bot:false,selectedChatGroup:event.target.value.split('*_*')[0],selectedChatGroupName:event.target.value.split('*_*')[1],selectedChatVal:event.target.value})
    }
    
}
toggleControlPannel = () =>{
    let {isPannelOpen} = this.state;
    isPannelOpen = !isPannelOpen;
    this.setState({isPannelOpen})
}
publishStream = () =>{
    
    this.props.publishStreamFromSettings();
}
handleCopy = () => {
    let copyText = document.getElementById("linkinp");
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");
}

  render() {
    let temphostExist = false
    Object.keys(this.state.userListMeeting).map( (key,index) =>{
        if(this.state.userListMeeting[key].temphost){
            temphostExist = this.state.userListMeeting[key].userId;
        }
    });
    let maxUserNameCharLength = 7;
    if(this.state.public){
        maxUserNameCharLength = 16;
    }
    let loaded = 0;
    return (
        <div class="col se-p is-chat right-box-shadow chat-list call-cont">
                <div class="call-detail">
                    <span class="d-block d-sm-none">
                        <a id="mob-option" class="mob-option hide">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </a></span>

                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" onClick={this.selectTab.bind(this,'userlist')}>
                            <a class={this.state.selectedMenu == 'userlist' ? "nav-link active":"nav-link"} id="user-tab" data-toggle="tab" role="tab" title='Participants'>
                                <i class="fa fa-users" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="nav-item" onClick={this.selectTab.bind(this,'chat')}>
                            <a class={this.state.selectedMenu == 'chat' ? "nav-link active":"nav-link"} id="chat-tab" data-toggle="tab" role="tab" title='Chat'>
                                <i class="fa fa-commenting-o" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class={this.state.caption ? "nav-item":"hide"} onClick={this.selectTab.bind(this,'dialogue')}>
                            <a class={this.state.selectedMenu == 'dialogue' ? "nav-link active":"nav-link"} id="chat-tab" data-toggle="tab" role="tab" title='Dialogue'>
                                <i class="fa fa-microphone" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="nav-item hide" onClick={this.selectTab.bind(this,'settings')}>
                            <a class={this.state.selectedMenu == 'settings' ? "nav-link active":"nav-link"} id="setting-tab" data-toggle="tab" role="tab" title='Settings'>
                                <i class="fa fa-cog" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="nav-item" onClick={this.selectTab.bind(this,'info')}>
                            <a class={this.state.selectedMenu == 'info' ? "nav-link active":"nav-link"} id="info-tab" data-toggle="tab"  role="tab" title='Info'>
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" onClick={this.onClose} data-toggle="tab" title="Close Side Panel"  role="tab">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content plist-height" id="myTabContent">

                        <div className={this.state.selectedMenu == 'userlist' ?"tab-pane fade show active user height100":"hide"} id="user" role="tabpanel" aria-labelledby="user-tab">
                            <div class="title">
                                <h6>Participants ({ Object.keys(this.state.userListMeeting).length})</h6>
                                <span class="d-flex flex-row">
                                    <i class="fa fa-search hide" aria-hidden="true"></i>
                                    <a data-tip="Open Control Menu" onClick={this.toggleControlPannel} id="parti-menu-btn"><i class={!this.state.public ? "fa fa-ellipsis-v":"hide"} aria-hidden="true"></i></a>
                                    {/* <i data-tip="Close Side Panel" class="fa fa-times" aria-hidden="true" onClick={this.onClose}></i> */}
                                    
                                </span>
                            </div>
                            <ul class={this.state.isPannelOpen ? "parti-menu show":"hide"}>
                                <li onClick={this.givePresentingToAll.bind(this,true)}><a><i class="fa fa-random" aria-hidden="true"></i> Give access to all</a></li>
                                <li onClick={this.givePresentingToAll.bind(this,false)}><a><i class="fa fa-compress" aria-hidden="true"></i> Revoke access from all</a></li>
                                <li><a><i class="fa fa-microphone" aria-hidden="true"></i> Mute all Mic <input id="miccontrlall" onChange={this.micAllControl} type="checkbox"></input></a></li> 
                                <li><a><i class="fa fa-video-camera" aria-hidden="true"></i> Disable all camera <input id="camcontrlall" onChange={this.camAllControl} type="checkbox"></input></a></li>
                            </ul>
                            <ul class="content user-list">
                            <li className="host-pres"> Host & Presenters</li>
                                {
                                    Object.keys(this.state.userListMeeting).map( (key,index) =>{
                                        if(this.state.userListMeeting[key].userMode === 'presenter'){
                                          return(
                                                <li>
                                                    <p class="name" data-tip={this.state.userListMeeting[key].userName}>
                                                        <span className={this.state.userListMeeting[key].public === false ? 'host':(this.state.userListMeeting[key].userMode === 'presenter'? 'presenter':'')}>{this.state.userListMeeting[key].userName.charAt(0).toUpperCase()+''+this.state.userListMeeting[key].userName.charAt(1).toUpperCase()}</span>{(this.state.userListMeeting[key].userName.length> maxUserNameCharLength ?this.state.userListMeeting[key].userName.substr(0,maxUserNameCharLength)+'..':this.state.userListMeeting[key].userName)+(this.state.userListMeeting[key].userId == this.state.userId ? '(Me)':(this.state.userListMeeting[key].public ? '':'(Host)'))}
                                                        <div class="audio-chker-blk">
                                                            <div id={this.state.userListMeeting[key].windowId+"a1"} class="first-dot"></div>
                                                            <div id={this.state.userListMeeting[key].windowId+"a2"} class="second-dot"></div>
                                                            <div id={this.state.userListMeeting[key].windowId+"a3"} class="third-dot"></div>
                                                        </div>
                                                    </p>
                                                    <button className={this.state.userListMeeting[key].userId == this.state.userId ? "edit-name":"hide"} onClick={this.editName}>Edit Name</button>
                                                    <p className={this.state.meetingStarted ? ( this.state.userListMeeting[key].userId === this.userId || (this.temphost && this.state.userListMeeting[key].public === false) ? "hide":"particon"):'hide'}>
                                                    <i data-tip={"Call To "+this.state.userListMeeting[key].phone} onClick={this.callAWSConnect.bind(this,this.state.userListMeeting[key])} className={(this.state.public || (this.state.userListMeeting[key].phone.toString().length < 11)) ? "hide":"fa fa-phone voice green pointer"} aria-hidden="true"></i>
                                                        <i data-tip={this.state.userListMeeting[key].screenAllowed ? "Revoke Screen Share Control":"Give Screen Share Control"} onClick={this.onGiveScreenConrol.bind(this,this.state.userListMeeting[key])} className={this.state.public ? "hide":(this.state.userListMeeting[key].screenAllowed ? "fa fa-tv chat green":"fa fa-tv chat red")}  aria-hidden="true"></i>
                                                        <i data-tip={this.state.userListMeeting[key].wbAllowed ? "Revoke Whiteboard Control":"Give Whiteboard Control"} onClick={this.giveWbControl.bind(this,this.state.userListMeeting[key])} className={this.state.public ? "hide":(this.state.userListMeeting[key].wbAllowed ? "fa fa-pencil chat green":"fa fa-pencil chat red")}  aria-hidden="true"></i>
                                                        <i data-tip={this.state.userListMeeting[key].userMode == 'presenter' ? "Revoke Presenting Power":"Give Presenting Power (cam,mic)"} onClick={this.giveControl.bind(this,this.state.userListMeeting[key])} className={this.state.public ? "hide":(this.state.userListMeeting[key].userMode == 'presenter' ? "fa fa-user chat green":"fa fa-user chat red")}  aria-hidden="true"></i>
                                                        <i data-tip="Lower Hand" onClick={this.onLowerHand.bind(this,this.state.userListMeeting[key])}  className={this.state.public ? "hide":(this.state.userListMeeting[key].handRaised ? "fa fa-hand-paper-o chat green":"hide")}  aria-hidden="true"></i>
                                                        {/*<i class="fa fa-commenting-o chat" aria-hidden="true"></i>*/}
                                                        <i data-tip={this.state.userListMeeting[key].videoEnabled ? this.state.public?"Video is on":"Disable Video":this.state.public?"Video is off":"Request to enable video"}  onClick={this.controlCam.bind(this,this.state.userListMeeting[key])} className={this.state.userListMeeting[key].videoEnabled ? "fa fa-video-camera video green":"fa fa-video-camera video red"} aria-hidden="true"></i>
                                                        <i data-tip={this.state.userListMeeting[key].audioEnabled ? this.state.public?"Audio is active":"Mute Audio":this.state.public?"Audio is muted":"Request to unmute audio"} onClick={this.controlMic.bind(this,this.state.userListMeeting[key])} className={this.state.userListMeeting[key].audioEnabled ? "fa fa-microphone voice green":"fa fa-microphone voice red"} aria-hidden="true"></i>
                                                        
                                                        <i data-tip={this.state.userListMeeting[key].temphost ? "Revoke Hosting":"Transfer your access"} onClick={this.onHostingTransfer.bind(this,this.state.userListMeeting[key])} className={(this.state.public || this.temphost) || (temphostExist && temphostExist !== this.state.userListMeeting[key].userId) ? "hide":(this.state.userListMeeting[key].temphost ? "fa fa-bullhorn chat green":"fa fa-bullhorn chat red")}  aria-hidden="true"></i>
                                                        <i data-tip="Remove User" onClick={this.onKick.bind(this,this.state.userListMeeting[key])} className={this.state.public ? "hide":(this.state.userListMeeting[key].userMode !== 'presenter' ?"fa fa-times voice red pointer":"hide")} aria-hidden="true"></i>
                                                    </p>
                                                    
                                                </li>
                                          )
                                        }
                                    },this) 
                                }
                                
                                <li className="viewers"> Listeners</li>
                                {
                                    Object.keys(this.state.userListMeeting).map( (key,index) =>{
                                        if(this.state.userListMeeting[key].userMode !== 'presenter'){
                                            //if(!this.state.public || !this.WEBINAR){
                                                if(loaded < this.state.allowedUsers || !this.WEBINAR){
                                                loaded++;
                                                return(
                                                        <li>
                                                            <p class="name" data-tip={this.state.userListMeeting[key].userName}>
                                                                <span  className={this.state.userListMeeting[key].public === false ? 'host':(this.state.userListMeeting[key].userMode === 'presenter'? 'presenter':'')}>{this.state.userListMeeting[key].userName.charAt(0).toUpperCase()+''+this.state.userListMeeting[key].userName.charAt(1).toUpperCase()}</span>{(this.state.userListMeeting[key].userName.length> maxUserNameCharLength ?this.state.userListMeeting[key].userName.substr(0,maxUserNameCharLength)+'..':this.state.userListMeeting[key].userName)  +(this.state.userListMeeting[key].userId == this.state.userId ? '(Me)':(this.state.userListMeeting[key].public ? '':'(Host)'))}
                                                                <div class="audio-chker-blk">
                                                                    <div id={this.state.userListMeeting[key].windowId+"a1"} class="first-dot"></div>
                                                                    <div id={this.state.userListMeeting[key].windowId+"a2"} class="second-dot"></div>
                                                                    <div id={this.state.userListMeeting[key].windowId+"a3"} class="third-dot"></div>
                                                                </div>
                                                            </p>
                                                            <button className={this.state.userListMeeting[key].userId == this.state.userId ? "edit-name":"hide"} onClick={this.editName}>Edit Name</button>
                                                            <p className={this.state.meetingStarted ? ( this.state.userListMeeting[key].userId === this.userId || (this.temphost && this.state.userListMeeting[key].public === false) ? "hide":"particon"):'hide'}>
                                                            <i data-tip={"Call To "+this.state.userListMeeting[key].phone} onClick={this.callAWSConnect.bind(this,this.state.userListMeeting[key])} className={(this.state.public || (this.state.userListMeeting[key].phone.toString().length < 11)) ? "hide":"fa fa-phone voice green pointer"} aria-hidden="true"></i>
                                                            <i data-tip={this.state.userListMeeting[key].screenAllowed ? "Revoke Screen Share Control":"Give Screen Share Control"} onClick={this.onGiveScreenConrol.bind(this,this.state.userListMeeting[key])} className={this.state.public ? "hide":(this.state.userListMeeting[key].screenAllowed ? "fa fa-tv chat green":"fa fa-tv chat red")}  aria-hidden="true"></i>
                                                                <i data-tip={this.state.userListMeeting[key].wbAllowed ? "Revoke Whiteboard Control":"Give Whiteboard Control"} onClick={this.giveWbControl.bind(this,this.state.userListMeeting[key])} className={this.state.public ? "hide":(this.state.userListMeeting[key].wbAllowed ? "fa fa-pencil chat green":"fa fa-pencil chat red")}  aria-hidden="true"></i>
                                                                <i data-tip={this.state.userListMeeting[key].userMode == 'presenter' ? "Revoke Presenting Power":"Give Presenting Power (cam,mic)"} onClick={this.giveControl.bind(this,this.state.userListMeeting[key])} className={this.state.public ? "hide":(this.state.userListMeeting[key].userMode == 'presenter' ? "fa fa-user chat green":"fa fa-user chat red")}  aria-hidden="true"></i>
                                                                <i data-tip="Lower Hand" onClick={this.onLowerHand.bind(this,this.state.userListMeeting[key])}  className={this.state.public ? "hide":(this.state.userListMeeting[key].handRaised ? "fa fa-hand-paper-o chat green":"hide")}  aria-hidden="true"></i>
                                                                {/*<i class="fa fa-commenting-o chat" aria-hidden="true"></i>*/}
                                                                <i data-tip={this.state.userListMeeting[key].videoEnabled ? this.state.public?"Video is on":"Disable Video":this.state.public?"Video is off":"Request to enable video"}  onClick={this.controlCam.bind(this,this.state.userListMeeting[key])} className={this.state.userListMeeting[key].videoEnabled ? "fa fa-video-camera video green":"fa fa-video-camera video red"} aria-hidden="true"></i>
                                                                <i data-tip={this.state.userListMeeting[key].audioEnabled ? this.state.public?"Audio is active":"Mute Audio":this.state.public?"Audio is muted":"Request to unmute audio"} onClick={this.controlMic.bind(this,this.state.userListMeeting[key])} className={this.state.userListMeeting[key].audioEnabled ? "fa fa-microphone voice green":"fa fa-microphone voice red"} aria-hidden="true"></i>
                                                                <i data-tip={this.state.userListMeeting[key].temphost ? "Revoke Hosting":"Transfer your access"} onClick={this.onHostingTransfer.bind(this,this.state.userListMeeting[key])} className={(this.state.public || this.temphost) || (temphostExist && temphostExist !== this.state.userListMeeting[key].userId) ? "hide":(this.state.userListMeeting[key].temphost ? "fa fa-bullhorn chat green":"fa fa-bullhorn chat red")}  aria-hidden="true"></i>
                                                                <i data-tip="Remove User" onClick={this.onKick.bind(this,this.state.userListMeeting[key])} className={this.state.public ? "hide":(this.state.userListMeeting[key].userMode !== 'presenter' ?"fa fa-times voice red pointer":"hide")} aria-hidden="true"></i>
                                                            </p>
                                                            <ReactTooltip />
                                                        </li>
                                                )
                                                }else{
                                                    return(
                                                        <button className="load-more" onClick={this.loadMore}>Load More</button>
                                                    )
                                                }
                                            //}
                                        }
                                    },this) 
                                }

                            </ul>

                            
                            <div class="footer hide" >
                                  <span onClick={this.inviteParticipants}>
                                    <a class="btn btn-second invite-ic"><i class="fa fa-envelope" aria-hidden="true"></i> Invite
                                        Participants</a>
                                </span>
                            </div>


                        </div>
                        <div className={this.state.selectedMenu == 'chat' ? "tab-pane fade chat":"hide"} id="chat" role="tabpanel" aria-labelledby="chat-tab">

                            <div class="title">
                                <h6 className='mar-bot-10 chathd'>Chat <input className={this.state.public ? 'hide':''} id="chatcontrol" onChange={this.chatSettingsChange} type="checkbox"></input><span className={this.state.public ? 'hide':''}>Disable Private Chat </span></h6>
                                {/* <span>
                                    <i data-tip="Close Side Panel" class="fa fa-times" aria-hidden="true" onClick={this.onClose}></i>
                                </span> */}
                            </div>
                            <div class="content" id="chat-scroll">

                                <ul class="chat-area">
                                {
                                        this.state.chatList.map((chat,index)=>{
                                            return(
                                                <li className={chat.myChat ? '':'right'}>
                                                    <div class="person">
                                                        {chat.name.charAt(0).toUpperCase()}
                                                    </div>
                                                    <div class="msg">
                                                        <p class="text">{chat.message}</p>
                                                        <p class="time">{(chat.myChat ? 'You':chat.name) + ' to ' + (chat.group == 'public' ? 'everyone':(chat.myChat ? chat.selectedChatGroupName:'You'))} {chat.time}</p>
                                                    </div>


                                                </li>
                                            )
                                        })
                                    }
                                   



                                </ul>


                            </div>
                            <div class="input">
                                <p>To : <select onChange={this.onSendtoChange.bind(this)} value={this.state.selectedChatVal}>
                                        <option className={this.state.disablePv === 'false' ? '':'hide'} value="public*_*Public">Public</option>
                                        <option className={this.state.disablePv === 'false' ? '':'hide'} value='bot'>Ask To Bot</option>
                                        {
                                            
                                    Object.keys(this.state.userListMeeting).map( (key,index) =>{
                                        
                                        if(this.state.userListMeeting[key].userId != this.state.userId && this.state.disablePv === 'false'){
                                          return(
                                          <option value={this.state.userListMeeting[key].userId+'*_*'+this.state.userListMeeting[key].userName}>{this.state.userListMeeting[key].userName}</option>
                                          )
                                        }else {
                                            if(this.state.userListMeeting[key].userId != this.state.userId && this.state.disablePv === 'true' && this.state.userListMeeting[key].public === false){
                                                return(
                                                    <option value={this.state.userListMeeting[key].userId+'*_*'+this.state.userListMeeting[key].userName}>Chat With Host</option>
                                                    )
                                            }
                                            }
                                    },this) 
                                }
                                    </select></p>
                                <div class="d-flex justify-content-between">
                                    <input type="text" onKeyUp={this.onMessageKeyUp} value={this.state.currentChat} onChange={this.onMsgInputChange} placeholder="Type your message..."/><a><i onClick={this.sendChat} class="fa fa-paper-plane" aria-hidden="true"></i></a>
                                </div>
                            </div>


                        </div>
                        <div className={this.state.selectedMenu == 'dialogue' ? "tab-pane fade chat":"hide"} id="chat" role="tabpanel" aria-labelledby="chat-tab">
                            <div class="title">
                                <h6 className='mar-bot-10'>Dialogue</h6>
                            </div>
                            <div class="content" id="chat-scroll-dialogue">
                                <ul class="chat-area">
                                    {
                                        this.state.chatListDialogue.map((chat,index)=>{
                                            return(
                                                <li className={chat.myChat ? '':'right'}>
                                                    <div class="person">
                                                        {chat.name.charAt(0).toUpperCase()}
                                                    </div>
                                                    <div class="msg">
                                                        <p class="text">{chat.message}</p>
                                                        <p class="time">{(chat.myChat ? 'You':chat.name) + ' to ' + (chat.group == 'public' ? 'everyone':(chat.myChat ? chat.selectedChatGroupName:'You'))} {chat.time}</p>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>          
                        <div className={this.state.selectedMenu == 'settings' ? "tab-pane fade setting":"hide"} id="setting" role="tabpanel" aria-labelledby="setting-tab">

                            <div class="title">
                                <h6 className='mar-bot-10'>Settings</h6>
                                {/* <span>
                                    <i data-tip="Close Side Panel" class="fa fa-times" aria-hidden="true" onClick={this.onClose}></i>
                                </span> */}
                            </div>
                        </div>
                        <div className={this.state.selectedMenu == 'info' ? "tab-pane fade info":"hide"} id="info" role="tabpanel" aria-labelledby="info-tab">


                            <div class="title">
                                <h6 className='mar-bot-10'>Meeting Details</h6>
                                <span class="d-flex flex-row">
                                    <i class="fa fa-files-o hide" aria-hidden="true"></i>
                                    {/* <i data-tip="Close Side Panel" class="fa fa-times" aria-hidden="true" onClick={this.onClose}></i> */}
                                </span>
                            </div>
                            <div class="content">

                            <p><span>Meeting Title :</span> {this.state.title}</p>
                            <p><span>Start Date :</span> {this.state.startDate}</p>
                            <p><span>End Date :</span> {this.state.endDate}</p>
                                <p><span>Organizer :</span> {this.state.Org}</p>
                                <p><span>Meeting ID :</span> {this.state.alias}</p>
                                <p><span class="d-flex justify-content-between">Meeting Link : <i onClick={this.handleCopy.bind()}  class="fa fa-files-o pointer" aria-hidden="true"></i></span><input readOnly  id="linkinp" className="linkinput" type="text" value={this.state.link} /></p>
                            </div>

                            <div class="footer" >
                                <span onClick={this.inviteParticipants}>
                                    <a class="btn btn-second invite-ic"><i class="fa fa-envelope" aria-hidden="true"></i> Invite
                                        Participants</a>
                                </span>
                            </div>

                        </div>

                    </div>

                </div>
                
        </div>
      )
    
  }
}
export default CallSideBarComponent;
