import React, { Component } from 'react';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
import SocketHandler from '../helpers/socket-service';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';

import moment from 'moment';
import momenttz from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import GroupUserListing from './GroupUserList';
import RecordingPop from './RecordingPop';
// import Pagination from './pagination';
import Pagination from "react-js-pagination";
import { defaultConfig } from '../helpers/common-config';
import LessonListing from './LessonListing';


const Input = ({onChange, placeholder, value, isSecure, id, onClick}) => (
	<input
		onChange={onChange}
		placeholder={placeholder}
		value={value}
		isSecure={isSecure}
		id={id}
        onClick={onClick}
        className="input"
	/>
);

class SessionListing extends Component {

    constructor(props) {
        super(props);
        this.currentUser = ''
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.validator = '';
        let currentUserSession =  UserSession.getSession();
        if(!currentUserSession.device) {
            currentUserSession.device = '';
        }

        this.state = {
            curentloggedUser: { ...JSON.parse(localStorage.getItem('userData')) },
            userData: {...currentUserSession},
            meetSelectedUser: this.props.selectedUserData,
            currentDate: new Date(),
            meetList: [],
            chatOpen: false,
            createMeetingPop: false,
            viewModalData: false,
            viewSchduleModal: false,
            meetName: '',
            meetSeatType: 'conference',
            aliasName:'',
            meetType: null,
            startNow: { value: false },
            startTime: '',
            endTime: '',
            selected: this.calculateMinTime(new Date()),
            selectedEnd: this.calculateEndMinTime(new Date()),
            startDate: '',
            groupUserView: false,
            pageOfItems: [],
            meetingListData:{},
            pageOffset: defaultConfig.DEFAULT_OFFSET,
            pageLimit: defaultConfig.DEFAULT_RECORD_LIMIT
            // minTime: this.calculateMinTime(new Date())
        }
        this.fetchSessionStat();
    }
    fetchSessionStat = () =>{
        if(window.sessInterv){
            clearInterval(window.sessInterv)
        }
        window.sessInterv = setInterval(this.callStatApi, 5000);
        setTimeout(this.callStatApi,1000)
    }
    callStatApi = () =>{
        fetch('https://'+Config.DOMAIN+'/canstring/v1/active-rooms').then(function(response) {
              return response.json();
            }).then(function(data) {
              let elements = document.getElementsByClassName('onlinestat');
              if(elements.length > 0){
                for(let ind = 0; ind < elements.length; ind++){
                  elements[ind].classList.remove('green-stat')
                  elements[ind].classList.add('red-stat')
                  elements[ind].innerHTML = 'OFFLINE';
                }
              }
              let el = document.getElementsByClassName('onlineico');
              if(el.length > 0){
                for(let elct = 0; elct < el.length; elct++){
                  el[elct].classList.remove('circle-green')
                  el[elct].classList.add('circle-gray')
                }
              }
              data.map((value,index)=>{
                if(document.getElementById(value.room)){
                  document.getElementById(value.room).classList.remove('red-stat')
                  document.getElementById(value.room).classList.add('green-stat')
                  document.getElementById(value.room).innerHTML = 'ONLINE';
                }
                if(document.getElementById(value.userId+'stat')){
                  document.getElementById(value.userId+'stat').classList.remove('circle-gray')
                  document.getElementById(value.userId+'stat').classList.add('circle-green')
                }
              })
            }).catch(function(error) {
              console.log(error);
            });
    }
    toggleChat = () => {
        this.setState(preChat => ({
            chatOpen: !preChat.chatOpen
        }))
        this.props.handler();
        if (this.state.chatOpen) {
            this.getMeetingList(this.state.meetSelectedUser, this.state.pageOffset)
        }
    }
    openClassView = () =>{
        this.props.openClassView();
    }
    toggleCreateMeeting = () => {
        this.setState(prevState => ({
            createMeetingPop: !prevState.createMeetingPop
        }));
        this.setState(this.getClearMeet())
        if (!this.state.createMeetingPop) {
            this.validator.hideMessages();
            this.setState({ viewSchduleModal: true })
            this.createRoom();
        }
    }

    createJoinMeet = () => {
        localStorage.setItem('createdRoom', this.room);        
        this.onCreateMeet('instant');
    }
    getClearMeet = () => {
        return {
            meetName: '',
            aliasName: '',
            meetType: null,
            startTime: '',
            endTime: '',
            startDate: '',
            meetSeatType: 'conference'
        }
    }

    handleChange = (param, event) => {
        let stateObj = {};
        // let error = false;
        // if (event.target.value === "") {
        //     // @ts-ignore
        //     error = "This field is required";
        // }
        // stateObj[param] = { value: event.target.value, error: error };
        stateObj[param] = event.target.value;
        this.setState(stateObj);
    };

    showUserList = () => {
        this.setState(prevState => ({
            groupUserView: !prevState.groupUserView
        }));
    }

    toggleViewContent = () => {
        this.props.parentToggle();
    }

    hideUserView = () => {
        this.setState(prevState => ({
            groupUserView: !prevState.groupUserView
        }));
    }

  calculateMinTime = date => {
    let isToday = moment(date).isSame(moment(), 'day');
    if (isToday) {
        let nowAddOneHour = moment(new Date()).add({hours: 0}).toDate();
        return nowAddOneHour;
    }
    return moment().startOf('day').toDate(); 
}

    calculateEndMinTime = date => {
        let isToday = moment(date).isSame(moment(), 'day');
        if (isToday) {
            let nowAddOneHour = moment(new Date()).add({hours: 0.30}).toDate();
            return nowAddOneHour;
        }
        return moment().startOf('day').toDate();
        // let isToday = moment(date).isSame(moment(), 'day');
        // if (isToday) {
        //     let nowAddOneHour = moment(new Date()).add({hours: 1}).toDate();
        //     return nowAddOneHour;
        // }
        // return moment().startOf('day').toDate(); 
    }

    handleEnd = (date) => {
        this.setState({
            selectedEnd: this.calculateEndMinTime(date),
          endTime: date
        })
      }

    handle = (date) => {
            this.setState({
                selected: this.calculateMinTime(date),
                startTime: date,
                selectedEnd: this.calculateEndMinTime(date),
                endTime: date
            })        
      }

    meetTypeChange = (param, event) => {
        let stateObj = {};
        stateObj[param] = event.target.value;
        if(stateObj.meetType === 'false') {
            let date = new Date();
            let dateformat = moment(date).toDate();
            let endDate = moment(date).toDate();;
            // console.log(date)
            // console.log(dateformat)
            this.setState({startTime: dateformat, endTime: endDate}); 
            this.setState(stateObj);   
        } else {
            var d = new Date();
            var v = new Date();
            v.setMinutes(d.getMinutes()+30);
            this.setState({startTime: new Date(), endTime: v, selected: this.calculateMinTime(new Date()),
                selectedEnd: this.calculateEndMinTime(v),});
            this.setState(stateObj); 
        }
    };
    
    meetSeatChange = (param, event) => {
        let stateObj = {};
        stateObj[param] = event.target.value;
        this.setState(stateObj); 
        if(event.target.value === 'webinar'){
            let date = new Date();
            let dateformat = moment(date).toDate();
            let endDate = moment(date).toDate();
            this.setState({meetType:'false',startTime: dateformat, endTime: endDate})
        }
        // if(stateObj.meetType === 'false') {
        //     let date = new Date();
        //     let dateformat = moment(date).toDate();
        //     let endDate = moment(date).toDate();;
        //     // console.log(date)
        //     // console.log(dateformat)
        //     this.setState({startTime: dateformat, endTime: endDate}); 
        //     this.setState(stateObj);   
        // } else {
            
        //     this.setState({startTime: new Date(), endTime: new Date(), selected: this.calculateMinTime(new Date()),
        //         selectedEnd: this.calculateEndMinTime(new Date()),});
        // }
    };

    onCreateMeet = (data) => {
        if (this.validator.allValid() || this.state.meetSeatType === 'webinar') {
            this.userData = JSON.parse(localStorage.getItem('userData'));
            // const userList = JSON.parse(localStorage.getItem('userList'));
            // let date = new Date();
            // let dateformat = moment(date).format('yyyy-MM-DDTHH:mm');
            // let endDate = moment(date).add(2, 'hours').format('yyyy-MM-DDTHH:mm');
            let endDate;
            let startDate;
            if(this.state.meetType === 'false') {
                // endDate = moment(this.state.endTime).add(2, 'minutes').format('yyyy-MM-DDTHH:mm');
                // endDate = moment(this.state.endTime).add(2, 'hours').format('yyyy-MM-DDTHH:mm');
                // startDate = moment(this.state.startTime).format('yyyy-MM-DDTHH:mm');
                endDate = moment(this.state.endTime).utc().add(2, 'hours').format();
                startDate = moment(this.state.startTime).utc().format();
                if(this.state.meetSeatType === 'webinar'){
                    endDate = moment(this.state.endTime).utc().add(24 * 7, 'hours').format();
                }
            } else {
                endDate = moment(this.state.endTime).utc().format();
                startDate = moment(this.state.startTime).utc().format();
            }
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (this.state.meetSelectedUser.chat_type === 'user') {
                
                let payload = {}
                if (data === 'instant') {               
                    
                    payload = {
                        meeting_number: this.room,
                        session_name: this.state.meetName,
                        meeting_uri: this.state.inviteUrl,
                        is_recurring: this.state.meetType,
                        start_date_time: startDate,
                        end_date_time: endDate,
                        meeting_alias_name: this.state.aliasName,
                        is_group_meeting: false,
                        to_user_id: this.state.meetSelectedUser.user_id,
                        zone: tz,
                        meet_type: this.state.meetSeatType
                    }
                } else {
                    payload = {
                        meeting_number: this.room,
                        session_name: this.state.meetName,
                        meeting_uri: this.state.inviteUrl,
                        is_recurring: this.state.meetType,
                        start_date_time: startDate,
                        end_date_time: endDate,
                        // meeting_alias_name: this.userData.userDetail.first_name,
                        meeting_alias_name: this.state.aliasName,
                        is_group_meeting: false,
                        to_user_id: this.state.meetSelectedUser.user_id,
                        zone: tz,
                        meet_type: this.state.meetSeatType
                    }
                }
                // console.log('user', payload)
                if(this.state.meetSeatType === 'webinar'){
                    this.state.aliasName = this.room;
                    payload.meeting_alias_name = this.room;
                }
                console.log('Input = > '+JSON.stringify(payload))
                RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/meetings', payload, (response) => {
                    if(response.success === true){
                    this.getMeetingList(this.state.meetSelectedUser, defaultConfig.DEFAULT_OFFSET);
                    if (data === 'instant') {
                        window.open(`/host/${this.state.aliasName}/${this.currentUser.userDetail.first_name}`, 'newWin');
                        this.toggleCreateMeeting();
                        this.setState({ viewModalData: false })
                    } else {
                        this.setState({ viewModalData: true })
                    }
                    this.setState({ viewSchduleModal: false, selected:'', selectedEnd:'', pageOffset: defaultConfig.DEFAULT_OFFSET })
                    toast.dark("Meeting Scheduled Successfully", {autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,});
                    
                    // console.log('response', response)
                    } else {                        
                        toast.dark(response.message, {autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,});
                    }
                })
            } else if (this.state.meetSelectedUser.chat_type === 'group') {
                let start = moment(this.state.startTime).format('yyyy-MM-DDTHH:mm');
                let end = moment(this.state.endTime).format('yyyy-MM-DDTHH:mm');
                let payload = {}
                if (data === 'instant') {
                    // let date = new Date();
                    // let dateformat = moment(date).format('yyyy-MM-DDTHH:mm');
                    // let endDate = moment(date).add(2, 'hours').format('yyyy-MM-DDTHH:mm');
                    payload = {
                        meeting_number: this.room,
                        session_name: this.state.meetName,
                        meeting_uri: this.state.inviteUrl,
                        is_recurring: this.state.meetType,
                        start_date_time: startDate,
                        end_date_time: endDate,
                        meeting_alias_name: this.state.aliasName,
                        is_group_meeting: false,
                        group_id: this.state.meetSelectedUser.group_id,
                        zone: tz,
                        meet_type: this.state.meetSeatType
                    }
                } else {
                    payload = {
                        meeting_number: this.room,
                        session_name: this.state.meetName,
                        meeting_uri: this.state.inviteUrl,
                        is_recurring: this.state.meetType,
                        start_date_time: startDate,
                        end_date_time: endDate,
                        meeting_alias_name: this.state.aliasName,
                        is_group_meeting: false,
                        group_id: this.state.meetSelectedUser.group_id,
                        zone: tz,
                        meet_type: this.state.meetSeatType
                    }
                }
                // console.log('group', payload)
                if(this.state.meetSeatType === 'webinar'){
                    this.state.aliasName = this.room;
                    payload.meeting_alias_name = this.room;
                }
                console.log('Input = > '+JSON.stringify(payload))
                RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/meetings', payload, (response) => {
                    if(response.success === true){
                        this.getMeetingList(this.state.meetSelectedUser, defaultConfig.DEFAULT_OFFSET);
                        if (data === 'instant') {
                            window.open(`/host/${this.state.aliasName}/${this.currentUser.userDetail.first_name}`, 'newWin');
                            this.toggleCreateMeeting();
                            this.setState({ viewModalData: false })
                        } else {
                            this.setState({ viewModalData: true })
                        }
                        this.setState({ viewSchduleModal: false , selected:'', selectedEnd:'', pageOffset: defaultConfig.DEFAULT_OFFSET})
                    toast.dark("Meeting Scheduled Successfully", {autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,});
                    // console.log('response', response)
                    } else {                        
                        toast.dark(response.message, {autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,});
                    }
                })
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    meetingEdit = (data, index) => {
        this.setState(prevState => ({
            createMeetingPop: !prevState.createMeetingPop
        }));
        if (!this.state.createMeetingPop) {
            this.validator.hideMessages();
            this.setState({ viewSchduleModal: true })
            this.setState({meetName:data.session_name, meetType:data.is_recurring, startTime: data.start_date_time, endTime: data.end_date_time})
            // this.createRoom();
        }
        // this.toggleCreateMeeting();
        
        console.log(data)
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
    }

    meetingDelete = (id, ind) => {
        RequestHandler.deleteAPI(Config.API_DOMAIN + '/canstring/v1/meeting/' + id, (response) => {
            if (response.success === true) {
                const array = [...this.state.meetList]
                array.splice(ind, 1);
                this.setState({ meetList: array });
                toast.dark("Meeting Deleted Successfully", {autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,});
            } else {
                console.log(response.message);
            }
        })
    }

    shareMeet = () => {
        this.toggleCreateMeeting();
        this.setState({ viewModalData: false })
    }
    getAllUsers = () => {
        // const logged = JSON.parse(localStorage.getItem('userData'));
        // this.setState({curentloggedUser:{...logged}});
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/chats/users?offset=0&limit=25&search=', (response) => {
            if (response.success === true) {
                // this.setState({userList:response.userList})                
                localStorage.setItem('userList', JSON.stringify(response.userList));
                if (this.state.chatOpen === false) {
                    this.getMeetingList(this.state.meetSelectedUser, this.state.pageOffset);
                }
            } else {
                console.log(response.message);
            }
        })
    }

    onUserselect = (user) => {
        this.setState({ meetSelectedUser: { ...user }, meetList: [], groupUserView: false, pageOffset: defaultConfig.DEFAULT_OFFSET });
        this.getMeetingList(user, defaultConfig.DEFAULT_OFFSET);
    }

    getMeetingList = (user, offset) => {
        this.userData = JSON.parse(localStorage.getItem('userData'));
        if (user.chat_type === 'user') {
            RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/meeting/getUserMeeting?user_id=' + user.user_id+'&offset='+ offset +'&limit='+this.state.pageLimit, (response) => {
                if (response.TotalRowCount) {
                    const arr = response.meeting.filter(function (o) {
                        return o.is_active === true;
                    });
                    const uniq = [...new Set(arr)];
                    this.setState({ meetList: uniq, meetingListData: {...response} })
                } else {
                    this.setState({ meetList: [], meetingListData: {}  })
                }
            })
        } else if (user.chat_type === 'group') {
            RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/meeting/getGroupMeeting?group_id=' + user.group_id+'&offset='+ offset +'&limit='+this.state.pageLimit, (response) => {
                if (response.success === true) {
                    const arr = response.record.filter(function (o) {
                        return o.is_active === true;
                    });
                    let uniq = [...new Set(arr)];
                    // uniq.reverse();
                    this.setState({ meetList: uniq, meetingListData: {...response} })
                } else {
                    this.setState({ meetList: [], meetingListData: {} })
                }
            })
        }
    }

    copyClipboard = (url) => {
        const Meet_Url = `https://${Config.SITE_URL}/join/${url}`
        navigator.clipboard.writeText(Meet_Url);
        toast.dark("Meeting URL Copied", {autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,});
    }


    handleCopy = () => {
        let copyText = document.getElementById("url");
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
    }

    handleInfoCopy = () => {
        let copyText = document.getElementById("globalurl");
        var range = document.createRange();
        range.selectNode(copyText); //changed here
        window.getSelection().removeAllRanges(); 
        window.getSelection().addRange(range); 
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        toast.dark("Meeting Info Copied", {autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,});
    }

    componentDidMount = () => {
        // this.getMeetingList();
        this.getAllUsers();
    }

    componentWillMount = () => {
        this.validator = new SimpleReactValidator();
    }

    onGetRecordings = () =>{
        this.refs.recordingReference.toggleCreateMeeting();
    }

    callAWSConnect = (data) => {
        const payload = {
            user_id: this.state.meetSelectedUser.user_id,
            to_user_name: this.state.meetSelectedUser.full_name,
            phone_no: this.state.meetSelectedUser.phone_no
        }
        RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/awsConnect', payload, (response) => {
            window.open('https://canstringmeet.my.connect.aws/ccp-v2/softphone', '_blank');
        });
    }

    createRoom = () => {
        let inviteUrl = '';
        let headerMsg = ''
        let type = ''
        let alert = '';
        let popData = {};

        type = 'createRoom';
        headerMsg = 'Create Instant Meeting';
        console.log('create room')
        if (Config.MEDIA_SERVER) {
            CommonFunctions.generateMediaServerRoom((roomId) => {
                this.room = roomId;
                inviteUrl = Config.DOMAIN == 'localhost' ? 'http://localhost:3000/join/' + this.room : 'https://'+Config.SITE_URL+'/join/' + this.room;
                SocketHandler.createRoom({ room: this.room, userId: UserSession.getSession().userDetail.user_id });
                console.log('inviteUrl = '+inviteUrl)
                this.setState({ type: type, headerMsg: headerMsg, inviteUrl: inviteUrl, alert: alert })
            });
        } else {
            this.room = CommonFunctions.generateUID();
            inviteUrl = Config.DOMAIN == 'localhost' ? 'http://localhost:3000/join/' + this.room : 'https://'+Config.SITE_URL+'/join/' + this.room;
            SocketHandler.createRoom({ room: this.room, userId: UserSession.getSession().userDetail.user_id });
            console.log('inviteUrl = '+inviteUrl)
        }

        this.setState({ type: type, headerMsg: headerMsg, inviteUrl: inviteUrl, alert: alert, popData: popData })
    }

    joinMeeting = (id) => {
        localStorage.setItem('createdRoom', id)
    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }

    handlePageChange(pageNumber) {
        // console.log(`active page is ${pageNumber}`);
        this.getMeetingList(this.state.meetSelectedUser, pageNumber - 1);
        this.setState({pageOffset: pageNumber});
      }

    render() {
        this.currentUser = JSON.parse(localStorage.getItem('currentuserData'));
        return (
            <app-single-chat id="chat-window" class="flex-grid main-screen width100 height100 mob-content">
                <div appdragdrop="" class="col th-p pos-relative">
                    <header class="top-box-shadow">
                        <div class=" chats width100" >
                            <div class="content-wrapper is-clearfix">
                                <div class="chat-name">
                                    <div class="is-pulled-left">
                                        <i id="side-pan-toggle1" onClick={this.toggleViewContent} className='fa is-pulled-left fa-chevron-right'></i>
                                        <span class="chat-title-name is-size-6 mar-5-0">{this.state.meetSelectedUser.full_name}</span>
                                    </div>
                                    <div class="is-marginless is-clearfix is-pulled-right">
                                        <div class="is-pulled-right mar-t-5">
                                            {/* <a class="btn mar-0-5" className={this.state.chatOpen === false ? 'btn mar-0-5' : 'hide'} onClick={this.toggleChat}>Chat</a> */}
                                            <a href='#' data-tip="Phone Call" className={this.state.meetSelectedUser.chat_type !== 'group' && this.state.userData.device !== 'mobile' ? 'button is-small is-link mar-0-10' : 'hide'} onClick={this.callAWSConnect.bind(this)}>
                                                <span className='icon is-small'><i className='fa fa-phone'></i></span>
                                            </a>
                                            <a href='#' data-toggle="modal" data-target="#shedule-modal" className={this.state.curentloggedUser.user_role === 1 ? 'button is-small is-link mar-0-10' : 'hide'} onClick={this.toggleCreateMeeting}>
                                                <span className='icon is-small'><i className='fa fa-plus'></i></span> <span>Create Meeting</span></a>
                                            <a href='#' className={this.state.curentloggedUser.user_role === 1 ? 'button is-small is-link mar-0-10' : 'hide'} onClick={this.onGetRecordings}>
                                                <span className='icon is-small'><i className='fa fa-list-alt'></i></span> <span>Recordings</span></a>
                                            <a href='#' className={this.state.chatOpen === false ? 'button is-small is-link mar-0-10 ':'hide'} onClick={this.toggleChat}>
                                                <span className='icon is-small'><i class="fa fa-comment"></i></span>
                                                <span>Chat</span>
                                            </a>
                                            <a href='#' className={this.state.chatOpen === false ? 'button is-small is-link mar-0-10 ':'hide'} onClick={this.openClassView}>
                                                <span>Class View</span>
                                            </a>
                                            <a className={this.state.meetSelectedUser.chat_type === 'group' ? 'is-small mar-0-5 ':'hide'}>
                                                <i class="fa fa-users is-size-6 pad-5 has-text-link"  title='Users' onClick={this.showUserList}></i>
                                            </a>
                                            {/* <a href="#" class="btn convbtn">New Conversation</a> */}
                                        </div>
                                        {/* <ReactTooltip place="left" type="dark" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    
                    <div class="contents is-marginless height100" className={this.state.chatOpen === false ? 'contents is-marginless height100' : 'hide'}>
                        <div appinfinitescroll="" class="chats scroll-bar chat-bg">
                            <div class="sessions">
                                <table className='table is-fullwidth'>
                                    <thead>
                                        <tr>
                                            {/* <th>#</th> */}
                                            <th>Status</th>
                                            <th>Session Name</th>
                                            <th>Session URL</th>
                                            <th>Meeting Type</th>
                                            <th>Start Date &amp; Time</th>
                                            <th>End Date &amp; Time</th>
                                            <th className='has-text-centered'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.meetList.length !== 0 ?
                                            this.state.meetList.map((file, index) => {
                                                // console.log(moment(this.state.currentDate).format('DD/MM/YYYY HH:mm'),  moment(file.end_date_time).format('DD/MM/YYYY HH:mm'));
                                                // console.log(moment(file.end_date_time).diff(this.state.currentDate));
                                                // console.log(moment(this.state.currentDate).format('DD/MM/YYYY HH:mm').valueOf() >= moment(file.end_date_time).format('DD/MM/YYYY HH:mm').valueOf());
                                                var startDateUTC = momenttz(file.start_date_time);
                                                var endDateUTC = momenttz(file.end_date_time);
                                                const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                                                // console.log('meeting details : '+JSON.stringify(file))
                                                return (
                                                    <tr>
                                                        {/* <td>#</td> */}
                                                        <td><div id={file.meeting_number} className="red-stat onlinestat">OFFLINE</div></td>
                                                        <td>{file.session_name}</td>
                                                        <td className=''>
                                                        <span className={this.state.userData.device === 'mobile' ? 'hide':''}>
                                                        <a href='#' title={`https://${Config.SITE_URL}/join/${file.meeting_alias_name}`} 
                                                        className={moment(file.end_date_time).diff(this.state.currentDate) >= 0 ? 'button is-small is-link' : 'disabled button is-small is-link'} onClick={this.copyClipboard.bind(this, file.meeting_alias_name)} disabled={moment(file.end_date_time).diff(this.state.currentDate) <= 0}>
                                                        <span className='icon is-small'><i className='fa fa-files-o'></i></span> <span>Copy Link</span></a></span>
                                                        </td>
                                                        <td>{file.is_recurring === true ? 'Recurring Meeting' : 'One-Time Meeting'}</td>
                                                        <td>{startDateUTC.tz(localtz).format("MMM DD YYYY, HH:mm")}</td>
                                                        <td>{endDateUTC.tz(localtz).format("MMM DD YYYY, HH:mm")}</td>
                                                        <td className='has-text-centered hide-mob' style={{minWidth:120}}>
                                                        
                                                            {this.userData.userDetail.user_role === 1 ? <a href={'/host/' + file.meeting_alias_name + '/' + this.userData.userDetail.first_name} data-tip="Start Meeting" target='_blank' onClick={this.joinMeeting.bind(this, file.meeting_number)}
                                                             className={moment(file.end_date_time).diff(this.state.currentDate) >= 0 ? 'button is-small is-link' : 'disabled button is-small is-link'}>
                                                            <span className='icon is-small'><i className='fa fa-play ' aria-hidden="true"></i></span>
                                                            </a> : <a href={'/join/' + file.meeting_alias_name + '/' + this.userData.userDetail.first_name} data-tip="Join Meeting" target='_blank' onClick={this.joinMeeting.bind(this, file.meeting_number)} className={moment(file.end_date_time).diff(this.state.currentDate) >= 0 ? 'button is-small is-link' : 'disabled button is-small is-link'}>
                                                            <span className='icon is-small'><i className='fa fa-play ' aria-hidden="true"></i></span>
                                                            </a>}
                                                            <a href='#' className={this.userData.userDetail.user_role === 1 ?  'button is-small is-link mar-0-5': 'hide'} data-tip="Delete">
                                                        <span className='icon is-small'><i onClick={this.meetingDelete.bind(this, file.meeting_id, index)}  className='fa fa-trash video' aria-hidden="true"></i></span>
                                                            </a>
                                                            {/* <i class="fa fa-video-camera video" data-tip="Video" aria-hidden="true"></i>  */}
                                                            {/* {this.userData.userDetail.user_role === 1 ?
                                                                <a class="btn" href={'/host/' + file.meeting_number + '/' + file.meeting_alias_name} target='_blank' onClick={this.joinMeeting.bind(this, file.meeting_number)} className={moment(this.state.currentDate).format('DD/MM/YYYY HH:mm') <= moment(file.start_date_time).format('DD/MM/YYYY HH:mm') ? 'btn' : 'disabled has-text-danger'}>{moment(this.state.currentDate).format('DD/MM/YYYY HH:mm') <= moment(file.start_date_time).format('DD/MM/YYYY HH:mm') ? 'Start' : 'Expired'}</a>
                                                                :
                                                                <a class="btn" href={'/join/' + file.meeting_number + '/' + this.userData.userDetail.first_name} target='_blank' onClick={this.joinMeeting.bind(this, file.meeting_number)} className={moment(this.state.currentDate).format('DD/MM/YYYY HH:mm') <= moment(file.start_date_time).format('DD/MM/YYYY HH:mm') ? 'btn' : 'disabled has-text-danger'}>{moment(this.state.currentDate).format('DD/MM/YYYY HH:mm') <= moment(file.start_date_time).format('DD/MM/YYYY HH:mm') ? 'Join' : 'Expired'}</a>} */}
                                                            <ReactTooltip place="top" type="dark" effect="float" />
                                                        </td>
                                                        <td className='hide-desk' style={{minWidth:120}}>
                                                        
                                                            {this.userData.userDetail.user_role === 1 ? <a href={'/host/' + file.meeting_alias_name + '/' + this.userData.userDetail.first_name} data-tip="Start Meeting" target='_blank' onClick={this.joinMeeting.bind(this, file.meeting_number)}
                                                             className={moment(file.end_date_time).diff(this.state.currentDate) >= 0 ? 'button is-small is-link' : 'disabled button is-small is-link'}>
                                                            <span className='icon is-small'>Start Meet</span>
                                                            </a> : <a href={'/join/' + file.meeting_alias_name + '/' + this.userData.userDetail.first_name} data-tip="Join Meeting" target='_blank' onClick={this.joinMeeting.bind(this, file.meeting_number)} className={moment(file.end_date_time).diff(this.state.currentDate) >= 0 ? 'button is-small is-link' : 'disabled button is-small is-link'}>
                                                            <span className='icon is-small'>Join Meet</span>
                                                            </a>}
                                                            <a href='#' className={this.userData.userDetail.user_role === 1 ?  'button is-small is-link mar-0-5': 'hide'} data-tip="Delete">
                                                        <span className='icon is-small' onClick={this.meetingDelete.bind(this, file.meeting_id, index)}>Delete</span>
                                                            </a>
                                                            {/* <i class="fa fa-video-camera video" data-tip="Video" aria-hidden="true"></i>  */}
                                                            {/* {this.userData.userDetail.user_role === 1 ?
                                                                <a class="btn" href={'/host/' + file.meeting_number + '/' + file.meeting_alias_name} target='_blank' onClick={this.joinMeeting.bind(this, file.meeting_number)} className={moment(this.state.currentDate).format('DD/MM/YYYY HH:mm') <= moment(file.start_date_time).format('DD/MM/YYYY HH:mm') ? 'btn' : 'disabled has-text-danger'}>{moment(this.state.currentDate).format('DD/MM/YYYY HH:mm') <= moment(file.start_date_time).format('DD/MM/YYYY HH:mm') ? 'Start' : 'Expired'}</a>
                                                                :
                                                                <a class="btn" href={'/join/' + file.meeting_number + '/' + this.userData.userDetail.first_name} target='_blank' onClick={this.joinMeeting.bind(this, file.meeting_number)} className={moment(this.state.currentDate).format('DD/MM/YYYY HH:mm') <= moment(file.start_date_time).format('DD/MM/YYYY HH:mm') ? 'btn' : 'disabled has-text-danger'}>{moment(this.state.currentDate).format('DD/MM/YYYY HH:mm') <= moment(file.start_date_time).format('DD/MM/YYYY HH:mm') ? 'Join' : 'Expired'}</a>} */}
                                                            <ReactTooltip place="top" type="dark" effect="float" />
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <tr>
                                                <td colSpan='7' className='has-text-centered'><p>No Sessions Found !</p></td>
                                            </tr>
                                        }
                                    </tbody>

                                </table>
                                
                                {/* <Pagination items={this.state.meetList} onChangePage={this.onChangePage.bind()} /> */}
                            </div>
                            {this.state.meetingListData.TotalRowCount > defaultConfig.DEFAULT_RECORD_LIMIT ? <div className='has-text-right pagination-align'>
                                <Pagination
                                activePage={this.state.pageOffset}
                                itemsCountPerPage={this.state.pageLimit}
                                totalItemsCount={this.state.meetingListData.TotalRowCount}
                                pageRangeDisplayed={defaultConfig.DEFAULT_PAGE_RANGE}
                                onChange={this.handlePageChange.bind(this)}
                                />
                            </div> : null}
                        </div>
                    </div>
                    
                </div>
                {this.state.groupUserView ? <GroupUserListing hideGroupList={this.hideUserView} slectedGroup={this.props.selectedUserData}></GroupUserListing> : null}
                {/* <GroupUserListing></GroupUserListing> */}
                <RecordingPop selectedListDetails={this.state.meetSelectedUser} ref="recordingReference"></RecordingPop>

                <div class={this.state.createMeetingPop ? "modal schedule fade bs-example-modal-lg demo modalshow" : "modal fade bs-example-modal-lg demo"} id="shedule-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div class="modal-dialog modal-md" role="document">
                        {!this.state.viewModalData && this.state.viewSchduleModal ? <div class="modal-content meet-input scroll-bar">
                            <form onSubmit={this.handleSubmit}>
                                <div class="modal-header">
                                    <button onClick={this.toggleCreateMeeting.bind()} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    <h4 class="modal-title">Schedule a Meeting</h4>
                                </div>
                                <div class="modal-body">
                                    <div class="field">
                                        <label className='label' for="fname">Meeting Title <span className='required'>*</span></label>
                                        <input id="fname" className="input" name="first_name" value={this.state.meetName}
                                            onChange={this.handleChange.bind(this, "meetName")} placeholder='Enter Meeting title'/>
                                        <p className="help is-danger height20">
                                            {this.validator.message('Meeting Tile', this.state.meetName, 'required')}
                                        </p>
                                    </div>
                                    <div class={this.state.meetSeatType === 'webinar' ? "hide":"field"}>
                                        <label className='label' for="mname">Meeting ID<span className='required'>*</span></label>
                                        <input id="mname" className="input" name="alias_name" value={this.state.aliasName}
                                            onChange={this.handleChange.bind(this, "aliasName")} placeholder='Enter Meeting ID'/>
                                        <p className="help is-danger height20">
                                            {this.validator.message('Meeting ID', this.state.aliasName, 'required')}
                                        </p>
                                    </div>
                                    {/* <div class="field">
                                        <label className='label'>Meeting Link</label>
                                        <input className='input' id='url' value={this.state.inviteUrl} disabled />
                                        <i class="fa fa-files-o copy" onClick={this.handleCopy.bind()} aria-hidden="true"></i>
                                    </div> */}
                                    <div className="field">
                                        <label className='label' for="">Meeting Seat <span className='required'>*</span></label>
                                        <div className="control">
                                            <label className="radio">
                                                <input name="onetoone" value={'onetoone'} checked={this.state.meetSeatType === 'onetoone'}
                                                    type="radio" onChange={this.meetSeatChange.bind(this, "meetSeatType")} /> One-To-One
                                            </label>
                                            <label className="radio" style={{paddingLeft:20}}>
                                                    <input name="onetoone" value={'conference'} checked={this.state.meetSeatType === 'conference'}
                                                        type="radio" onChange={this.meetSeatChange.bind(this, "meetSeatType")} /> Conference
                                            </label>
                                            <label className="radio hide" style={{paddingLeft:20}}>
                                                    <input name="onetoone" value={'webinar'} checked={this.state.meetSeatType === 'webinar'}
                                                        type="radio" onChange={this.meetSeatChange.bind(this, "meetSeatType")} /> Webinar
                                            </label>
                                        </div>
                                        <p className="help is-danger height20">
                                            {this.validator.message('Meeting Seat', this.state.meetSeatType, 'required')}
                                        </p>
                                    </div>
                                    <div class={this.state.meetSeatType === 'webinar' ? "hide":"field"}>
                                        {/* <div class="control">
                                        <input type="radio" id="onetime" name="radio" class="custom-control-input"/>
                                        <label class="custom-control-label" for="onetime">One-Time Meeting</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="Recurring" name="radio" class="custom-control-input"/>
                                        <label class="custom-control-label" for="Recurring">Recurring Meeting</label>
                                    </div> */}
                                        <label className='label' for="">Meeting Type <span className='required'>*</span></label>
                                        <div className="control">
                                            <label className="radio">
                                                <input name="onetime" value={false} checked={this.state.meetType === 'false'}
                                                    type="radio" onChange={this.meetTypeChange.bind(this, "meetType")} /> One-Time Meeting
                                        </label>
                                            <label className="radio">
                                                <input name="onetime" value={true} checked={this.state.meetType === 'true'}
                                                    type="radio" onChange={this.meetTypeChange.bind(this, "meetType")} /> Recurring Meeting
                                        </label>
                                            {/* <label className="radio">
                                            <input name="onetime" value='instant'
                                                             type="radio" onChange={this.handleChange.bind(this, "meetType")} /> Start Meeting Now 
                                        </label> */}
                                        </div>
                                        <p className="help is-danger height20">
                                            {this.validator.message('Meeting Type', this.state.meetType, 'required')}
                                        </p>
                                    </div>

                                    <div className={this.state.meetType === 'true' ? 'columns  onetime-meeting d-flex justify-content-between' : 'hide'}>
                                        <div class="column is-5 form-group">
                                            <label className='label' for="start">Start Time <span className='required'>*</span></label>
                                            {/* <input id="start" type="datetime-local" className="input" name="start" value={this.state.startTime}
                                                onChange={this.handleChange.bind(this, "startTime")} defaultValue={this.state.startTime}/> */}
                                                <DatePicker
                                                    popperPlacement='top-start'
                                                    // value={this.state.startTime}
                                                    customInput={<Input />}
                                                    selected={this.state.startTime}
                                                    excludeOut
                                                    onChange={this.handle}
                                                    minTime={this.state.selected}
                                                    maxTime={moment().endOf('day').toDate()}
                                                    minDate={new Date()}
                                                    showTimeSelect
                                                    placeholderText="Select Start Time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                />
                                            <p className="help is-danger height20">
                                                {this.validator.message('Start Time', this.state.startTime, 'required')}
                                            </p>
                                        </div>

                                        <div class="column is-5 form-group mar-r-20">
                                            <label className='label' for="edd">End Time <span className='required'>*</span></label>
                                            {/* <input id="edd" type="datetime-local" className="input" name="edd" value={this.state.endTime}
                                                onChange={this.handleChange.bind(this, "endTime")} defaultValue={this.state.endTime} /> */}
                                                <DatePicker
                                                    popperPlacement='top'
                                                    // value={this.state.endTime}
                                                    customInput={<Input />}
                                                    selected={this.state.endTime}
                                                    excludeOut
                                                    onChange={this.handleEnd}
                                                    minTime={this.state.startTime}
                                                    maxTime={moment().endOf('day').toDate()}
                                                    // onChange={date => this.setState({endTime:date})}
                                                    showTimeSelect
                                                    minDate={this.state.startTime}
                                                    placeholderText="Select End Time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                />
                                            <p className="help is-danger height20">
                                                {this.validator.message('End Time', this.state.endTime, 'required')}
                                            </p>
                                        </div> 

                                    </div>


                                </div>
                                <div className={this.state.meetType === 'false' ? 'modal-footer justify-content-between' : 'modal-footer'}>
                                    {/* <a href={'/host/'+this.room+'/'+'admin'} target="_blank" onClick={this.createJoinMeet.bind()} id="start-meet" className={this.state.meetType.value === 'false' ? 'btn btn-primary float-left' : 'hide'}>Start Meeting Now</a> */}
                                    {/* <a href={'/host/' + this.room + '/' + this.currentUser.userDetail.first_name} target="_blank" onClick={this.createJoinMeet.bind()} id="start-meet" className={this.state.meetType === 'false' ? 'btn btn-primary float-left' : 'hide'}>Start Meeting Now</a> */}
                                    <button onClick={this.createJoinMeet.bind()} id="start-meet" className={this.state.meetType === 'false' ? 'btn btn-primary float-left' : 'hide'}>Start Meeting Now</button>
                                    <div>
                                        <button class="btn btn-fade mar-0-10" onClick={this.toggleCreateMeeting.bind()}>Cancel</button>
                                        <button id="start-meet" class="btn btn-primary" onClick={this.onCreateMeet.bind(this)}>Schedule Meeting</button>
                                        
                                    </div>
                                </div>
                            </form>
                        </div> : null}

                        {this.state.viewModalData ? <div class="modal-content meet-info">
                            {/* <form id="" method="post" enctype="text/plain"> */}
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.shareMeet}><span aria-hidden="true">&times;</span></button>
                                <h4 class="modal-title">Schedule a Meeting</h4>
                            </div>
                            <div class="modal-body">

                                <h3>
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                    Meeting Scheduled Successfully
                                </h3>
                                <div id='globalurl'>
                                <p><u>Meeting Details</u></p>
                                <p><span>Meeting Title :</span> {this.state.meetName}</p>
                                <p><span>Organizer :</span> {this.userData.userDetail.first_name}</p>
                                <p><span>Meeting ID :</span> {this.state.aliasName}</p>
                                <p><span>Meeting Link :</span> {`https:/${Config.SITE_URL}/join/${this.state.aliasName}`}</p>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-fade pull-right" onClick={this.handleInfoCopy.bind()}><i class="fa fa-files-o" aria-hidden="true"></i> Copy Meeting Info</button>
                                <button class="btn btn-primary pull-right" onClick={this.shareMeet}>Ok</button>
                            </div>
                            {/* </form> */}
                        </div> : null}


                    </div>
                
                </div>
                
                
            </app-single-chat>
            
        )

    }
}
export default SessionListing;
