var UserSession = (function() {
    var userData = {};
    var getSession = function() {
      try{
        if(localStorage.getItem('userData')){
            userData = JSON.parse(localStorage.getItem('userData'));
        }
      }catch(e){

      }
      return userData;    // Or pull this from cookie/localStorage
    };
    var setSession = function(data) {
        userData = data;   
        try{ 
            localStorage.setItem('userData',JSON.stringify(userData)) 
        }catch(e){

        }
      // Also set this in cookie/localStorage
    };
    var destroySession = function() {
        userData = {};
        try{ 
            localStorage.removeItem('userData'); 
        }catch(e){

        }
    }
    return {
        getSession: getSession,
        setSession: setSession,
        destroySession: destroySession
    }
  })();
 export default UserSession;