import React, { Component } from 'react';
import CommonFunctions from '../helpers/common-functions';
class AvSettingComponent extends Component {
  
  constructor(props) {
    super(props);
    this.state = {videoEnabled:true,audioEnabled:true,volumeLevel:0,selectedMicName:'default',selectedCameraName:'default',microPhones:[],cameras:[],audio:this.props.audio}
    
    }
    componentDidMount = ()=> {
        this.gatherPermission();
    }
    gatherPermission =()=> {
         this.stopCam();
          var constraints = {
              audio: true,
              video: true
          };
          navigator.mediaDevices.getUserMedia(constraints).
          then(this.gotPermission).then(this.gotDevices).catch(this.handleError);
    }
    gotPermission = (stream) =>{
        this.gotStream(stream);
        
        return navigator.mediaDevices.enumerateDevices();
    } 
    gotStream = (stream) => {
        window.streamSettings = stream;
        let videoElements = document.getElementsByClassName('preview');
        
        this.micLevelIndicator(stream)
        try{
            for(let key in videoElements){
                if(videoElements[key]){
                    videoElements[key].srcObject = stream;
                    videoElements[key].play();
                }
            }
        }catch(e){

        }
    }
    handleError = (error) => {
        alert(error)
    }
    
    micLevelIndicator = (stream) => {
        try {
            if(CommonFunctions.getBrowser() === 'Safari' || CommonFunctions.getBrowser() === 'Mobile Safari'){
                this.audioContext = new window.webkitAudioContext;
            }else{
                this.audioContext = new AudioContext; //or webkitAudioContext
                
            }
            
            this.source = this.audioContext.createMediaStreamSource(stream);
            this.analyser = this.audioContext.createAnalyser();
            this.scriptNode = this.audioContext.createScriptProcessor(2048, 1, 1);
    
            var volume = this.audioContext.createGain();
            this.source.connect(volume);
            volume.gain.value = 0; //turn off the speakers
            volume.connect(this.audioContext.destination);
    
    
            this.analyser.smoothingTimeConstant = 0.3;
            this.analyser.fftSize = 1024;
            this.analyser.minDecibels = -90;
            this.analyser.maxDecibels = -10;
    
            this.source.connect(this.analyser);
            this.source.onended = ()=> {
                this.source.disconnect(this.scriptNode);
                this.scriptNode.disconnect(this.audioCtx.destination);
              }

              this.analyser.connect(this.scriptNode);
            this.scriptNode.connect(this.audioContext.destination);
            this.scriptNode.onaudioprocess = () => {
                    var array = new Uint8Array(this.analyser.frequencyBinCount);
                    this.analyser.getByteFrequencyData(array);
                    var values = 0;
    
                    var length = array.length;
                    for (var i = 0; i < length; i++) {
                        values += array[i];
                    }
                    var average = (values / length);
                    average = average * 4;
                    if (average > 100) {
                        average = 100;
                    }
                    this.setState({volumeLevel:average});
                
            }
    
        } catch (e) {
             
        }
    
    }
  
    handleError =()=> {

    }
    gotDevices =(deviceInfos)=> {
        let microPhones   = [];
        let cameras       = [];
        for (let i = 0; i !== deviceInfos.length; ++i) {
            var deviceInfo   = deviceInfos[i];
            let deviceObj      = {};
            deviceObj.deviceId = deviceInfo.deviceId;
            if (deviceInfo.kind === 'audioinput') {
                window.micAvailable = true;
                deviceObj.name = deviceInfo.label || `microphone ${this.state.microPhones.length + 1}`;
                if(deviceObj.name.length > 40){
                    deviceObj.name = deviceObj.name.substr(0,40)+'...'
                }
                if(!this.state.selectedMicName){
                    this.setState({selectedMicName:deviceObj.name})
                }
                microPhones.push(deviceObj);
                
                if(!window.selectedMic){
                    window.selectedMic = deviceInfo.deviceId;
                }
            }else if (deviceInfo.kind === 'videoinput') {
                deviceObj.name = deviceInfo.label || `camera ${this.state.cameras.length + 1}`;
                if(deviceObj.name.length > 40){
                    deviceObj.name = deviceObj.name.substr(0,40)+'...'
                }
                if(!this.state.selectedCameraName){
                    this.setState({selectedCameraName:deviceObj.name})
                }
                cameras.push(deviceObj);
                window.camAvailable = true;
                if(!window.selectedCam){
                    window.selectedCam = deviceInfo.deviceId;
                }
            }
        }
        this.setState({microPhones:microPhones,cameras:cameras});
    }
    stopCam = () =>{
        if(window.streamSettings){
            window.streamSettings.getTracks().forEach((track) =>{
                track.stop();
            });
        }
    }
    onChangeMicList = (event) =>{
        window.selectedMic = event.target.value;
        this.accessStreamChangeDevice();
    }
    onChangeCamList = (event) =>{
        window.selectedCam = event.target.value;
        this.accessStreamChangeDevice();
    }
    accessStreamChangeDevice = () =>{
        var constraints = {
            audio: {
                deviceId: window.selectedMic ? {
                    exact: window.selectedMic
                } : undefined
            },
            video: {
                deviceId: window.selectedCam ? {
                    exact: window.selectedCam
                } : undefined
            }
        };
        if(!this.state.videoEnabled){
            constraints.video = false;
        }
        if(!this.state.audioEnabled){
            constraints.audio = false;
        }
        navigator.mediaDevices.getUserMedia(constraints).
        then(this.gotStream).catch(this.handleError);
    }
    continue = () =>{
        // alert(window.selectedCam+'//'+window.selectedMic)
        this.stopCam();
        this.props.onContine(this.state.videoEnabled,this.state.audioEnabled);
    }
    onEnableOrDisableVideo = ()=>{
        let videoEnabled = this.state.videoEnabled
        if(videoEnabled){
            videoEnabled = false;
            this.setState({videoEnabled:videoEnabled})
        }else{
            videoEnabled = true;
            this.setState({videoEnabled:videoEnabled})
        } 
        this.stopCam();
        setTimeout(this.accessStreamChangeDevice,500);
    }
    onEnableOrDisableMic = ()=>{
        let audioEnabled = this.state.audioEnabled
        if(audioEnabled){
            audioEnabled = false;
            this.setState({audioEnabled:audioEnabled})
        }else{
            audioEnabled = true;
            this.setState({audioEnabled:audioEnabled})
        } 
        this.stopCam();
        setTimeout(this.accessStreamChangeDevice,500);
    }
  render() {
    return (
        <div class="content settings-content">
                <img className="log-settings" src="/files/pure-logo.png"/>
                <div className="set-cam-par">
                    <video className="preview" id="preview" autoPlay muted playsInline poster="/files/placeholder.png"></video>
                    <div className="setting-cam-mic-btn-wrap">
                        <span><a data-tip={this.state.videoEnabled ? "Disable":"Enable Video"} className={this.state.videoEnabled ? "option_btn on":"option_btn off"} onClick={this.onEnableOrDisableVideo}><img src={this.state.videoEnabled ?"/files/video.svg":"/files/video-cross.svg"}/></a> </span>
                        <span><a data-tip={this.state.audioEnabled ? "Mute":"Unmute audio"} onClick={this.onEnableOrDisableMic} className={this.state.audioEnabled ? "option_btn on":"option_btn off"}><img src={this.state.audioEnabled ?"/files/mic.svg":"/files/mic-cross.svg"}/></a></span>
                    </div>
                
                </div>
                <div className="sett-selection">
                <p class={this.state.audio ? "d-flex justify-content-between audividlab":"hide"}>Audio <i class="fa fa-microphone" aria-hidden="true"></i></p>
                <select className={this.state.audio ? "":"hide"} onChange={this.onChangeMicList.bind(this)}>
                    {
                        this.state.microPhones.map(function(mic, index){
                        return( 
                                <option key={index} value={mic.deviceId}>{mic.name}</option>
                            )
                        },this)
                    } 
                </select>
                <div className={this.state.audio ? '':'hide'} id="waveform">
                    <div className="audio-meter" style={{width:this.state.volumeLevel+'%',background:this.state.volumeLevel > 80 ? '#FF0000':(this.state.volumeLevel > 60 ?'#FF7F00': (this.state.volumeLevel > 40 ? '#FFFF00':'#00FF00'))}}></div>
                </div>
                <p class="d-flex justify-content-between audividlab">Camera <i class="fa fa-video-camera" aria-hidden="true"></i></p>
                
                <select onChange={this.onChangeCamList.bind(this)}>
                    {
                        this.state.cameras.map(function(cam, index){
                        return( 
                                <option key={index} value={cam.deviceId}>{cam.name}</option>
                            )
                        },this)
                    } 
                </select>
                <button className="cont-btn" onClick={this.continue}>Continue</button>
                </div>
                <div className={this.state.videoEnabled ? "camwarn":"hide"}>NOTE: Due to security reason, you should enable camera again to publish it inside the room. Make sure Mic activity present just below the Mic listing.</div>
                
        </div>
      )
    
  }
}
export default AvSettingComponent;
