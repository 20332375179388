var Config = (() =>{
  //alert(window.location.hostname)
  let domain = 'signal.canstringai.com'
  let siteurl = 'meet.canstringai.com';
  let apiDomain = 'https://api.meet.canstringai.com';
  let mediaServerDomain = 'https://mediaserver.canstringai.com';
  switch (window.location.hostname) {
    case 'localhost':
      domain = 'signal.dev.canstringai.com'
      siteurl = 'localhost:3000';
      mediaServerDomain = 'https://mediaserver.canstringai.com';
      //domain = 'signal.dev.canstringai.com'
      // siteurl = 'meet.dev.canstringai.com';
      apiDomain = 'https://api.dev.canstringai.com';
      //mediaServerDomain = 'https://mediaserver.poc.canstringai.com';
    break;
    case 'meet.canstringai.com':
      domain = 'signal.canstringai.com'
      siteurl = 'meet.canstringai.com';
      apiDomain = 'https://api.meet.canstringai.com';
      mediaServerDomain = 'https://mediaserver.canstringai.com';
    break;
    case 'meet.dev.canstringai.com':
      domain = 'signal.dev.canstringai.com'
      siteurl = 'meet.dev.canstringai.com';
      apiDomain = 'https://api.dev.canstringai.com';
      //mediaServerDomain = 'https://mediaserver.poc.canstringai.com';
      mediaServerDomain = 'https://mediaserver.canstringai.com';
    break;
    default:
      break;
  }
    return {
       //DOMAIN    :    'localhost:8080',
      SITE_URL : siteurl,
      DOMAIN    :    domain,
      API_DOMAIN : apiDomain,
      MEDIA_SERVER_DOMAIN:mediaServerDomain,
      // API_DOMAIN : 'http://localhost:8080',
      MEDIA_SERVER : true,
      PEER_TO_PEER: false,
      MCU : false,
      WEBINAR: false,
    }
  })();
export default Config;
