import React, { Component } from 'react';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
import SocketHandler from '../helpers/socket-service';
import UserSession from '../helpers/user-session';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequestHandler from '../helpers/request-handler';
import Form from './Form';
import Pagination from "react-js-pagination";
import { defaultConfig } from '../helpers/common-config';
import PhoneInput from 'react-phone-number-input'
class Popup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type: '', phone:'',headerMsg: '', inviteUrl: '', userName: '',userId:'', alert: '', room: '', nameUrl: '', popData: {},
            videoprogress: 0, files: [], viewInput: false, listInd: '', editData: {}, newFilename: '', attachRecordList:{},
            currentMeetUser: JSON.parse(localStorage.getItem('meetData')),
            pageOffset: defaultConfig.DEFAULT_OFFSET,
            pageLimit: defaultConfig.DEFAULT_RECORD_LIMIT,
            youtubeUrl:{value:'',error:false}
        }
        this.room = '';
        this.doc_extensions = ['pdf', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'PNG', 'JPG']
        this.video_extensions = ['mp4','webm'];
        this.userName = '';
        if (UserSession.getSession()) {
            if (UserSession.getSession().userDetail) {
                this.userName = UserSession.getSession().userDetail.first_name;
            }
        }

    }


    componentDidMount = () => {
        this.updateState();
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.updateState();
        }
    }

    getfileList = (offset) => {
        const meetData = JSON.parse(localStorage.getItem('meetData'))
        if(meetData.user_id === null) {
            RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/meets/attachments?groupId='+meetData.class_id+'&offset='+ offset +'&limit='+this.state.pageLimit, (response) => {
                // if(response.success === true){
                console.log('Get file response 1 = '+JSON.stringify(response))
                let dataRecord = [...this.state.files];
                dataRecord = [...response.attachments];
                // dataRecord.reverse();
                this.setState({ files: dataRecord, attachRecordList: response });
                // }else{
                //     console.log(response.message);
                // } 
            });
        } else {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/meets/attachments?userId='+meetData.user_id+'&offset='+ offset +'&limit='+this.state.pageLimit, (response) => {
            // if(response.success === true){
            console.log('Get file response 2 = '+JSON.stringify(response))
            let dataRecord = [...this.state.files];
            dataRecord = [...response.attachments];
            // dataRecord.reverse();
            this.setState({ files: dataRecord, attachRecordList: response });
            // }else{
            //     console.log(response.message);
            // } 
        })
    }
    }

    getRecordList = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/meets/records', (response) => {
            // if(response.success === true){
            let dataRecord = [...this.state.files];
            dataRecord = [...response.record];
            dataRecord.reverse();
            this.setState({ files: dataRecord })
            // }else{
            //     alert(response.message);
            // } 
        })
    }

    editRecord = (data, ind) => {
        let dataRecordintex = this.state.files.findIndex(rec => {
            return rec.recording_id === data.recording_id
        });
        let dataRecord = { ...this.state.files[dataRecordintex] };
        this.setState({newFilename: data.file_name})
        this.setState({ viewInput: true, editData: dataRecord, listInd: data.recording_id})
    }

    handlePageChange(pageNumber) {
        this.getfileList(pageNumber - 1);
        this.setState({pageOffset: pageNumber});
      }

    handleInputChange = e => {
        this.setState({ newFilename: e.target.value });
    };

    handleFormSubmit = e => {
        e.preventDefault();
        let record = [...this.state.files]
        let dataRecordintex = this.state.files.findIndex(rec => {
            return rec.recording_id === this.state.editData.recording_id
        });
        let dataRecord = { ...this.state.files[dataRecordintex] };
        dataRecord.file_name = this.state.newFilename;
        const payload = {
            recording_id: dataRecord.recording_id,
            unique_name: dataRecord.unique_name,
            room_id: dataRecord.room_id,
            file_name: dataRecord.file_name,
            record_desc: dataRecord.record_desc,
            record_file_path: dataRecord.record_file_path,
            title: dataRecord.title,
        }
        RequestHandler.put(Config.API_DOMAIN + '/canstring/v1/meets/records', payload, (response) => {
            if (response.success === true) {
                // this.getfileList();
                record[dataRecordintex] = dataRecord;
                toast.dark("Record Updated Successfully", {
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ viewInput: false, files: record });
            } else {
                console.log(response.message);
            }
        })
        //   console.log(dataRecord)
        // this.props.onUpdate(this.state);
    };

    updateName = (data) => {
        this.setState({ newFilename: data });
    }

    onCancel = () => {
        this.setState({ viewInput: false });
    };

    createDocFile = (parem) => {
        if(this.state.currentMeetUser.user_id === null) {
            parem.group_id = this.state.currentMeetUser.class_id;
            console.log('save file param 1 = '+JSON.stringify(parem))
            RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/meets/attachments', parem, (response) => {
                console.log('save file response 1 = '+JSON.stringify(response))
                if (response.success === true) {
                    this.getfileList();
                } else {
                    console.log(response.message);
                }
            })
        } else {
            parem.user_id = this.state.currentMeetUser.user_id;
            console.log('save file param 2 = '+JSON.stringify(parem))
            RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/meets/attachments', parem, (response) => {
                console.log('save file response 2 = '+JSON.stringify(response))
                if (response.success === true) {
                    this.getfileList();
                } else {
                    console.log(response.message);
                }
            })
        }
    }
    updateState = () => {
        let inviteUrl = '';
        let headerMsg = ''
        let type = ''
        let alert = '';
        let popData = {};
        if (this.props.popup) {
            switch (this.props.popup) {
                case 'docshare':
                    type = 'docshare';
                    headerMsg = 'Share Content';
                    if (this.props.popData) {
                        if (this.props.popData.room) {
                            this.room = this.props.popData.room
                        }
                    }
                    this.getfileList(defaultConfig.DEFAULT_OFFSET);
                    break;
                case 'invite':
                    type = 'invite';
                    headerMsg = 'Invite User';
                    if (this.props.popData) {
                        if (this.props.popData.room) {
                            inviteUrl = Config.DOMAIN == 'localhost' ? 'http://localhost:3000/join/' + this.props.popData.room : 'https://'+Config.SITE_URL+'/join/' + this.props.popData.room;
                        }
                    }
                    break;
                    case 'editName':
                        type = 'editName';
                        headerMsg = 'Edit Name';
                        this.setState({userName:this.props.popData.name,userId:this.props.popData.userId,room:this.props.popData.room})
                        break;
                    
                case 'alert':
                    type = 'alert';
                    headerMsg = 'Message';
                    alert = this.props.popData.alert;
                    break;
                case 'confirmLeave':
                    type = 'confirmLeave';
                    headerMsg = 'Message';
                    alert = this.props.popData.alert;
                    popData = this.props.popData;
                    break;

                case 'recordings':
                    type = 'recordings';
                    headerMsg = 'Recordings';
                    if (this.props.popData) {
                        popData = this.props.popData;
                    }
                    this.getRecordList()
                    break;
                case 'noName':
                    type = 'noName';
                    headerMsg = 'Enter Name';
                    if (window.location.href.indexOf('/host/') > -1) {
                        this.setState({ nameUrl: '/host/' + this.props.popData.room })
                    } else {
                        this.setState({ nameUrl: '/join/' + this.props.popData.room })
                    }

                    break;
                case 'createRoom':
                    type = 'createRoom';
                    headerMsg = 'Start Meeting';
                    if (Config.MEDIA_SERVER) {
                        CommonFunctions.generateMediaServerRoom((roomId) => {
                            this.room = roomId;
                            inviteUrl = Config.DOMAIN == 'localhost' ? 'http://localhost:3000/join/' + this.room : 'https://'+Config.SITE_URL+'/join/' + this.room;
                            SocketHandler.createRoom({ room: this.room, userId: UserSession.getSession().userDetail.user_id });
                            this.setState({ type: type, headerMsg: headerMsg, inviteUrl: inviteUrl, alert: alert })
                        });
                    } else {
                        this.room = CommonFunctions.generateUID();
                        inviteUrl = Config.DOMAIN == 'localhost' ? 'http://localhost:3000/join/' + this.room : 'https://'+Config.SITE_URL+'/join/' + this.room;
                        SocketHandler.createRoom({ room: this.room, userId: UserSession.getSession().userDetail.user_id });
                    }

                    //window.alert(UserSession.getSession().userDetail.user_id)

                    break;
                default:
                    break;
            }
        }

        this.setState({ type: type, headerMsg: headerMsg, inviteUrl: inviteUrl, alert: alert, popData: popData })
    }
    componentWillUnmount = () => {

    }
    onClosePopup = () => {
        this.props.onClosePopup();
        this.setState({viewInput: false})
        if (this.state.type == 'createRoom') {
            SocketHandler.desposeRoom({ room: this.room, userId: UserSession.getSession().userId });
        }
    }
    onCreateAndJoinRoom = () => {
        localStorage.setItem('createdRoom', this.room)
        this.props.joinInstantRoom(this.room);
    }
    onJoinRoom = () =>{
        localStorage.setItem('phone'+this.state.userName, this.state.phone)
    }
    onChangeInput = (event) => {
        this.setState({ userName: event.target.value })
    }
    onChangePhoneInput = (value) => {
        this.setState({ phone: value })
    }
    shareYoutube = () =>{
        let url = this.state.youtubeUrl.value;
        if(url){
            let yId = this.getYoutubeId(url);
            if(yId){
                    
                this.setState({youtubeUrl:{value:url,error:false}})
                let payload =
                {
                    original_name: url,
                    unique_name: yId,
                    total_pages: 'youtube',
                    room_id: this.room,
                    file_name: yId,
                    file_desc: "some value user types",
                    fs_file_path: "pathfile",
                    s3_file_path: "/test-s3-pathfile"

                }
                if (!payload.total_pages) {
                    payload.total_pages = 100;
                }
                this.createDocFile(payload);
                this.setState({youtubeUrl:{value:'',error:false}})

            }else{
                this.setState({youtubeUrl:{value:url,error:'Enter valid youtube url !'}})
            }
            //this.props.shareVideo(url,'youtube');
        }else{
            this.setState({youtubeUrl:{value:'',error:'Enter valid youtube url !'}})
        }
        
    }
    getYoutubeId = (url) => {
        var videoid = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        if (videoid != null) {
            return videoid[1];
        } else {
            return false;
        }
    }
    youtubeinpchange = (event) =>{
        if(event.target.value){
            this.setState({youtubeUrl:{value:event.target.value,error:false}})
        }else{
            this.setState({youtubeUrl:{value:'',error:'Enter valid youtube url !'}})
        }
    }
    handleUploadFile = (event) => {
        this.setState({ videoprogress: 0 });
        var ext = event.target.files[0].name.substr(event.target.files[0].name.lastIndexOf('.') + 1);
        if (this.doc_extensions.indexOf(ext) == -1 && this.video_extensions.indexOf(ext) == -1) {
            document.getElementById("file-upload2").value = "";
            let type = 'alert';
            let headerMsg = 'Error Message';
            let alert = 'Please choose a valid file. The extension ' + ext + ' is not supported';
            this.setState({ type: type, headerMsg: headerMsg, alert: alert })
            return;
        }
        var filesizeMb = (event.target.files[0].size) / (1024 * 1024);
        console.log('File size = '+filesizeMb)
        if(filesizeMb  > 50){
            document.getElementById("file-upload2").value = "";
            let type = 'alert';
            let headerMsg = 'Error Message';
            let alert = 'File size should be less than 50 Mb';
            this.setState({ type: type, headerMsg: headerMsg, alert: alert })
            return;
        }

        const data = new FormData();
        var fileob = event.target.files[0];
        fileob.userId = UserSession.getSession().userId;
        fileob.room = this.room;
        data.append('sampleFile', fileob);
        data.append('userId', fileob.userId);
        data.append('userId', fileob.room);
        data.append('name', 'sampleFile');
        data.append('description', 'some value user types');
        axios.post('https://' + Config.DOMAIN + '/upload', data, {
            onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                if (totalLength !== null) {
                    let videoprogress = (Math.round((progressEvent.loaded * 100) / totalLength));
                    this.setState({ videoprogress: videoprogress });
                    if (videoprogress > 98) {
                        let type = 'alert';
                        let headerMsg = 'Message';
                        let alert = '';
                        if(this.video_extensions.indexOf(ext) > -1){
                            alert = 'File uploaded successfully and intiated conversion. Please check after some time.Time for conversion depends on the number of pages and size of the document.';
                        }else{
                            alert = 'File uploaded successfully, please share it from the list';
                        }
                        
                        this.setState({ type: type, headerMsg: headerMsg, alert: alert, videoprogress: 0 })
                    }
                }
            }
        }).then((response) => {
            document.getElementById("file-upload2").value = "";
            console.log(response)
            let payload =
            {
                original_name: response.data.org_name,
                unique_name: response.data.name,
                total_pages: response.data.pages,
                room_id: fileob.room,
                file_name: response.data.org_name,
                file_desc: "some value user types",
                fs_file_path: "pathfile",
                s3_file_path: "/test-s3-pathfile"

            }
            if (!payload.total_pages) {
                payload.total_pages = 100;
            }
            this.createDocFile(payload);

            //console.log('File uploaded successfully')

        });
    }
    shareDocument = (fileName, totalPages) => {
        let extension = this.extention(fileName)
        if(this.video_extensions.indexOf(extension) > -1){
            this.props.shareVideo(fileName,'video');
        }else if(totalPages === 'youtube'){
            this.props.shareVideo(fileName,'youtube');
        }else{
            this.props.shareDocument(fileName.replace('.' + extension, ''), totalPages);
        }
        
        this.onClosePopup();
    }
    shareDelete = (id, ind) => {
        RequestHandler.deleteAPI(Config.API_DOMAIN + '/canstring/v1/meets/attachments/' + id, (response) => {
            if (response.success === true) {
                const array = [...this.state.files]
                array.splice(ind, 1);
                toast.dark("Record Deleted Successfully", {
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ files: array })
            } else {
                console.log(response.message);
            }
        })
    }
    recordingDelete = (id, ind) => {
        RequestHandler.deleteAPI(Config.API_DOMAIN + '/canstring/v1/meets/records/' + id, (response) => {
            if (response.success === true) {
                const array = [...this.state.files]
                array.splice(ind, 1);
                toast.dark("Record Deleted Successfully", {
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ files: array })
            } else {
                console.log(response.message);
            }
        })
    }

    handleCopy = () => {
        let copyText = document.getElementById("globalurl");
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
    }
    
    onChangeName = (event) => {
        this.setState({userName:event.target.value})
    }
    saveName = () => {
        let copyText = document.getElementById("usernameEdit").value;
        copyText = copyText.trim()
        if(copyText.trim() == ""){
            alert('Name required')
        }else{
            let dt = {userName:copyText,userId:this.state.userId,room:this.state.room};
            SocketHandler.editName(dt)
            this.onClosePopup();

            if(document.getElementsByClassName('remote-name-0')[0]){
                document.getElementsByClassName('remote-name-0')[0].innerHTML = 'ME('+copyText+')';
                document.getElementsByClassName('0-audio')[0].innerHTML = copyText.charAt(0).toUpperCase()+''+copyText.charAt(1).toUpperCase()
            }
        }
        
    }
    

    extention = (filename) => {
        return filename.substr(filename.lastIndexOf('.') + 1)
    }
    leave = () => {
        localStorage.removeItem('temphost'+this.state.popData.room+this.state.popData.userName);
    }
    render() {
        return (
            <div className="flex-grid pop">
                <div className="col th-p">
                    <div className="contents is-marginless">
                        <div className="scroll-bar">
                            <div className="column">
                                <div className="columns">
                                    <div className="column is-half is-offset-one-quarter halffull">
                                        <div className="columns new-chat mar-top15">
                                            <div className="column">
                                                <div className="card">
                                                    <header class="card-header">
                                                        <h3 class="card-header-title">
                                                            {this.state.headerMsg}
                                                        </h3>
                                                        <span className={this.state.type !== 'noName' ? 'card-header-icon': 'hide'} onClick={this.onClosePopup}>
                                                            <span class="icon">
                                                                <i class="fa fa-close" aria-hidden="true" ></i>
                                                            </span>
                                                        </span>
                                                        <a href="/thankyou" className={this.state.type === 'noName' ? 'card-header-icon': 'hide'}>
                                                            <span class="icon">
                                                                <i class="fa fa-close" aria-hidden="true" ></i>
                                                            </span>
                                                        </a>
                                                    </header>
                                                    <div className="card-content">
                                                        <div className="content form-style">
                                                            {/* <h3>{this.state.headerMsg}</h3> */}
                                                            <div className="columns">

                                                                <div className={this.state.type == 'invite' ? "column is-12" : "hide"}>
                                                                    Copy and share the following url to invite other users to this meeting <br /> <br />
                                                                    <div className="field has-addons">
                                                                        <div className="control has-icons-left has-icons-right is-expanded">
                                                                            <input value={this.state.inviteUrl} readOnly className="input" type="text" id='globalurl' />
                                                                            <span className="icon is-small is-left"><i className="fa fa-copy"></i></span>
                                                                        </div>
                                                                        <p class="control">
                                                                            <a class="button is-link" onClick={this.handleCopy.bind()}>Copy</a>
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className={this.state.type == 'editName' ? "column is-12" : "hide"}>
                                                                    Please edit your name <br /> <br />
                                                                    <div className="field has-addons">
                                                                        <div className="control has-icons-left has-icons-right is-expanded">
                                                                            <input value={this.state.userName} onChange={this.onChangeName} className="input inpeditname" type="text" id='usernameEdit' />
                                                                        </div>
                                                                        <p class="control">
                                                                            <a class="button is-link" onClick={this.saveName}><i className="fa fa-save"></i> &nbsp; &nbsp;Save</a>
                                                                        </p>
                                                                    </div>
                                                                </div>


                                                                <div className={this.state.type == 'docshare' ? "column is-12" : "hide"}>
                                                                    Upload Files (ppt,pptx,pdf,docx,png,jpeg,mp4,webm)<br /> <br />
                                                                    <div className="upload-wraper">

                                                                        <button><input onChange={this.handleUploadFile} ref="file" type="file" id="file-upload2" class="file-upload-input"></input><i className="fa fa-upload" /></button>
                                                                    </div>
                                                                    <div className={this.state.videoprogress == 0 ? "hide" : "progress-bar"} style={{ width: this.state.videoprogress + '%' }}>{this.state.videoprogress + '% uploaded'}</div>
                                                                    <hr />
                                                                    <div className='urlshareparent'>
                                                                        <input value={this.state.youtubeUrl.value} onChange={this.youtubeinpchange} id='youubeurlinp' className={this.state.youtubeUrl.error ? 'youtubeshare errorinp':'youtubeshare'} placeholder='Enter youtube url'/>
                                                                        <button className='youtubesharebtn' onClick={this.shareYoutube}>Share</button>
                                                                    </div>
                                                                    {/* <h4></h4> */}
                                                                    <div className='card pop-scroll scroll-bar'>
                                                                        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th colSpan='3'>
                                                                                        Saved Contents
                                                                </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>File Name</th>
                                                                                    <th>Created Date</th>
                                                                                    <th className='has-text-centered'>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    this.state.files.length !== 0 ?
                                                                                        this.state.files.map((file, index) => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>{file.original_name}</td>
                                                                                                    <td>{moment(file.createdAt).format('MMM DD YYYY')}</td>
                                                                                                    <td className='has-text-centered'>
                                                                                                        <i data-tip="Share Content" onClick={this.shareDocument.bind(this, file.unique_name, file.total_pages)} className="fa fa-share" />
                                                                                                        <i data-tip="Delete" onClick={this.shareDelete.bind(this, file.attachment_id, index)} className="fa fa-trash" />
                                                                                                    </td>
                                                                                                    <ReactTooltip place="top" type="dark" effect="float" />
                                                                                                </tr>
                                                                                            )
                                                                                        }) : <tr>
                                                                                            <td colSpan='3' className='has-text-centered'><p>No Records Here</p></td>
                                                                                        </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        {this.state.attachRecordList.TotalRowCount > defaultConfig.DEFAULT_RECORD_LIMIT ? <div className='has-text-right pagination-align'>
                                                                            <Pagination
                                                                            activePage={this.state.pageOffset}
                                                                            itemsCountPerPage={this.state.pageLimit}
                                                                            totalItemsCount={this.state.attachRecordList.TotalRowCount}
                                                                            pageRangeDisplayed={defaultConfig.DEFAULT_PAGE_RANGE}
                                                                            onChange={this.handlePageChange.bind(this)}
                                                                            />
                                                                        </div> : null}
                                                                    </div>
                                                                    {/* <ul className="doc-list">
                                                        {
                                                            this.state.files.map((file) =>{
                                                                return(
                                                                <li><div onClick={this.shareDocument.bind(this,file.fileName,file.pages)} className="doc-name">{file.original_name}</div> <div className="doc-tools"><i onClick={this.shareDocument.bind(this,file.fileName,file.pages)} className="fa fa-share"/> <i  className="fa fa-trash hide"/></div></li>
                                                                )
                                                            })
                                                        }
                                                   </ul> */}

                                                                </div>

                                                                <div className={this.state.type == 'createRoom' ? "column is-12" : "hide"}>
                                                                    Copy and share the following url to invite other participants to this meeting. Please make sure you have copied the meeting url before joining the meeting. You can invite other participants from inside the meeting room as well.<br /><br />
                                                Supported platforms : Windows Computer (chrome,firefox) - Mac Computer (chrome,firefox,safari) - Android Mobile ( Only Chrome ) - IOS Mobile (Only Safari)<br /><br />
                                                                    <div className="field has-addons">
                                                                        <div className="control has-icons-left has-icons-right is-expanded">
                                                                            <input value={this.state.inviteUrl} className="input" type="text" id='globalurl' />
                                                                            <span class="icon is-medium is-left">
                                                                                <i className="fa fa-copy"></i>
                                                                            </span>
                                                                        </div>
                                                                        <p class="control">
                                                                            <a class="button is-link" onClick={this.handleCopy.bind()}>Copy</a>
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className={this.state.type == 'noName' ? "column is-12" : "hide"}>
                                                                Please enter your name to join the meeting<br /><br />
                                                                    <div className="field">
                                                                        <div className="control has-icons-left has-icons-right"><input placeholder="Enter your name" value={this.state.userName} onChange={this.onChangeInput.bind(this)} className="input" type="text" /><span className="icon is-small is-left"><i className="fa fa-user"></i></span></div>
                                                                    </div>
                                                                    <div className="field">
                                                                        <div className="control has-icons-left has-icons-right">
                                                                        <PhoneInput
                                                                            placeholder="Enter phone number"
                                                                            value={this.state.phone}
                                                                            onChange={this.onChangePhoneInput.bind(this)}
                                                                            defaultCountry="CA"
                                                                            />
                                                                        <span className="icon is-small is-left"><i className="fa fa-phone"></i></span></div>
                                                                    </div>
                                                                </div>

                                                                <div className={this.state.type == 'alert' || this.state.type == 'confirmLeave' ? "column is-12" : "hide"}>
                                                                    {this.state.alert}
                                                                </div>

                                                                <div className={this.state.type == 'recordings' ? "column is-12" : "hide"}>
                                                                    {/* Click on file name to download and playback recordings. first one will be the latest recording<br/> */}
                                                                    {/* <ul className="ulrec">
                                                    {
                                                        
                                                        Object.keys(this.state.popData).slice(0).reverse().map((key,index)=>{
                                                            return(
                                                                
                                                                this.state.popData[key].slice(0).reverse().map((value,i)=>{
                                                                    return(
                                                                        <span> <br/>
                                                                    <h6 className={i==0 ? 'show':'hide'}>Room : {key}</h6>
                                                                            <li><a href={'https://mediaserver.canstringai.com/recordings/'+value+'.mkv'} target="_blank">{value}.mkv</a></li>
                                                                            </span>
                                                                    )
                                                                },this)
                                                            )
                                                        },this)
                                                    } 
                                                    </ul> */}
                                                                    <div className='card pop-scroll scroll-bar'>
                                                                        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                                                            <thead>
                                                                                {/* <tr>
                                                                <th colSpan='3'>
                                                                    Record Documents
                                                                </th>
                                                            </tr> */}
                                                                                <tr>
                                                                                    <th>File Name</th>
                                                                                    <th>Created Date</th>
                                                                                    <th className='has-text-centered'>Action</th>
                                                                                </tr>
                                                                                {this.state.viewInput ? <tr>
                                                                                    <th colSpan='3'>
                                                                                        <Form
                                                                                            newFilename = {this.state.newFilename}
                                                                                            handleFormSubmit={this.handleFormSubmit}
                                                                                            handleInputChange={this.handleInputChange}
                                                                                            onCancel={this.onCancel}
                                                                                            updateforminput={this.updateName}
                                                                                        />
                                                                                    </th>
                                                                                </tr> : null}
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.files.length !== 0 ?
                                                                                    this.state.files.map((file, index) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{file.file_name}</td>
                                                                                                <td>{moment(file.createdAt).format('MMM DD YYYY')}</td>
                                                                                                <td className='has-text-centered'>
                                                                                                    <a data-tip="Download" href={'https://mediaserver.canstringai.com/recordings/' + file.unique_name + '.mkv'} target="_blank">
                                                                                                        <i className="fa fa-download" />
                                                                                                    </a>
                                                                                                    <a data-tip="Edit" onClick={this.editRecord.bind(this, file, index)}>
                                                                                                        <i className="fa fa-pencil" />
                                                                                                    </a>
                                                                                                    <a data-tip="Delete" onClick={this.recordingDelete.bind(this, file.recording_id, index)}>
                                                                                                        <i className="fa fa-trash" />
                                                                                                    </a>
                                                                                                </td>
                                                                                                <ReactTooltip place="top" type="dark" effect="float" />
                                                                                            </tr>
                                                                                        )
                                                                                    }) : <tr>
                                                                                        <td colSpan='3' className='has-text-centered'><p>No Records Here</p></td>
                                                                                    </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="columns is-clearfix">
                                                                <div className="column pad-b-0 confirm-page">
                                                                    <div className="has-text-right is-pulled-right"><button onClick={this.onClosePopup} className={this.state.type != 'createRoom' && this.state.type != 'confirmLeave' && this.state.type != 'noName' && this.state.type != 'editName' ? "button mar-5" : "hide"} type="button">Ok</button>

                                                                        <a href={'/host/' + this.room + '/' + this.userName} target="_blank"><button onClick={this.onCreateAndJoinRoom} className={this.state.type == 'createRoom' ? "button mar-5" : "hide"} type="button">Join</button></a>

                                                                        <button onClick={this.onClosePopup} className={this.state.type == 'createRoom'  ? "button mar-5" : "hide"} type="button">Cancel</button>

                                                                        <button onClick={this.onClosePopup} className={this.state.type == 'confirmLeave' ? "button mar-5" : "hide"} type="button">Stay Here</button>
                                                                        <a href="/thankyou" onClick={this.leave} className={this.state.type == 'confirmLeave' ? "button mar-5" : "hide"} type="button">Leave</a>

                                                                        <a href={this.state.nameUrl + '/' + this.state.userName} onClick={this.onJoinRoom}><button className={this.state.type == 'noName' ? "button mar-5" : "hide"} type="button">Join</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ToastContainer
                                        position="top-right"
                                        autoClose={1000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        />
                                        <ToastContainer /> */}

                    </div>
                </div>

            </div>
        )

    }
}
export default Popup;
