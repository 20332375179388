import React, { Component } from 'react';
class CallNotification extends Component {
  
  constructor(props) {
    super(props);
    this.state = {first_name:this.props.rignerInfo.first_name,user_id:this.props.rignerInfo.user_id}
  }
  

  componentDidMount = ()=> {
    
  }
  
  componentWillUnmount = ()=> {
    
  }
  attendCall = ()=>{
      this.props.attendCall(this.state.user_id,this.state.first_name);
  } 
  rejectRinging = ()=>{
      this.props.rejectRinging();
  }
  render() {
    return (
        <div class="audio_receiver">
            <div class="audio_rec_view">
                <div class="">
                  
                </div>
                <h2>{this.state.first_name}</h2>
                <p class="is-small">Ringing...</p>
                <a class="audio_btn" onClick={this.rejectRinging}><i class="fa fa-phone rotate-img"></i></a> <a onClick={this.attendCall} class="audio_btn"><i class="fa fa-phone"></i></a>
            </div>
        </div>
      )
    
  }
}
export default CallNotification;
