export const defaultConfig = {
    VERSION: 'v1',
    DEFAULT_RECORD_LIMIT: 15,
    DEFAULT_SCROLL_RECORD_LIMIT: 25,
    DEFAULT_OFFSET: 0,
    DEFAULT_PAGE_RANGE: 2,
    DEFAULT_SCROLL_DIST: 2,
    SCROLL_THROTTLE: 300,
    PROFILE_SIZE: 5, // 5MB
    PROFILE_EXT: 'PNG,JPEG,JPG',
    POST_FILE_EXT: 'PNG,JPEG,JPG,MP4',
    TOPIC_EXT:'MP3,MP4,WAV,AVI,FLV,PDF,PPT,PPTX,PPTM,DOC,DOCX,TXT',
    TOPIC_FILE_SIZE: 5, // 5 MB
    ATTACHMENT_SIZE: 15, // 15 MB
    ATTACHMENT_EXT: 'PNG,JPEG,JPG,SVG,MP3,MP4,WAV,AVI,FLV,JS,PDF,CSV,PPT,PPTX,PPTM,XML,PSD,XLS,XLSX,XLSM,XLW,SQL,JAR,CSS,HTML,HTM,PHP,ZIP,RAR,DOC,DOCX,TXT,DAT,RPTDESIGN,JSON',
    ATTACHMENT_LIMIT: 5,
    ASSIGN_FILE_EXT: 'PNG,JPEG,JPG,SVG,PDF,PPT,PPTX,PPTM,DOC,DOCX,TXT',
    ASSIGN_FILE_SIZE: 5, //5MB
    NOTIFICATION_TIMEOUT: 5000,
    MAX_ATTACH_FILE_SIZE: 50,
    ENCRYPTION: true
};