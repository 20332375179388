import React, { Component } from 'react';
import Header from './Header';
import CallOpenWindow from './CallOpenWindow';
import SocketHandler from '../helpers/socket-service';
import Popup from './Popup';
import Config from '../config';
import CallOpenMediaServer from './CallOpenMediaServer';
class GuestView extends Component {
  
  constructor(props) {
    super(props);
    this.public = true;
    if(window.location.href.indexOf('/host') > -1){
      if(localStorage.getItem('createdRoom') == this.props.room){
          this.public = false;
      }else{
          this.unAutorised = true;
      }
      
  }
    this.state = {room:this.props.room,public:this.public,userId:this.props.userId,userName:this.props.userName,callToken:this.props.callToken,popup:'',popData:{}}
    SocketHandler.getSocket((socket)=>{
        socket.on('onReceiveSendToAll',(data) => {
            data =  JSON.parse(data);
            switch (data.method) {
              case 'onListRecordings':
                this.setState({popup:'recordings',popData:data.recordings})
              break;
            }
        });
    })
  }

  componentDidMount = ()=> {
    
  }
  
  componentWillUnmount = ()=> {
    
  }
  alert = (msg) =>{
    this.setState({popup:'alert',popData:{alert:msg}});
  }
  confirmLeave = (msg,room,userName) =>{
    this.setState({popup:'confirmLeave',popData:{alert:msg,room:room,userName:userName}});
  }
  notAutherized = (msg)=>{
    this.setState({popup:'alert',popData:{alert:msg}});
  }
  onClosePopup = () =>{
    this.setState({popup:false});
  }
  inviteUers = (room) => {
     this.setState({popup:'invite',popData:{room:room}})
  }
  editName = (name,userId,room) => {
    this.setState({popup:'editName',popData:{name:name,userId:userId,room:room}})
}
  
  onSetSession = ()=>{
    //SocketHandler.onAddUser({user_id:UserSession.getSession().user_id,userName:UserSession.getSession().userDetail.first_name});
  }
  onGetRecordings = () =>{
    SocketHandler.getRecordings({});
  }
  openDocumentPop = (room) =>{
    this.setState({popup:'docshare',popData:{room:room}})
  }
  shareDocument = (fileName,totalPages) =>{
    this.callOpenComp.shareDocument(fileName,totalPages);
  }
  shareVideo = (fileName,totalPages) =>{
    this.callOpenComp.shareVideo(fileName,totalPages);
  }
  
  renderCallWindow = () =>{
    if(Config.MEDIA_SERVER){
      return(
            <CallOpenMediaServer
                currentChatUser={{full_name:'Group',user_id:this.state.userId}}
                room = {this.state.room}
                userId = {this.state.userId}
                userName = {this.state.userName}
                callToken = {this.state.callToken}
                alert = {this.alert}
                notAutherized = {this.notAutherized}
                inviteUers = {this.inviteUers}
                editName = {this.editName}
                openDocumentPop={this.openDocumentPop}
                ref={(callOpenComp) => this.callOpenComp = callOpenComp}
                confirmLeave={this.confirmLeave}
              />
      )
    }else{
      return(
        <CallOpenWindow
            currentChatUser={{full_name:'Group',user_id:this.state.userId}}
            room = {this.state.room}
            userId = {this.state.userId}
            userName = {this.state.userName}
            callToken = {this.state.callToken}
            alert = {this.alert}
            notAutherized = {this.notAutherized}
            inviteUers = {this.inviteUers}
            editName = {this.editName}
          />
  )
    }
                
  }
  
  render() {
    if(this.state.userName){
    return (
        <div>
          <span className="hide">
          <Header public={this.state.public} onSetSession={this.onSetSession}
            onGetRecordings={this.onGetRecordings}/>
          </span>
            
            <app-menu-options class="flex-grid tab-view">
                <app-chat-list class="flex-grid width100 main-cht-area" >
                    <section className='width100'>
                      {
                        this.renderCallWindow()
                      }
                    </section>
                    <section className={this.state.popup? 'pop-parent':'hide'}>
                      <Popup
                        onClosePopup={this.onClosePopup}
                        popup={this.state.popup}
                        popData = {this.state.popData}
                        shareDocument = {this.shareDocument}
                        shareVideo = {this.shareVideo}
                      />
                      </section>
                </app-chat-list>
            </app-menu-options>
        </div>
       )
    }else{
      let popData = {room:this.state.room}
      return(
      <div>
            <Header public={this.state.public} onSetSession={this.onSetSession}/>
            <app-menu-options class="flex-grid tab-view">
                <app-chat-list class="flex-grid width100 main-cht-area" >
                    <section className='pop-parent'>
                      <Popup
                        onClosePopup={this.onClosePopup}
                        popup="noName"
                        popData = {popData}
                      />
                      </section>
                </app-chat-list>
            </app-menu-options>
        </div>
      )
    }
  }
}
export default GuestView;
