import React, { Component } from 'react';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
import SocketHandler from '../helpers/socket-service';
import UserSession from '../helpers/user-session';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequestHandler from '../helpers/request-handler';
import Form from './Form';
class AttachmentList extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        expanded: false,
        height: 0,
        fileData: [...this.props.fileList]
      };
      
      
      this.toggleCardState = this.toggleCardState.bind(this);
    }
    
    toggleCardState(e) {
      const test = this.content.clientHeight;
      
      this.setState({
        "expanded": !this.state.expanded
      });
    }

    removeFileList = (data) => {
        this.props.removeFiles();
    }
    
    render() {
      const {title, children} = this.props;
      const {expanded} = this.state;
      
      return(
        <div className="file-upload ">
          <div 
            className={ "card upload-list " + (expanded ? "not-expanded" : "expanded") }
          >
            <header className="card-header" onClick={this.toggleCardState}>
              <p className="card-header-title">
                Attachements
              </p>
              <a className="card-header-icon">
                <span className="icon">
                  <i className="fa fa-angle-up"></i>
                </span>
              </a>
            </header>
            <div className="card-content" style={{ maxHeight: this.state.height }} ref={(content) => this.content = content }>
              <div className="content">
                {this.state.fileData.map((file) => { 
                    return(
                    <div class="pos-relative" >
                        <figure class="image is-48x48">
                        <img src={'/files/file_type/'+file.mimeType+'.png'}/>
                        </figure>
                        <div class="file-detail">
                            <div class="content-align">
                                <p>{file.name}</p>
                                <span class="file-size">{file.size}</span>
                                {/* <progress class="progress is-link is-small" value="{{eachFile.progressVal}}" max="100"></progress> */}
                            </div>
                        </div>
                        <i class="fa fa-times" aria-hidden="true" onClick={this.removeFileList.bind(file)}></i>
                    </div>)})}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
export default AttachmentList;
