import React, { Component } from 'react';
import Login from './Login';
import UserSession from '../helpers/user-session';
import ChatParent from './ChatParent';
import GuestView from './GuestView';
import CommonFunctions from '../helpers/common-functions';
import { ToastContainer } from 'react-toastify';
import Verify from './verify';

class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {viewVerify: false,token:UserSession.getSession().token,room:this.props.match.params.room,userId:this.props.match.params.userId?this.props.match.params.userId:CommonFunctions.generateUID(),userName:this.props.match.params.userName,callToken:this.props.match.params.callToken}
    
  }
  componentDidMount = ()=> {
    
    
  }
  
  componentWillUnmount = ()=> {
    
  }
  onErrorPublishing = ()=>{

  }
  onLogout = ()=>{
      window.localStorage.clear();
      this.setState({token:UserSession.getSession().token, viewVerify: false})
  }
  onLoginFailed = () => {
    this.setState({viewVerify: true});
  }
  onLoginSuccess = ()=>{
       this.setState({token:UserSession.getSession().token})
      }
      render() {
        if(this.state.token && UserSession.getSession().user_id &&  !this.state.room){
          return (
            <div>
              <ToastContainer/>
              <ChatParent onLogout={this.onLogout}/>
            </div>
       )
    }else{
      if(this.state.room){
        return (
          <div>
          <ToastContainer/>
         <GuestView onLogout={this.onLogout}
         room={this.state.room}
         userId={this.state.userId}
         userName={this.state.userName}
         callToken = {this.state.callToken}/>
         </div>
        )
      }else if(this.state.viewVerify) {
        
        return (
          <div>
            <ToastContainer/>
            <Verify onLoginSuccess={this.onLoginSuccess} ref={(element) => {window.verifyComponent = element}}></Verify>
        </div>
        )
      }else if (!this.state.viewVerify){
        return (
          <div>
            <ToastContainer/>
          <Login onLoginSuccess={this.onLoginSuccess} onLoginFailed={this.onLoginFailed} ref={(element) => {window.loginComponent = element}}/>
          </div>
        )
      }
    }
    
  }

  // render() {
  //   return (
  //     <div>
  //             {/* <Login onLoginSuccess={this.onLoginSuccess}/> */}
  //             <ToastContainer/><ToastContainer/>
  //             </div>
  //           )
  // }
}
export default App;
