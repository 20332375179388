import React, { Component } from 'react';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
// import 'react-toastify/dist/ReactToastify.css';
import Config from '../config';
class Verify extends Component {
  
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {otp:{value:'',error:false}}
  }

  componentDidMount = ()=> {
}
componentWillUnmount = ()=> {
    
  }
  /*
anand@acinfotech.com,alwin@acinfotech.com,avinash.kumbar@acinfotech.com,remesh@acinfotech.com,mohan.raj@acinfotech.com,prakash.thangaraj@acinfotech.com,pradeep.shingatalur@acinfotech.com


demo@stevefotos.com
mind123#

demo1@stevefotos.com
mind123#

demo2@stevefotos.com
mind123#

demo3@stevefotos.com
mind123#
  */
  //mani.raju@acinfotech.com
  //mohan.raj@acinfotech.com
  //mind123#
  //{email_id: "alexa@lowdh.com", password: "mind123"}
  onSignIn = () =>{
    RequestHandler.post(Config.API_DOMAIN+'/canstring/v1/users/authenticate',{email_id: this.state.email.value, password: this.state.password.value},(response)=>{
            if(response.success === true){
                UserSession.setSession(response)
                this.props.onLoginSuccess();
            }else{
                //alert(response.message);
            }
            
    })
  }

  Verify = () => {
    if (this.validator.allValid()) {
        RequestHandler.put(Config.API_DOMAIN+'/canstring/v1/users/verify_otp',{verify_otp: this.state.otp.value},(response)=>{
            if(response.success){
                UserSession.setSession(response)
                this.props.onLoginSuccess();
            }else{
                // toast.dark('Please check your credentials.' , {autoClose: 3000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,});
            }
            
        })
    } else {
        this.validator.showMessages();
        this.forceUpdate();
    }
  }
  verifyValidateAccount = () => {
      toast.dark('Please Enter Valid PIN.' , {autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,});
  }
  onInputFieldChange = (param,event) =>{
      let stateObj = {};
      let error    = false;
      if(event.target.value === ''){
          error =  'This field is requered';
      }
      stateObj[param] = {value:event.target.value,error:error};
      this.setState(stateObj)
  }
  render() {
    return (
        <div className="app-signin">
                <div className="container">
                    <div className="progressLoader"></div>
                    <div className="signin-container columns">
                        <div className="column is-offset-4 is-4 card top-bottom-moderate-box-shadows mobile-loginwrap">
                            <div className="brand has-text-centered"><img height="50px" width="" src="./login_files/login-logo.png"/>
                                <p>We've sent you a six digit verfication PIN to your email</p>
                            </div>
                            <div className="login-form">
                                <div novalidate="">
                                    <div className="field"><label className="label">Verfication PIN</label>
                                        <div className="control has-icons-left">
                                            <input className="input" onChange={this.onInputFieldChange.bind(this,'otp')} value={this.state.otp.value} placeholder="Enter your PIN" type="number"/>
                                            <span class="icon is-small is-left"><i class="fa fa-envelope"></i></span>
                                        {/* <p className="help is-danger height20">{this.state.otp.error}</p> */}
                                        </div>
                                        <p className="help is-danger height20">
                                            {this.validator.message('Verification Pin', this.state.otp, 'required')}
                                        </p>
                                    </div>
                                    <a className="pri-cus-btn mar-top15" disabled={(this.state.otp.error) ||  (!this.state.otp.value ? true:false)} onClick={this.Verify}>Verify</a>
                                    <div className="columns f-sm">
                                        <div className="column is-6">
                                            <div className="checkbox"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sign-footer">
                    <footer className="">
                        <div className="columns">
                            <div className="column is-6">
                                <p>All Rights Reserved CanString.AI</p>
                            </div>
                            <div className="column is-6 has-text-right">
                                <nav>
                                    <ul className="reset">
                                        <li className="display-ib"><a href="https://demo.canstrings.com/#/privacy" routerlink="/privacy">Privacy &amp; Terms </a></li>
                                        <li className="display-ib"><a href="https://demo.canstrings.com/#/help" routerlink="/help">Help</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
       )
  }
}
export default Verify;
