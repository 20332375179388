import React, { Component } from 'react';
import CommonFunctions from '../helpers/common-functions';
import RequestHandler from '../helpers/request-handler';
import Config from '../config';
import SessionListing from './SessionListing';
import SocketHandler from '../helpers/socket-service';
import UserSession from '../helpers/user-session';
import AttachmentList from './attachmentList';
import LessonListing from './LessonListing';

class SingleTextChatWindow extends Component {
  
  constructor(props) {
    super(props);
    this.state = {user_id:this.props.currentChatUser.user_id,fullName:this.props.currentChatUser.full_name,
        message:[],currentMessage:'',userList:{},callSupported:true, currentNewChat:{}, chatView: false, uploadedList:[],lessonView:false}
        this.inputReference = React.createRef();
    this.messagesEndRef = React.createRef();
    this.handler = this.handler.bind(this)
    SocketHandler.getSocket((socket)=>{
        socket.on('onUserList',(data) => {
            data =  JSON.parse(data);
            let list = {};
            for(let key in data){
                 list[data[key].user_id] = 'available';
            }
            this.setState({userList:list})
        });
        socket.on('onReceiveSendToAll',(data) => {
            data =  JSON.parse(data);
            switch (data.method) {
                case 'newMessage':
                    if(data.receiverId === UserSession.getSession().user_id){
                        if(this.state.user_id == data.senderId){
                            this.getMessages(this.state.user_id);
                        }
                    }
                break;
            }
        });
    })
    
  }

  hitParent = () => {
    this.props.connectUserList();
  }

  fileUploadAction = () =>this.inputReference.current.click();
  fileUploadInputChange = (e) => {
      console.log(e.target.files)
      const files = e.target.files;
      const splitArr = files[0].name.split('.');
      files[0].mimeType = splitArr[splitArr.length - 1].toLowerCase();
    this.setState({uploadedList:files});
  }

  removeFiles = () => {
    this.setState({uploadedList:[]});
  }
  onNewUserSelect = (user) => {
    this.setState({user_id:user.user_id,fullName:user.full_name, currentNewChat: {...user},
        message:[], chatView: false});
        // this.handler()
        if(!this.state.chatView) {
            
            if(this.state.lessonView){
                this.refs.lessonList.onUserselect(user);
            }else{
                this.refs.customListReference.onUserselect(user);
            }
        }
    this.getMessages(user);
  }
  getMessages = (user)=>{
      if(user.chat_type === 'user') {
        RequestHandler.get(Config.API_DOMAIN+'/canstring/v1/chats/messages?userId='+user.user_id+'&offset=0&limit=25',(response)=>{
            if(response.success === true){
                    this.setState({message:response.message})
                    this.scrollToBottom();
            }else{
                console.log(response.message);
            } 
        })
    } else if (user.chat_type === 'group') {
        RequestHandler.get(Config.API_DOMAIN+'/canstring/v1/chats/groupMessages?groupId='+user.group_id+'&offset=0&limit=25',(response)=>{
            if(response.success === true){
                    this.setState({message:response.message})
                    this.scrollToBottom();
            }else{
                console.log(response.message);
            } 
        })
    }
  }
  saveMessages = ()=>{
    if(this.state.currentNewChat.chat_type === 'user') {    
        RequestHandler.post(Config.API_DOMAIN+'/canstring/v1/chats/messages',{to_user_id:this.state.user_id,message:this.state.currentMessage,unique_ref_id:CommonFunctions.generateUID()},(response)=>{
            if(response.success === true){
                this.getMessages(this.props.currentChatUser);
            }
        })
    } else if (this.state.currentNewChat.chat_type === 'group') {
        RequestHandler.post(Config.API_DOMAIN+'/canstring/v1/chats/groupMessages',{group_id:this.props.currentChatUser.group_id,message:this.state.currentMessage,unique_ref_id:CommonFunctions.generateUID()},(response)=>{
            if(response.success === true){
                this.getMessages(this.props.currentChatUser);
            }
        }) 
    }
 }
 onMessageKeyUp = (e)=>{
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code == 13) {
        this.saveMessages();
        this.syncMessage();
        this.setState({currentMessage:''})
        e.preventDefault();
    }
}
syncMessage = (e)=>{
    SocketHandler.sendToAll({method:'newMessage',senderId:UserSession.getSession().user_id,receiverId:this.state.user_id})
}
 onMessgeInputChange = (e)=>{
     this.setState({currentMessage:e.target.value})
 }
 componentDidMount = ()=> {
    if(CommonFunctions.getBrowser() == 'Edge'){
            this.setState({callSupported:false,})
    }
    if(CommonFunctions.getOS() == 'Android'){
        if(CommonFunctions.getBrowser() != 'Chrome'){
                this.setState({callSupported:false})
        }
    }
    if(CommonFunctions.getOS() == 'iOS'){
        if(CommonFunctions.getBrowser() != 'Mobile Safari'){
                this.setState({callSupported:false})
        }
    }
    this.setState({chatView: false})
  }
  onStartCall = ()=> {
      if(this.state.userList[this.state.user_id]){
        this.props.onStartCall();
      }else{
          this.props.userOffileCall();
          //alert('user is offline');
      }
  }
  renderAllMessages = ()=>{
    
  }

  handler() {
    this.setState(preChat => ({
        chatView: !preChat.chatView
    }))
  }
  onBackToList = () =>{
      this.props.onBackToList();
  }
  scrollToBottom = () => {
      if(this.messagesEndRef.current){
        this.messagesEndRef.current.scrollIntoView({block:'end', inline:'nearest'})
      }
  }
  backToSchedule = () =>{
      this.setState({chatView:false,lessonView:false})
  }
  openClassView = () =>{
    this.setState({chatView:false,lessonView:true})
    setTimeout(()=>{
        this.refs.lessonList.onUserselect(this.state.currentNewChat );
    },500)
    
  }
  render() {
       if(this.state.chatView === true) { 
      return (
        //   <div>
        <div id="chat-window" className={this.state.chatView === true ? 'flex-grid widthhight100 mob-content': 'hide'}>
                        <div appdragdrop="" className="col th-p pos-relative">
                        <header className="top-box-shadow">
                            <div className=" chats width100">
                                <div className="content-wrapper is-clearfix">
                                    <div className="chat-name">
                                    <div className="is-pulled-left">
                                        <i onClick={this.hitParent} className='fa is-pulled-left fa-chevron-right'></i>
                                        {/* <i onClick={this.toggleCollapse} className={this.state.toggleCollapse ? 'fa is-pulled-left fa-chevron-right' : 'hide'}></i> */}
                                        <div className="display-ib">
                                            <div className="empty-head-icon">{this.state.fullName?this.state.fullName.charAt(0).toUpperCase():''}</div>
                                        </div>
                                    <span className="chat-title-name is-size-6 mar-5-0">{this.state.fullName}</span>
                                    </div>
                                    <div className="is-clearfix is-pulled-right mar-t-5">
                                        {/* <div className="is-pulled-right mar-10-0"> */}
                                        <a href='#' data-toggle="modal" data-target="#shedule-modal" className={this.state.chatView === true ?  'button is-small is-link' : 'hide'} onClick={this.handler}>
                                                <span className='icon is-small'><i className='fa fa-calendar-check-o'></i></span> <span>Go to Schedule</span></a>
                                            {/* <a class="btn mar-0-5" className={this.state.chatView === true ? 'btn mar-0-5': 'hide'} onClick={this.handler}>Schedule</a> */}
                                            {/* <a className={this.state.callSupported ? (this.state.userList[this.state.user_id] ? "pad-10":"pad-10 offlinecall"):"hide"} id="opencall" onClick={this.onStartCall}><i aria-hidden="true" className="fa fa-phone mar-10"></i></a> */}
                                            {/* <a class="is-small pad-10 ">
                                                <i class="fa fa-users is-size-6 pad-10"></i>
                                            </a> */}
                                            <a className="is-small pad-10 hide"><i aria-hidden="true" className="fa fa-info-circle is-size-6"></i></a>
                                        {/* </div> */}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="contents is-marginless height100">
                            <div appinfinitescroll="" className="chats scroll-bar chat-bg">
                                <div className="chat-list mar-0-15" ref={this.messagesEndRef}>
                                    <div>
                                    {
                                        this.state.message.map((message,index)=>{
                                            return(
                                                <div key={index}>
                                                    <div className="column has-text-centered timing"><span className="time-history">{message.day}</span></div>
                                                    <div className="msg-z-view">
                                                        <div className="column is-clearfix">
                                                            <div className="is-pulled-left has-text-centered">
                                                            <figure className="image is-32x32">
                                                                <div className="display-ib">
                                                                    <div className="empty-head-icon">{message.first_name.charAt(0).toUpperCase()}</div>
                                                                </div>
                                                            </figure>
                                                            <i aria-hidden="true" className="fa fa-ellipsis-h"></i>
                                                            </div>
                                                            <div className="box chat-file-type">
                                                            <article className="media left-top">
                                                                <div className="media-content">
                                                                    <div className="content">
                                                                        <strong className="is-size-7">{message.first_name+' '+message.last_name}</strong><small className="chat-time">{CommonFunctions.getTime12Hour(message.createdAt)}</small>
                                                                        <p className="size-14 normal-chat-content file-links">
                                                                            <span className="show-less" id="scrollFrame_1">{message.message}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            })
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="chats">
                            <div className="field is-grouped">
                                <div className="control has-icons-left has-icons-right is-expanded">
                                <input onKeyUp={this.onMessageKeyUp} onChange={this.onMessgeInputChange} value={this.state.currentMessage} aria-label="Type Message here..." aria-multiline="true" className="input multiple contenteditable scroll-bar alignleft" contenteditable="true" dir="ltr" placeholder="Type Message here..." spellcheck="true"/>
                                <input type="file" hidden ref={this.inputReference} onChange={this.fileUploadInputChange} />
                                
                                {/* {this.state.fileUploadState} */}
                                    <span className="icon is-small is-left first-icon" onClick={this.fileUploadAction}><i className="fa fa-paperclip"></i></span>
                                    <span className="icon is-small is-right">
                                    {/* imoji */}
                                    </span>
                                </div>
                            </div>
                            <div className="columns up-btn-fix">
                            </div>
                            <input name="msg-file hide" type="file" />
                        </footer>
                        {this.state.uploadedList.length !== 0 ?
                            <AttachmentList fileList={this.state.uploadedList} removeFiles = {this.removeFiles}></AttachmentList> : null
                        }
                        </div>
                    </div>
                    // {/* <div className={this.state.chatView === true ? 'hide': null}>
                    // <SessionListing ref="customListReference"
                    //     selectedUserData={this.state.currentNewChat 
                    //     } handler = {this.handler} />
                    // </div> */}
                // </div>
        
      ) 
    } else if (this.state.chatView === false){
        if(this.state.lessonView){
            return(  
                <LessonListing ref="lessonList" backToSchedule={this.backToSchedule}></LessonListing>
            )                
           
        }else{
            return(
                <SessionListing ref="customListReference" className={this.state.chatView === false ? '': 'hide'}
              selectedUserData={this.state.currentNewChat 
              } handler = {this.handler} parentToggle = {this.hitParent} openClassView = {this.openClassView}/>
            )
        }
        
    }



          
  }
}
export default SingleTextChatWindow;
