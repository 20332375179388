import React, { Component } from 'react';
import Config from '../config';
import RequestHandler from '../helpers/request-handler';


class InteractiveVideoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {classInfo:this.props.classInfo,playerState:'pause',audioState:'unmuted',questState:'notadding',
                      buffering:false,questions:[],surveys:[],polls:[],notes:[],activeTab:'assessment',
                      videoQuestionSetList:[],newqset:false,
                      totalDuration:0};
        this.totalDuration = 0;
        this.selectedQuestionIds = [];
        this.questionIdQuestionTypeMap = {};
    }
    componentDidMount = () =>{
       // alert(JSON.stringify(this.state.classInfo))
       this.videoElement = document.getElementById('intVideo');
       this.addListeners();
       let slowInternetTimeout = null;

        let threshold = 500; //ms after which user perceives buffering

        this.videoElement.addEventListener('waiting', () => {
            slowInternetTimeout = setTimeout(() => {
                this.setState({buffering:true})
            }, threshold);
        });
        this.videoElement.addEventListener('playing', () => {
            if(slowInternetTimeout != null){
                clearTimeout(slowInternetTimeout);
                slowInternetTimeout = null;
                this.setState({buffering:false})
            }
        });
        this.getAllQuestions();
       
    }
    deleteQuestionSet = (id) =>{
        RequestHandler.deleteAPI(Config.API_DOMAIN + '/canstring/v1/deleteVideoQuiz/'+id, {}, (response) => {
            // alert(JSON.stringify(response))
            //if(response.success === true){
               //alert('deleted')
               
            //}
        });
        setTimeout(this.getAllQuestions,3000);
    }
    onAddQuestions = () =>{
        this.editQuizTime = false;
        this.showAllOtherTabs();
        let videoQuestionSetList = this.state.videoQuestionSetList;
        for(let index in videoQuestionSetList){
            let time = videoQuestionSetList[index].quiz_time;
            if(parseInt(Math.abs(parseInt(time) - this.videoElement.currentTime)) < 15){
                alert('THERE IS ALREADY A QUESTION SET NEAR BY TIME PLEASE CHOOSE ANOTHER TIMESTAMP')
                return;
            }
        }
        this.setState({questState:'adding'})
        this.uncheckAllCheckBox();
        
    }
    editQuestionSet = (questSet) =>{
        // alert(JSON.stringify(questSet))
        this.editQuizTime = questSet.quiz_time;
        this.showAllOtherTabs();
        this.setState({questState:'adding'})
        this.uncheckAllCheckBox();
        this.selectedQuestionIds = [];
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/editVideoQuiz/'+questSet.quiz_id
        , (response) => {
            if (response.success === true) {
                let questions = response.record.question_ids;
                if(questions.length > 0){
                    this.hideAllOtherTabs(questSet.quiz_type)
                    for(let k in questions){
                        document.getElementById(questions[k]).checked = true;
                        this.selectedQuestionIds.push(questions[k]);
                    }
                    
                }
            }
        });

    }
    showAllOtherTabs = () =>{
        document.getElementById('assessment').style.display = 'inline';
        document.getElementById('survey').style.display = 'inline';
        document.getElementById('polls').style.display = 'inline';
        document.getElementById('notes').style.display = 'inline';
    }
    hideAllOtherTabs = (qtype) =>{
        //alert(qtype)
        document.getElementById('assessment').style.display = 'none';
        document.getElementById('survey').style.display = 'none';
        document.getElementById('polls').style.display = 'none';
        document.getElementById('notes').style.display = 'none';
        switch (qtype) {
            case 'question':
                document.getElementById('assessment').style.display = 'inline';
                this.onChangeTab('assessment')
            break;
            case 'assessment':
                document.getElementById('assessment').style.display = 'inline';
                this.onChangeTab('assessment')
            break;
            case 'survey':
                document.getElementById('survey').style.display = 'inline';
                this.onChangeTab('survey')
            break;
            case 'notes':
                document.getElementById('polls').style.display = 'inline';
                this.onChangeTab('notes')
            break;
            case 'polls':
                document.getElementById('notes').style.display = 'inline';
                this.onChangeTab('polls')
            break;
            default:
                break;
        }
    }
    
    getAllQuestions = () => {
            RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/interactionQuestions/'+this.state.classInfo.message_id
            , (response) => {
                if (response.success === true) {
                    if(response.record.assessment){
                        this.setState({ questions: response.record.assessment})
                    }
                    if(response.record.survey){
                        this.setState({ surveys: response.record.survey})
                    }
                    if(response.record.polls){
                        this.setState({ polls: response.record.polls})
                    }
                    if(response.record.notes){
                        this.setState({ notes: response.record.notes})
                    }
                    if(response.record.inter_questions){
                        let savedQuestionList = response.record.inter_questions;
                        // alert(JSON.stringify(savedQuestionList))
                        let videoQuestionSetList = [];
                        let processedIndex = [];
                        let allow = true;
                        for(let index in savedQuestionList){
                            allow = true;
                            let savedQuest = savedQuestionList[index];
                            processedIndex.push(index)
                            for(let nextIndex in savedQuestionList){

                                if(savedQuest.quiz_id !== savedQuestionList[nextIndex].quiz_id && processedIndex.indexOf(nextIndex) == -1){
                                    let diff = parseInt(savedQuestionList[nextIndex].quiz_time) - parseInt(savedQuestionList[index].quiz_time);
                                    
                                    if(parseInt(Math.abs(diff)) < 15 ){
                                        allow = false;
                                    }
                                }

                                
                            }
                            if(allow){
                                videoQuestionSetList.push({quiz_id:savedQuest.quiz_id,quiz_time:savedQuest.quiz_time,quizQuestions:savedQuest.question_ids,quiz_type:JSON.parse(savedQuest.quizQuestions)[0].question_type});
                                
                            }
                            
                        }
                        this.setState({videoQuestionSetList:videoQuestionSetList})
                    }
                    //this.setState({videoQuestionSetList:response.quiz_questions})
                    
                }
            })
    }
    seekVideo = (val) =>{
        this.videoElement.currentTime = val;
    }
    playVideo = () =>{
        this.videoElement.play();
        this.setState({playerState:'play'})
        
    }
    pauseVideo = () =>{
        this.videoElement.pause();
        this.setState({playerState:'pause'})
        
        
        
    }
    onFullScrn = () =>{
        this.videoElement.webkitEnterFullscreen();
    }
    muteAudio = ()=>{
        this.videoElement.volume = 0;
        this.setState({audioState:'muted'})
    }
    unMuteAudio = ()=>{
        this.videoElement.volume = 1;
        this.setState({audioState:'unmuted'})
    }
    addListeners = () =>{
        this.videoElement.ontimeupdate = this.onTimeupdate;

    }
    onTimeupdate = (event)=>{
        if(this.totalDuration === 0){
            this.setState({totalDuration:event.target.duration});
        }
        this.totalDuration = event.target.duration;
        this.onTrackedVideoFrame(event.target.currentTime, event.target.duration);
    }
    onTrackedVideoFrame = (currentTime,duration)=>{
        if(this.state.playerState === 'play'){
            if(document.getElementById('video-time')){
                document.getElementById('video-time').value = this.secondsToHms(parseInt(currentTime));
            }
        }
        this.updateQuestionSeekBar(currentTime,duration)
        this.updateMainSeekBar(currentTime,duration)
    }
    updateQuestionSeekBar = (currentTime,duration) =>{
         let sliderLength = 376;
         let seakPosition = (currentTime/duration) * sliderLength;
         seakPosition = seakPosition - 17;
         if(seakPosition < 0){
             seakPosition = 0;
         }
         if(document.getElementById('q-slider-thumb')){
            document.getElementById('q-slider-thumb').style.top = seakPosition +"px";
         }
    }
    getTopForTimeline = (currentTime,duration) =>{
        console.log('fff'+currentTime+'//'+duration)
        let sliderLength = 376;
         let seakPosition = (currentTime/duration) * sliderLength;
         seakPosition = seakPosition - 15;
         if(seakPosition < 0){
             seakPosition = 0;
         }
         return seakPosition;
    }
    updateMainSeekBar = (currentTime,duration) =>{
        let seakPosition = (currentTime/duration) * 100;
        if(document.getElementById('main-seak-thumb')){
            document.getElementById('main-seak-thumb').style.width = seakPosition+"%";
            if(document.getElementById('main-seak-thumb').offsetWidth > 15){
                document.getElementById('seak-bubble').style.left = "calc("+seakPosition+"% - 15px)";
            }else{
                document.getElementById('seak-bubble').style.left = "0%";
            }
        }
        
        
    }
    onSeek = (e) =>{
        let totalSeakLength = document.getElementById('seek-bar').offsetWidth;
        let currentLength = e.pageX - 360;
        let seakLength = (currentLength/totalSeakLength) * this.totalDuration;
        this.seekVideo(seakLength)
    }
    onUpdateTimekeyuP = () =>{
        let inSecond = this.mmSSToSecond(document.getElementById('video-time').value);
        this.videoElement.currentTime = inSecond;
    }
    mmSSToSecond = (time) =>{
        var actualTime = time.split(':');
        var totalSeconds = (+actualTime[0]) * 60 + (+actualTime[1]) ;
        return totalSeconds;
    }
    secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);
    }
    
    closeQuestAdding = ()=>{
        this.setState({questState:'notadding'})
        this.playVideo();
    }
    onChangeTab = (tabname) =>{
        this.setState({activeTab:tabname});
        this.selectedQuestionIds = [];
        this.uncheckAllCheckBox();
    }
    uncheckAllCheckBox = () =>{
        var items = document.getElementsByClassName('qsetcheck');
        for (var i = 0; i < items.length; i++) {
            if (items[i].type == 'checkbox')
                items[i].checked = false;
        }
        document.getElementById('req-inp').checked = false;
    }
    selectQ = (qid,e) =>{
        if(e.target.checked){
            this.selectedQuestionIds.push(qid)
        }else{
            let index = this.selectedQuestionIds.indexOf(qid);
            if(index > -1){
                this.selectedQuestionIds.splice(index, 1);
            }
        }
        
    }
    createQuestionSet = () =>{
        // alert(JSON.stringify(this.selectedQuestionIds))
        if(this.selectedQuestionIds.length === 0){
            alert('SELECT ATLEAST ONE QUESTION.')
            return;
        }
        const payload = {
            lesson_id: this.state.classInfo.message_id,
            question_ids: this.selectedQuestionIds,
            quiz_time: this.editQuizTime ? this.editQuizTime:parseInt(this.videoElement.currentTime),
            is_required: document.getElementById('req-inp').checked
        }
        RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/createVideoQuiz', payload, (response) => {
            // alert(JSON.stringify(response))
            if(response.success === true){
                // videoQuestionSetList:[{quiz_id:1,quiz_time:30,quizQuestions:[{},{}]}]
                let result = response.result;
                let videoQuestionSetList = this.state.videoQuestionSetList;
                videoQuestionSetList.push({quiz_id:result.quiz_id,quiz_time:result.quiz_time,quizQuestions:result.question_ids,quiz_type:this.state.activeTab});
                this.setState({videoQuestionSetList:videoQuestionSetList})
                this.setState({newqset:true})
            }
        });

       
        
        this.closeQuestAdding();
        this.selectedQuestionIds = [];
        this.uncheckAllCheckBox();
    }
    onCancelUpdate = ()=>{
        this.setState({videoQuestionSetList:[]})
    }
    unpdateInteractiveVideo = () =>{
        const payload = {
            lesson_id: this.state.classInfo.message_id,
            is_video_quiz_found: true
        }
        RequestHandler.put(Config.API_DOMAIN + '/canstring/v1/updateInteractiveVideo', payload, (response) => {
            //alert(JSON.stringify(response))
            if(response.success === true){
                alert('QUESTION SET SUCCESSFULLY UPDATED TO LESSION')
                this.setState({newqset:false})
            }else{
                alert('QUESTION SET FAILED TO UPDATE')
            }
        });
    }
    //classInfo.view_Url
    render() {
            const {newqset,classInfo,playerState,audioState,questState,buffering,activeTab,videoQuestionSetList,totalDuration} = this.state;
            return (
            <section className='intract-bg'>
            <div className='player-head'>{classInfo.message} | {classInfo.shared_by} | {classInfo.created_at} | {classInfo.duration}</div>
            <div className='intrvideo-wrap'>
                <div className="video-view">
                    <video id='intVideo' className="interactive-video"  src={classInfo.view_Url}></video>
                    <div className={buffering ? 'loader-intvid':'hide'}>
                        <img src="/files/loader.gif" />
                    </div>
                </div>
                <div className='video-control'>
                    <div id='seek-bar' className='seek-bar' onClick={this.onSeek}>
                        <div id='main-seak-thumb' className='seak-thumb'>
                        
                        </div>
                        <div id='seak-bubble' className='seak-bubble'></div>
                        {
                       
                            videoQuestionSetList.map((qlistObj, index) => {
                                return(
                                    <div className={totalDuration > 0 ? 'quiz-indicator':'hide'} style={{'left':((qlistObj.quiz_time/totalDuration) * 100)+'%'}}>
                                        
                                    </div>
                                )
                            })
                        }
                    </div>
                    <img onClick={this.playVideo} className={playerState === 'pause' ? '':'hide'} src='/files/play_arrow.svg'/>
                    <img onClick={this.pauseVideo} className={playerState === 'pause' ? 'hide':''} src='/files/pause.svg'/>
                    <img className={audioState === 'unmuted' ? 'vol-btn':'hide'} src='/files/loude_speaker.svg' onClick={this.muteAudio}/>
                    <img className={audioState === 'unmuted' ? 'hide':'vol-btn'} src='/files/louse_spaker_off_hover.svg' onClick={this.unMuteAudio}/>
                    <img className='fullscrn-btn' src='/files/video-expand.svg' onClick={this.onFullScrn}/>
                </div>
                <div className={playerState === 'pause' ? 'play-overlay':'hide'}s onClick={this.playVideo}> <img src="/files/play_custom.png"/></div>
            </div>
            <div className='quest-slider-wrap'>
                    <div id='question-slider' className='quest-slider'>
                    {
                       
                       videoQuestionSetList.map((qlistObj, index) => {
                           return(
                               <div id='q-saved-indicator' className={totalDuration > 0 ? 'add-q-option-par':'hide'} style={{'top':(this.getTopForTimeline(qlistObj.quiz_time,totalDuration) )+'px'}}>
                                   <input value={this.secondsToHms(qlistObj.quiz_time)} id='q-save-time' placeholder='00:00' className='time-inp-adq nocursr' type='text'/>
                                   <div className='quiz-indicator-right'></div>
                                   <button className='add-opbtn qright-btn'>{qlistObj.quizQuestions.length+' Ques'}</button>
                                   <button onClick={this.editQuestionSet.bind(this,qlistObj)} className='add-opbtn add-opt-delete margin-right-5'>Edit</button>
                                   <button onClick={this.deleteQuestionSet.bind(this,qlistObj.quiz_id)} className='add-opbtn add-opt-delete'>Delete</button>
                               </div>
                           )
                       })
                   }
                        <div id='q-slider-thumb' className='add-q-option-par' onClick={this.pauseVideo}>
                            <input id='video-time' onKeyUp={this.onUpdateTimekeyuP} placeholder='00:00' className='time-inp-adq' type='text'/>
                            <div className='qseekbuble'></div>
                            <button className='add-opbtn bgwt' onClick={this.onAddQuestions}>Add Option</button>
                        </div>

                        
                    </div>
                    <div className='int-savebtnwrap'>
                        <button onClick={this.onCancelUpdate} className='int-cancel-btn hide'>Cancel</button>
                        <button onClick={this.unpdateInteractiveVideo} className={newqset ? 'int-save-btn':'hide'}>Save Interactive Video</button>
                    </div>
            </div>
            <div className={questState === 'adding' ? 'int-pop-overlay':'hide'}>
                
                <div className='int-pop-wrap'>
                <div className='int-pop-head'>Select</div>
                <div className='int-nav'>
                     <button id='assessment' onClick={this.onChangeTab.bind(this,'assessment')} className={activeTab === 'assessment'? 'active':''}>Assessment</button>
                    <button id='survey' onClick={this.onChangeTab.bind(this,'survey')} className={activeTab === 'survey'? 'active':''}>Servey</button>
                    <button id='polls' onClick={this.onChangeTab.bind(this,'polls')} className={activeTab === 'polls'? 'active':''}>poll</button>
                    <button id='notes' onClick={this.onChangeTab.bind(this,'notes')} className={activeTab === 'notes'? 'active':''}>Notes</button>
                </div>
                <div className={activeTab === 'assessment'? 'content-list':'hide'}>
                    {
                       
                       this.state.questions.map((questionObj, index) => {
                           return(
                            <div className='individual-question-par'>
                                <input id={questionObj.question_id} className='qsetcheck' onChange={this.selectQ.bind(this,questionObj.question_id)} type='checkbox' />
                                <div>Q{index+1} : {questionObj.question} ?</div>
                            </div>
                           )
                       })
                   }
                </div>
                <div className={activeTab === 'survey'? 'content-list':'hide'}>
                    {
                       
                       this.state.surveys.map((surveyObj, index) => {
                           return(
                            <div className='individual-question-par'>
                                <input id={surveyObj.question_id} className='qsetcheck' onChange={this.selectQ.bind(this,surveyObj.question_id)} type='checkbox' />
                                <div>Q{index+1} : {surveyObj.question} ?</div>
                            </div>
                           )
                       })
                   }
                </div>
                <div className={activeTab === 'polls'? 'content-list':'hide'}>
                    {
                       
                       this.state.polls.map((pollObj, index) => {
                           return(
                            <div className='individual-question-par'>
                                <input id={pollObj.question_id} className='qsetcheck' onChange={this.selectQ.bind(this,pollObj.question_id)} type='checkbox' />
                                <div>Q{index+1} : {pollObj.question} ?</div>
                            </div>
                           )
                       })
                   }
                </div>
                <div className={activeTab === 'notes'? 'content-list hide':'hide'}>
                    {
                       
                       this.state.notes.map((noteObj, index) => {
                           return(
                            <div className='individual-question-par'>
                                <input id={noteObj.question_id} className='qsetcheck' onChange={this.selectQ.bind(this,noteObj.question_id)} type='checkbox' />
                                <div>Q{index+1} : {noteObj.question} ?</div>
                            </div>
                           )
                       })
                   }
                </div>
                <br/> <br/>
                <hr/>
                <div className='content-list'>
                    <div className='individual-question-par'>
                        <input type='checkbox' id='isreq' className='qsetcheck' id='req-inp' /> <div className='noborder'>Required ?</div>
                    </div>
                </div>
                    <div className='int-savebtnwrap botton35'>
                        <button onClick={this.closeQuestAdding} className='int-cancel-btn'>Cancel</button>
                        <button onClick={this.createQuestionSet} className='int-save-btn'>Add Selected</button>
                    </div>
                </div>
            </div>
            </section>
            
               
            )
    }
}

export default InteractiveVideoComponent;