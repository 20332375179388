import React from "react";
class Form extends React.Component {

  constructor(props) {
    super(props);

    this.state = { inputFilename: this.props.newFilename}

}

handleInputChange = (parem, event) => {
  let stateObj = {};
  stateObj[parem] = event.target.value;
  this.setState(stateObj);
  // this.setState({ inputFilename: e.target.value });
};

updateform = () => {
  this.props.updateforminput(this.state.inputFilename);
}


  
  render() {
    return (
      <div class="main">
        <form id="formInput" onSubmit={this.props.handleFormSubmit}>
          <input
            id="filename"
            value={this.state.inputFilename}
            type="text"
            name="filename"
            placeholder="Filename"
            className='input'
            onChange={this.handleInputChange.bind(this, 'inputFilename')}
            required
            style={{'width':250}}
          />
          
          <button type="reset" className='button is-small  mar-5' value="Reset" onClick={this.props.onCancel}>
            {" "}
            Cancel{" "}
          </button>
          <button type="submit" className='button is-link is-small mar-5' disabled={this.state.inputFilename === ''} value="Submit" onClick={this.updateform}>
            {" "}
            Update{" "}
          </button>
        </form>
      </div>
    );
  }
}

export default Form;