import io from "socket.io-client";
import Config from "../config";
var socket = null;
var SocketHandler = (function() {
    var getSocket = function(cb) {
        if(socket){
            cb(socket);
        }else{
            socket = io('https://'+Config.DOMAIN);
            socket.on('connect',()=>{
                socket.emit('join');
                cb(socket);
            });
        }
    };
    /*
    var onConnect = ()=> {
        socket.on('onReceiveSendToAll',function(data){
            data =  JSON.parse(data);
            switch (data.method) {
                case 'onCalling':
                
                break;
            }
        });
    };
    */
    var onSendWebrtcSignal = (data)=>{
        socket.emit('message',JSON.stringify(data))
    }
    var onAddUser = (data)=>{
        socket.emit('addUser',JSON.stringify(data))
    }
    var createRoom = (data)=>{
        socket.emit('createRoom',JSON.stringify(data))
    }
    var checkRoomStatus = (data)=>{
        socket.emit('checkRoomStatus',JSON.stringify(data))
    }
    var desposeRoom = (data)=>{
        socket.emit('desposeRoom',JSON.stringify(data))
    }
    var getRecordings = (data) =>{
        socket.emit('getRecordings',JSON.stringify(data))
    }
    var saveRecordings = (data) =>{
        socket.emit('saveRecordings',JSON.stringify(data))
    }
    var editName = (data) =>{
        socket.emit('editName',JSON.stringify(data))
    }
    var sendToAll = (data)=> {
        socket.emit('onSendToAll',JSON.stringify(data))
    };
    var send = (method,data) =>{
        socket.emit(method,JSON.stringify(data))
    };
    var addUserMeeting = (data) =>{
        socket.emit('addUserMeeting',JSON.stringify(data))
    }
    var close = () =>{
        socket.close();
        socket = 'closed';
    }
    return {
        getSocket: getSocket,
        sendToAll: sendToAll,
        send: send,
        onSendWebrtcSignal:onSendWebrtcSignal,
        onAddUser:onAddUser,
        createRoom:createRoom,
        desposeRoom:desposeRoom,
        checkRoomStatus:checkRoomStatus,
        getRecordings:getRecordings,
        saveRecordings:saveRecordings,
        addUserMeeting:addUserMeeting,
        close:close,
        editName:editName
    }
  
  })();
 export default SocketHandler;