import React, { Component } from 'react';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';
import Config from '../config';
class Login extends Component {

    constructor(props) {
        super(props);
        let device = localStorage.getItem('device');
        this.state = { email: { value: '', error: false }, password: { value: '', error: false }, device: device, meetingId: { value: '', error: false }, activeTab: 'organize' }
    }

    componentDidMount = () => {

    }
    componentWillUnmount = () => {

    }
    /*
  anand@acinfotech.com,alwin@acinfotech.com,avinash.kumbar@acinfotech.com,remesh@acinfotech.com,mohan.raj@acinfotech.com,prakash.thangaraj@acinfotech.com,pradeep.shingatalur@acinfotech.com
  
Live credential
Teacher 
    adamjose@northsixty.com 
    mind123#
student 
    alliya@logicstreak.com 
    mind123#
Dev Credentials
    yomlomalte@biyac.com
    mind123#
    gitrumirzu@biyac.com

    demo@acinfotech.com
    lms2021@28
*/
    //{email_id: "alexa@lowdh.com", password: "mind123"}
    onSignIn = () => {
        // alert(1)
        RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/users/authenticate', { email_id: this.state.email.value, password: this.state.password.value }, (response) => {
            if (response) {
                if (response.success === true) {
                    /*
                    if(response.user_id == 5){
                        response.user_id = 8;
                    }
                    */
                    response.email_id = this.state.email.value;
                    UserSession.setSession(response)
                    this.props.onLoginSuccess();
                } else {
                    // if (response.success !== true) {
                    //     this.props.onLoginFailed();
                    // }
                }
                // console.log(response);
            }

        }, error => {
            console.log(error)
        })
    }
    verifyAccount = () => {
        this.props.onLoginFailed();
    }
    onInputFieldChange = (param, event) => {
        let stateObj = {};
        let error = false;
        if (event.target.value === '') {
            error = 'This field is requered';
        }
        stateObj[param] = { value: event.target.value, error: error };
        this.setState(stateObj)
    }

    tabSwitch = (tabName) => {
        this.setState({ activeTab: tabName });
    }

    // joinMeet = (link) => {
    //     if(link !== '') {
    //         window.open(`/join/${link}`, 'newWin');
    //         this.setState({meetingId : ''});
    //     }
    // }


    render() {
        return (
            <div className="app-signin">
                {this.state.device !== 'mobile' ? <div>
                    <div class="top-menu-strip d-none d-md-block d-lg-block">
                        <div class="container">
                            <p class="float-right"><a href="mailto:support@canstring.ai">support@canstring.ai</a> | <a href="tel:14086234427">1-408-623-4427</a></p>
                        </div>
                    </div>
                    <section class="section is-header" >
                        <div class="container">
                            <nav class="navbar">
                                <div class="navbar-brand" >
                                    <a href="/">
                                        <img src="./login_files/Meet.svg" width="200" />
                                    </a>
                                </div>

                                <div id="navbar-default" class="navbar-menu">
                                    <div class="navbar-end">

                                        <a href="#whymeet">
                                            {/* <div class="level-item" > */}
                                            Why CanString Meet
                                            {/* </div> */}
                                        </a>

                                        <a href="#Features" >
                                            {/* <div class="level-item" > */}
                                            Features
                                            {/* </div> */}
                                        </a>
                                        <a href="#demo" class="button is-link is-outlined">Sign Up</a>

                                        {/* <div >
                        <a href="https://pingstack.io/app/register">
                            <button class="button" >
                                Start free trial
                            </button>
                        </a>
                    </div> */}
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </section>
                    <div className='product_content'>
                        <section className=''>
                            <div className='container'>
                                <h1 className='product-title'>Affordable Online Meeting for all your Business Constituents</h1>
                            </div>
                        </section>

                        <div className='container'>
                            <div className='columns item'>
                                <div className='column is-6'>
                                    <div className="container">

                                        <div className="progressLoader"></div>
                                        <div className="signin-container columns">
                                            <div className="column is-10 card top-bottom-moderate-box-shadows mobile-loginwrap">
                                                {/* <div className="brand has-text-centered">
                                                <div class="tabs is-centered">
                                                    <ul>
                                                        <li class="" onClick={this.tabSwitch.bind(this, 'organize')} className={this.state.activeTab === 'organize' ? 'is-active' : ''}>
                                                            <a>
                                                                <span>Organize Meeting</span>
                                                            </a>
                                                        </li>
                                                        <li onClick={this.tabSwitch.bind(this, 'join')} className={this.state.activeTab === 'join' ? 'is-active' : ''}>
                                                            <a>
                                                                <span>Join Meeting</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                                <div className="login-form">
                                                    <h3>Sign In here to start or Join meeting</h3>
                                                    <div novalidate="">
                                                        <div className="field"><label className="label">Email</label>
                                                            <div className="control">
                                                                <input className="input" onChange={this.onInputFieldChange.bind(this, 'email')} value={this.state.email.value} formcontrolname="email_id" placeholder="Enter your email" type="email" />
                                                                {/* <span class="icon is-small is-left"><i class="fa fa-envelope"></i></span> */}
                                                                <p className="help is-danger height20">{this.state.email.error}</p>
                                                            </div>
                                                        </div>
                                                        <div className="field"><label className="label">Password</label>
                                                            <div className="control">
                                                                <input className="input" onChange={this.onInputFieldChange.bind(this, 'password')} value={this.state.password.value} formcontrolname="password" placeholder="Enter your password" type="password" />
                                                                {/* <span class="icon is-small is-left"><i class="fa fa-lock"></i></span> */}
                                                                <p className="help is-danger height20">{this.state.password.error}</p>
                                                            </div>
                                                        </div>
                                                        <a className="pri-cus-btn mar-top15" disabled={(this.state.email.error || this.state.password.error) || (!this.state.email.value || !this.state.password.value) ? true : false} onClick={this.onSignIn}>Sign In</a>
                                                        <div className="columns f-sm">
                                                            <div className="column is-6">
                                                                <div className="checkbox"></div>
                                                            </div>
                                                            <div className="column is-6 has-text-right">
                                                                {/* <div className="checkbox "><a href="https://demo.canstrings.com/#/reset_password" target="_blank"> Forgotten your password? </a></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="login-form" className={this.state.activeTab === 'join' ? 'is-active' : 'hide'}>
                                                <div novalidate="" className='mar-top20'>
                                                    <div className="field"><label className="label">Meeting ID</label>
                                                        <div className="control">
                                                            <input className="input" onChange={this.onInputFieldChange.bind(this, 'meetingId')} value={this.state.meetingId.value} placeholder="Enter your Meeting ID" type="text" />
                                                            
                                                            <p className="help is-danger height20">{this.state.meetingId.error}</p>
                                                        </div>
                                                    </div>
                                                    <a className="pri-cus-btn mar-top15" disabled={(this.state.meetingId.error) || (!this.state.meetingId.value) ? true : false} onClick={this.joinMeet.bind(this, this.state.meetingId.value)}>Join</a>
                                                    <div className="columns f-sm">
                                                        <div className="column is-6">
                                                            <div className="checkbox"></div>
                                                        </div>
                                                        <div className="column is-6 has-text-right">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='column is-6'>
                                    <div class="banner">
                                        <a href="#vid" class="video-play"><i class="fa fa-play-circle" aria-hidden="true"></i></a>
                                        <img src="./login_files/VirtualMeeting.png" />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <section id="whymeet" class="main-cs">
                            <div className='container' style={{ paddingTop: '60px' }}>
                                <div className='columns item'>
                                    <div className='column is-6'>
                                        <br />		<br />
                                        <h3>Why CanString Meet ? </h3>
                                        <p class="small-box">CanString Meet brings the power of team working to companies of all sizes, allowing you the ability to schedule online meetings with your employees, customers and vendors. The most affordable solution for online-meetings and team collaborations. Features includes screen sharing, recording, interactive whiteboard, meeting transcript,  real-time caption, and can share documents,  images, audio and video files.</p>

                                    </div>
                                    <div className='column is-6'>
                                        <video id="vid" autoplay="true" loop muted="muted" poster="./login_files/coloboration-Video-poster.png" controls>
                                            <source src="./login_files/CanStringMeet.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id="Features" class="feature">

                            <div class="container container-small" style={{ paddingTop: '90px' }}>
                                <h2>Discover how true Online meeting with your team can transform your productivity</h2>

                                <div class="columns feature-item">

                                    <div class="column is-6" >
                                        <img src="./login_files/icons/OnlineMeet.png" />
                                        <div class="content">
                                            <h4>Online Meeting</h4>
                                            <p>Team Collaboration Platform allows you to collaborate with your team, colleagues, customers and vendors. You can
                                                conduct instant or schedule meetings, join meeting via web and mobile browsers.</p>
                                        </div>

                                    </div>

                                    <div class="column is-6 item">
                                        <img src="./login_files/icons/Smartboard.png" />
                                        <div class="content">
                                            <h4>Whiteboard</h4>
                                            <p>Team collaboration on Whiteboard feature enables to share ideas, online teaching, presentations, conduct design and brain storm session, it supports PPT, PDF, Word & Images and Works with Digital Pen, Touch and Mouse. It allows to record, save, download and share Whiteboard session content</p>
                                        </div>

                                    </div>
                                </div>
                                <div class="columns feature-item">
                                    <div class="column is-6 item">
                                        <img src="./login_files/icons/chat.png" />
                                        <div class="content">
                                            <h4>Screen Sharing and Recording</h4>
                                            <p>Online meeting with Screen Sharing and recording feature option. </p>
                                        </div>
                                    </div>

                                    <div class="column is-6 item">
                                        <img src="./login_files/icons/voice-to-text.png" />
                                        <div class="content">
                                            <h4>Meeting Transcript</h4>
                                            <p>Enable/Disable Real-time Captions and transcript. </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns feature-item">

                                    <div class="column is-6 item">
                                        <img src="./login_files/icons/extractinfo.png" />
                                        <div class="content">
                                            <h4>Extract Information</h4>
                                            <p>Extract information from meeting transcript (optional).</p>
                                        </div>
                                    </div>

                                    <div class="column is-6 item">
                                        <img src="./login_files/icons/chat.png" />
                                        <div class="content">
                                            <h4>Chat</h4>
                                            <p>Real-time collaboration with your colleagues, customers and vendors. </p>
                                        </div>


                                    </div>
                                </div>
                                <div class="columns feature-item">

                                    <div class="column is-6 item">
                                        <img src="./login_files/icons/users.png" />
                                        <div class="content">
                                            <h4>User Management</h4>
                                            <p>Manage user account and group, user access control</p>
                                        </div>
                                    </div>

                                    <div class="column is-6 item">
                                        <img src="./login_files/schedule.png" />
                                        <div class="content">
                                            <h4>Meeting Schedules</h4>
                                            <p>Schedule a meeting for user and group, invite the internal and exteranl users</p>
                                        </div>


                                    </div>
                                </div>
                                <div class="columns feature-item">


                                    <div class="column is-6 item">
                                        <img src="./login_files/icons/mobile.png" />
                                        <div class="content">
                                            <h4>On-The-Go</h4>
                                            <p>Never be out of reach with full mobile access to all of your discussions</p>
                                        </div>


                                    </div>
                                    <div class="column is-6 item">
                                        <img src="./login_files/icons/notification.png" />
                                        <div class="content">
                                            <h4>Notification</h4>
                                            <p>Send notifications to user and groups</p>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </section>

                        <section id="demo" class="main-cs">
                            <div className='container' >
                                <div className='columns' style={{ paddingTop: '90px' }}>
                                    <div className='column is-6'>
                                        <h3>Request A CanString Meet </h3>
                                        <div class="banner">
                                            <img src="./login_files/dept-ban.svg" />
                                        </div>
                                    </div>
                                    <div className='column is-6'>
                                        <div className="container">

                                            <div className="signin-container columns">
                                                <div className="column is-10 card Demo_panel top-bottom-moderate-box-shadows mobile-loginwrap">
                                                    <div className="login-form">
                                                        <div novalidate="">
                                                            <h3>Fill out the form below, we'll schedule a demo for you in 24 hours</h3>
                                                            <h5 class="text-center">We offer 30 day free trail</h5>
                                                            <form id="csDemo">

                                                                <div class="field">
                                                                    <label className="label" for="Name">Name</label>
                                                                    <input id="Name" type="text" class="input" name="Name" required="" />
                                                                </div>

                                                                <div class="field">
                                                                    <label className="label" for="Title">Job Title</label>
                                                                    <input id="Title" type="text" class="input" name="Title" required="" />
                                                                </div>

                                                                <div class="field">
                                                                    <label className="label" for="Email">Work Email</label>
                                                                    <input id="Email" type="email" class="input" name="Email" required="" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" />
                                                                </div>

                                                                <div class="field">
                                                                    <label className="label" for="Company">Company Name</label>
                                                                    <input type="text" class="input" id="Company" name="Company" required="" />
                                                                </div>
                                                                <div class="field">
                                                                    <label className="label" for="Phone">Work Phone Number</label>
                                                                    <input id="Phone" type="text" pattern="[0-9.]+" oninvalid="setCustomValidity('Plz enter Numbers Only ')" onchange="try{setCustomValidity('')}catch(e){}" class="input" name="Phone" required="" />
                                                                </div>

                                                                <div class="field">
                                                                    <label className="label" for="Message">Message</label>
                                                                    <textarea class="input" id="Message" name="Message" required=""></textarea>
                                                                </div>

                                                                <div class="field">
                                                                    {/* <button type="submit" class="btn btn-primary float-right">Submit</button> */}
                                                                    <a class="pri-cus-btn mar-top15">Submit</a>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    <div className="sign-footer">
                        <footer className="">
                            <div className="columns">
                                <div className="column is-6">
                                    <p>All Rights Reserved CanString.AI {(new Date().getFullYear())}</p>
                                </div>
                                <div className="column is-6 has-text-right">
                                    <nav>
                                        <ul className="reset">
                                            <li className="display-ib"><a href="https://demo.canstrings.com/#/privacy" routerlink="/privacy">Privacy &amp; Terms </a></li>
                                            <li className="display-ib"><a href="https://demo.canstrings.com/#/help" routerlink="/help">Help</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </footer>
                    </div> </div> :
                    <div className='mobile-device'>
                        <div className='container-fluid'>
                            <div className="columns">
                                <div className="column">
                                    <div className="login-form">
                                        <a href="/">
                                            <img src="./login_files/Meet.svg" width="200" />
                                        </a>
                                        <div novalidate="">
                                            <div className="field"><label className="label">Email</label>
                                                <div className="control">
                                                    <input className="input" onChange={this.onInputFieldChange.bind(this, 'email')} value={this.state.email.value} formcontrolname="email_id" placeholder="Enter your email" type="email" />
                                                    <p className="help is-danger height20">{this.state.email.error}</p>
                                                </div>
                                            </div>
                                            <div className="field"><label className="label">Password</label>
                                                <div className="control">
                                                    <input className="input" onChange={this.onInputFieldChange.bind(this, 'password')} value={this.state.password.value} formcontrolname="password" placeholder="Enter your password" type="password" />
                                                    <p className="help is-danger height20">{this.state.password.error}</p>
                                                </div>
                                            </div>
                                            <a className="pri-cus-btn mar-top15" disabled={(this.state.email.error || this.state.password.error) || (!this.state.email.value || !this.state.password.value) ? true : false} onClick={this.onSignIn}>Sign In</a>
                                            <div className="columns f-sm">
                                                <div className="column is-6">
                                                    <div className="checkbox"></div>
                                                </div>
                                                <div className="column is-6 has-text-right">
                                                    {/* <div className="checkbox "><a href="https://demo.canstrings.com/#/reset_password" target="_blank"> Forgotten your password? </a></div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sign-footer">
                            <footer className="">
                                <div className="columns">
                                    <div className="column is-12">
                                        <p>All Rights Reserved CanString.AI {(new Date().getFullYear())}</p>
                                    </div>
                                    {/* <div className="column is-6 has-text-right">
                                <nav>
                                    <ul className="reset">
                                        <li className="display-ib"><a href="https://demo.canstrings.com/#/privacy" routerlink="/privacy">Privacy &amp; Terms </a></li>
                                        <li className="display-ib"><a href="https://demo.canstrings.com/#/help" routerlink="/help">Help</a></li>
                                    </ul>
                                </nav>
                            </div> */}
                                </div>
                            </footer>
                        </div>
                    </div>}
            </div>
        )
    }
}
export default Login;
