import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import RequestHandler from '../helpers/request-handler';
import InfiniteScroll from 'react-infinite-scroll-component';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
import UserSession from '../helpers/user-session';
import { defaultConfig } from '../helpers/common-config';
import SocketHandler from '../helpers/socket-service';
class ChatUsers extends Component {
  
  constructor(props) {
    super(props);
    this.state = {chatList:[],userList:{},loggedUser:JSON.parse(localStorage.getItem('userData')),callSupported:true,selectedSort:'time',tooltype:'tool',searchVal:'', activeChat:{},
    pageOffset: defaultConfig.DEFAULT_OFFSET,
    pageLimit: defaultConfig.DEFAULT_SCROLL_RECORD_LIMIT,
    hasMoreItems: true,
totlaRowCount:''}
    SocketHandler.getSocket((socket)=>{
        socket.on('onUserList',(data) => {
            data =  JSON.parse(data);
            let list = {};
            for(let key in data){
                 list[data[key].user_id] = 'available';
            }
            this.setState({userList:list})
        });
    })
    
  }
  
  componentDidMount = ()=> {
    
    this.getCurrentChatUsers('time',false);
    if(CommonFunctions.getBrowser() == 'Edge'){
            this.setState({callSupported:false})
    }
    if(CommonFunctions.getOS() == 'Android'){
        if(CommonFunctions.getBrowser() != 'Chrome'){
                this.setState({callSupported:false})
        }
    }
    if(CommonFunctions.getOS() == 'iOS'){
        if(CommonFunctions.getBrowser() != 'Mobile Safari'){
                this.setState({callSupported:false})
        }
    }
  }
  
  componentWillUnmount = ()=> {
    
  }
  getCurrentChatUsers = (sort,search)=>{
    // const userDate = JSON.parse(localStorage.getItem('userData'));
    RequestHandler.get(Config.API_DOMAIN+'/canstring/v1/chats?offset='+defaultConfig.DEFAULT_OFFSET+'&limit='+this.state.pageLimit+'&sort='+sort+(search?'&search='+search:''),(response)=>{
            if(response.success === true){
                this.setState({chatList:response.chatlist, TotalRowCount: response.TotalRowCount});
            }else{
                console.log(response.message);
            } 
    })
  }

  getMoreChats = ()=>{
    //   console.log(sort, parem)
    // const userDate = JSON.parse(localStorage.getItem('userData'));
    RequestHandler.get(`${Config.API_DOMAIN}/canstring/v1/chats?offset=${this.state.pageOffset + 1}&limit=${this.state.pageLimit}&sort=${'time'}`,(response)=>{
            if(response.success === true){
                this.setState({
                    chatList: [...this.state.chatList, ...response.chatlist]
                  });
                // this.setState({chatList:response.chatlist});
            }else{
                console.log(response.message);
            } 
    })
  }
  onNewChat = ()=>{
      this.props.onNewChat();
  }
  removeChat = (user) => {
    RequestHandler.plainput(Config.API_DOMAIN + '/canstring/v1/chats/removeChat/'+user.chat_id, (response) => {                
        if(response.success) {
            this.getCurrentChatUsers(this.state.selectedSort,'');
        }
})
  }
  createNewRoom = () => {
      this.props.createNewRoom();
  }
  onSelectChatUser = (user)=>{
      this.setState({activeChat:{...user}})
      this.props.onSelectChatUser(user)
  }
  onFilterChange = (event)=>{
    this.setState({selectedSort:event.target.value})
    this.getCurrentChatUsers(event.target.value,false);
  }
  onChangeToolType = (tooltype) =>{
      this.setState({tooltype,searchVal:''})
      this.getCurrentChatUsers(this.state.selectedSort,false);
  }
  onChangeSearch = (event) =>{
      this.setState({searchVal:event.target.value})
      this.getCurrentChatUsers(this.state.selectedSort,event.target.value);
  }
  render() {
    return (
        <div className="col se-p is-chat right-box-shadow chat-list">
            <div className={this.state.tooltype == 'tool' ? "menu-options level is-marginless display-flex":"hide"}>
                <div className="level-left">
                    <div className="level-item">
                        <div className="select is-small">
                            <select value={this.state.selectedSort} onChange={this.onFilterChange.bind(this)}>
                            <option value="time">Sort by Recent</option>
                            <option value="name">Sort A to Z</option>                      
                            </select>
                        </div>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        <Link to="/createroom" onClick={this.props.viewCreate} className={this.state.callSupported && this.state.loggedUser.user_role === 1 ? "button is-small":"hide"} title="Register New User And Group">
                            <i className="fa fa-user"></i>
                        </Link>
                        <a onClick={this.onChangeToolType.bind(this,'search')} className="button is-small" title="Search"><i className="fa fa-search"></i></a>
                        <a onClick={this.onNewChat} className="is-small button i-want-highlight-this" id="createChat" title="Create New Chat"><i className="fa fa-plus-circle"></i></a>
                    {/* <a onClick={this.createNewRoom} className={this.state.callSupported && this.state.loggedUser.user_role === 1 ? "is-small button i-want-highlight-this":"hide"} id="createChat" title="Create Instant Meeting"><i className="fa fa-video-camera"></i></a> */}
                    </div>
                </div>
            </div>

            <div class={this.state.tooltype == 'search' ? "menu-options level is-marginless searchBox-pad display-flex":"hide"}>
                <div class="level-left">
                    <div class="level-item input-search-box">
                        <div class="field">
                            <p class="control has-icons-left has-icons-right"><input class="input ng-pristine ng-valid ng-touched" onChange={this.onChangeSearch.bind(this)} value={this.state.searchVal} inputfocus="" placeholder="Search here..." type="text" ng-reflect-model=""/><a class="icon is-small is-left" title="Search"><i class="fa fa-search"></i></a><span class="input-bar"></span></p>
                        </div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item"><span onClick={this.onChangeToolType.bind(this,'tool')} class="icon is-small is-right search-return"><i class="fa fa-times-circle"></i></span></div>
                </div>
            </div>
            <div id='scrollableDiv' className="list-wrapper scroll-bar list-overflow" 
//             style={{
//     height: 300,
//     overflow: 'auto',
//     display: 'flex',
//   }}
  >
            {/* <InfiniteScroll
        pageStart={0}
        loadMore={() => this.getMoreChats.bind(this,'time', this.state.pageOffset + 1)}
        hasMore={this.state.hasMoreItems}
        useWindow={false}
        getScrollParent={() => this.scrollParentRef}
        loader={
          <div key="loading" className="loader">
            Loading ...
          </div>
        }
      >
          </InfiniteScroll> */}

         {this.state.TotalRowCount !== '' ? <InfiniteScroll
    dataLength={26}
    next={this.getMoreChats}
    // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
    inverse={false} //
    hasMore={this.state.hasMoreItems}
    // loader={<h4>Loading...</h4>}
    scrollableTarget="scrollableDiv"
  >
            <ul>
            {
                this.state.chatList.map((user,index)=>{
                    if (user.chat_type === 'user') {
                    return(
                        <li key={index} className={this.state.activeChat.user_id === user.user_id ? 'cursor-pointer pos-relative chat-list active': 'cursor-pointer pos-relative chat-list'}>
                            <div onClick={this.onSelectChatUser.bind(this,user)} className="columns is-gapless active display-flex" tabIndex="0">
                                <div className="column is-one-quarter">
                                <div title="Available" className="display-ib">
                                    <div className="empty-img">{user.full_name.charAt(0).toUpperCase()}</div>
                                </div>
                                <span className="icon is-small"><i id={user.user_id+'stat'} className="onlineico fa fa-circle circle-gray"></i></span>
                                </div>
                                <div className="column is-clearfix pos-relative">
                                <div className="is-pulled-left"><label className="is-size-6"><span className="list-active" title={user.full_name}>{user.full_name}</span></label></div>
                                <div className="">
                                <span><label className="chat-msg-text" dangerouslySetInnerHTML={{__html: user.last_message}}></label></span>
                                </div>
                                <span className={user.is_online === false ? 'is-size-7 text-clr-grey time-ago' : 'hide'}>{CommonFunctions.getAgo(user.last_seen)} ago</span>
                                </div>
                            </div>
                            <div className="chat-close"><i className="fa fa-times" onClick={this.removeChat.bind(this, user)} title="Remove Chat"></i></div>
                        </li>
                        )
                    } else if (user.chat_type === 'group') {
                        if(user.group_active === true) {
                        return(
                            <li key={index} onClick={this.onSelectChatUser.bind(this,user)} className={this.state.activeChat.group_id === user.group_id ? 'cursor-pointer pos-relative chat-list active': 'cursor-pointer pos-relative chat-list'}>
                                <div className="columns is-gapless active display-flex" tabIndex="0">
                                    <div className="column is-one-quarter">
                                    <div title="Available" className="display-ib">
                                        <div className="empty-img">{user.full_name.charAt(0).toUpperCase()}</div>
                                    </div>
                                    <span className="icon is-small"><i id={user.user_id+'stat'} className="onlineico fa fa-circle circle-gray"></i></span>
                                    {/* <span className="icon is-small"><i className={this.state.userList[user.user_id] ? "fa fa-circle circle-green":"fa fa-circle circle-gray"}></i></span> */}
                                    </div>
                                    <div className="column is-clearfix pos-relative">
                                    <div className="is-pulled-left"><label className="is-size-6"><span className="list-active" title={user.full_name}>{user.full_name}</span></label></div>
                                    <div className='group-identify'>
                                        <i className='fa fa-users'></i>
                                    </div>
                                    <div className="">
                                    <span><label className="chat-msg-text" dangerouslySetInnerHTML={{__html: user.last_message}}></label></span>
                                    </div>
                                    {/* <span className="is-size-7 text-clr-grey time-ago">{CommonFunctions.getAgo(user.last_seen)} ago</span> */}
                                    </div>
                                </div>
                                {/* <div className="chat-close"><i className="fa fa-times" title="Remove Chat"></i></div> */}
                            </li>
                            )
                        }
                    }
                    })
                }
            </ul>
            </InfiniteScroll> : null }
            
            </div>
        </div>
      )
    
  }
}
export default ChatUsers;
