import {isMobileSafari,isChrome,isFirefox,isSafari,isOpera,isEdge} from "react-device-detect";
import Config from "../config";
import RequestHandler from "./request-handler";
var CommonFunctions = (function() {
    var getAgo = (date) => {
        date = new Date(date)
        var seconds = Math.floor((new Date() - date) / 1000);
    
        var interval = Math.floor(seconds / 31536000);
    
        if (interval > 1) {
        return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
        return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
        return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
        return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
        return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    };
    var getTime12Hour = (d) =>{
        const date = new Date(d)
        const options = {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        };
        return new Intl.DateTimeFormat('en-US', options).format(date)
    }
    var getScreenCapture = ()=>{
        if (navigator.getDisplayMedia) {
            return navigator.getDisplayMedia({video: true,audio:true});
          } else if (navigator.mediaDevices.getDisplayMedia) {
            return navigator.mediaDevices.getDisplayMedia({video: true,audio:true});
          } else {
            return navigator.mediaDevices.getUserMedia({video: {mediaSource: 'screen'},audio:true});
          }
    }
    var generateMediaServerRoom = (callback) =>{
      let body = {name:"room"};
      let entity = 'https://'+Config.DOMAIN+'/createRoom';
      var req = new XMLHttpRequest();
      req.onreadystatechange = function() {
          if (req.readyState === 4) {
              let resp = JSON.parse(req.responseText)
              callback(resp._id);
          }
      };
      req.open('POST', entity, true);
      req.setRequestHeader('Content-Type', 'application/json');
      if (body !== undefined) {
          req.send(JSON.stringify(body));
      } else {
          req.send();
      }
      
    }
    var generateUID = ()=>{
       return (Math.floor(Math.random() * 100000000000) + 100000000000).toString().substring(1);
    }
    var getOS = () => {
      var userAgent = window.navigator.userAgent,
          platform = window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'],
          os = null;
    
      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }
    
      return os;
    }
    var getBrowser = () => { 
        if(isMobileSafari){
          return('Mobile Safari')
        }
        if(isChrome){
          return('Chrome')
        }
        if(isFirefox){
          return('Firefox')
        }
        if(isFirefox){
          return('Firefox')
        }
        if(isSafari){
          return('Safari')
        }
        if(isOpera){
          return('Opera')
        }
        if(isEdge){
          return('Edge')
        }
        return('unknown');
     }
     var setItem = (key, value, ttl) => {
      const now = new Date()
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    }
    var getItem = (key) => {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    }
    return {
        getAgo: getAgo,
        getTime12Hour:getTime12Hour,
        getScreenCapture:getScreenCapture,
        generateUID:generateUID,
        getOS:getOS,
        getBrowser:getBrowser,
        generateMediaServerRoom:generateMediaServerRoom,
        setItem:setItem,
        getItem:getItem
    }
  
  })();
 export default CommonFunctions;