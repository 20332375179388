import React, { Component } from 'react';
import UserSession from '../helpers/user-session';
import Config from '../config';
class Thankyou extends Component {
  
  constructor(props) {
    super(props);
    let currentUserSession =  UserSession.getSession();
    if(!currentUserSession.device) {
        currentUserSession.device = '';
       }
    this.state = {userData: {...currentUserSession}};
  }

  gotoHome = () => {
    window.location = `https://${Config.SITE_URL}`;
  }

  componentDidMount = ()=> {
    setTimeout(() => {
        // alert('123redirect 2')
        window.location.href = '/';
    }, 4000);
  }
  componentWillUnmount = ()=> {
    
  }
  render() {
    if(this.state.userData.device === 'mobile') {
        setTimeout(() => {
            return(
                window.location = `https://${Config.SITE_URL}`
            )
        }, 2000);        
    }
    return (
        <div className="app-signin">
                <div className="container">
                    <div className="progressLoader"></div>
                    <div className="signin-container columns">
                        <div className="column is-offset-4 is-4 card top-bottom-moderate-box-shadows mobile-loginwrap">
                            <div className="brand has-text-centered"><img height="50px" width="" src="./login_files/login-logo.png"/>
                                <p><h2>Thank you</h2></p>
                                <a href='#' className={this.state.userData.device === 'mobile' ? 'button is-small is-link mar-0-10 ':'hide'} onClick={this.gotoHome}>
                                                <span>Go to Home</span>
                                            </a>
                            </div>
                            
                            </div>
                    </div>
                </div>
                <div className="sign-footer">
                    <footer className="">
                        <div className="columns">
                            <div className="column is-6">
                                <p>All Rights Reserved CanString.AI</p>
                            </div>
                            <div className="column is-6 has-text-right">
                                <nav>
                                    <ul className="reset">
                                        <li className="display-ib"><a href="https://demo.canstrings.com/#/privacy" routerlink="/privacy">Privacy &amp; Terms </a></li>
                                        <li className="display-ib"><a href="https://demo.canstrings.com/#/help" routerlink="/help">Help</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
       )
  }
}
export default Thankyou;
