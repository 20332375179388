import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RequestHandler from '../helpers/request-handler';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
import UserSession from '../helpers/user-session';
import SocketHandler from '../helpers/socket-service';
import CreateUserForm from './CreateUserForm';
import CreateGroupForm from './createGroup';
import { toast } from 'react-toastify';

class CreateUsers extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            chatList: [],
            groupList: [], 
            userList: {}, 
            callSupported: true, 
            selectedSort: 'time', 
            tooltype: 'tool', 
            searchVal: '',
            groupView: false ,
            viewNewUserForm: false,
            commonView: false,
            viewGroupForm: false,
            updateUserView: false,
            selectedUser:{}
        }
        SocketHandler.getSocket((socket) => {
            socket.on('onUserList', (data) => {
                data = JSON.parse(data);
                let list = {};
                for (let key in data) {
                    list[data[key].user_id] = 'available';
                }
                this.setState({ userList: list })
            });
        })

    }

    componentDidMount = () => {
        this.getCurrentChatUsers('time', false);
        if (CommonFunctions.getBrowser() == 'Edge') {
            this.setState({ callSupported: false })
        }
        if (CommonFunctions.getOS() == 'Android') {
            if (CommonFunctions.getBrowser() != 'Chrome') {
                this.setState({ callSupported: false })
            }
        }
        if (CommonFunctions.getOS() == 'iOS') {
            if (CommonFunctions.getBrowser() != 'Mobile Safari') {
                this.setState({ callSupported: false })
            }
        }
    }

    componentWillUnmount = () => {

    }
    getCurrentChatUsers = (sort, search) => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/users?offset=0&limit=25&sort=' + sort + (search ? '&search=' + search : ''), (response) => {
            if (response.success === true) {
                this.setState({ chatList: response.userList })
            } else {
                console.log(response.message);
            }
        })
    }

    showUsers = (sort, search) => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/users?offset=0&limit=25&sort=' + sort + (search ? '&search=' + search : ''), (response) => {
            if (response.success === true) {
                this.setState({groupView: false, commonView: false,viewGroupForm: false, viewNewUserForm: false})
                this.setState({ chatList: response.userList })
                this.refs.customUserReference.hideUserForm();
            } else {
                console.log(response.message);
            }
        })
    } 

    sendEmail = () => {
        const userIds = [];
        for (let index = 0; index < this.state.chatList.length; index++) {
            userIds.push(this.state.chatList[index]['user_id']);
        }

        const payLoad = {
            include_all: true,
            excludeAddress: [],
            domain: '',
            activeUserIds : userIds
        }; 
        RequestHandler.put(Config.API_DOMAIN + '/canstring/v1/sendEmailToAllUser', payLoad, (response) => {
            if (response !== undefined) {
                toast.dark(response.message, {autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,});
            }
         });
    }

    updateUserList = () => {
        // this.showUsers('time', false);
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/users?offset=0&limit=25&sort=time&search=', (response) => {
            if (response.success === true) {
                // this.setState({groupView: false, commonView: false,viewGroupForm: false, viewNewUserForm: false})
                this.setState({ chatList: response.userList })
                // this.refs.customUserReference.hideUserForm();
            } else {
                console.log(response.message);
            }
        })
    }

    updateGroupList = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/classList', (response) => {
            if (response.success === true) {
                // this.setState({groupView: true, commonView: false, viewNewUserForm: false, selectedUser:{}})
                this.setState({ groupList: response.chatList })
            } else {
                console.log(response.message);
            }
        })
    }

    // groupList = (sort, search) => {
    //     RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/groups?offset=0&limit=25&sort=' + sort + (search ? '&search=' + search : ''), (response) => {
    //         if (response.success === true) {
    //             // this.setState({ chatList: response.userList })
    //         } else {
    //             console.log(response.message);
    //         }
    //     })
    // }

    groupList = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/classList', (response) => {
            if (response.success === true) {
                this.setState({groupView: true, commonView: false, viewNewUserForm: false, selectedUser:{}});
                this.refs.customGroupReference.hideUserForm();
                this.setState({ groupList: response.chatList })
            } else {
                this.setState({groupView: true, commonView: false, viewNewUserForm: false, selectedUser:{}})
                // console.log(response.message);
            }
        })
    }

    backtoHome = () => {
        this.props.openViewChat()
    }
    onNewChat = () => {
        this.props.onNewChat();
    }
    createNewUser = () => {
        // this.props.createNewUser();
        this.setState({viewNewUserForm: true, commonView: true})
        this.refs.customUserReference.getDefault();
    }

    createNewGroup = () => {
        this.setState({viewGroupForm: true, commonView: true});
        this.refs.customGroupReference.getactiveUserList();
    }
    hideForm = () => {
        this.setState({viewNewUserForm: false, commonView: false, selectedUser:{},viewGroupForm: false})
    }
    createNewRoom = () => {
        this.props.createNewRoom();
    }
    onSelectChatUser = (user) => {
        this.setState({viewNewUserForm: true, commonView: true, selectedUser:{...user}})
        this.refs.customUserReference.onUserselect(user);
    }
    onSelectGroup = (group) => {
        this.setState({viewGroupForm: true, commonView: true, selectedUser:{...group}})
        this.refs.customGroupReference.onGroupselect(group);
    }
    onFilterChange = (event) => {
        this.setState({ selectedSort: event.target.value })
        if(!this.state.groupView) {
            this.getCurrentChatUsers(event.target.value, false);
        } else {
            this.groupSearchList(event.target.value, false)
        }
    }
    onChangeToolType = (tooltype) => {
        this.setState({ tooltype, searchVal: '' })
        if(!this.state.groupView) {
            this.getCurrentChatUsers(this.state.selectedSort, false);
        } else {
            this.groupSearchList(this.state.selectedSort, '')
        }
    }
    onChangeSearch = (event) => {
        this.setState({ searchVal: event.target.value })
        if(!this.state.groupView) {
            this.getCurrentChatUsers(this.state.selectedSort, event.target.value);
        } else {
            this.groupSearchList(this.state.selectedSort, event.target.value)
        }
    }

    groupSearchList = (sort, search) => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/classList?offset=0&limit=25&sort='+sort+'&search='+search, (response) => {
            if (response.success === true) {
                this.setState({groupView: true, commonView: false, viewNewUserForm: false, selectedUser:{}})
                this.setState({ groupList: response.result })
            } else {
                this.setState({groupView: true, commonView: false, viewNewUserForm: false, selectedUser:{}})
                // console.log(response.message);
            }
        })
    }
    render() {
        return (
            <div className='width100 flex-grid'>
                <div className="col se-p is-chat right-box-shadow chat-list second-level-menu">
                    <div class=" tabs is-centered is-marginless is-small is-mobile display-flex">
                    <Link to="/" title="Back to Home" onClick={this.backtoHome}>
                    <i class="fa fa-arrow-circle-left backto" aria-hidden="true"></i>
                    </Link>
                        <ul>
                            <li routerlinkactive="is-active" className={this.state.groupView ? 'pad' : 'is-active'} >
                                <a class="is-semibold" onClick={this.showUsers.bind(this, 'time', false)}><i className='fa fa-plus is-small'></i> User</a>
                            </li>
                            <li routerlinkactive="is-active" className={!this.state.groupView ? 'pad' : 'is-active'}>
                                <a class="is-semibold" onClick={this.groupList}><i className='fa fa-plus is-small'></i> Group</a>
                            </li>
                        </ul>
                    </div>
                    <div className={this.state.tooltype == 'tool' ? "menu-options level is-marginless display-flex" : "hide"}>
                        <div className="level-left">
                            <div className="level-item">
                                <div className="select is-small">
                                    <select value={this.state.selectedSort} onChange={this.onFilterChange.bind(this)}>
                                        <option value="time">Sort by Recent</option>
                                        <option value="name">Sort A to Z</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <a onClick={this.onChangeToolType.bind(this, 'search')} className="button is-small"><i className="fa fa-search"></i></a>
                                <a className={!this.state.groupView ? "is-small button i-want-highlight-this" : 'hide'} onClick={this.sendEmail} id="createChat" title="Send email to all In-active users"><i className="fa fa-envelope"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class={this.state.tooltype == 'search' ? "menu-options level is-marginless searchBox-pad display-flex" : "hide"}>
                        <div class="level-left">
                            <div class="level-item input-search-box">
                                <div class="field">
                                    <p class="control has-icons-left has-icons-right"><input class="input ng-pristine ng-valid ng-touched" onChange={this.onChangeSearch.bind(this)} value={this.state.searchVal} inputfocus="" placeholder="Search here..." type="text" ng-reflect-model="" /><a class="icon is-small is-left" title="Search"><i class="fa fa-search"></i></a><span class="input-bar"></span></p>
                                </div>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item"><span onClick={this.onChangeToolType.bind(this, 'tool')} class="icon is-small is-right search-return"><i class="fa fa-times-circle"></i></span></div>
                        </div>
                    </div>
                    <div appinfinitescroll="" className="list-wrapper scroll-bar list-overflow">
                    {!this.state.groupView ? <ul>
                            {
                                this.state.chatList.map((user, index) => {
                                    return (
                                        <li key={index} onClick={this.onSelectChatUser.bind(this, user)} className={this.state.selectedUser.user_id === user.user_id ? 'cursor-pointer pos-relative chat-list user-status active': 'cursor-pointer pos-relative chat-list user-status'}>
                                            <div className="columns is-gapless active display-flex" tabIndex="0">
                                                <div className="column is-one-quarter">
                                                    <div title="Available" className="display-ib">
                                                        <div className="empty-img">{user.first_name.charAt(0).toUpperCase()}</div>
                                                    </div>
                                                    {/* <span className="icon is-small"><i className={this.state.userList[user.user_id] ? "fa fa-circle circle-green" : "fa fa-circle circle-gray"}></i></span> */}
                                                </div>
                                                <div className="column is-clearfix pos-relative">
                                                    <div className="is-pulled-left"><label className="is-size-6"><span className="list-active" title="">{user.first_name}</span></label></div>
                                                    {/* <div className="">
                                                        <span><label className="chat-msg-text" dangerouslySetInnerHTML={{ __html: user.last_message }}></label></span>
                                                    </div> */}
                                                    <div className="is-pulled-right">
                                                        <span className="is-size-7 text-clr-grey timeAgo">{CommonFunctions.getAgo(user.createdAt)} ago</span>
                                                        <i class="" title="User status is Active" className={user.acc_active ? 'fa fa-stop has-text-success': 'hide'}></i>
                                                        <i class="" title="User status is Deactive" className={!user.acc_active ? 'fa fa-stop has-text-danger': 'hide'}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="chat-close"><i className="fa fa-times" title="Remove Chat"></i></div> */}
                                        </li>
                                    )
                                })
                            }
                        </ul> : null}
                        {this.state.groupView ? <ul>
                            {
                                this.state.groupList.map((group, index) => {
                                    return (
                                        <li key={index} onClick={this.onSelectGroup.bind(this, group)} className={this.state.selectedUser.group_id === group.group_id ? 'cursor-pointer pos-relative chat-list active': 'cursor-pointer pos-relative chat-list'}>
                                            <div className="columns is-gapless active display-flex" tabIndex="0">
                                                <div className="column is-one-quarter">
                                                    <div title="Available" className="display-ib">
                                                        <div className="empty-img">{group.full_name.charAt(0).toUpperCase()}</div>
                                                    </div>
                                                    {/* <span className="icon is-small"><i className={this.state.userList[group.user_id] ? "fa fa-circle circle-green" : "fa fa-circle circle-gray"}></i></span> */}
                                                </div>
                                                <div className="column is-clearfix">
                                                    {/* <div className="is-pulled-left"><label className="is-size-6"><span className="list-active" title="">{group.group_name}</span></label></div> */}
                                                    <p className='is-size-6'>{group.full_name}</p>
                                                    <div className='is-pulled-right'>
                                                        <span class="is-size-7 pos-relative">
                                                        {group.TotalUserCount} <i className='fa fa-users mar-t-2 mar-l-2'></i>
                                                        </span>
                                                    </div>
                                                    {/* <div className="">
                                                        <span><label className="chat-msg-text" dangerouslySetInnerHTML={{ __html: group.last_message }}></label></span>
                                                    </div> */}
                                                    {/* <span className="is-size-7 text-clr-grey time-ago">{CommonFunctions.getAgo(group.createdAt)} ago</span> */}
                                                </div>
                                            </div>
                                            {/* <div className="chat-close"><i className="fa fa-times" title="Remove Chat"></i></div> */}
                                        </li>
                                    )
                                })
                            }
                        </ul> : null}
                    </div>
                </div>
                <CreateUserForm getUserList={this.updateUserList} cancelform={this.hideForm} ref="customUserReference" viewForm={this.state.viewNewUserForm}  className={this.state.viewNewUserForm === true ? '': 'hide'} />
                <CreateGroupForm getGroupList={this.updateGroupList} cancelform={this.hideForm} ref="customGroupReference" viewForm={this.state.viewGroupForm}  className={this.state.viewGroupForm === true ? '': 'hide'} />
                {!this.state.commonView ? <div className='col th-p notification-content-wrapper'>
                        <div class="has-text-centered box">
                            <img src="/files/chat_empty.png"/>
                            <p>So nice to meet you, Stay tuned for exciting events heading YOUR way!</p>
                            
                            <a onClick={this.createNewUser} className={!this.state.groupView ? 'button is-link mar-top15 newmeeting':'hide'}><span class="icon"><i class="fa fa-plus"></i></span><span>Create New User</span></a>
                            <a onClick={this.createNewGroup} className={this.state.groupView ? 'button is-link mar-top15 newmeeting':'hide'}><span class="icon"><i class="fa fa-plus"></i></span><span>Create New Group</span></a>
                            {/* <a onClick={this.onNewChat} class="button is-link mar-top15"><span class="icon"><i class="fa fa-plus"></i></span><span> New Conversation</span></a> */}
                        </div>
                      </div> : null}
            </div>
        )

    }
}
export default CreateUsers;
