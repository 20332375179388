import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import PasswordStrengthBar from 'react-password-strength-bar'
import RequestHandler from '../helpers/request-handler';
import Config from '../config';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import "./Form.css";

class CreateUserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            roll_number: '',
            user_role: '',
            phone_no: null,
            reg_number: '',
            email_id: '',
            password: '',
            aadhar_number: '',
            user_address: '',
            user_city: '',
            pan_number: '',
            user_state: '',
            user_zip: null,
            emis_number: '',
            gender: '',
            class_group: '',
            other_ids: '',
            user_notes: '',
            user_id: '',
            user_icon: '',
            classList: [],
            roleList: [],
            userDetails: {},
            updateFormView: false,
            acc_active: null,
            passFormView: false,
            confirm_pass: '',
            new_pass: '',
            deleteModal: false
        };
    }

    componentWillMount = () => {
        this.validator = new SimpleReactValidator();
    }

    componentDidMount = () => {

    }

    getDefault = () => {
        this.getClassRoomList();
        this.getDefaultLookup();
    }

    getClassRoomList = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/classList', (response) => {
            if (response.success === true) {
                this.setState({ classList: response.chatList });
            } else {
                console.log(response.message);
            }
        })
    }

    getDefaultLookup = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/users/defaultLookup', (response) => {
            if (response.success === true) {
                this.setState({ roleList: response.roleList })
            } else {
                console.log(response.message);
            }
        });
    };

    onUserselect = (user) => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/users/' + user.user_id, (response) => {
            if (response.success === true) {
                this.setState({ roleList: response.roleList, userDetails: response.userDetail, updateFormView: true })
                this.setState(this.setFormValue());
                this.setState({ passFormView: false });
            } else {
                console.log(response.message);
            }
        });
    }

    activeAndDeeactive = () => {
        RequestHandler.patch(Config.API_DOMAIN + '/canstring/v1/users/' + this.state.userDetails.user_id, (response) => {
            if (response.success === true) {
                const userObj = { ...this.state.userDetails }
                userObj.acc_active = response.acc_active
                this.setState({ userDetails: userObj })
                this.handleUserList();
                // this.setState(this.setFormValue());
                toast.dark(response.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                console.log(response.message);
            }
        });
    }

    deleteModal = () => {
        this.setState({ deleteModal: true });
    }

    deleteCancelModal = () => {
        this.setState({ deleteModal: false });
    }

    deleteUser = () => {
        RequestHandler.deleteAPI(Config.API_DOMAIN + '/canstring/v1/users/' + this.state.userDetails.user_id, (response) => {
            if (response.success === true) {
                const userObj = { ...this.state.userDetails }
                userObj.acc_active = response.acc_active
                this.setState({ userDetails: userObj, deleteModal: false });
                this.handleUserList();
                this.hideUserForm();
                // this.setState(this.setFormValue());
                toast.dark('User deleted successfully', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                console.log(response.message);
            }
        });
    }

    handleChange = (param, event) => {
        let stateObj = {};
        stateObj[param] = event.target.value;
        this.setState(stateObj);
    };

    handleUserList = () => {
        this.props.getUserList();
    }

    hideUserForm = () => {
        this.setState({ userDetails: {}, acc_active: null, updateFormView: false });
        this.setState(this.getClearUser());
        this.validator.hideMessages();
        this.props.cancelform();
    }

    getClearUser = () => {
        return {
            first_name: '',
            last_name: '',
            roll_number: '',
            user_role: '',
            phone_no: '',
            reg_number: '',
            email_id: '',
            password: '',
            aadhar_number: '',
            user_address: '',
            user_city: '',
            pan_number: '',
            user_state: '',
            user_zip: '',
            emis_number: '',
            gender: '',
            class_group: '',
            other_ids: '',
            user_notes: '',
            user_id: '',
            user_icon: '',
            acc_active: null
        }
    }

    setFormValue = () => {
        return {
            first_name: this.state.userDetails.first_name,
            last_name: this.state.userDetails.last_name,
            roll_number: this.state.userDetails.roll_number,
            user_role: this.state.userDetails.user_role,
            phone_no: this.state.userDetails.phone_no,
            reg_number: this.state.userDetails.reg_number,
            email_id: this.state.userDetails.email_id,
            password: 'mind123#',
            aadhar_number: this.state.userDetails.aadhar_number,
            user_address: this.state.userDetails.user_address,
            user_city: this.state.userDetails.user_city,
            pan_number: this.state.userDetails.pan_number,
            user_state: this.state.userDetails.user_state,
            user_zip: this.state.userDetails.user_zip,
            emis_number: this.state.userDetails.emis_number,
            gender: this.state.userDetails.gender,
            class_group: this.state.userDetails.class_group,
            other_ids: this.state.userDetails.other_ids,
            user_notes: this.state.userDetails.user_notes,
            user_id: this.state.userDetails.user_id,
            user_icon: this.state.userDetails.user_icon,
            acc_active: this.state.userDetails.acc_active
        }
    }

    createNewUser = () => {
        if (this.validator.allValid()) {
            const payload = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                roll_number: this.state.roll_number,
                user_role: this.state.user_role,
                phone_no: this.state.phone_no,
                reg_number: this.state.reg_number,
                email_id: this.state.email_id,
                password: this.state.password,
                aadhar_number: this.state.aadhar_number,
                user_address: this.state.user_address,
                user_city: this.state.user_city,
                pan_number: this.state.pan_number,
                user_state: this.state.user_state,
                user_zip: this.state.user_zip,
                emis_number: this.state.emis_number,
                gender: this.state.gender,
                class_group: this.state.class_group,
                other_ids: this.state.other_ids,
                user_notes: this.state.user_notes,
                user_id: this.state.user_id,
                user_icon: this.state.user_icon
            }
            // console.log('payload', payload)
            RequestHandler.post(Config.API_DOMAIN + '/canstring/v1/users', payload, (response) => {
                if (response.success) {
                    this.handleUserList();
                    this.setState(this.getClearUser());

                    toast.dark(response.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        } else {
            this.validator.showMessages();
            // toast.dark('Fill the Mandatory fields', {autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,});
            this.forceUpdate();
        }
    }

    updateUser = () => {
        if (this.validator.allValid()) {
            const payload = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                roll_number: this.state.roll_number,
                user_role: this.state.user_role,
                phone_no: this.state.phone_no,
                reg_number: this.state.reg_number,
                email_id: this.state.email_id,
                aadhar_number: this.state.aadhar_number,
                user_address: this.state.user_address,
                user_city: this.state.user_city,
                pan_number: this.state.pan_number,
                user_state: this.state.user_state,
                user_zip: this.state.user_zip,
                emis_number: this.state.emis_number,
                gender: this.state.gender,
                other_ids: this.state.other_ids,
                user_notes: this.state.user_notes,
                user_id: this.state.user_id,
                groupIdList: []
            }
            // console.log('payload', payload)
            RequestHandler.put(Config.API_DOMAIN + '/canstring/v1/users/' + payload.user_id, payload, (response) => {
                if (response.success) {
                    this.handleUserList();
                    // this.setState(this.getClearUser());
                    toast.dark('User details updated successfully', {
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (!response.success) {
                    toast.dark("Sorry,You can't update the deactive/disabled User.", {
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            })
        } else {
            console.log(this.validator)
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    changePass = () => {
        this.setState({ passFormView: true });
    }

    updatePassword = () => {
        if (this.validator.allValid()) {
            const payload = {
                confirm_password: this.state.confirm_pass,
                new_password: this.state.new_pass,
                user_id: this.state.userDetails.user_id
            }
            RequestHandler.patchpayload(Config.API_DOMAIN + '/canstring/v1/users/userPassword', payload, (response) => {
                this.setState({ passFormView: false, new_pass: '', confirm_pass: '' });
                toast.dark('Password updated successfully', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    cancelReset = () => {
        this.validator.hideMessages();
        this.setState({ passFormView: false, new_pass: '', confirm_pass: '' })
    }

    render() {
        return (
            <div className={this.props.viewForm ? 'col th-p notification-content-wrapper' : 'hide'}>


                <header class="top-box-shadow">
                    <div class="columns is-gapless is-vcentered is-mobile">
                        {this.state.updateFormView ? <div className='column'><div class="is-pulled-left">
                            <div className='empty-head-icon display-ib'>
                                {this.state.userDetails.first_name.charAt(0).toUpperCase()}
                            </div>
                            <span className='is-size-6 has-text-weight-semibold'>{this.state.userDetails.first_name}</span>

                        </div>
                            <div class="is-pulled-right userandgroupDetails" >

                                <a className={!this.state.userDetails.acc_active ? 'hide' : 'button is-small'} onClick={this.activeAndDeeactive}>
                                    <i class="fa fa-ban has-text-danger " aria-hidden="true"></i> Deactive User
              </a>

                                <a className={this.state.userDetails.acc_active ? 'hide' : 'button is-small'} onClick={this.activeAndDeeactive}>
                                    <i class="fa fa-check has-text-success" aria-hidden="true"></i> Active User
                </a>

                                <a class="button is-small " onClick={this.deleteModal}>
                                    <i class="fa fa-trash-o " aria-hidden="true"></i> Delete User
              </a>

                                <a class="button is-small " onClick={this.changePass}>
                                    <i class="fa fa-lock " aria-hidden="true" ></i> Change Password
              </a>

                            </div>

                        </div> : null}

                        {!this.state.updateFormView ? <div class="is-pulled-left">
                            <span className='is-size-6 has-text-weight-semibold'>User Creation</span>
                        </div> : null}
                    </div>
                </header>
                { this.state.updateFormView ?
                    <div className="contents is-marginless">
                        <div className="scroll-bar">
                            <div className="column">
                                <div className="columns">


                                    <div className={this.state.passFormView ? 'hide' : 'column is-8 is-offset-2'}>
                                        <div className='card'>
                                            <div className='card-content'>
                                                <div className='content form-style'>
                                                    <h3>User Details</h3>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">FirstName <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.first_name}
                                                                        onChange={this.handleChange.bind(this, "first_name")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className="help is-danger height20">
                                                                {this.validator.message('FirstName', this.state.first_name, 'required')}
                                                            </p>
                                                        </div>

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">LastName</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.last_name}
                                                                        onChange={this.handleChange.bind(this, "last_name")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="column">
                                                            <label className="has-text-weight-bold">Roll Number</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.roll_number}
                                                                        onChange={this.handleChange.bind(this, "roll_number")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">User Role <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <div className="select proto" style={{ minWidth: '100%' }}>
                                                                        <select
                                                                            className="proto disabled"
                                                                            value={this.state.user_role}
                                                                            onChange={this.handleChange.bind(this, "user_role")}
                                                                            style={{ minWidth: '100%' }}
                                                                        >
                                                                            {/* <option>-- Select --</option> */}
                                                                            {this.state.roleList.map((role) => {
                                                                                return (
                                                                                    <option value={role.role_id} className={role.role_id === 2 ? '' : 'hide'}>{role.role_name}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('User Role', this.state.user_role, 'required')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Phone <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    {/* <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.phone_no}
                                                                        onChange={this.handleChange.bind(this, "phone_no")}
                                                                    /> */}
                                                                    <PhoneInput
                                                                        country={'us'}
                                                                        value={this.state.phone_no}
                                                                        onChange={phone_no => this.setState({ phone_no })}
                                                                        />
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('Phone', this.state.phone_no, 'required|phone')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="column">
                                                            <label className="has-text-weight-bold">
                                                                Registration Number
                    </label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.reg_number}
                                                                        onChange={this.handleChange.bind(
                                                                            this,
                                                                            "reg_number"
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column is-6">
                                                            <label className="has-text-weight-bold">Email <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        readOnly
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.email_id}
                                                                        onChange={this.handleChange.bind(this, "email_id")}
                                                                    />
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('Email', this.state.email_id, 'required|email')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="column hide">
                                                            <label className="has-text-weight-bold">Password <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="password"
                                                                        value={this.state.password}
                                                                        onChange={this.handleChange.bind(this, "password")}
                                                                    />
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('Password', this.state.password, 'required')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="column">
                                                            <label className="has-text-weight-bold">Address</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.user_address}
                                                                        onChange={this.handleChange.bind(this, "user_address")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Aadhar Card Number</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.aadhar_number}
                                                                        onChange={this.handleChange.bind(this, "aadhar_number")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    {/* <div className="columns">
                                                        

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">City</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.user_city}
                                                                        onChange={this.handleChange.bind(this, "user_city")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">State</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.user_state}
                                                                        onChange={this.handleChange.bind(this, "user_state")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Pan Card Number</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.pan_number}
                                                                        onChange={this.handleChange.bind(this, "pan_number")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="columns">
                                                        

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Zip</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.user_zip}
                                                                        onChange={this.handleChange.bind(this, "user_zip")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">EMIS Number</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.emis_number}
                                                                        onChange={this.handleChange.bind(this, "emis_number")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Other ID's</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.other_ids}
                                                                        onChange={this.handleChange.bind(this, "other_ids")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Gender</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <label className="radio">
                                                                        <input
                                                                            type="radio"
                                                                            name="answer"
                                                                            value={'male'}
                                                                            checked={this.state.gender === 'male'}
                                                                            onChange={this.handleChange.bind(this, "gender")}
                                                                        /> 
                             {' '}Male
                        </label>
                                                                    <label className="radio">
                                                                        <input
                                                                            type="radio"
                                                                            name="answer"
                                                                            value={'female'}
                                                                            checked={this.state.gender === 'female'}
                                                                            onChange={this.handleChange.bind(this, "gender")}
                                                                        /> 
                             {' '}Female
                        </label>
                                                                    <label className="radio">
                                                                        <input
                                                                            type="radio"
                                                                            name="answer"
                                                                            value={'others'}
                                                                            checked={this.state.gender === 'others'}
                                                                            onChange={this.handleChange.bind(this, "gender")}
                                                                        /> 
                            {' '} Others
                        </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        

                                                        
                                                    </div> */}

                                                    {/* <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Note</label>
                                                            <div className="control">
                                                                <textarea
                                                                    style={{ maxHeight: '70%' }}
                                                                    className="textarea"
                                                                    value={this.state.user_notes}
                                                                    onChange={this.handleChange.bind(this, "user_notes")}
                                                                    rows='1'
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="columns mt-2 level-right">
                                                        <div className="column is-12 has-text-right">
                                                            <button className="button is-right mar-0-10" onClick={this.hideUserForm}>Cancel</button>
                                                            <button
                                                                className="button is-link"
                                                                // disabled={
                                                                //     this.state.user_role ||
                                                                //         this.state.phone ||
                                                                //         this.state.email_id ||
                                                                //         this.state.password ||
                                                                //         !this.state.user_role ||
                                                                //         !this.state.phone ||
                                                                //         !this.state.email_id ||
                                                                //         !this.state.password
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                                onClick={this.updateUser}
                                                            >
                                                                Update
              </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.passFormView ? <div className={this.state.passFormView ? 'column is-6 is-offset-3' : 'hide'}>
                                        <div className='card'>
                                            <div className='card-content'>
                                                <div className='content form-style'>
                                                    <h3>Change Password</h3>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">New Password <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="password"
                                                                        value={this.state.new_pass}
                                                                        onChange={this.handleChange.bind(this, "new_pass")}
                                                                    />
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('New Password', this.state.new_pass, 'required|min:5')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Confirm Password <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="password"
                                                                        value={this.state.confirm_pass}
                                                                        onChange={this.handleChange.bind(this, "confirm_pass")}
                                                                    />
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('confirm_pass', this.state.confirm_pass, `required|in:${this.state.new_pass}`, { messages: { in: 'Passwords need to match!' } })}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* <p className="help is-danger height20">
                                                                {this.validator.message('FirstName', this.state.first_name, 'required')}
                                                            </p> */}
                                                        </div>
                                                    </div>
                                                    <div className="columns mt-2 level-right">
                                                        <div className="column is-12 has-text-right">
                                                            <button className="button is-right mar-0-10" onClick={this.cancelReset}>Cancel</button>
                                                            <button
                                                                className="button is-link"
                                                                // disabled={
                                                                //     this.state.user_role ||
                                                                //         this.state.phone ||
                                                                //         this.state.email_id ||
                                                                //         this.state.password ||
                                                                //         !this.state.user_role ||
                                                                //         !this.state.phone ||
                                                                //         !this.state.email_id ||
                                                                //         !this.state.password
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                                onClick={this.updatePassword}
                                                            >
                                                                Update
              </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}


                                </div>
                            </div>
                        </div>
                    </div>

                    : null}


                { !this.state.updateFormView ?
                    <div className="contents is-marginless">
                        <div className="scroll-bar">
                            <div className="column">
                                <div className="columns">


                                    <div className="column is-8 is-offset-2">
                                        <div className='card'>
                                            <div className='card-content'>
                                                <div className='content form-style'>
                                                    <h3>User Creation</h3>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">FirstName <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.first_name}
                                                                        onChange={this.handleChange.bind(this, "first_name")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className="help is-danger height20">
                                                                {this.validator.message('FirstName', this.state.first_name, 'required')}
                                                            </p>
                                                        </div>

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">LastName</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.last_name}
                                                                        onChange={this.handleChange.bind(this, "last_name")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="column">
                                                            <label className="has-text-weight-bold">Roll Number</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.roll_number}
                                                                        onChange={this.handleChange.bind(this, "roll_number")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">User Role <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <div className="select proto" style={{ minWidth: '100%' }}>
                                                                        <select
                                                                            className="proto"
                                                                            value={this.state.user_role}
                                                                            onChange={this.handleChange.bind(this, "user_role")}
                                                                            style={{ minWidth: '100%' }}
                                                                        >
                                                                            <option>-- Select --</option>
                                                                            {this.state.roleList.map((role) => {
                                                                                return (
                                                                                    <option value={role.role_id} className={role.role_id === 1 ? 'hide' : null}>{role.role_name}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('User Role', this.state.user_role, 'required')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Phone <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    {/* <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.phone_no}
                                                                        onChange={this.handleChange.bind(this, "phone_no")}
                                                                    /> */}
                                                                    <PhoneInput
                                                                        country={'us'}
                                                                        value={this.state.phone_no}
                                                                        onChange={phone_no => this.setState({ phone_no })}
                                                                        />
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('Phone', this.state.phone_no, 'required|phone')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="column">
                                                            <label className="has-text-weight-bold">
                                                                Registration Number
                    </label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.reg_number}
                                                                        onChange={this.handleChange.bind(
                                                                            this,
                                                                            "reg_number"
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Email <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.email_id}
                                                                        onChange={this.handleChange.bind(this, "email_id")}
                                                                    />
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('Email', this.state.email_id, 'required|email')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Password <span className='required'>*</span></label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="password"
                                                                        value={this.state.password}
                                                                        onChange={this.handleChange.bind(this, "password")}
                                                                    />
                                                                    <PasswordStrengthBar password={this.state.password} />
                                                                    <p className="help is-danger height20">
                                                                        {this.validator.message('Password', this.state.password, 'required')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="column">
                                                            <label className="has-text-weight-bold">Aadhar Card Number</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.aadhar_number}
                                                                        onChange={this.handleChange.bind(this, "aadhar_number")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    {/* <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Address</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.user_address}
                                                                        onChange={this.handleChange.bind(this, "user_address")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">City</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.user_city}
                                                                        onChange={this.handleChange.bind(this, "user_city")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Pan Card Number</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.pan_number}
                                                                        onChange={this.handleChange.bind(this, "pan_number")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">State</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.user_state}
                                                                        onChange={this.handleChange.bind(this, "user_state")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Zip</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.user_zip}
                                                                        onChange={this.handleChange.bind(this, "user_zip")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">EMIS Number</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.emis_number}
                                                                        onChange={this.handleChange.bind(this, "emis_number")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="columns">
                                                        {/* <div className="column">
                                                            <label className="has-text-weight-bold">Gender</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <label className="radio">
                                                                        <input
                                                                            type="radio"
                                                                            name="answer"
                                                                            value={'male'}
                                                                            onChange={this.handleChange.bind(this, "gender")}
                                                                        /> 
                             {' '}Male
                        </label>
                                                                    <label className="radio">
                                                                        <input
                                                                            type="radio"
                                                                            name="answer"
                                                                            value={'female'}
                                                                            onChange={this.handleChange.bind(this, "gender")}
                                                                        /> 
                             {' '}Female
                        </label>
                                                                    <label className="radio">
                                                                        <input
                                                                            type="radio"
                                                                            name="answer"
                                                                            value={'others'}
                                                                            onChange={this.handleChange.bind(this, "gender")}
                                                                        /> 
                            {' '} Others
                        </label>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        <div className="column is-6">
                                                            <label className="has-text-weight-bold">Class Room</label>
                                                            <div className="field">
                                                                <div className="select proto" style={{ minWidth: '100%' }}>
                                                                    <select
                                                                        className="proto"
                                                                        value={this.state.class_group}
                                                                        style={{ minWidth: '100%' }}
                                                                        onChange={this.handleChange.bind(this, "class_group")}
                                                                    >
                                                                        <option>-- Select Class Group --</option>
                                                                        {this.state.classList.map((room) => {
                                                                            return (
                                                                                <option value={room.group_id}>{room.full_name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="column">
                                                            <label className="has-text-weight-bold">Other ID's</label>
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input
                                                                        className="input"
                                                                        type="text"
                                                                        value={this.state.other_ids}
                                                                        onChange={this.handleChange.bind(this, "other_ids")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    {/* <div className="columns">
                                                        <div className="column">
                                                            <label className="has-text-weight-bold">Note</label>
                                                            <div className="control">
                                                                <textarea
                                                                    style={{ maxHeight: '70%' }}
                                                                    className="textarea"
                                                                    value={this.state.user_notes}
                                                                    onChange={this.handleChange.bind(this, "user_notes")}
                                                                    rows='1'
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="columns mt-2 level-right">
                                                        <div className="column is-12 has-text-right">
                                                            <button className="button is-right mar-0-10" onClick={this.hideUserForm}>Cancel</button>
                                                            <button
                                                                className="button is-link"
                                                                // disabled={
                                                                //     this.state.user_role ||
                                                                //         this.state.phone ||
                                                                //         this.state.email_id ||
                                                                //         this.state.password ||
                                                                //         !this.state.user_role ||
                                                                //         !this.state.phone ||
                                                                //         !this.state.email_id ||
                                                                //         !this.state.password
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                                onClick={this.createNewUser}
                                                            >
                                                                Save
              </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

                <div className={this.state.deleteModal ? 'is-active modal delete-modal' : 'hide'}>
                    <div class="modal-background"></div>
                    <div class="modal-content" style={{ paddingLeft: 15, paddingRight: 15, width: 540 }}>
                        <h2>Confirmation</h2>
                        <p>Are you sure want to Delete?</p>
                        <div class="column has-text-right">
                            <button type="button" class="button is-right mar-0-10" onClick={this.deleteCancelModal}>Cancel</button>
                            <button type="submit" class="button is-danger is-right" onClick={this.deleteUser}>Delete</button>
                        </div>
                    </div>
                    <button class="modal-close is-large" aria-label="close"></button>
                </div>
            </div>
        );
    }
}

export default CreateUserForm;
