import { json } from 'body-parser';
import React, { Component } from 'react';
import SocketHandler from '../helpers/socket-service';
class Whiteboard extends Component {
  
  constructor(props) {
    super(props);
    this.state = {currentPage:'0',selectedTool:'Pencil',selectedStrock:5,selectedColor:'#000000',toolExpanded:false,currentWbUser:''};
    this.room = '';
    this.userId = this.props.userId;
    this.userName = this.props.userName;
    this.pageObjects = {};
    this.wbAllowed = this.props.wbAllowed
    SocketHandler.getSocket((socket)=>{
        socket.on('onInitialData',(data) => {
            data =  JSON.parse(data);
            this.onInitialData(data)
        });
        socket.on('onReceiveSendToAll',(data) => {
            data =  JSON.parse(data);
            switch (data.method) {
                case 'whiteboardData':
                  console.log('receive : '+JSON.stringify(data));
                  if(data.room === this.room){
                    if(data.fullBoard){
                        delete this.pageObjects[this.state.currentPage] ;
                        this.clearBoard();
                    }
                    let fullData = this.getFullWbDataFromLastShape(data.wbData);
                    if(data.clearAll == 1){
                        fullData.shapes = [];
                    }
                    
                    this.pageObjects[this.state.currentPage] = fullData;
                    if(data.fullBoard){
                      this.loadWhiteboardData()
                    }else{
                      this.loadWhiteboardData()
                    }
                    
                  }
                break;
                case 'wbstart':
                  if(data.room === this.room){
                      this.setState({currentWbUser:data.name+' is using the whiteboard'})
                  }
                break;
                case 'wbstop':
                  if(data.room === this.room){
                    this.setState({currentWbUser:''})
                  }
                break;
                
            }
        });
    })
  }
  updateRoom = (room)=>{
    this.room = room;
}
sendFullBoard = () => {
  if(this.wbAllowed){
    setTimeout(() => {
        var dt = window.demoLC.getSnapshot();
        let data = {};
        data.page = this.state.currentPage;
        data.method = 'whiteboardData';
        data.room = this.room;
        data.wbData = this.getFullBoardData(dt);
        data.clearAll = 0;
        data.fullBoard = true;
        console.log('data = '+JSON.stringify(data))
        this.pageObjects[this.state.currentPage] = dt;
        SocketHandler.sendToAll(data)
        //localStorage.setItem('wbdata-'+roomSlotId,JSON.stringify(whiteboardArray))

    }, 1000)
  }
}
sendBoard = () => {
  if(this.wbAllowed){
    setTimeout( () =>{
        var dt = window.demoLC.getSnapshot();
        let data = {};
        data.page = this.state.currentPage;
        data.method = 'whiteboardData';
        data.wbData = this.getLastUpdatedData(dt);
        data.clearAll = 0;
        if(dt.shapes.length == 0){
            data.clearAll = 1;
        }  
        data.room = this.room;
        data.currentPage = this.state.currentPage;
        console.log('last shape = '+JSON.stringify(data))
        SocketHandler.sendToAll(data);
        var wbwidth   = document.getElementById('wb').clientWidth;
        var wbheight  = document.getElementById('wb').clientHeight;
        for(let k in dt.shapes){
          if(!dt.shapes[k].hasOwnProperty('senderWbHeight')){
              dt.shapes[k].senderWbHeight = wbheight;
              dt.shapes[k].senderWbWidth  = wbwidth;
          }
      }

        this.pageObjects[this.state.currentPage] = dt;
    },500)
  }
}
getFullBoardData = (fullDataOrig) => {
  var wbwidth = document.getElementById('wb').clientWidth;
  var wbheight = document.getElementById('wb').clientHeight;
  var fullData = fullDataOrig.shapes;
  let newShapes = [];
  for (let index in fullData) {
      let shapeObj = fullData[index]
      shapeObj.senderWbHeight = wbheight;
      shapeObj.senderWbWidth = wbwidth;
      newShapes.push(shapeObj);
  }
  return newShapes;
}
/**
 * function to get last updated whiteboard object
 */
getLastUpdatedData = (fullDataOrig) =>{
  let exstingShapeIds = {};
  if(this.pageObjects[this.state.currentPage]){
     let existingFullData = this.pageObjects[this.state.currentPage].shapes;
     for(let existingIndex in existingFullData){
         let existingShapeObj = existingFullData[existingIndex]
         exstingShapeIds[existingShapeObj.id] = existingShapeObj;
     }
  }
  console.log('Existing shapes = '+JSON.stringify(exstingShapeIds))
  var wbwidth   = document.getElementById('wb').clientWidth;
  var wbheight  = document.getElementById('wb').clientHeight;
  var fullData = fullDataOrig.shapes;
  let newShapes = [];
  for(let index in fullData){
      let shapeObj = fullData[index]
      if(exstingShapeIds.hasOwnProperty(shapeObj.id)){
          if(shapeObj.className == 'LinePath'){
              if(exstingShapeIds[shapeObj.id].data.pointCoordinatePairs[0][0] != shapeObj.data.pointCoordinatePairs[0][0]){
                     shapeObj.senderWbHeight = wbwidth;
                     shapeObj.senderWbWidth  = wbwidth;
                     newShapes.push(shapeObj);
              }
              
          }else {
              if((exstingShapeIds[shapeObj.id].data.x + exstingShapeIds[shapeObj.id].data.y) != (shapeObj.data.x + shapeObj.data.y)){
                     shapeObj.senderWbHeight = wbheight;
                     shapeObj.senderWbWidth =  wbwidth;
                     newShapes.push(shapeObj);
              }
             
         }
      }else{
         shapeObj.senderWbHeight = wbheight;
         shapeObj.senderWbWidth = wbwidth;
         newShapes.push(shapeObj);
      }
  }
  return newShapes;
}
/**
* function get entire objects at receiver side 
*/
getFullWbDataFromLastShape = (lastShape) =>{
 if(this.pageObjects[this.state.currentPage]){
     var dt = this.pageObjects[this.state.currentPage];
 }else{
     var dt = window.demoLC.getSnapshot();
 }
 if(lastShape.length > 0 ){
     for(let index in lastShape){
         let newshape = lastShape[index];
         let shapeFound = false;
         for(let index2 in dt.shapes){
             let existShape = dt.shapes[index2];
             if(newshape.id == existShape.id){
                 dt.shapes[index2] = newshape;
                 shapeFound = true;
             }
         }
         if(shapeFound == false){
             dt.shapes.push(newshape);
         }
     }
 }
 return dt;
}

whiteboardDocPageSwitch = () =>{
  setTimeout(() => {
    if(this.pageObjects.hasOwnProperty(this.state.currentPage)){
        var newSnap = {};
        
        newSnap = this.goclone(this.pageObjects[this.state.currentPage]);
        //console.log(JSON.stringify(this.recalculateCoordinate(newSnap)))
        window.demoLC.loadSnapshot(this.recalculateCoordinate(newSnap,true));
        // alert('orgObj = '+JSON.stringify(this.pageObjects[this.state.currentPage]))
        // alert('dupObj = '+JSON.stringify(newSnap))
    }else{
        window.demoLC.clear();
    }
  }, 1000);
}
goclone = (source) =>{
  if (Object.prototype.toString.call(source) === '[object Array]') {
      var clone = [];
      for (var i=0; i<source.length; i++) {
          clone[i] = this.goclone(source[i]);
      }
      return clone;
  } else if (typeof(source)=="object") {
      var clone = {};
      for (var prop in source) {
          if (source.hasOwnProperty(prop)) {
              clone[prop] = this.goclone(source[prop]);
          }
      }
      return clone;
  } else {
      return source;
  }
}
/**
* function load   whiteboard data from dictionary 
*/
loadWhiteboardData = (timeout) =>{
  if(timeout){
    setTimeout(() => {
      let fullData = this.recalculateCoordinate(this.pageObjects[this.state.currentPage],false)
       window.demoLC.loadSnapshot(fullData);
    }, 1000);
  }else{
    let fullData = this.recalculateCoordinate(this.pageObjects[this.state.currentPage],false)
    window.demoLC.loadSnapshot(fullData);
  }
  
 
}
/**
 * function recalculate object size based on screen size
 */
recalculateCoordinate = (wbdata,local) =>{
  var shapeArray = wbdata.shapes;
  var newShapeArray = [];
  if(shapeArray){
  for (var i = 0; i < shapeArray.length; i++) {
      var shapeObj = shapeArray[i];
      if(!shapeObj.reCalculated || local){
          if (shapeObj.className == 'LinePath') {
              var pointCodArray = shapeObj.data.pointCoordinatePairs;
              var newPointCodArray = [];
              for (var j = 0; j < pointCodArray.length; j++) {
                  var points = pointCodArray[j];
                  var newX = this.recalculateX(points[0], shapeObj.senderWbWidth);
                  var newY = this.recalculateY(points[1], shapeObj.senderWbHeight) - 15;
                  points = [];
                  points.push(newX);
                  points.push(newY);
                  newPointCodArray.push(points);
              }
              shapeObj.data.pointCoordinatePairs = newPointCodArray;

              // reset next point array

              var smoothPointCodArray = shapeObj.data.smoothedPointCoordinatePairs;
              var newSmoothPointCodArray = [];
              for (var j = 0; j < smoothPointCodArray.length; j++) {
                  var points = smoothPointCodArray[j];
                  var newX = this.recalculateX(points[0], shapeObj.senderWbWidth);
                  var newY = this.recalculateY(points[1], shapeObj.senderWbHeight);
                  points = [];
                  points.push(newX);
                  points.push(newY);
                  newSmoothPointCodArray.push(points);
              }
              shapeObj.data.smoothedPointCoordinatePairs = newSmoothPointCodArray;
          }
          if (shapeObj.className == 'Ellipse' || shapeObj.className == 'Rectangle' || shapeObj.className == 'Text') {
              shapeObj.data.x = this.recalculateX(shapeObj.data.x, shapeObj.senderWbWidth);
              shapeObj.data.width = this.recalculateX(shapeObj.data.width, shapeObj.senderWbWidth);
              shapeObj.data.y = this.recalculateY(shapeObj.data.y, shapeObj.senderWbHeight);
              shapeObj.data.height = this.recalculateY(shapeObj.data.height, shapeObj.senderWbHeight);
          }
          if (shapeObj.className == 'Line') {
              shapeObj.data.x1 = this.recalculateX(shapeObj.data.x1, shapeObj.senderWbWidth);
              shapeObj.data.x2 = this.recalculateX(shapeObj.data.x2, shapeObj.senderWbWidth);
              shapeObj.data.y1 = this.recalculateY(shapeObj.data.y1, shapeObj.senderWbHeight);
              shapeObj.data.y2 = this.recalculateY(shapeObj.data.y2, shapeObj.senderWbHeight);
          }
          if(shapeObj.className == 'Image'){
              shapeObj.data.x = this.recalculateX(shapeObj.data.x, shapeObj.senderWbWidth);
              shapeObj.data.y = this.recalculateY(shapeObj.data.y, shapeObj.senderWbHeight);
              shapeObj.data.width = this.recalculateX(shapeObj.data.width, shapeObj.senderWbWidth);
              shapeObj.data.height = this.recalculateY(shapeObj.data.height, shapeObj.senderWbHeight);
          }
      }
      shapeObj.reCalculated = true;
      newShapeArray.push(shapeObj);
  }
 }
 wbdata.shapes = newShapeArray;
  return wbdata;
}
recalculateX = (xVal, senderWbWidth) =>{
  var wbWidthHere = document.getElementById('wb').clientWidth;
  var newX = (xVal / senderWbWidth) * wbWidthHere;
  return newX;

}
recalculateY = (yVal, senderWbHeight) => {
  var wbHeightHere = document.getElementById('wb').clientHeight;
  var newY = (yVal / senderWbHeight) * wbHeightHere;
  newY = newY;
  return newY;
}
  onInitialData = (data)=>{
    if(data.whiteboardData){
       this.state.currentPage = data.whiteboardData.page
       let whiteboardArrayHere     = data.whiteboardData.whiteboardArray;
       let recreatedWhiteboardArray = {};
       for(let itx in whiteboardArrayHere){
           var dt = window.demoLC.getSnapshot()
           dt.shapes = [];
           recreatedWhiteboardArray[itx] = dt;
           dt.shapes = whiteboardArrayHere[itx]
       }
       this.pageObjects = recreatedWhiteboardArray;
       this.loadWhiteboardData(true);
    }
}
  componentDidMount = ()=> {
      const LC = window.LC;
      const LiterallyCanvas = window.LC.LiterallyCanvas;
      const mainDiv = document.querySelector('.literally');
      const lc = new LiterallyCanvas(mainDiv, {
        ...LC.defaultOptions,
        defaultStrokeWidth: 5,
        backgroundColor: '#FFF',
        tools:[window.LC.Pencil,window.LC.Line,window.LC.Eraser]
      });
      console.log(lc)
      window.demoLC = lc;
      // Tools
      window.Pencil = new window.LC.Pencil(lc);
      window.Line = new window.LC.Line(lc);
      window.Eraser = new window.LC.Eraser(lc,{strokeWidth:20});
      window.Ellipse = new window.LC.Ellipse(lc);
      window.Pan  = new window.LC.Pan(lc);
      window.Rectangle  = new window.LC.Rectangle(lc);
      window.SelectShape  = new window.LC.SelectShape(lc);
      window.Eyedropper  = new window.LC.Eyedropper(lc);
      window.Text  = new window.LC.Text(lc);
      window.Polygon  = new window.LC.Polygon(lc);
  }
  updatePage = (page) =>{
    this.setState({currentPage:page})
    window.demoLC.clear();
    if(this.pageObjects[page]){
      window.demoLC.loadSnapshot(this.pageObjects[page]);
    }
  }
  changeCanvasSize = () => {
    setTimeout(() => {
        let canvases =  document.getElementById("wbpar").getElementsByTagName("canvas");
        for(let i = 0; i<canvases.length; i++){
            canvases[i].style.width = document.getElementById("wb").offsetWidth+"px";
            document.getElementById("wbpar").style.width = document.getElementById("wb").offsetWidth+"px";
        }
        if(this.pageObjects[this.state.currentPage]){
          window.demoLC.loadSnapshot(this.pageObjects[this.state.currentPage]);
        }
    }, 100);
    
    //alert(canvases.length)
}
  setTool = (tool)=>{
    this.setState({selectedTool:tool})
    
    if(tool == 'Eraser'){
      setTimeout(() => {
        window.demoLC.trigger('setStrokeWidth', 50)
      }, 500);
    }
    window.demoLC.setTool(window[tool])
    window.demoLC.setColor('background', 'rgb(255 255 255 / 0%)')
    window.demoLC.setColor('primary', '#000')
    window.demoLC.setColor('secondary', 'rgb(255 255 255 / 0%)')
    window.demoLC.trigger('setStrokeWidth', this.state.selectedStrock)
    window.demoLC.trigger("setFont", "bold "+(this.state.selectedStrock * 4 )+"px sans-serif");
  }
  setStrock = (strockWidth) =>{
    this.setState({selectedStrock:strockWidth})
    window.demoLC.trigger('setStrokeWidth', strockWidth)
    window.demoLC.trigger("setFont", "bold "+(strockWidth * 4 )+"px sans-serif");
    
  }
  setColor = () =>{
    let color = document.getElementById('html5colorpicker').value;
    this.setState({selectedColor:color})
    window.demoLC.setColor('primary', color)
  }
  onUndoBoard = () =>{
    window.demoLC.undo();
    this.sendFullBoard();
  }

  onRdoBoard = () =>{
    window.demoLC.redo();
    this.sendBoard();
  }
  
  onMouseDown = () =>{
     SocketHandler.sendToAll({method:'wbstart',name:this.userName,room:this.room});
     window.demoLC.trigger("setFont", "bold "+(this.state.selectedStrock * 4 )+"px sans-serif");
  }
  onMouseUp = () =>{
    this.sendBoard();
    if(window.wbtmout){
      clearTimeout(window.wbtmout)
    }
    window.wbtmout =  setTimeout(() => {
      SocketHandler.sendToAll({method:'wbstop',room:this.room});
    }, 10000);
  }
  onMouseUpLeave = () =>{
    //this.onMouseUp();
  }
  clearDict = () =>{
      this.pageObjects = {};
  }
  setWbAllowed(wbAllowed){
    this.wbAllowed = wbAllowed;
  }
  
  tryDraw = () =>{
      if(this.state.currentWbUser){
        this.props.showNotification(this.state.currentWbUser+', please try when he stops')
      }
  }
  clearBoardClick = () =>{
    this.clearBoard();
    this.sendBoard();
  }
  clearBoard = () =>{
    window.demoLC.clear();
  }
  onToolHideShow = () =>{
    this.setState(prevState => ({
      toolExpanded: !prevState.toolExpanded
    }));
  }
  saveSnapshot = () =>{
      let wb = document.getElementById('wbpar')
      let canvas = wb.childNodes[1];
      var rawImageData = canvas.toDataURL("image/png;base64");
      rawImageData = rawImageData.replace("image/png", "image/octet-stream");
      document.getElementById('image-downlaod').setAttribute('href',rawImageData);
      this.dwon.click();
      //document.location.href = rawImageData;
  }
  render() {
    return (
        <section className="wb-wrap" onMouseDown={this.tryDraw} onTouchStart={this.tryDraw}> 
          <div className="wbusr">{this.state.currentWbUser}</div>
            <div id="wbpar" className={this.state.currentWbUser ?  "literally wbinactive":"literally"} onMouseDown={this.onMouseDown} onTouchStart={this.onMouseDown} onMouseLeave={this.onMouseUpLeave} onMouseUp={this.onMouseUp} onTouchEnd={this.onMouseUp}></div>
            <div className="tools">
              
                <button data-tip={this.state.toolExpanded ? "Hide Tools":"Expand Tools"} onClick={this.onToolHideShow}> <i  className="fa fa-bars" /></button>
                <section className={this.state.toolExpanded ? "tool-pa":"hide"}>
              <div className="tool-left">
                <button className="hide" onClick={this.refreshWbData} />
                <button data-tip="Pencil" className={this.state.selectedTool === 'Pencil' ? 'active':''} onClick={this.setTool.bind(this,'Pencil')}> <i  className="fa fa-pencil" /></button>
                <button data-tip="Line" className={this.state.selectedTool === 'Line' ? 'active':''} onClick={this.setTool.bind(this,'Line')}><i  className="fa fa-window-minimize" /></button>
                <button data-tip="Ellipse" className={this.state.selectedTool === 'Ellipse' ? 'active':''} onClick={this.setTool.bind(this,'Ellipse')}><i  className="fa fa-circle-o" /></button>
                <button data-tip="Eraser" className={this.state.selectedTool === 'Eraser' ? 'active':''} onClick={this.setTool.bind(this,'Eraser')}><i  className="fa fa-eraser" /></button>
                <button data-tip="Rectangle" className={this.state.selectedTool === 'Rectangle' ? 'active':''} onClick={this.setTool.bind(this,'Rectangle')}><i  className="fa fa-square-o" /></button>
                <button data-tip="Select And Move" className={this.state.selectedTool === 'SelectShape' ? 'active':''} onClick={this.setTool.bind(this,'SelectShape')}><i  className="fa fa-mouse-pointer" /></button>
                <button data-tip="Clear Board" onClick={this.clearBoardClick} > <i  className="fa fa-times" /></button>
                <button data-tip="Move All" className={this.state.selectedTool === 'Pan' ? 'active':''} onClick={this.setTool.bind(this,'Pan')}><i  className="fa fa-arrows" /></button>
                <button data-tip="Text" className={this.state.selectedTool === 'Text' ? 'margin-bottom-5 active':'margin-bottom-5'} onClick={this.setTool.bind(this,'Text')}><i  className="fa fa-font" /></button>
                <button data-tip="Download Board" onClick={this.saveSnapshot} > <i  className="fa fa-save" /></button>
                <button data-tip="Undo" onClick={this.onUndoBoard} > <i  className="fa fa-undo" /></button>
                  <button data-tip="Redo" onClick={this.onRdoBoard} > <i  className="fa fa-repeat" /></button>
              
                   <button data-tip="Pen" className="hide" onClick={this.setTool.bind(this,'Eyedropper')} ><i  className="fa fa-pencil" /></button>
                  <button className="hide" onClick={this.setTool.bind(this,'Polygon')} title="Polygon"><i  className="fa fa-pencil" /></button>
                  <input data-tip="Choose Color" onChange={this.setColor} class="inp-color" type="color" id="html5colorpicker" value={this.state.selectedColor}/>
                  <button data-tip="10px" className={this.state.selectedStrock === 5 ? 'active':''} onClick={this.setStrock.bind(this,5)} title="10px" >10</button>
                  <button data-tip="20px" className={this.state.selectedStrock === 10 ? 'active':''} onClick={this.setStrock.bind(this,10)} title="20px">20 </button>
                  <button data-tip="30px" className={this.state.selectedStrock === 20 ? 'active':''} onClick={this.setStrock.bind(this,20)} title="30px">30 </button>
              </div>
              
                 </section>
                

            </div>
            <a id="image-downlaod" ref={(dwon) => this.dwon = dwon} download="whitebaord.png" target="_blank"></a>
        </section>
      )
    
  }
}
export default Whiteboard;
