import React, { Component } from 'react';
import RequestHandler from '../helpers/request-handler';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
class NewChatUserList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {userList:[],selecteUser:{}}
    
  }
  

  componentDidMount = ()=> {
    this.getAllUsers();
  }
  
  componentWillUnmount = ()=> {
    
  }
  getAllUsers = ()=>{
    RequestHandler.get(Config.API_DOMAIN+'/canstring/v1/chats/users?offset=0&limit=25&search=',(response)=>{
            if(response.success === true){
                this.setState({userList:response.userList})
            }else{
                console.log(response.message);
            } 
    })
  }
  onCloseChat = ()=>{
      this.props.onCloseChat();
      this.setState({selecteUser:{}})
  }
  selectChatUser = (user) =>{
      this.setState({selecteUser:user})
  }
  onStartChat = ()=>{
      if(this.state.selecteUser.user_id){
          
          let user = this.state.selecteUser;
          user.full_name = user.first_name+' '+user.last_name;
        const payload = {
            chat_type: 'user',
            to_user_id: user.user_id
        }
          RequestHandler.post(Config.API_DOMAIN+'/canstring/v1/chats/createChat',payload,(response)=>{
            if(response.success === true){
                this.props.updateChatList();
                this.props.onSelectChatUser(user);
                this.onCloseChat();
            }else{
            }
            
            })
         
      }
  }
  render() {
    return (
        <div className="flex-grid width100 newchat-backdrop">
            <div className="col th-p">
                <div className="contents is-marginless">
                    <div className="scroll-bar">
                        <div className="column">
                        <div className="columns">
                            <div className="column is-half is-offset-one-quarter">
                                <div className="columns new-chat mar-top15">
                                    <div className="column">
                                    <div className="card">
                                        <div className="card-content">
                                            <div className="content form-style">
                                                <div className="columns">
                                                    <h3 className="botton35 pad-10">New Chat</h3>
                                                    <button onClick={this.onCloseChat} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                </div>
                                                <div className="columns">
                                                <div className="column is-12 hide">
                                                    <div className="field">
                                                        <div className="control has-icons-left has-icons-right"><input className="input ng-pristine ng-valid ng-touched" inputfocus="" placeholder="Search Here..." type="text"/><span className="icon is-small is-left"><i className="fa fa-search"></i></span><span className="icon is-small is-right"><i className="fa fa-close"></i></span></div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="tabs is-boxed">
                                                <ul>
                                                    <li className="is-active"><a> All Users </a></li>
                                                    {/*<li className="hide"><a> Frequent Chated Users </a></li>*/}
                                                </ul>
                                                </div>
                                                
                                                <div className="columns userList">
                                                <div className="column">
                                                    <div className="field pos-relative scroll-bar" infinite-scroll="">
                                                        <div className="panel">
                                                            {
                                                                this.state.userList.map((user,index)=>{
                                                                    return(
                                                                        <label key={index} className="panel-block pos-relative" onClick={this.selectChatUser.bind(this,user)}>
                                                                            <div className="chat-user-status">
                                                                                <div className={user.user_icon?"hide":"display-ib"}><div className="empty-img">{user.first_name.charAt(0).toUpperCase()}</div></div>
                                                                                <img className={user.user_icon?"image is-48x48":"hide"} src={user.user_icon}/><span className="icon is-small"><i className={user.is_online ? "fa fa-circle circle-green":"fa fa-circle circle-grey"}></i></span>
                                                                            </div>
                                                                            <div className="mar-0-10">
                                                                                <span className="display-b"> {user.first_name+' '+user.last_name}</span>
                                                                                <p className="is-size-7 text-clr-grey">{CommonFunctions.getAgo(user.last_seen)} ago</p>
                                                                                
                                                                            </div>
{/* 
                                                                            <div className="mar-0-10 select-usr">
                                                                                <span className="display-b"> <input type="checkbox" /></span>
                                                                            </div>
                                                                            */}
                                                                            <span class={this.state.selecteUser.user_id == user.user_id ? "icon is-small icon-align is-pulled-right":"hide"}><i class="fa fa-check"></i></span>
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="columns is-clearfix">
                                                <div className="column pad-b-0">
                                                    <div className="has-text-right is-pulled-right"><button onClick={this.onCloseChat} className="button mar-5" type="button">Cancel</button><button onClick={this.onStartChat} className="button is-link mar-5-0" disabled="">Chat</button></div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
      )
    
  }
}
export default NewChatUserList;
