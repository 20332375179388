import React, { Component } from 'react';
import Config from '../config';
import CommonFunctions from '../helpers/common-functions';
import SocketHandler from '../helpers/socket-service';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';
import moment from 'moment';
class GroupUserListing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            memberList: []
        }
    }

    closeView = () => {
        this.props.hideGroupList()
    }

    groupUserList = () => {
        RequestHandler.get(Config.API_DOMAIN + '/canstring/v1/chats/groupMemberList?offset=0&limit=25&groupId=' + this.props.slectedGroup.group_id, (response) => {
            if (response.success === true) {
                this.setState({ memberList: response.groupMemberList })
            } else {
                console.log(response.message);
            }
        });
    }

    componentDidMount = () => {
        this.groupUserList();
    }



    render() {
        this.currentUser = JSON.parse(localStorage.getItem('currentuserData'));
        return (

            <div>
                <div class="col fo-p">

                    <div class="is-centered is-fullwidth">
                        <div class="tabsPanel is-active">
                            <a>
                                <span class="icon is-small">
                                    <i class="fa fa-users"></i>
                                </span>
                                <span>Users
                                    <a title="Close">
                                        <span class="icon is-small closeIcon">
                                            <i class="fa fa-times" onClick={this.closeView.bind()}></i>
                                        </span>
                                    </a>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="list-wrapper scroll-bar list-overflow">
                        {/* <div class="menu-options level is-marginless display-flex">
                            <div class="level-left">
                            </div>
                            <div class="level-right">
                                <div class="level-item">
                                    <a class="is-small" title="Add Members">
                                        <i class="fa fa-user-plus"></i>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        <ul class="group-members">
                            {this.state.memberList.map((user, index) => {
                                return (
                                    <li class="cursor-pointer pos-relative">
                                        <div class="columns is-gapless ">
                                            <div class="column is-one-quarter">
                                                <div className='empty-img'>{user.full_name.charAt(0).toUpperCase()}</div>
                                                <span class="icon is-small">
                                                    <i className={user.is_online === true ? 'fa fa-circle circle-green' : 'hide'} ></i>
                                                    <i className={user.is_online === false ? 'fa fa-circle circle-grey' : 'hide'} ></i>
                                                </span>
                                            </div>
                                            <div class="column is-clearfix pos-relative">
                                                <div class="is-pulled-left">
                                                    <span class="display-b">{user.full_name}</span>
                                                    <p className={user.is_online === true ? 'is-size-7 has-text-success' : 'hide'}>online</p>
                                                    <p className={user.is_online === false ? 'is-size-7 text-clr-grey' : 'hide'}>{CommonFunctions.getAgo(user.last_seen)} ago</p>
                                                    {/* <p class="is-size-7 text-clr-grey"></p> */}
                                                    <a className={user.is_group_admin === true ? 'button is-primary is-outlined is-small time-ago' : 'hide'}>Admin</a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="chat-close" >
                                    <i class="fa fa-times" title="Remove Chat"></i>
                                </div> */}
                                    </li>
                                )
                            }
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default GroupUserListing;
