import React, { Component } from 'react';
import UserSession from '../helpers/user-session';
import RequestHandler from '../helpers/request-handler';
import Config from '../config';
import SocketHandler from '../helpers/socket-service';
import { BrowserRouter, Link, Redirect, Route } from 'react-router-dom';
class Header extends Component {
  
  constructor(props) {
    super(props);
    let first_name = '';
    if(UserSession.getSession().userDetail && !this.props.public){
      first_name = UserSession.getSession().userDetail.first_name;
    }else{
        first_name = 'Guest';
    }
    this.state = {first_name:first_name,public:this.props.public, currentuserData:{}, userData: {}}
    if(!this.props.public){
        RequestHandler.get(Config.API_DOMAIN+'/canstring/v1/users/currentUser',(response)=>{
            if(response.success === true){
                let currentUserSession =  UserSession.getSession();
                localStorage.setItem('currentuserData',JSON.stringify(response))
                /*
                if(response.userDetail.user_id == 5){
                    response.userDetail.user_id = 8;
                }
                */
               if(!currentUserSession.device) {
                currentUserSession.device = '';
               }
               currentUserSession.userDetail = response.userDetail;
               UserSession.setSession(currentUserSession)
               this.setState({first_name:UserSession.getSession().userDetail.first_name,userData: {...currentUserSession}, currentuserData: {...response.userDetail}})
               this.props.onSetSession();
                return <Route exact> <Redirect
                to={{
                  pathname: "/home"
                }}
              /> </Route>
            }else{
                this.onLogout()
            } 
        })
    }
  }
  onGetRecordings = () =>{
      if(this.props.onGetRecordings){
          this.props.onGetRecordings();
      }
  }

  componentDidMount = ()=> {
    
  }
  
  componentWillUnmount = ()=> {
    
  }
  onLogout = ()=>{
    RequestHandler.post(Config.API_DOMAIN+'/canstring/v1/users/status',{status_id: 0, user_id: UserSession.getSession().user_id},(response)=>{
          
    })
    UserSession.destroySession();
    this.props.onLogout();
    
  }
  render() {
    return (
        <div>
            <div className="header-wrapper">
                <nav className="navbar">
                    <div className="navbar-start"><a className="menu-bar"><i className="fa fa-bars" aria-hidden="true"></i></a>
                    <a href="/" className="nav-item pointer"><img alt="CanString logo" src="/files/pure-logo.png" /></a><a href="/" className="pad-13-10 is-size-5 has-text-white logotxt"><strong>CanString Meet</strong></a>
                    </div>
                    <div className="nav-center"></div>
                    <div className="navbar-end">
                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className={this.props.public || this.state.userData.device === 'mobile' ? "navbar-link nodrop hide":"navbar-link"}>
                            <div className="empty-head-icon">{this.state.first_name.charAt(0).toUpperCase()}</div>
                            <span className="icon is-small"><i className="fa fa-circle circle-green"></i></span>
                        </a>
                        <div className={this.props.public || this.state.userData.device === 'mobile' ? "hide":"navbar-dropdown is-right"}>
                            <a className="nested navbar-item dropdown hide">
                            <a aria-controls="dropdown-menu" aria-haspopup="true" className="dropdown-trigger hide" ><i className="fa fa-circle circle-green"></i> Available </a>
                            <div className="dropdown-menu hide" id="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                <p className="has-text-grey">Default Status</p>
                                <p><a className="dropdown-item" title="Available" ><i className="fa fa-circle circle-green"></i> Available </a></p>
                                <p><a className="dropdown-item" title="Busy"><i className="fa fa-circle circle-red" ></i> Busy </a></p>
                                <p><a className="dropdown-item" title="Away"><i className="fa fa-circle circle-yellow" ></i> Away </a></p>
                                <p><a className="dropdown-item" title="Out Of Office"><i className="fa fa-circle circle-grey"></i> Out Of Office </a></p>
                                </div>
                            </div>
                            </a>
                           
                            <a class="navbar-item"><i class="fa fa-user"></i>Welcome {this.state.first_name} </a>
                            <hr className="navbar-divider"/>
                            <Link to="/record" class="navbar-item"><i class="fa fa-tasks"></i>Recorder App </Link>
                            {/* <hr className={this.state.currentuserData.user_role === 1 ? 'navbar-divider':'hide'}/>
                            <a className={this.state.currentuserData.user_role === 1 ? 'navbar-item':'hide'} onClick={this.onGetRecordings}><i class="fa fa-save"></i>Recordings</a> */}
                            <hr className="navbar-divider"/>
                            <a onClick={this.onLogout} className="navbar-item"><i className="fa fa-sign-out"></i> Log out </a>
                        </div>
                    </div>
                    </div>
                </nav>
            </div>
        </div>
      )
    
  }
}
export default Header;
